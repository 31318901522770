module.exports = [
  {
    value: -10,
    icon: 'icon-chevron-down',
    color: '#55b7e6',
    string: 'WO.PRIORITY_LOW'
  },
  {
    value: 0,
    icon: 'icon-minus',
    color: '#ff9800',
    string: 'WO.PRIORITY_NORMAL'
  },
  {
    value: 10,
    icon: 'icon-chevron-up',
    color: '#f44336',
    string: 'WO.PRIORITY_HIGH'
  },
  {
    value: 20,
    icon: 'icon-chevron-double-up',
    color: '#7c0800',
    string: 'WO.PRIORITY_VERY_HIGH'
  }
]
