module.exports = {
  less: '<',
  less_or_equal: '<=',
  greater: '>',
  greater_or_equal: '>=',
  between: 'isBetween',
  not_between: 'isNotBetween',
  changes_to: 'changes_to',
  changes: 'changes',
  changes_from: 'changes_from',
  equal: '==',
  not_equal: '!=',
  checked: 'checked',
  not_checked: 'not_checked',
  is_null: 'isNull',
  is_not_null: 'isNotNull',
  before: 'before',
  after: 'after',
  includes: 'includes',
  in: 'includes',
  includesPositive: 'includesPositive',
  includesNegative: 'includesNegative'
}
