/** @ngInject */
function TinyMCEPlaygroundController ($scope, FormData, FormDataComment) {
  const run = async () => {
    $scope.messages = [
      { role: 'assistant', content: 'Hello, how can I assist you today?' },
      { role: 'user', content: 'I would like to create a new comment.' },
      {
        role: 'assistant',
        content: `Here you go\n* new\n* comment\n* markdown`
      },
      {
        role: 'assistant',
        content: ``,
        typing: true
      }
    ]
    const message3 = `## Sure, here is the graphs:
Bar Chart:

\`\`\`chart
---
data:
-
  x:
  - "giraffes"
  - "orangutans"
  - "monkeys"
  y:
  - "20"
  - "14"
  - "23"
  type: "bar"
layout:
  title: "Zoo Animals"
\`\`\`

Pie Chart:

\`\`\`chart
---
data:
-
  labels:
  - "giraffes"
  - "orangutans"
  - "monkeys"
  values:
  - "20"
  - "14"
  - "23"
  type: "pie"
layout:
  title: "Zoo Animals"
\`\`\`

Line Graph:

\`\`\`chart
---
data:
-
  x:
  - "0"
  - "1"
  - "2"
  - "3"
  - "4"
  - "5"
  - "6"
  - "7"
  - "8"
  y:
  - "0"
  - "1"
  - "2"
  - "3"
  - "4"
  - "5"
  - "6"
  - "7"
  - "8"
  name: "Name of Trace 1"
  type: "scatter"
-
  x:
  - "0"
  - "1"
  - "2"
  - "3"
  - "4"
  - "5"
  - "6"
  - "7"
  - "8"
  y:
  - "1"
  - "0"
  - "3"
  - "2"
  - "5"
  - "4"
  - "7"
  - "6"
  - "8"
  name: "Name of Trace 2"
  type: "scatter"
layout:
  title:
    text: "Plot Title"
  xaxis:
    title:
      text: "x Axis"
  yaxis:
    title:
      text: "y Axis"
\`\`\`

Multiple Charts:

\`\`\`chart
---
data:
-
  x:
  - "1"
  - "2"
  - "3"
  y:
  - "4"
  - "5"
  - "6"
  type: "scatter"
-
  x:
  - "20"
  - "30"
  - "40"
  y:
  - "50"
  - "60"
  - "70"
  xaxis: "x2"
  yaxis: "y2"
  type: "scatter"
layout:
  grid:
    rows: "1"
    columns: "2"
    pattern: "independent"
\`\`\`

Stacked bar chart:

\`\`\`chart
---
data:
  - x:
    - giraffes
    - orangutans
    - monkeys
    "y":
    - 20
    - 14
    - 23
    name: SF Zoo
    type: bar
  - x:
    - giraffes
    - orangutans
    - monkeys
    "y":
    - 12
    - 18
    - 29
    name: LA Zoo
    type: bar
layout:
  barmode: stack
\`\`\`

Histogram:

\`\`\`chart
---
data:
- x:
  - 0.7312921663526653
  - 0.542694322571746
  - 0.14308084057688286
  - 0.5252000280836777
  - 0.7776550528492974
  type: histogram
\`\`\`

    Bla bla bla bla`
    // Create code to simulate typing to messages[2].content
    const typingMessage = {
      role: 'assistant',
      content: '',
      typing: true
    }
    let currentIndex = 0
    const typingInterval = setInterval(() => {
      if (currentIndex < message3.length) {
        typingMessage.content +=
          message3[currentIndex++] +
          message3[currentIndex++] +
          message3[currentIndex++] +
          message3[currentIndex++] +
          message3[currentIndex++] +
          message3[currentIndex++] +
          message3[currentIndex++] +
          message3[currentIndex++] +
          message3[currentIndex++] +
          message3[currentIndex++] +
          message3[currentIndex++] +
          message3[currentIndex++] +
          message3[currentIndex++]

        $scope.messages[3] = typingMessage
        $scope.$apply()
      } else {
        clearInterval(typingInterval)
        $scope.messages[3] = {
          role: 'assistant',
          content: message3
        }
        $scope.$apply()
      }
    }, 100)
  }
  run()
}
module.exports = TinyMCEPlaygroundController
