/** @ngInject */
function AsBuiltTreeController ($scope, $rootScope, $translate, ReportRes) {
  const vm = this

  vm.rootStock = [ReportRes]

  $scope.toggle = function (scope) {
    scope.toggle()
  }

  $scope.collapseAll = function () {
    $scope.$broadcast('angular-ui-tree:collapse-all')
  }

  $scope.expandAll = function () {
    $scope.$broadcast('angular-ui-tree:expand-all')
  }

  $scope.tempTable = null
  $scope.loadTable = function (node) {
    $scope.tempTable = node
  }

  vm.page = {}
  vm.page.title = $translate.instant('ASBUILT.TREE.TITLE')
  $rootScope.title = vm.page.title

  $scope.headerOptions = {
    icon: 'icon-file-document-box',
    template: require('app/templates/headers/simple.html'),
    title: `${$translate.instant(
      'ASBUILT.TREE.TITLE'
    )} (As Built) ${$translate.instant('ASBUILT.sku')}: ${
      vm.rootStock[0].sku
    } / ${$translate.instant('ASBUILT.serial')}: ${vm.rootStock[0].serial}`,
    fabButton: {}
  }
}

module.exports = AsBuiltTreeController
