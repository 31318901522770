/* global _ angular */
'use strict'
import AddEditProductionEntityTypeController from './add-edit-dialog/add-edit-production-entity-type.controller'
const debug = require('debug')('nextplus:production-entity.controller')
/** @ngInject */
function ProductionEntityTypeListController (
  $scope,
  $rootScope,
  $translate,
  PermissionUtils,
  KendoGridHelper,
  ViewsService,
  Page,
  ProductionEntityType,
  $mdDialog,
  htmlWork
) {
  const hasCreatePermissions = PermissionUtils.isPermit(
    'ProductionEntityType',
    'create'
  )
  const hasEditPermissions = PermissionUtils.isPermit(
    'ProductionEntityType',
    'patchAttributes'
  )
  const hasDeletePermissions = PermissionUtils.isPermit(
    'ProductionEntityType',
    'deleteById'
  )

  $scope.addEditType = function addEditType (id = null, name = null) {
    $mdDialog
      .show({
        controller: AddEditProductionEntityTypeController,
        template: require('./add-edit-dialog/add-edit-production-entity-type.template.html'),
        parent: angular.element(document.body),
        targetEvent: '',
        locals: {
          typeId: id,
          typeName: name
        },
        clickOutsideToClose: true
      })
      .then(
        function (typeName) {
          if (id) {
            debug('Edit type name', typeName)
            if (typeName) {
              ProductionEntityType.prototype$patchAttributes(
                { id },
                { name: typeName }
              )
                .$promise.then(() => {
                  debug(`Type '${typeName}' updated successfully`)
                  $scope.kendoGrid.reloadData()
                })
                .catch(err => {
                  console.error(err)
                  $rootScope.showErrorToast('NP-8051')
                  debug(
                    `An error occurred while trying to update type '${typeName}'`,
                    err
                  )
                })
            }
          } else {
            debug('Add type name', typeName)
            if (typeName) {
              ProductionEntityType.create({ name: typeName })
                .$promise.then(() => {
                  debug(`Type '${typeName}' created successfully`)
                  $scope.kendoGrid.reloadData()
                })
                .catch(err => {
                  console.error(err)
                  $rootScope.showErrorToast('NP-8052')
                  debug(
                    `An error occurred while trying to create type '${typeName}'`,
                    err
                  )
                })
            }
          }
        },
        function () {}
      )
  }
  $scope.deleteType = function deleteType (id, name) {
    $mdDialog
      .show(
        $mdDialog
          .confirm()
          .title(
            $translate.instant(
              'PRODUCTION_ENTITY.PRODUCTION_ENTITY_TYPE.REAL_DELETE_TYPE',
              {
                name
              }
            )
          )
          .clickOutsideToClose(true)
          .multiple(true)
          .parent(angular.element(document.body))
          .ok($translate.instant('BUTTONS.DELETE'))
          .cancel($translate.instant('BUTTONS.CANCEL'))
      )
      .then(
        function () {
          ProductionEntityType.deleteById({ id })
            .$promise.then(() => {
              debug(`Type '${id}' deleted successfully`)
              $scope.kendoGrid.reloadData()
            })
            .catch(err => {
              console.error(err)
              let message = 'NP-8053'
              if (err?.data?.error?.code === 'PRODUCTION_ENTITY_TYPE_IN_USE') {
                message = $translate.instant(
                  'PRODUCTION_ENTITY.PRODUCTION_ENTITY_TYPE.ERRORS.PRODUCTION_ENTITY_TYPE_IN_USE'
                )
              }
              $rootScope.showErrorToast(message)
              debug(
                `An error occurred while trying to delete type '${id}'`,
                err
              )
            })
        },
        function () {}
      )
  }

  const initScreen = async function initScreen () {
    $scope.title = $rootScope.title = $translate.instant(
      'PRODUCTION_ENTITY.PRODUCTION_ENTITY_TYPE.LIST_TITLE'
    )
    Page.setTitleText($scope.title)
    $scope.PermissionUtils = PermissionUtils

    const tableColumns = [
      // NAME
      {
        uniqueId: 'bvz7824d-8957-4a4d-9745-d1e0428dc773',
        field: 'name',
        translateCode: 'PRODUCTION_ENTITY.FIELDS.NAME'
        // media: '(min-width: 768px)'
      }
    ]

    if (hasCreatePermissions) {
      tableColumns.push(
        // ACTIONS
        {
          uniqueId: 's8a7c8b1-2a9a-4c0b-8c6b-4a9b6a9b6b6b',
          field: 'id',
          translateCode: 'COMMON.ACTIONS',
          headerAttributes: {
            class: 'actions-column-header'
          },
          filterable: false,
          sortable: false,
          trustedTemplate: data => {
            const name = htmlWork.escapeHTMLQuotes(data.name)
            let html = `<div layout="row" layout-align="start center" style="direction: ${$rootScope.dir}">`
            if (hasEditPermissions) {
              html += `<md-button ng-class="($root.isTabletOrMobile) ? ['md-fab','md-mini','md-primary'] : ['md-icon-button']"
                                ng-click="addEditType('${data.id}','${name}')"
                                data-testid="add-edit-${data.id}">
                      <md-icon md-font-icon="icon-pencil" class="s16"></md-icon>
                    </md-button>`
            }
            if (hasDeletePermissions) {
              html += `<md-button ng-class="($root.isTabletOrMobile) ? ['md-fab','md-mini'] : ['md-icon-button']" class="md-warn"
                                ng-click="deleteType('${data.id}','${name}')" data-testid="delete-${data.id}">
                      <md-icon md-font-icon="icon-delete" class="s16"></md-icon>
                    </md-button>`
            }
            html += '</div>'
            return html
          }
        }
      )
    }

    const { defaultTableColumns } = ViewsService.generateCustomFieldColumns(
      'ProductionEntityType',
      tableColumns
    )

    const baseFilter = {
      where: {},
      order: 'modified DESC'
    }

    const { columns, selectedViewId } =
      ViewsService.getTablesColumns(defaultTableColumns)

    const { newBaseFilter, filters } = ViewsService.getViewCustomFilters(
      selectedViewId,
      _.cloneDeep(baseFilter)
    )

    const defaultTableSetup = {
      // stateName
      // ignoreParams
      find: ProductionEntityType.find,
      count: ProductionEntityType.count,
      cleanBaseFilter: baseFilter,
      baseFilter: newBaseFilter,
      selectedViewId,
      columns: defaultTableColumns
    }

    const defaultTableToolbarSetup = {
      // stateName
      columns: defaultTableColumns,
      currentColumnIds: columns.map(c => c.uniqueId),
      filters,
      selectedViewId,
      title: $scope.title
    }

    $scope.downloadFunction = () => {}

    $scope.kendoGrid = await KendoGridHelper.GridInstance(
      defaultTableSetup,
      $scope,
      columns
    )

    await $scope.kendoGrid.isBound()

    $scope.tableToolbar = await ViewsService.GridToolBarInstance(
      defaultTableToolbarSetup,
      $scope.kendoGrid,
      $scope
    )

    $scope.$applyAsync()
  }

  initScreen()

  $scope.headerOptions = {
    icon: 'icon-account',
    template: require('app/templates/headers/simple.html'),
    title: $scope.title,
    fabButton: {
      template: require('app/templates/headers/fab-button.html'),
      action: $scope.addEditType,
      icon: 'icon-plus',
      href: '',
      state: '',
      showExpression: () => hasCreatePermissions
    }
  }
}

module.exports = ProductionEntityTypeListController
