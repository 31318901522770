/* global */

/** @ngInject */
function CheckInCheckOutDialogController (
  $scope,
  $rootScope,
  $mdDialog,
  ResolvedSessions,
  ResolvedProductionEntity,
  productionEntityService
) {
  $scope.sessions = ResolvedSessions
  $scope.cancel = function cancel () {
    $mdDialog.cancel()
  }

  const init = async function init () {
    $scope.productionEntityFactory = productionEntityService
    $scope.childScope = $scope.$new(true)
    await $scope.productionEntityFactory.init(
      $scope.childScope,
      $scope.sessions,
      [ResolvedProductionEntity],
      true
    )
  }

  init()
}

module.exports = CheckInCheckOutDialogController
