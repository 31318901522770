/* global */
/** @ngInject */
function WorkorderStatusFieldsController (
  $rootScope,
  $scope,
  $mdDialog,
  $mdToast,
  locals,
  $translate
) {
  $scope.fields = locals.dbFields
  $scope.sessionId = 'WorkorderStatus'
  $scope.model = { fields: locals.fields }
  $scope.form = { type: 'WorkorderStatus' }
  $scope.formExtras = {}
  $scope.formExtras[$scope.form.type] = {}
  $scope.header = $translate.instant('WO.WORKORDER_CHANGE_STATUS', {
    workorderNumber: locals.workorderNumber
  })
  $scope.cancel = () => $mdDialog.cancel()

  $scope.save = async function save () {
    $scope.isSaving = true

    try {
      $mdToast.show(
        $mdToast.nextplus({
          position: $rootScope.toastLocation,
          parent: 'document.body',
          theme: 'success-toast',
          hideDelay: 2500
        })
      )
      $mdToast.updateTextContent(
        $translate.instant('WO.WORKORDER_STATUS_CHANGED_SUCCESSFULLY')
      )
      $scope.model.fieldsWithValues = []
      $scope.model.fields.forEach(field => {
        $scope.model.fieldsWithValues.push({
          id: field.id,
          fieldId: field.fieldId,
          value: $scope.model[field.id]
        })
      })
      $mdDialog.hide({
        fields: $scope.model.fieldsWithValues
      })
    } catch (err) {
      const mdToastError = $mdToast.nextplus({
        position: $rootScope.toastLocation,
        parent: 'document.body',
        theme: 'error-toast',
        hideDelay: 2500
      })
      $mdToast.show(mdToastError)
      $mdToast.updateTextContent(err)
    }

    $scope.isSaving = false
  }
}

module.exports = WorkorderStatusFieldsController
