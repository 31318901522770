/* eslint-disable */
/** @ngInject */
function WorkflowSessionController(
  $rootScope,
  $scope,
  $translate,
  PermissionUtils,
  Page,
  KendoGridHelper,
  ResolvedUsers,
  ResolvedWorkflow,
  WorkflowSessionItem,
  MultiTranslateService,
  ViewsService,
  htmlWork,
  Workflow,
  DateTimeFormatService
) {
  const users = ResolvedUsers.map(user => ({
    id: user.id,
    name: user.firstName + ' ' + user.lastName
  }))

  const usersByName = users.reduce((obj, user) => {
    obj[user.id] = user.name
    return obj
  }, {})

  const getGrade = function getGrade(data) {
    if (
      data &&
      (data.cmi_core_lesson_status === 'passed' ||
        data.cmi_core_lesson_status === 'failed')
    ) {
      return data.cmi_core_score_raw ? data.cmi_core_score_raw : 'N/A'
    } else {
      return 'N/A'
    }
  }

  const initScreen = async function initScreen() {
    $scope.title = $rootScope.title = $translate.instant('WORKFLOW_SESSION')
    Page.setTitleText($scope.title)
    $scope.PermissionUtils = PermissionUtils

    const tableColumns = [
      // USER
      {
        uniqueId: '176eced7-f197-468f-ac6c-8caa78bd5521',
        field: 'UserId',
        translateCode: 'WF.USER',
        width: '180px',
        type: 'string',
        filterable: {
          mode: 'row',
          cell: {
            showOperators: false,
            template: function (args) {
              args.element.kendoDropDownList({
                filter: 'contains',
                autoBind: false,
                dataTextField: 'name',
                dataValueField: 'id',
                dataSource: new kendo.data.DataSource({
                  data: users
                }),
                valuePrimitive: true
              })
            }
          }
        },
        template: data => {
          const userName = usersByName[data.UserId]
          if (userName) return userName
          return 'Deleted User'
        }
      },
      // WORKFLOW_NAME
      {
        uniqueId: '4a81f435-f01d-46fd-b7e6-d7e67814cf06',
        field: 'workflowId',
        translateCode: 'WF.NAME',
        width: '180px',
        type: 'string',
        filterable: {
          mode: 'row',
          cell: {
            showOperators: false,
            template: function (args) {
              args.element.kendoDropDownList({
                filter: 'contains',
                autoBind: false,
                dataTextField: 'name',
                dataValueField: 'id',
                dataSource: new kendo.data.DataSource({
                  data: ResolvedWorkflow
                }),
                valuePrimitive: true
              })
            }
          }
        },
        template: data => {
          let workflow = data.workflow
          if (workflow) {
            workflow = MultiTranslateService.getForView(
              Workflow,
              $rootScope.currentLang,
              workflow
            )
            return workflow.name
          }
          return 'Deleted Workflow'
        }
      },
      // VIEWING_DATE
      {
        uniqueId: 'ef70b607-5241-43a2-aa1e-9248688e97bb',
        field: 'start',
        translateCode: 'ContentPackageSession.viewingDate',
        width: '150px',
        type: 'date',
        filterable: true,
        template: data => {
          return data.start === null
            ? '--'
            : DateTimeFormatService.formatDateTime(data.start, 'dateTime')
        }
      },
      // GRADE
      {
        uniqueId: 'ebbf8d9b-84ff-4019-9de9-ada50c4af608',
        field: 'scromSessions',
        translateCode: 'ContentPackageSession.GRADE',
        width: '150px',
        type: 'object',
        filterable: false,
        template: data => {
          return data.scromSessions
            .map(scromSession => getGrade(scromSession.data))
            .join(', ')
        }
      }
    ]

    const basicFields = {
      id: true,
      UserId: true,
      workflowId: true,
      start: true,
      scromSessions: true
    }

    const { defaultTableColumns, customFieldsDisplay } =
      ViewsService.generateCustomFieldColumns(
        'WorkflowSessionItem',
        tableColumns
      )

    const baseFilter = {
      include: [
        'scromSessions',
        {
          relation: 'workflow',
          scope: {
            fields: {
              id: true,
              name: true,
              translations: true,
              fallbackStatus: true
            }
          }
        }
      ],
      fields: _.merge(basicFields, customFieldsDisplay)
    }

    const { columns, selectedViewId } =
      ViewsService.getTablesColumns(defaultTableColumns)

    const { newBaseFilter, filters } = ViewsService.getViewCustomFilters(
      selectedViewId,
      _.cloneDeep(baseFilter)
    )

    const defaultTableSetup = {
      // stateName
      // ignoreParams
      find: WorkflowSessionItem.find,
      count: WorkflowSessionItem.count,
      cleanBaseFilter: baseFilter,
      baseFilter: newBaseFilter,
      selectedViewId: selectedViewId,
      columns: defaultTableColumns
    }

    const defaultTableToolbarSetup = {
      // stateName
      columns: defaultTableColumns,
      currentColumnIds: columns.map(c => c.uniqueId),
      filters: filters,
      selectedViewId: selectedViewId,
      title: $scope.title
    }

    $scope.downloadFunction = () => {}

    $scope.kendoGrid = await KendoGridHelper.GridInstance(
      defaultTableSetup,
      $scope,
      columns
    )

    await $scope.kendoGrid.isBound()

    $scope.tableToolbar = await ViewsService.GridToolBarInstance(
      defaultTableToolbarSetup,
      $scope.kendoGrid,
      $scope
    )

    $scope.$applyAsync()
  }

  initScreen()

  $scope.headerOptions = {
    icon: 'icon-document',
    template: require('app/templates/headers/simple.html'),
    title: $scope.title,
    fabButton: {}
  }
}

module.exports = WorkflowSessionController
