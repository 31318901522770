export const tinymceConfigValue = {
  baseURL: '/tinymce',
  toolbar_mode: 'floating',
  contextmenu: 'link useBrowserSpellcheck table',
  relative_urls: false,

  browser_spellcheck: true,
  menu: {
    file: {
      title: 'File',
      items: 'print'
    },
    edit: {
      title: 'Edit',
      items: 'undo redo | cut copy paste pastetext | selectall | searchreplace'
    },
    view: {
      title: 'View',
      items:
        'code | visualaid visualchars visualblocks | spellchecker | preview'
    },
    insert: {
      title: 'Insert',
      items:
        'nesteditem image link media addcomment pageembed template codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor tableofcontents | insertdatetime'
    },
    format: {
      title: 'Format',
      items:
        'bold italic underline strikethrough superscript subscript codeformat | styles blocks fontfamily fontsize align lineheight | forecolor backcolor | language | removeformat'
    },
    tools: {
      title: 'Tools',
      items: 'code wordcount'
    }
    // table: {
    //   title: 'Table',
    //   items:
    //     'inserttable | cell row column | advtablesort | tableprops deletetable'
    // },
    // help: { title: 'Help', items: 'help' }
  },
  extended_valid_elements:
    'img[class|src|ta-insert-video|border=0|alt|title|hspace|vspace|width|height|align|name]',
  branding: false,
  promotion: false,
  resize: true,
  width: '100%'
}
module.exports = tinymceConfigValue
