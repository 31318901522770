/* global angular _ */
const debug = require('debug')('nextplus:workorders')

/** @ngInject */
function WorkordersController (
  $scope,
  $translate,
  $rootScope,
  $mdDialog,
  $state,
  $stateParams,
  Page,
  PermissionUtils,
  KendoGridHelper,
  ViewsService,
  WorkorderUtils,
  ResolvedUsers,
  Workorder,
  ResolvedWorkorderStatuses,
  ResolvedWorkflowTypes
) {
  $scope.deleteWorkorder = async function deleteWorkorder (
    workorderId,
    workorderNumber
  ) {
    await WorkorderUtils.deleteWorkorder(workorderId, workorderNumber)
    $scope.kendoGrid.reloadData()
  }
  $scope.createNewWorkorder = function createNewWorkorder (
    template = {
      quantity: 1,
      dueDateInHours: 24,
      releasedBefore: 0,
      assignedUsers: []
    }
  ) {
    $mdDialog
      .show({
        controller: require('./template/workorder-template-dialog.controller.js'),
        controllerAs: 'vm',
        template: require('./template/workorder-template-dialog.html'),
        parent: angular.element(document.body),
        locals: {
          template
        },
        resolve: {
          ResolvedGroups: Group => Group.find({}).$promise
        },
        escapeToClose: true,
        clickOutsideToClose: true,
        multiple: true,
        fullscreen: true
      })
      .then(
        function ({ workorderId }) {
          debug('Close create workorder dialog with id', workorderId)
          $state.go('app.workorder.show', { id: workorderId })
        },
        function () {
          debug('Close create workorder dialog without save')
        }
      )
  }

  $scope.selectWorkorderTemplate = function selectWorkorderTemplate () {
    $mdDialog
      .show({
        controller: 'WorkorderTemplatesController',
        controllerAs: 'vm',
        template: require('./template/workorder-template-list-dialog.html'),
        parent: angular.element(document.body),
        targetEvent: '',
        locals: {},
        resolve: {
          isDialog: () => true
        },
        escapeToClose: true,
        clickOutsideToClose: false,
        multiple: true,
        fullscreen: true
      })
      .then(
        async function (workorderTemplate) {
          debug(
            'Close select workorder template dialog with data',
            workorderTemplate
          )
          $scope.createNewWorkorder(workorderTemplate)
        },
        function () {
          debug('Close select workorder template dialog without save')
        }
      )
  }

  $scope.showKit = function showKit (workorderId) {
    $mdDialog
      .show({
        controller: require('../workflow/show/dialogs/workflow.kit.controller.js'),
        controllerAs: 'vm',
        templateUrl:
          './modules/main/workflow/show/dialogs/workflow.kit.template.html',
        parent: angular.element(document.body),
        locals: {
          preview: false,
          stocksOrWorkorder: workorderId,
          model: 'workorder'
        },
        escapeToClose: false,
        clickOutsideToClose: true,
        fullscreen: true
      })
      .then(
        function (answer) {},
        function () {}
      )
  }
  $scope.pullWorkorder = function pullWorkorder (isServiceCall = false) {
    $mdDialog.show({
      controller: 'WorkorderPriorityPullDialog',
      controllerAs: 'vm',
      template: require('./template/workorder-priority-pull-dialog.template.html'),
      parent: angular.element(document.body),
      escapeToClose: true,
      clickOutsideToClose: true,
      locals: {
        isServiceCall
      }
    })
  }

  const initScreen = async function initScreen () {
    $scope.PermissionUtils = PermissionUtils
    $scope.usersPerRow = {}
    $scope.assigneePerRow = {}
    const users = ResolvedUsers

    const baseFilter = {
      order: ['priority DESC', 'expectedDate DESC', 'modified DESC'],
      where: { sendToNextPlus: true },
      fields: {
        kitItems: false,
        KITITEMS_SUBFORM: false,
        TRANSORDER_K_SUBFORM: false,
        orderLines: false
      },
      include: [
        {
          relation: 'stocks',
          scope: {
            fields: {
              id: true,
              serial: true,
              kitStatus: true,
              end: true,
              quantity: true
            }
          }
        },
        {
          relation: 'sessions',
          scope: {
            fields: {
              id: true,
              serials: true,
              end: true,
              validQuantity: true,
              invalidQuantity: true,
              quantity: true
            }
          }
        },
        {
          relation: 'groupModel',
          scope: {
            fields: { id: true, name: true }
          }
        },
        {
          relation: 'workorderType',
          scope: {
            fields: { id: true, name: true }
          }
        }
      ]
    }

    if ($stateParams.baseFilter) {
      try {
        const extraBaseFilter = JSON.parse($stateParams.baseFilter)
        baseFilter.where = Object.assign(
          baseFilter.where,
          extraBaseFilter.where
        )
      } catch (ex) {
        console.error(ex)
      }
    }

    if (
      $rootScope.currentUser &&
      _.isArray($rootScope.currentUser.locationIds) &&
      $rootScope.currentUser.locationIds.length > 0
    ) {
      baseFilter.where.locationIds = { inq: $rootScope.currentUser.locationIds }
    }
    if ($stateParams.type === 'opened') {
      baseFilter.where.end = null
      baseFilter.where.and = [{ erpClosed: false }, { closed: false }]

      $scope.title = $rootScope.title = $translate.instant('WO.OPEN_WORKORDERS')
    } else if ($stateParams.type === 'closed') {
      baseFilter.order = ['priority DESC', 'end DESC']
      baseFilter.where.or = [{ erpClosed: true }, { closed: true }]

      delete baseFilter.where.locationIds

      $scope.title = $rootScope.title = $translate.instant(
        'WO.CLOSE_WORKORDERS'
      )
      // TODO: Need to change query between aero to radar
    } else if ($stateParams.type === 'mine') {
      baseFilter.where.assignedUsers = { inq: $rootScope.currentUser.id }
      baseFilter.where.and = [{ erpClosed: false }, { closed: false }]

      baseFilter.where.end = null
      $scope.title = $rootScope.title = $translate.instant('WO.MINE_WORKORDERS')
    } else if ($stateParams.type === 'qa') {
      const location = $rootScope.locations.find(loc => {
        return loc.otherId === 'QA'
      })
      baseFilter.where.and = [{ erpClosed: false }, { closed: false }]
      baseFilter.where.end = null
      baseFilter.where.locationIds = {
        inq: [location.id]
      }
      $scope.title = $rootScope.title = $translate.instant('WO.QA_WORKORDERS')
    }
    Page.setTitleText($scope.title)

    const tableColumns = WorkorderUtils.generateWorkorderColumns(
      $scope,
      $rootScope.locations,
      users,
      ResolvedWorkorderStatuses
    )

    const { defaultTableColumns } = ViewsService.generateCustomFieldColumns(
      'Workorder',
      tableColumns
    )

    const { columns, selectedViewId } =
      ViewsService.getTablesColumns(defaultTableColumns)

    const { newBaseFilter, filters } = ViewsService.getViewCustomFilters(
      selectedViewId,
      _.cloneDeep(baseFilter)
    )

    const defaultTableSetup = {
      // stateName
      // ignoreParams
      find: Workorder.find,
      count: Workorder.count,
      cleanBaseFilter: baseFilter || {},
      baseFilter: newBaseFilter,
      selectedViewId,
      columns: defaultTableColumns,
      encodeTitles: true
    }

    const defaultTableToolbarSetup = {
      // stateName
      columns: defaultTableColumns,
      currentColumnIds: columns.map(c => c.uniqueId),
      filters,
      selectedViewId,
      title: $scope.title,
      AiInjections: {
        users: users.map(u => {
          return {
            id: u.id,
            value: u.displayName
          }
        }),
        statuses: ResolvedWorkorderStatuses.map(status => {
          return {
            id: status.id,
            value: status.name
          }
        }),
        stations: $rootScope.locations.map(location => {
          return {
            id: location.id,
            value: location.name
          }
        }),
        types: ResolvedWorkflowTypes.map(type => {
          return {
            id: type.id,
            value: type.name
          }
        })
      }
    }

    $scope.downloadFunction = () => {}

    $scope.kendoGrid = await KendoGridHelper.GridInstance(
      defaultTableSetup,
      $scope,
      columns
    )

    await $scope.kendoGrid.isBound()

    $scope.tableToolbar = await ViewsService.GridToolBarInstance(
      defaultTableToolbarSetup,
      $scope.kendoGrid,
      $scope
    )

    $scope.$apply()
  }

  initScreen()
  const menu = []
  if (PermissionUtils.isPermit('Workorder', 'fetchWorkorderFromPriority')) {
    if ($rootScope.appSettings?.prioritySettings?.enabled === true) {
      menu.push({
        content: `<md-button ng-click="pullWorkorder()" class="md-default-theme md-ink-ripple" data-testid="pull-priority-workorder">
                  <md-icon md-font-icon="icon-download"></md-icon>
                  <span translate="WO.PULL_WORKORDER"></span>
                </md-button>`
      })
      if (
        $rootScope.appSettings.prioritySettings.serviceCallEnabled &&
        PermissionUtils.isPermit('Workorder', 'fetchWorkorderFromPriority')
      ) {
        menu.push({
          content: `<md-button ng-click="pullWorkorder(true)" class="md-default-theme md-ink-ripple" data-testid="pull-priority-service-call">
                    <md-icon md-font-icon="icon-download"></md-icon>
                    <span translate="WO.PULL_SERVICE_CALL"></span>
                  </md-button>`
        })
      }
    }
  }

  $scope.headerOptions = {
    icon: 'icon-file-document-box',
    template: require('app/templates/headers/simple.html'),
    title: $scope.title,
    menuHideExpression: () => menu.length === 0,
    menu,
    fabButton: {
      template: `
      <div class="md-fab-buttons header-fab-button" ng-if="headerOptions.fabButton.showExpression()" >
        <md-menu md-position-mode="target-right target">
          <md-button class="add-file-button md-fab md-warn add-button md-button" ng-click="$mdOpenMenu($event)">
            <md-icon md-font-icon="{{headerOptions.fabButton.icon}}" role="img" aria-hidden="true"></md-icon>
          </md-button>
          <md-menu-content width="4">
            <md-menu-item>
              <md-button ng-click="selectWorkorderTemplate()" data-testid="workorder-from-template">
                <div layout="row">
                  <p flex>${$translate.instant(
                    'WO.TEMPLATE.NEW_WORKORDER_FROM_TEMPLATE'
                  )}</p>
                </div>
              </md-button>
            </md-menu-item>
            <md-menu-item>
              <md-button ng-click="createNewWorkorder()" data-testid="workorder-from-scratch">
                <div layout="row">
                  <p flex>${$translate.instant(
                    'WO.TEMPLATE.NEW_WORKORDER_FROM_SCRATCH'
                  )}</p>
                </div>
              </md-button>
            </md-menu-item>
          </md-menu-content>
        </md-menu>
      </div>`,
      icon: 'icon-plus',
      href: '',
      state: '',
      showExpression: () =>
        PermissionUtils.isPermit(
          'Workorder',
          'createMultipleWorkordersFromTemplate'
        )
    }
  }
}

module.exports = WorkordersController
