/* global _ */

/** @ngInject */
function QuantitySelectorDialog (
  $scope,
  locals,
  $mdDialog,
  $translate,
  ResolvedPart
) {
  $scope.items = locals.items
  $scope.mode = locals.mode
  if ($scope.mode === 'start') {
    $scope.header = $translate.instant('WO.SELECT_QUANTITY_FOR_SESSION')
  } else if ($scope.mode === 'split') {
    $scope.header = $translate.instant('WO.SPLIT_DEVICE')
  } else {
    $scope.header = $translate.instant('WO.EDIT_DEVICE_QUANTITY')
  }
  $scope.model = {}
  $scope.fields = []
  $scope.items.forEach((stock, index) => {
    $scope.model[`${index}-id`] = stock.id
    $scope.model[`${index}-displayName`] =
      stock.serial || `${index + 1}(${stock.indicator})`
    $scope.model[`${index}-quantity`] = stock.quantity
    if ($scope.mode === 'split') {
      $scope.model[`${index}-newStockQuantity`] = 0
      $scope.model[`${index}-quantityLeft`] = stock.quantity
    } else {
      $scope.model[`${index}-newStockQuantity`] = stock.quantity
      $scope.model[`${index}-quantityLeft`] = 0
    }
    let regexString = '\\d*'
    if (!ResolvedPart.unit.each) {
      regexString = '^\\d+(\\.\\d+)?$'
    }
    $scope.model[`${index}-formsAction`] = 'move'
    $scope.fields.push({
      className: 'display-flex layout-xs-column layout-md-row',
      fieldGroup: [
        // DEVICE_NAME
        {
          key: `${index}-displayName`,
          type: 'input',
          className: 'flex-xs-100 flex-20 m-5 layout-row',
          ngModelAttrs: {
            readonly: {
              bound: 'ng-readonly',
              attribute: 'ng-readonly'
            }
          },
          templateOptions: {
            label: $translate.instant('WO.DEVICE_NAME'),
            className: 'flex forceDirLTR',
            readonly: true
          }
        },
        // QUANTITY_TO_SPLIT / QUANTITY / QUANTITY_TO_START
        {
          key: `${index}-newStockQuantity`,
          type: 'input',
          className: 'flex-xs-100 flex-15 m-5 layout-row',
          templateOptions: {
            type: 'number',
            max: ['split', 'start'].includes($scope.mode)
              ? stock.quantity
              : null,
            min: 0,
            pattern: regexString, // only numbers
            className: 'flex forceDirLTR',
            label:
              $scope.mode === 'edit'
                ? $translate.instant('WO.quantity')
                : $scope.mode === 'start'
                ? $translate.instant('WO.QUANTITY_TO_START')
                : $translate.instant('WO.QUANTITY_TO_SPLIT'),
            onChange: function (value, options) {
              $scope.model[`${index}-quantityLeft`] =
                Math.round(($scope.model[`${index}-quantity`] - value) * 1e15) /
                1e15
              if (value === $scope.model[`${index}-quantity`]) {
                $scope.model[`${index}-formsAction`] = 'move'
              }
            }
          }
        },
        // QUANTITY_LEFT
        {
          key: `${index}-quantityLeft`,
          type: 'input',
          className: 'flex-xs-100 flex-15 m-5 layout-row',
          templateOptions: {
            type: 'number',
            max: stock.quantity,
            min: 0,
            pattern: regexString, // only numbers
            className: 'flex forceDirLTR',
            label: $translate.instant('WO.QUANTITY_LEFT'),
            onChange: function (value, options) {
              $scope.model[`${index}-quantityLeft`] =
                Math.round(($scope.model[`${index}-quantity`] - value) * 1e15) /
                1e15
              if (value === $scope.model[`${index}-quantity`]) {
                $scope.model[`${index}-formsAction`] = 'move'
              }
            }
          },
          hideExpression: function ($viewValue, $modelValue, scope) {
            return $scope.mode === 'edit'
          }
        },
        // FORMS_ACTIONS
        {
          key: `${index}-formsAction`,
          type: 'radioMaterial',
          className: 'flex-xs-100 flex-50 m-5 layout-row',
          templateOptions: {
            className: 'flex forceDirLTR',
            label: $translate.instant('WO.CLONE_SESSION.FORMS_ACTIONS.TITLE'),
            options: [
              {
                name: $translate.instant(
                  'WO.CLONE_SESSION.FORMS_ACTIONS.OPTIONS.DO_NOTHING'
                ),
                value: 'nothing'
              },
              {
                name: $translate.instant(
                  'WO.CLONE_SESSION.FORMS_ACTIONS.OPTIONS.COPY'
                ),
                value: 'copy'
              },
              {
                name: $translate.instant(
                  'WO.CLONE_SESSION.FORMS_ACTIONS.OPTIONS.MOVE'
                ),
                value: 'move'
              }
            ],
            onChange: function (value, options) {}
          },
          hideExpression: function ($viewValue, $modelValue, scope) {
            return $scope.mode !== 'split'
          },
          expressionProperties: {
            'templateOptions.disabled': ($viewValue, $modelValue, scope) => {
              return (
                $scope.model[`${index}-newStockQuantity`] ===
                  $scope.model[`${index}-quantity`] ||
                $scope.model[`${index}-newStockQuantity`] === 0
              )
            }
          }
        }
      ]
    })
  })
  $scope.allQuantitiesInserted = () => {
    if ($scope.mode !== 'edit') {
      if (
        _.every($scope.items, (stock, index) => {
          const newStockQuantity = $scope.model[`${index}-newStockQuantity`]
          return newStockQuantity === 0
        })
      ) {
        return false
      }
    }
    return _.every($scope.items, (stock, index) => {
      const newStockQuantity = $scope.model[`${index}-newStockQuantity`]
      return (
        newStockQuantity !== undefined &&
        newStockQuantity !== null &&
        newStockQuantity !== ''
      )
    })
  }

  $scope.save = () => {
    const manipulatedModel = {}
    Object.keys($scope.model).forEach(key => {
      const splittedKey = key.split('-')
      const stockIndex = splittedKey[0]
      const stockProperty = splittedKey[1]
      if (manipulatedModel[stockIndex]) {
        manipulatedModel[stockIndex][stockProperty] = $scope.model[key]
      } else {
        manipulatedModel[stockIndex] = {
          [stockProperty]: $scope.model[key]
        }
      }
    })
    const items = Object.values(manipulatedModel).filter(
      stock => stock.newStockQuantity && stock.newStockQuantity > 0
    )
    $mdDialog.hide(items)
  }
  $scope.cancel = () => $mdDialog.cancel()
}
module.exports = QuantitySelectorDialog
