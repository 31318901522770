import angular from 'angular'

import NavigationController from './navigation.controller'

const MODULE_NAME = 'app.navigation'

angular
  .module(MODULE_NAME, [])
  .controller('NavigationController', NavigationController)

export default MODULE_NAME
