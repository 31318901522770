/* global _ angular */
'use strict'
const debug = require('debug')('nextplus:production-entity.controller')
/** @ngInject */
function ProductionEntityListController (
  $scope,
  $rootScope,
  $translate,
  PermissionUtils,
  KendoGridHelper,
  ViewsService,
  Page,
  ProductionEntity,
  ProductionEntityType,
  KendoDataSourceHelper,
  $mdDialog,
  htmlWork,
  ProductionEntityHelper
) {
  const hasCreatePermissions = PermissionUtils.isPermit(
    'ProductionEntity',
    'create'
  )
  const hasEditPermissions = PermissionUtils.isPermit(
    'ProductionEntity',
    'patchAttributes'
  )
  const hasDeletePermissions = PermissionUtils.isPermit(
    'ProductionEntity',
    'deleteById'
  )
  const hasViewPermissions = PermissionUtils.isPermit(
    'ProductionEntity',
    'find'
  )
  $scope.deleteProductionEntity = function deleteProductionEntity (id, name) {
    $mdDialog
      .show(
        $mdDialog
          .confirm()
          .title(
            $translate.instant(
              'PRODUCTION_ENTITY.REAL_DELETE_PRODUCTION_ENTITY',
              {
                name
              }
            )
          )
          .clickOutsideToClose(true)
          .multiple(true)
          .parent(angular.element(document.body))
          .ok($translate.instant('BUTTONS.DELETE'))
          .cancel($translate.instant('BUTTONS.CANCEL'))
      )
      .then(
        function () {
          ProductionEntity.deleteById({ id })
            .$promise.then(() => {
              debug(`Type '${id}' deleted successfully`)
              $scope.kendoGrid.reloadData()
            })
            .catch(err => {
              console.error(err)
              $rootScope.showErrorToast('NP-8054')
              debug(
                `An error occurred while trying to delete type '${id}'`,
                err
              )
            })
        },
        function () {}
      )
  }
  const initScreen = async function initScreen () {
    $scope.title = $rootScope.title = $translate.instant(
      'PRODUCTION_ENTITY.PRODUCTION_ENTITIES'
    )
    Page.setTitleText($scope.title)
    $scope.PermissionUtils = PermissionUtils

    const tableColumns = [
      // NAME
      {
        uniqueId: '74a7824d-8957-4a4d-9745-d1e0428dc773',
        field: 'name',
        translateCode: 'PRODUCTION_ENTITY.FIELDS.NAME'
        // media: '(min-width: 768px)'
      },
      // TYPE
      {
        uniqueId: 'b8a7c8b1-2a9a-4c0b-8c6b-4a9b6a9b6b6b',
        field: 'productionEntityTypeId',
        translateCode: 'PRODUCTION_ENTITY.FIELDS.TYPE',
        filterable: {
          mode: 'row',
          cell: {
            showOperators: false,
            operator: 'eq',
            suggestionOperator: 'eq',
            template: function (args) {
              args.element.kendoDropDownList({
                filter: 'contains',
                autoBind: false,
                dataTextField: 'name',
                dataValueField: 'id',
                dataSource: new KendoDataSourceHelper.KendoNextplusDataSource({
                  serverFiltering: true,
                  find: ProductionEntityType.find,
                  count: ProductionEntityType.count
                }),
                valuePrimitive: true
              })
            }
          }
        },
        sortable: true,
        template: data => {
          if (!data.productionEntityTypeId) return '--'
          return data.entityType?.name || '--'
        }
        // media: '(min-width: 768px)'
      },
      // STATUS
      {
        uniqueId: '9e4b6b6e-1b8b-4c7b-9c4e-9f4f7b2f7a7f',
        field: 'status',
        translateCode: 'PRODUCTION_ENTITY.FIELDS.STATUS',
        // media: '(min-width: 768px)'
        trustedTemplate: function (dataItem) {
          const statusId = dataItem.statusId
          const statusObj = dataItem.statuses.find(s => s.id === statusId)
          return ProductionEntityHelper.generateStatusTemplate(
            statusObj?.name,
            statusObj?.color ? statusObj.color : '#000000'
          )
        }
      }
    ]

    if (hasCreatePermissions) {
      tableColumns.push(
        // ACTIONS
        {
          uniqueId: '3b4b6b6e-1b8b-4c7b-9c4e-9f4f7b2f7a7f',
          field: 'id',
          translateCode: 'COMMON.ACTIONS',
          headerAttributes: {
            class: 'actions-column-header'
          },
          // media: '(min-width: 768px)',
          filterable: false,
          sortable: false,
          trustedTemplate: data => {
            const name = htmlWork.escapeHTMLQuotes(data.name)
            let html = `<div layout="row" layout-align="start center" style="direction: ${$rootScope.dir}">`
            if (hasViewPermissions) {
              html += `<md-button ng-class="($root.isTabletOrMobile) ? ['md-fab','md-mini','md-primary'] : ['md-icon-button','show-button']" 
                       data-testid="view-${data.id}" 
                       ui-sref="app.production-entity.view({id: '${data.id}'})">
                        <md-icon md-font-icon="icon-eye" class="s20"></md-icon>
                      </md-button>`
            }
            if (hasEditPermissions) {
              html += `<md-button ng-class="($root.isTabletOrMobile) ? ['md-fab','md-mini','md-primary'] : ['md-icon-button','show-button']" data-testid="show-${data.id}" ui-sref="app.production-entity.edit({id: '${data.id}'})">
                        <md-icon md-font-icon="icon-pencil" class="s20"></md-icon>
                      </md-button>`
            }
            if (hasDeletePermissions) {
              html += `<md-button ng-class="($root.isTabletOrMobile) ? ['md-fab','md-mini'] : ['md-icon-button']" class="md-warn"
                                          ng-click="deleteProductionEntity('${data.id}','${name}')" data-testid="delete-${data.id}">
                                <md-icon md-font-icon="icon-delete" class="s16"></md-icon>
                              </md-button>`
            }
            html += '</div>'
            return html
          }
        }
      )
    }

    const { defaultTableColumns } = ViewsService.generateCustomFieldColumns(
      'ProductionEntity',
      tableColumns
    )

    const baseFilter = {
      where: {},
      include: [
        {
          relation: 'entityType',
          scope: {
            fields: { id: true, name: true }
          }
        }
      ],
      order: 'modified DESC'
    }

    const { columns, selectedViewId } =
      ViewsService.getTablesColumns(defaultTableColumns)

    const { newBaseFilter, filters } = ViewsService.getViewCustomFilters(
      selectedViewId,
      _.cloneDeep(baseFilter)
    )

    const defaultTableSetup = {
      // stateName
      // ignoreParams
      find: ProductionEntity.find,
      count: ProductionEntity.count,
      cleanBaseFilter: baseFilter,
      baseFilter: newBaseFilter,
      selectedViewId,
      columns: defaultTableColumns
    }

    const defaultTableToolbarSetup = {
      // stateName
      columns: defaultTableColumns,
      currentColumnIds: columns.map(c => c.uniqueId),
      filters,
      selectedViewId,
      title: $scope.title
    }

    $scope.downloadFunction = () => {}

    $scope.kendoGrid = await KendoGridHelper.GridInstance(
      defaultTableSetup,
      $scope,
      columns
    )

    await $scope.kendoGrid.isBound()

    $scope.tableToolbar = await ViewsService.GridToolBarInstance(
      defaultTableToolbarSetup,
      $scope.kendoGrid,
      $scope
    )

    $scope.$applyAsync()
  }

  initScreen()

  $scope.headerOptions = {
    icon: 'icon-account',
    template: require('app/templates/headers/simple.html'),
    title: $scope.title,
    fabButton: {
      template: require('app/templates/headers/fab-button.html'),
      action: null,
      icon: 'icon-plus',
      href: '',
      state: 'app.production-entity.create',
      showExpression: () => hasCreatePermissions
    }
  }
}

module.exports = ProductionEntityListController
