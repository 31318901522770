import angular from 'angular'

import ComponentConfig from './components.config'

import AppComponentsTablesSimpleTable from './tables/simple-table/simple-table.module'

const MODULE_NAME = 'app.components'

angular
  .module(MODULE_NAME, [AppComponentsTablesSimpleTable])
  .config(ComponentConfig)

export default MODULE_NAME
