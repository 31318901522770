module.exports = {
  productionEntity: {
    name: {
      type: 'string',
      translationKey: 'PRODUCTION_ENTITY.TRIGGERS_DIALOG.VARIABLES.name',
      defaultValue: 'Production Entity Name Placeholder'
    },
    type: {
      type: 'string',
      translationKey: 'PRODUCTION_ENTITY.TRIGGERS_DIALOG.VARIABLES.type',
      defaultValue: 'Production Entity Type Placeholder'
    },
    status: {
      type: 'string',
      translationKey: 'PRODUCTION_ENTITY.TRIGGERS_DIALOG.VARIABLES.status',
      defaultValue: 'Production Entity Status Placeholder'
    }
  }
}
