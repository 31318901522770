'use strict'

import MobileDetect from 'mobile-detect'

/** @ngInject */
function msUtils ($window) {
  // Private variables
  var mobileDetect = new MobileDetect($window.navigator.userAgent)
  var browserInfo = null

  var service = {
    exists: exists,
    detectBrowser: detectBrowser,
    guidGenerator: guidGenerator,
    isMobile: isMobile,
    toggleInArray: toggleInArray
  }

  return service

  /// ///////

  /**
   * Check if item exists in a list
   *
   * @param item
   * @param list
   * @returns {boolean}
   */
  function exists (item, list) {
    return list.indexOf(item) > -1
  }

  /**
   * Returns browser information
   * from user agent data
   *
   * Found at http://www.quirksmode.org/js/detect.html
   * but modified and updated to fit for our needs
   */
  function detectBrowser () {
    // If we already tested, do not test again
    if (browserInfo) {
      return browserInfo
    }

    var browserData = [
      {
        string: $window.navigator.userAgent,
        subString: 'Edge',
        versionSearch: 'Edge',
        identity: 'Edge'
      },
      {
        string: $window.navigator.userAgent,
        subString: 'Chrome',
        identity: 'Chrome'
      },
      {
        string: $window.navigator.userAgent,
        subString: 'OmniWeb',
        versionSearch: 'OmniWeb/',
        identity: 'OmniWeb'
      },
      {
        string: $window.navigator.vendor,
        subString: 'Apple',
        versionSearch: 'Version',
        identity: 'Safari'
      },
      {
        prop: $window.opera,
        identity: 'Opera'
      },
      {
        string: $window.navigator.vendor,
        subString: 'iCab',
        identity: 'iCab'
      },
      {
        string: $window.navigator.vendor,
        subString: 'KDE',
        identity: 'Konqueror'
      },
      {
        string: $window.navigator.userAgent,
        subString: 'Firefox',
        identity: 'Firefox'
      },
      {
        string: $window.navigator.vendor,
        subString: 'Camino',
        identity: 'Camino'
      },
      {
        string: $window.navigator.userAgent,
        subString: 'Netscape',
        identity: 'Netscape'
      },
      {
        string: $window.navigator.userAgent,
        subString: 'MSIE',
        identity: 'Explorer',
        versionSearch: 'MSIE'
      },
      {
        string: $window.navigator.userAgent,
        subString: 'Trident/7',
        identity: 'Explorer',
        versionSearch: 'rv'
      },
      {
        string: $window.navigator.userAgent,
        subString: 'Gecko',
        identity: 'Mozilla',
        versionSearch: 'rv'
      },
      {
        string: $window.navigator.userAgent,
        subString: 'Mozilla',
        identity: 'Netscape',
        versionSearch: 'Mozilla'
      }
    ]

    var osData = [
      {
        string: $window.navigator.platform,
        subString: 'Win',
        identity: 'Windows'
      },
      {
        string: $window.navigator.platform,
        subString: 'Mac',
        identity: 'Mac'
      },
      {
        string: $window.navigator.platform,
        subString: 'Linux',
        identity: 'Linux'
      },
      {
        string: $window.navigator.platform,
        subString: 'iPhone',
        identity: 'iPhone'
      },
      {
        string: $window.navigator.platform,
        subString: 'iPod',
        identity: 'iPod'
      },
      {
        string: $window.navigator.platform,
        subString: 'iPad',
        identity: 'iPad'
      },
      {
        string: $window.navigator.platform,
        subString: 'Android',
        identity: 'Android'
      }
    ]

    var versionSearchString = ''

    function searchString (data) {
      for (var i = 0; i < data.length; i++) {
        var dataString = data[i].string
        var dataProp = data[i].prop

        versionSearchString = data[i].versionSearch || data[i].identity

        if (dataString) {
          if (dataString.indexOf(data[i].subString) !== -1) {
            return data[i].identity
          }
        } else if (dataProp) {
          return data[i].identity
        }
      }
    }

    function searchVersion (dataString) {
      var index = dataString.indexOf(versionSearchString)

      if (index === -1) {
        return
      }

      return parseInt(
        dataString.substring(index + versionSearchString.length + 1)
      )
    }

    var browser = searchString(browserData) || 'unknown-browser'
    var version =
      searchVersion($window.navigator.userAgent) ||
      searchVersion($window.navigator.appVersion) ||
      'unknown-version'
    var os = searchString(osData) || 'unknown-os'

    // Prepare and store the object
    browser = browser.toLowerCase()
    version = browser + '-' + version
    os = os.toLowerCase()

    browserInfo = {
      browser: browser,
      version: version,
      os: os
    }

    return browserInfo
  }

  /**
   * Generates a globally unique id
   *
   * @returns {*}
   */
  function guidGenerator () {
    function s4 () {
      return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1)
    }
    return (
      s4() +
      s4() +
      '-' +
      s4() +
      '-' +
      s4() +
      '-' +
      s4() +
      '-' +
      s4() +
      s4() +
      s4()
    )
  }

  /**
   * Return if current device is a
   * mobile device or not
   */
  function isMobile () {
    return mobileDetect.mobile()
  }

  /**
   * Toggle in array (push or splice)
   *
   * @param item
   * @param array
   */
  function toggleInArray (item, array) {
    if (array.indexOf(item) === -1) {
      array.push(item)
    } else {
      array.splice(array.indexOf(item), 1)
    }
  }
}

module.exports = msUtils
