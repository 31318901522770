import angular from 'angular'

import ToolsController from './tools.controller'
import ToolShowController from './show/tool.show.controller'
const MODULE_NAME = 'app.tool'

angular
  .module(MODULE_NAME, ['ngSanitize'])
  .controller('ToolsController', ToolsController)
  .controller('ToolShowController', ToolShowController)
  .config(config)

/** @ngInject */
function config ($stateProvider, msApiProvider, msNavigationServiceProvider) {
  // State
  $stateProvider
    .state('app.tool', {
      abstract: true,
      url: '/tool'
    })
    .state('app.tool.list', {
      url: '/list/?:filter',
      params: {
        filter: {
          dynamic: true
        }
      },
      views: {
        'content@app': {
          template: require('app/modules/components/tables/kendo-grid/grid.html'),
          controller: 'ToolsController',
          controllerAs: 'vm'
        }
      },
      resolve: {},
      bodyClass: 'tool'
    })
    .state('app.tool.show', {
      url: '/show/?:id',
      views: {
        'content@app': {
          template: require('./show/show.html'),
          controller: 'ToolShowController',
          controllerAs: 'vm'
        }
      },
      bodyClass: 'tool tool-show',
      resolve: {
        ResolvedTool: function (Tool, $stateParams) {
          if (!$stateParams.id) return null
          return Tool.findOne({
            filter: {
              where: { id: $stateParams.id },
              deleted: true,
              include: [
                {
                  relation: 'image_object'
                }
              ]
            }
          }).$promise
        },
        ResolvedGroups: Group => {
          return Group.find({ filter: { field: { id: true, name: true } } })
            .$promise
        }
      }
    })

  // Navigation
  // msNavigationServiceProvider.saveItem('apps.tool', {
  //   title: 'Tool.tools-list',
  //   icon: 'icon-basket',
  //   weight: 700,
  //   acls: [{ model: 'Tool', method: 'create' }]
  // })

  msNavigationServiceProvider.saveItem('apps.modeling.tool', {
    title: 'Tool.tools',
    icon: 'icon-tools',
    state: 'app.tool.list',
    weight: 60,
    acls: [{ model: 'Tool', method: 'create' }]
  })
}

export default MODULE_NAME
