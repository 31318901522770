import angular from 'angular'

import LoginConfig from './loginConfig'
import LoginService from './loginService'
import LoginController from './loginController'

const MODULE_NAME = 'app.login'

/** @ngInject */

angular
  .module(MODULE_NAME, [])
  .config(LoginConfig)
  .service('LoginService', LoginService)
  .controller('LoginController', LoginController)

export default MODULE_NAME
