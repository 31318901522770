/* global angular */

/** @ngInject */
function KitModificationDialogController (
  $scope,
  $mdDialog,
  locals,
  $translate,
  PartAssembly,
  ResolvedPart
) {
  $scope.header = $translate.instant('WO.KIT_MODIFICATION.KIT_ITEM_EDIT')
  $scope.kitItem = locals.kitItem
  const kitItemExists = !!locals.kitItem
  $scope.kitItems = locals.kitItems
  $scope.revisionOptions = []
  if (ResolvedPart?.managedByRevision) {
    $scope.revisionOptions = ResolvedPart.revisions
      .filter(rev => rev.active)
      .map(rev => ({
        id: rev.revision,
        name: rev.revision
      }))
    if (
      $scope.kitItem?.rev &&
      !$scope.revisionOptions.find(rev => rev.id === $scope.kitItem.rev)
    ) {
      $scope.revisionOptions.push({
        id: $scope.kitItem.rev,
        name: $scope.kitItem.rev
      })
    }
  }
  let kitItemsSkus = []
  if ($scope.kitItems && $scope.kitItems.length > 0) {
    kitItemsSkus = $scope.kitItems.map(kitItem => kitItem.sku)
  }

  $scope.save = () => {
    const model = angular.copy($scope.model)
    $mdDialog.hide(model)
  }
  $scope.cancel = () => $mdDialog.cancel()

  $scope.delete = () => {
    $scope.model.isDelete = true
    $mdDialog.hide($scope.model)
  }
  const initScreen = async function initScreen () {
    $scope.model = {
      id: kitItemExists ? $scope.kitItem.id : null,
      sku: kitItemExists ? $scope.kitItem.sku : null,
      rev: kitItemExists ? $scope.kitItem.rev : null,
      quantity: kitItemExists ? parseFloat($scope.kitItem.quantity) : null,
      isDelete: false
    }
    $scope.kitItemFields = [
      {
        key: 'sku',
        type: 'modelSelect',
        templateOptions: {
          required: true,
          label: $translate.instant('WF.SKU'),
          placeholder: $translate.instant('WF.SKU'),
          findMethod: PartAssembly.find,
          disabled: !!kitItemExists,
          showDeleted: true,
          mapObject: {
            id: 'number',
            name: 'number'
          },
          baseFilterObject: {
            where: {
              number: { neq: kitItemsSkus }
            },
            fields: {
              id: true,
              number: true,
              name: true,
              deletedAt: true,
              managedByRevision: true,
              revisions: true,
              currentRevision: true
            }
          },
          onChange: async function (value, options, model, selectedOption) {
            if (!kitItemExists) {
              $scope.model.rev = null
              const part = selectedOption ? selectedOption.originalModel : null
              const revField = $scope.kitItemFields.find(f => f.key === 'rev')
              if (
                part &&
                part.managedByRevision &&
                part.revisions?.length > 0
              ) {
                revField.templateOptions.options = part.revisions
                  .filter(rev => rev.active)
                  .map(rev => ({
                    value: rev.revision,
                    name: rev.revision
                  }))
                revField.templateOptions.required = true
                $scope.model.rev = angular.copy(part.currentRevision)
              } else {
                revField.templateOptions.options = []
                revField.templateOptions.required = false
              }
            }
          }
        }
      }
    ]
    if (!kitItemExists) {
      $scope.header = $translate.instant('WO.KIT_MODIFICATION.KIT_ITEM_ADD')
    }

    if (!kitItemExists || ResolvedPart?.managedByRevision) {
      $scope.kitItemFields.push({
        key: 'rev',
        type: 'select',
        className: 'layout-row',
        templateOptions: {
          options: $scope.revisionOptions,
          required: ResolvedPart?.managedByRevision || false,
          label: $translate.instant('WO.KIT_MODIFICATION.KIT_ITEM_REVISION')
        }
      })
    }

    $scope.kitItemFields.push({
      key: 'quantity',
      type: 'input',
      className: 'layout-row',
      templateOptions: {
        type: 'number',
        label: $translate.instant('WO.KIT_MODIFICATION.KIT_ITEM_QUANTITY')
      }
    })
  }
  initScreen()
}
module.exports = KitModificationDialogController
