/* global angular,_, */
import FormReviewController from '../../../form/review/form-review.controller'
import UUID from 'uuid'

const {
  FAILURE_FORM_DETAILS
} = require('../../../../../../../../common/constants/form-constants.json')
/** @ngInject */
function WorkflowFormListController (
  $scope,
  $rootScope,
  $translate,
  $state,
  $mdDialog,
  locals,
  KendoGridHelper,
  ResolvedFields,
  ViewsService,
  DialogService,
  FormData,
  FormUtils,
  PermissionUtils,
  Workorder,
  $mdToast
) {
  $scope.form = locals.form
  const sessionInstances = locals.sessionInstances
  $scope.usersById = locals.usersById || {}
  const users = Object.keys($scope.usersById).map(userId => ({
    id: userId,
    displayName: $scope.usersById[userId]
  }))

  $scope.sessionIds = locals.sessionIds
  $scope.stocks = locals.sessionData.stocks
  $scope.sessions = locals.sessionData.sessions
  $scope.sessionData = locals.sessionData
  $scope.fields = ResolvedFields
  $scope.canCreate = FormUtils.isPermit('create', $scope.form)
  $scope.canCreateForm = PermissionUtils.isPermit('FormData', 'create')
  $scope.filledFormDatasBySessionId = {}

  $scope.deleteFormData = function deleteFormData (formDataId) {
    DialogService.deleteDialog(
      $translate.instant('FORM.DELETE_MESSAGE', {}, null, null, 'sceParameters')
    ).then(
      function () {
        FormData.destroyById({ id: formDataId })
          .$promise.then(() => {
            $scope.kendoGrid.reloadData()
          })
          .catch(e => console.error(e))
      },
      function () {}
    )
  }

  $scope.openReportInvalidForm = async function openReportInvalidForm (
    formDatas
  ) {
    if (formDatas) {
      const formData = formDatas[0]
      const { sessionId, stockId, workorderId } = formData
      let removeStockItems, changeSerialNumber, newSerialNumber, quantity
      formData.fields.forEach(field => {
        if (
          field.id === FAILURE_FORM_DETAILS.FAILURE_REMOVE_STOCK_ITEMS_FIELD_ID
        ) {
          removeStockItems = field.value || false
        } else if (
          field.id ===
          FAILURE_FORM_DETAILS.FAILURE_CHANGE_SERIAL_NUMBER_FIELD_ID
        ) {
          changeSerialNumber = field.value || false
        } else if (
          field.id === FAILURE_FORM_DETAILS.FAILURE_NEW_SERIAL_NUMBER_FIELD_ID
        ) {
          newSerialNumber = field.value || ''
        } else if (
          field.id === FAILURE_FORM_DETAILS.FAILURE_QUANTITY_FIELD_ID
        ) {
          quantity = field.value || 1
        }
      })
      const faultObject = {
        sessionId,
        workorderId,
        uniqueRenderId: $scope.sessionData.uniqueRenderId || UUID(),
        formDataId: formData.id,
        fields: {
          removeStockItems: removeStockItems || false,
          changeSerialNumber: changeSerialNumber || false,
          newSerialNumber: newSerialNumber || '',
          quantity
        }
      }
      if (stockId && $scope.sessionData.createStock) {
        faultObject.stockId = stockId
      }
      Workorder.reportFaultSession(faultObject)
        .$promise.then(res => {
          let stock = null
          if ($scope.stocks.length > 1) {
            stock = $scope.stocks.find(s => s.id === stockId)
          } else {
            stock = $scope.stocks[0]
          }
          if (quantity < stock.quantity || res.priorityUpdate) {
            window.location.reload()
          } else if (changeSerialNumber && sessionInstances) {
            const session = sessionInstances.find(s => s.id === sessionId)
            session.setSerial(newSerialNumber)
          }
          if ($scope.filledFormDatasBySessionId[sessionId]) {
            $scope.filledFormDatasBySessionId[sessionId].push(formData)
          } else {
            $scope.filledFormDatasBySessionId[sessionId] = [formData]
          }
        })
        .catch(e => {
          console.error(e)
          if (e?.data?.error?.code === 'PRIORITY_REPORT_ERROR') {
            $mdDialog
              .show({
                /** @ngInject */
                controller: ($scope, locals, $mdDialog) => {
                  $scope.header = $translate.instant(
                    'WF.ERRORS.PRIORITY_REPORT_ERROR'
                  )
                  $scope.message = $translate.instant(
                    'WF.ERRORS.PRIORITY_REPORT_ERROR_MESSAGE'
                  )
                  $scope.errorMessage = locals.error.data.error.message
                  $scope.cancel = () => {
                    $mdDialog.cancel()
                  }
                },
                locals: {
                  error: e
                },
                title: $translate.instant('WF.ERRORS.PRIORITY_REPORT_ERROR'),
                multiple: true,
                // template: action.content,
                template: `<md-dialog style="height: 300px; width: 400px;">
                          <md-toolbar>
                            <div class="md-toolbar-tools">
                              <h2>{{header}}</h2>
                            </div>
                          </md-toolbar>
                          <md-dialog-content style="height: 100%;">
                            <div>
                              <span>{{message}}:</span>
                              <span>{{errorMessage}}</span>
                            </div>
                          </md-dialog-content>
                          <md-dialog-actions>
                            <md-button ng-click="cancel()" class="md-raised md-primary">
                              <span translate="BUTTONS.CLOSE"></span>
                            </md-button>
                          </md-dialog-actions>
                      </md-dialog>`,
                parent: angular.element(document.body),
                targetEvent: '',
                clickOutsideToClose: false,
                escapeToClose: false
              })
              .finally(() => {
                let stock = null
                if ($scope.stocks.length > 1) {
                  stock = $scope.stocks.find(s => s.id === stockId)
                } else {
                  stock = $scope.stocks[0]
                }
                if (quantity < stock.quantity) {
                  window.location.reload()
                } else if (changeSerialNumber && sessionInstances) {
                  const session = sessionInstances.find(s => s.id === sessionId)
                  session.setSerial(newSerialNumber)
                }
                if ($scope.filledFormDatasBySessionId[sessionId]) {
                  $scope.filledFormDatasBySessionId[sessionId].push(formData)
                } else {
                  $scope.filledFormDatasBySessionId[sessionId] = [formData]
                }
              })
          }
        })
    }
  }
  $scope.openForm = function openForm (
    formDataId = null,
    sessionId = null,
    stockId = null
  ) {
    const sessionSerialNonCreateStock =
      !$scope.sessionData.createStock && $scope.sessionData.isSerial
    if (
      sessionSerialNonCreateStock &&
      $scope.form.id === FAILURE_FORM_DETAILS.FAILURE_REPORT_ID
    ) {
      $scope.sessionData.serials = []
      $scope.sessionData.stocks = []
    }
    const validSessionId = sessionId === 'null' ? null : sessionId
    $scope.sessionData.sessionId = validSessionId
    $scope.sessionData.sessionIds = [validSessionId]
    $scope.sessionData.stockId = stockId
    const currentItem =
      $scope.sessionData.createStock && stockId
        ? $scope.stocks.find(s => s.id === stockId)
        : $scope.sessions.find(s => s.id === sessionId)
    $scope.sessionData.currentQuantity =
      currentItem?.quantity || $scope.sessionData.currentQuantity

    $mdDialog
      .show({
        resolve: {
          lazyLoad: $ocLazyLoad => {
            return import(
              /* webpackChunkName: "form.module" */ '../../../form/form.module.js'
            )
              .then(mod => {
                return $ocLazyLoad.inject(mod.default)
              })
              .catch(err => {
                throw new Error('Ooops, something went wrong, ' + err)
              })
          },
          sessionData: () => _.cloneDeep($scope.sessionData),
          isDisabled: () => false,
          ResolvedData: (
            FormData,
            Field,
            $rootScope,
            MultiTranslateService
          ) => {
            return new Promise(async (resolve, reject) => {
              let fields
              if (!formDataId || formDataId === '') {
                const fieldIds = $scope.form.fields.map(
                  formField => formField.fieldId
                )
                fields = await Field.getFieldsWithInnerFields({
                  fieldIds
                }).$promise
                fields.push(...$scope.fields)
                fields = fields.map(field =>
                  MultiTranslateService.getForView(
                    Field,
                    $rootScope.currentLang,
                    field
                  )
                )
                resolve({
                  formDatas: [],
                  users: [],
                  form: $scope.form,
                  fields,
                  workflows: []
                })
              } else {
                try {
                  const result = await FormData.findInstance({
                    where: { id: formDataId }
                  }).$promise
                  const { users, formData, workflows } = result
                  fields = result.fields.map(field =>
                    MultiTranslateService.getForView(
                      Field,
                      $rootScope.currentLang,
                      field
                    )
                  )

                  resolve({
                    formDatas: [formData],
                    users,
                    form: $scope.form,
                    fields,
                    workflows
                  })
                } catch (err) {
                  reject(err)
                }
              }
            })
          },
          ResolvedForms: Form =>
            Form.find({
              filter: {
                where: {
                  isTemplate: false
                }
              }
            }).$promise,
          parentLinkedForm: () => null
        },
        controller: FormReviewController,
        template: require('./workflow.form-review.template.html'),
        fullscreen: true,
        parent: angular.element(document.body),
        targetEvent: '',
        multiple: true,
        clickOutsideToClose: false
      })
      .then(async formDatas => {
        if ($scope.form.id === FAILURE_FORM_DETAILS.FAILURE_REPORT_ID) {
          await $scope.openReportInvalidForm(formDatas)
        }
        $scope.kendoGrid.reloadData()
      })
  }
  $scope.openNewForm = function openNewForm () {
    const sessionSerialNonCreateStock =
      !$scope.sessionData.createStock && $scope.sessionData.isSerial
    if (
      !$scope.sessionData.openInPreview &&
      sessionSerialNonCreateStock &&
      $scope.stocks.length === 0
    ) {
      $mdToast.show(
        $mdToast.nextplus({
          position: $rootScope.toastLocation,
          parent: 'body',
          theme: 'error-toast',
          hideDelay: 3000
        })
      )
      $mdToast.updateTextContent(
        $translate.instant('WF.ERRORS.SERIAL_NUMBER_REQUIRED_TO_CREATE_FORM')
      )
      return
    }
    const isStocks =
      $scope.sessionData.createStock ||
      (sessionSerialNonCreateStock && $scope.stocks.length > 0)
    if (!$scope.sessions.length > 1 || (isStocks && $scope.stocks.length > 1)) {
      // multi session handler
      $mdDialog
        .show({
          locals: {
            sessions: $scope.sessions,
            stocks: $scope.stocks,
            isStocks
          },
          /** @ngInject */
          controller: function selectSessionController (
            $scope,
            $mdDialog,
            locals
          ) {
            $scope.cancel = () => {
              $mdDialog.cancel()
            }
            $scope.close = () => {
              $mdDialog.hide({
                valueId: $scope.selectModel.value
              })
            }
            const options = []
            if (locals.isStocks) {
              locals.stocks.forEach(stock => {
                options.push({
                  id: stock.id,
                  name: stock.displayName || stock.serial
                })
              })
            } else {
              locals.sessions.forEach(session => {
                options.push({
                  id: session.id,
                  name: session.displayName
                })
              })
            }
            $scope.selectModel = {
              value: null,
              options
            }
          },
          template: `<md-dialog style="min-width: 30em;">
                        <md-toolbar class="md-primary">
                          <div layout="row" layout-align="start center">
                            <h2 translate="WF.SELECT_SERIAL" style="padding: 0em 1em 0em 1em;"></h2>
                            <span flex></span>
                            <md-button
                              ng-disabled="vm.inProgress"
                              class="md-icon-button"
                              ng-click="cancel()"
                            >
                              <md-icon md-font-icon="icon-close" class="icon"></md-icon>
                            </md-button>
                          </div>
                        </md-toolbar>
                        <md-dialog-content>
                          <div layout="row" layout-align="center center">
                            <md-select ng-model="selectModel.value" style="width:100%">
                                <md-option ng-repeat="option in selectModel.options" ng-value="option.id">
                                  {{option.name}}
                                </md-option>
                            </md-select>
                          </div>
                        </md-dialog-content>
                        <md-dialog-actions>
                          <md-button ng-disabled="vm.inProgress" class="close-btn md-raised md-warn" ng-click="cancel()">
                            <span translate="BUTTONS.CLOSE"></span>
                          </md-button>
                          <md-button ng-disabled="vm.inProgress || selectModel.value === null" ng-click="close()" class="md-raised md-accent">
                            <span translate="BUTTONS.SAVE"></span>
                          </md-button>
                        </md-dialog-actions>
                      </md-dialog>`,
          fullscreen: true,
          parent: angular.element(document.body),
          targetEvent: '',
          multiple: true,
          clickOutsideToClose: false
        })
        .then(
          obj => {
            if (isStocks) {
              const stock = $scope.stocks.find(s => s.id === obj.valueId)
              $scope.openForm(null, stock.session.id, stock.id)
            } else {
              $scope.openForm(null, obj.valueId)
            }
          },
          () => {}
        )
    } else {
      if (isStocks) {
        $scope.openForm(null, $scope.sessions[0].id, $scope.stocks[0].id)
      } else {
        $scope.openForm(null, $scope.sessions[0].id)
      }
    }
  }

  $scope.cancel = function cancel () {
    $mdDialog.hide($scope.filledFormDatasBySessionId)
  }

  const initScreen = async function initScreen () {
    $scope.stateName = `${$state.current.name}.${$scope.form.id}`
    $scope.loading = true

    const defaultTableColumns = FormUtils.generateFormColumns(
      'dialog',
      $scope.form,
      $scope.fields,
      users,
      [],
      $scope
    )

    const { columns, selectedViewId } = ViewsService.getTablesColumns(
      defaultTableColumns,
      $scope.stateName
    )

    const sessionDataForFilter = {}
    if ($scope.sessionData && !_.isEmpty($scope.sessionData)) {
      sessionDataForFilter.sessionIds = $scope.sessionData.sessions.map(
        session => session.id
      )
      sessionDataForFilter.stockIds = $scope.sessionData.stocks.map(
        stock => stock.id
      )
      sessionDataForFilter.serials = $scope.sessionData.serials
      sessionDataForFilter.partSku = $scope.sessionData.partSku
      sessionDataForFilter.workorderId = $scope.sessionData.workorderId
      sessionDataForFilter.workorderNumber = $scope.sessionData.workorderNumber
      sessionDataForFilter.isSerial = $scope.sessionData.isSerial
      sessionDataForFilter.createStock = $scope.sessionData.createStock
      sessionDataForFilter.workflow = _.pick($scope.sessionData.workflow, [
        'recordId',
        'version'
      ])
    }
    const baseFilter = {
      extraData: sessionDataForFilter,
      order: 'created DESC'
    }

    const { newBaseFilter, filters } = ViewsService.getViewCustomFilters(
      selectedViewId,
      _.cloneDeep(baseFilter),
      $scope.stateName
    )
    const defaultTableSetup = {
      stateName: $scope.stateName,
      ignoreParams: true,
      autoSize: true,
      find: FormData.getFormDataList,
      count: FormData.getFormDataCount,
      params: { formId: $scope.form.id },
      cleanBaseFilter: baseFilter,
      baseFilter: newBaseFilter,
      selectedViewId,
      columns: defaultTableColumns,
      encodeTitles: true,
      noRecords: {
        template: function (e) {
          if (
            !$scope.kendoGrid.instance.dataSource._filter ||
            $scope.kendoGrid.instance.dataSource._filter.filters.length === 0
          ) {
            return `
            <div class="forms-not-found">
                <img ng-src="assets/images/cat.svg"/>
                <div layout="column" layout-align="center" class="text-div">
                    <h2><span translate="FORM.NO_FORM_MESSAGE"></span></h2>
                    <md-button class="md-raised md-accent" ng-if="canCreate && canCreateForm" ng-click="openNewForm()" style="margin: auto;">
                        <span translate="FORM.BUTTON.CREATE"></span>
                    </md-button>
                </div>
            </div>`
          } else {
            return `
            <div class="forms-not-found">
                <img ng-src="assets/images/cat.svg"/>
                <div layout="column" layout-align="center" class="text-div">
                    <h2><span translate="FORM.NO_FILTER_RESULTS"></span></h2>
                </div>
            </div>`
          }
        }
      }
    }

    const defaultTableToolbarSetup = {
      stateName: $scope.stateName,
      columns: defaultTableColumns,
      currentColumnIds: columns.map(c => c.uniqueId),
      filters,
      selectedViewId,
      allowDownload: false,
      title: $scope.form.name
    }

    const subFormMapping = KendoGridHelper.createComplexTypeMapping(columns)

    columns.forEach(c => {
      if (c.uniqueId.includes('_') && !c.uniqueId.includes('_lookup_')) {
        c.attributes = KendoGridHelper.calculateColumnAttributes(
          subFormMapping,
          c.uniqueId
        )
      }
      return c
    })

    $scope.kendoGrid = await KendoGridHelper.GridInstance(
      defaultTableSetup,
      $scope,
      columns
    )

    await $scope.kendoGrid.isBound()

    $scope.tableToolbar = await ViewsService.GridToolBarInstance(
      defaultTableToolbarSetup,
      $scope.kendoGrid,
      $scope
    )

    $scope.loading = false

    $scope.$applyAsync()
  }

  initScreen()
}

module.exports = WorkflowFormListController
