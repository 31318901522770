/* global _ angular */
require('./category-grid.scss')

/** @ngInject */
function CategoryGridController (
  $scope,
  $window,
  $state,
  $stateParams,
  $translate,
  $mdDialog,
  ResolvedCategories,
  ResolvedWorkflows,
  ResolvedContentPackages,
  ResolvedCatalog,
  ResolvedWiringlist,
  ResolvedTimeline,
  ResolvedCurrentCategory,
  Category,
  Page,
  PermissionUtils
) {
  Page.setTitleText(
    ResolvedCurrentCategory
      ? ResolvedCurrentCategory.name
      : $translate.instant('HOME')
  )
  $scope.createHref = categoryId =>
    `app.category-grid({category:'${categoryId}'})`

  $scope.newCreateHref = state => {
    return $scope.categoryId
      ? `${state}({initialValues: {categories: ['${$scope.categoryId}']}})`
      : state
  }

  $scope.breadcrumbs = []
  $scope.categoryId = $stateParams.category
  if (_.isUndefined($stateParams.category) || $stateParams.category === '') {
    $scope.Categories = ResolvedCategories.filter(
      category => !category.parentId
    )
  } else {
    $scope.Categories = ResolvedCategories.filter(
      category => category.parentId === $stateParams.category
    )
  }
  $scope.categoriesItems = $scope.Categories.map(category => {
    const item = {
      icon: 'icon-folder',
      name: category.name,
      imageFallback: false,
      metadata: '',
      link: $state.href('app.category-grid', {
        category: category.id
      })
    }
    return item
  })
  const workflowPermission = PermissionUtils.isPermit('Workflow', 'create')
  const contentPackagePermission = PermissionUtils.isPermit(
    'ContentPackage',
    'create'
  )
  const catalogPermission = PermissionUtils.isPermit('Catalog', 'create')
  const wiringlistPermission = PermissionUtils.isPermit('Wiringlist', 'create')
  const timelinePermission = PermissionUtils.isPermit('Timeline', 'create')

  $scope.Items = ResolvedWorkflows.map(workflow => {
    const item = {
      type: 'workflow',
      icon: 'icon-sitemap',
      name: workflow.name,
      metadata: '',
      recordId: workflow.recordId,
      link: $state.href('app.workflow.show-details', {
        recordId: workflow.recordId,
        tabName: 'sessions'
      })
    }
    if (workflow.ResourceId) {
      item.image = `./api/Resources/${workflow.ResourceId}/thumbnail/0`
    }
    if (workflow.parts && workflow.parts.length > 0) {
      workflow.parts = workflow.parts.map(part => {
        return `${part.sku} (${part.rev})`
      })
    }
    if (workflow.parts && workflow.parts.length > 0) {
      item.metadata = `P/N: ${workflow.parts.join(', ')}`
    }
    return item
  })
    .concat(
      ResolvedContentPackages.map(contentpackage => {
        const item = {
          type: 'contentPackage',
          icon: 'icon-file-document-multiple',
          name: contentpackage.name,
          metadata: '',
          id: contentpackage.id,
          link: $state.href('app.content-package.show', {
            id: contentpackage.id
          })
        }
        if (contentpackage.image) {
          item.image = `./api/Resources/${contentpackage.image}/thumbnail/0`
        }
        return item
      })
    )
    .concat(
      ResolvedCatalog.map(catalog => {
        const item = {
          type: 'catalog',
          icon: 'icon-file-cog',
          name: catalog.name,
          metadata: '',
          id: catalog.id,
          image: `./api/Resources/assemblyPicture/${encodeURIComponent(
            catalog.partNumber
          )}`,
          link: $state.href('app.catalog.show', {
            id: catalog.id
          })
        }
        return item
      })
    )
    .concat(
      ResolvedWiringlist.map(wiringlist => {
        const item = {
          wiringlist: 'wiringList',
          icon: 'icon-cable-data',
          name: wiringlist.name,
          metadata: '',
          description: '',
          id: wiringlist.id,
          link: $state.href('app.wiring-list.show', {
            id: wiringlist.id
          })
        }
        if (wiringlist.resource_id) {
          item.image = `./api/Resources/${wiringlist.resource_id}/thumbnail/0`
        }
        return item
      })
    )
    .concat(
      ResolvedTimeline.map(timeline => {
        const item = {
          type: 'timeline',
          icon: 'icon-timeline-clock-outline',
          name: timeline.name,
          metadata: '',
          id: timeline.id,
          link: $state.href('app.timeline.display', {
            id: timeline.id
          })
        }
        if (timeline.ResourceId) {
          item.image = `./api/Resources/${timeline.ResourceId}/thumbnail/0`
        }
        return item
      })
    )
  $scope.PermissionUtils = PermissionUtils
  $scope.currentCategory = ResolvedCurrentCategory
  if (ResolvedCurrentCategory) {
    let category = ResolvedCurrentCategory
    while (category) {
      $scope.breadcrumbs.push({ id: category.id, name: category.name })
      if (category.parentId) {
        category = ResolvedCategories.find(cat => cat.id === category.parentId)
      } else {
        category = null
      }
    }
  }
  $scope.breadcrumbs = $scope.breadcrumbs.reverse()
  $scope.filteredCategories = _.cloneDeep($scope.Categories)
  $scope.filteredItems = _.cloneDeep($scope.Items)
  $scope.addCategory = () => {
    $mdDialog
      .show({
        controller: require('../../main/settings/category/add_edit_category'),
        template: require('../../main/settings/category/add_edit_category.html'),
        parent: angular.element(document.body),
        targetEvent: '',
        locals: {
          name: '',
          parentId: $scope.currentCategory ? $scope.currentCategory.id : null,
          weight: 0,
          haveParent: !!$scope.currentCategory
        },
        resolve: {
          categories: Category => Category.find({}).$promise
        },
        clickOutsideToClose: true
      })
      .then(
        function (obj) {
          if (obj) {
            Category.create(obj)
              .$promise.then(() => $window.location.reload())
              .catch(e => {
                console.log(e)
              })
          }
        },
        function () {}
      )
  }
  $scope.goToState = categoryId => {
    $state.go('app.category-grid', { category: categoryId })
  }
  $scope.headerOptions = {
    preTitleHtml: `
    <div layout="row" layout-items="start center">
      <md-button class="md-icon-button" ng-click="goToState(null)" data-testid="breadcrumb-home">
        <md-icon md-font-icon="icon-home" class="s24" style="color:rgba(255, 255, 255, 0.7);"></md-icon>
      </md-button>
      <ul class="breadcrumb" ng-if="breadcrumbs.length > 0">
          <li ng-repeat="breadcrumb in breadcrumbs">
            <span class="breadcrumb-divider">/</span>
            <ng-switch on="$last">
                <span ng-switch-when="true">{{breadcrumb.name}}</span>
                <span ng-switch-default><a data-testid="breadcrumb-{{breadcrumb.id}}" ui-sref="app.category-grid({{ {category:breadcrumb.id} }})">{{breadcrumb.name}}</a></span>
            </ng-switch>
          </li>
      </ul>
    </div>
    `,
    postTitleHtml: '', // require('app/templates/headers/filter.html'),
    template: require('app/templates/headers/simple.html'),
    title: '',
    fabButton: {
      template: `
      <div class="md-fab-buttons header-fab-button">
        <md-menu  ng-show="{{headerOptions.fabButton.showExpression()}}"
                  md-position-mode="target-right target">
          <md-button class="add-file-button md-fab md-warn add-button md-button" ng-click="$mdOpenMenu($event)">
            <md-icon md-font-icon="{{headerOptions.fabButton.icon}}" role="img" aria-hidden="true"></md-icon>
          </md-button>
          <md-menu-content width="4">
            <md-menu-item id="create-category" ng-if="PermissionUtils.isPermit('Category','create')">
              <md-button ng-click="addCategory()">
                ${$translate.instant('SETTINGS.ADD_CATEGORY')}
              </md-button>
            </md-menu-item>
            <md-menu-item id="create-workflow" ng-if="${workflowPermission}">
              <md-button ui-sref="{{::newCreateHref('app.workflow.create')}}" style="color: #000000de;">
                ${$translate.instant('WF.NEW_WORKFLOW')}
              </md-button>
            </md-menu-item>
            <md-menu-item id="create-content-package" ng-if="${contentPackagePermission}">
              <md-button ui-sref="{{::newCreateHref('app.content-package.new')}}" style="color: #000000de;">
                ${$translate.instant('CONTENTPACKAGE.NEW_PACKAGE')}
              </md-button> 
            </md-menu-item>
            <md-menu-item id="create-catalog" ng-if="${catalogPermission}">
              <md-button ui-sref="{{::newCreateHref('app.catalog.new')}}" style="color: #000000de;">
                ${$translate.instant('CATALOG.NEW_EDIT_NEW_CATALOG')}
              </md-button>
            </md-menu-item>
            <md-menu-item ng-if="${wiringlistPermission}" >
              <md-button ui-sref="{{::newCreateHref('app.wiring-list.new')}}" style="color: #000000de;">
                ${$translate.instant('Wiring.NEW_WIRING_LIST')}
              </md-button>
            </md-menu-item> 
            <md-menu-item ng-if="${timelinePermission}">
              <md-button ui-sref="{{::newCreateHref('app.timeline.show')}}" style="color: #000000de;">
                ${$translate.instant('TIMELINE.NEW_TIMELINE')}
              </md-button>
            </md-menu-item>
          </md-menu-content>
        </md-menu>
      </div>
      `,
      action: () => {},
      disabled: false,
      icon: 'icon-plus',
      href: '',
      state: '',
      showExpression: () =>
        PermissionUtils.isPermit('Category', 'create') ||
        catalogPermission ||
        contentPackagePermission ||
        workflowPermission
    }
  }
}

module.exports = CategoryGridController
