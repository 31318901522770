/* global _, angular */

import ConfigurationEditDialogController from '../configuration/configuration-edit-dialog.controller'
import CreateQRController from '../dialog/create-qr.controller.js'

export const openConfiguration = function openConfiguration (
  $mdDialog,
  sessionId
) {
  return new Promise((resolve, reject) => {
    $mdDialog
      .show({
        /** @ngInject */
        controller: ConfigurationEditDialogController,
        locals: {
          stocks: [{ session: { id: sessionId }, displayName: '' }]
        },
        resolve: {
          Resolved: /** @ngInject */ (
            Workflow,
            Configuration,
            WorkflowSessionItem,
            MultiTranslateService,
            $rootScope
          ) => {
            return new Promise(async (resolve, reject) => {
              try {
                $rootScope.loadingProgress = true
                const data = {}
                const sessionIds = [sessionId]
                const sessionConfiguration = {}
                const sessionConfigurationBySessionId = {}
                const workflowRecordIds = []
                const sessions = await WorkflowSessionItem.find({
                  filter: {
                    where: { id: { inq: sessionIds } },
                    fields: { id: true, configuration: true, recordId: true }
                  }
                }).$promise
                sessionIds.forEach(sessionId => {
                  const oneSession = sessions.find(
                    sessionData => sessionData.id === sessionId
                  )
                  sessionConfigurationBySessionId[sessionId] = oneSession
                    ? oneSession.configuration
                    : []
                })
                const configurations = await Configuration.find({
                  filter: {
                    where: {
                      relatedWorkflows: {
                        inq: [sessions[0].recordId]
                      }
                    }
                  }
                }).$promise
                const configurationIds = _.map(configurations, 'id')
                let usedConfigurationIds = []
                sessionIds.forEach(sessionId => {
                  sessionConfiguration[sessionId] = _.groupBy(
                    sessionConfigurationBySessionId[sessionId],
                    'configurationId'
                  )
                  const allConfigurationId = _.map(
                    sessionConfigurationBySessionId[sessionId],
                    'configurationId'
                  )
                  usedConfigurationIds.push(...allConfigurationId)
                })
                usedConfigurationIds = _.uniq(usedConfigurationIds)
                const missedIds = usedConfigurationIds.filter(
                  id => configurationIds.indexOf(id) === -1
                )
                if (missedIds.length > 0) {
                  const missedConfigurations = await Configuration.find({
                    filter: {
                      where: {
                        relatedWorkflows: {
                          inq: [sessions[0].recordId]
                        },
                        id: { inq: missedIds }
                      },
                      deleted: true
                    }
                  }).$promise
                  missedConfigurations.forEach(config => {
                    configurations.push(config)
                    configurationIds.push(config.id)
                  })
                }
                configurations.forEach(config => {
                  config.options.forEach(recordId => {
                    workflowRecordIds.push(recordId)
                  })
                })
                let workflows = await Workflow.find({
                  filter: {
                    where: {
                      currentReleasedVersion: true,
                      recordId: { inq: workflowRecordIds }
                    },
                    fields: {
                      recordId: true,
                      name: true,
                      parts: true,
                      translations: true,
                      fallbackStatus: true
                    }
                  }
                }).$promise
                workflows = workflows.map(workflow =>
                  MultiTranslateService.getForView(
                    Workflow,
                    $rootScope.currentLang,
                    workflow
                  )
                )
                const workflowsByRecordId = _.keyBy(workflows, 'recordId')
                data.workflowsByRecordId = workflowsByRecordId
                data.configurationsById = _.keyBy(configurations, 'id')
                data.configurationIds = configurationIds
                data.sessionConfiguration = sessionConfiguration
                data.sessionIds = sessionIds
                $rootScope.loadingProgress = false
                resolve(data)
              } catch (err) {
                reject(err)
              }
            })
          }
        },
        multiple: true,
        template: require('../configuration/configuration-edit-dialog.html'),
        parent: angular.element(document.body),
        targetEvent: '',
        clickOutsideToClose: false,
        escapeToClose: true,
        fullscreen: true
      })
      .then(
        res => {
          resolve()
        },
        () => {
          resolve()
        }
      )
  })
}
