/** @ngInject */
function TinyMCEPlaygroundController ($scope, $interval, $timeout) {
  $scope.tinymceModel = `
  <div class="tinymce-bar tinymce-bar-info">
    <h3>Info</h3>
    <p>Initial content</p>
  </div>
<div class="tinymce-bar tinymce-bar-warning">
  <h3>Info</h3>
  <p>Initial content</p>
</div>
<div class="tinymce-bar tinymce-bar-error">
  <h3>Info</h3>
  <p>Initial content</p>
</div>

  <p>Initial <%account_id%> content <%not a var%>gg</p>
<p>Hi @USER_f83f0070-4ac3-11ed-94d5-e50371e95a84@</p>
<p>&nbsp;</p>
<table style="border-collapse: collapse; width: 100%;" border="1">
   <colgroup>
      <col style="width: 50%;">
      <col style="width: 50%;">
   </colgroup>
   <tbody>
      <tr>
         <td><img src="https://az779572.vo.msecnd.net/screens-800/3c8db96e108349bcbff5b1861df4c198" alt="" width="184" height="138"></td>
         <td>@USER_e1965120-4b13-11ed-884a-6d20b2b16846@</td>
      </tr>
      <tr>
         <td><img width=50 src="https://www.tiny.cloud/docs/images/logos/for-base/logo-spaces-docs-for-base.svg" /></td>
         <td>&nbsp;</td>
      </tr>
   </tbody>
</table>
  `
  $scope.selectedFileId = null
  $scope.selectedFile = null
  $timeout(() => {
    $scope.object = {
      id: 'de265320-5eb1-11ee-a7f4-bfea6f5a8db7',
      resource: {
        id: 'de265320-5eb1-11ee-a7f4-bfea6f5a8db7',
        name: 'ccf4e909-e85a-4a32-bf60-235b857c69b1.mp4',
        originalFilename: 'Next-Plus-טופס.mp4',
        mediatype: '',
        folderName: '',
        folderId: null,
        checksum: 'a878fbf6c86fbed8113b98a1bf9d8280e34fb9c9',
        ext: 'mp4',
        type: 'video/mp4',
        hidden: false,
        container: 'Users',
        size: 1052446,
        optimizedSizes: [],
        optimalFiles: [],
        posters: [],
        UserId: 'f83f0070-4ac3-11ed-94d5-e50371e95a84',
        created: '2023-09-29T10:20:52.434Z',
        modified: '2023-09-29T10:20:52.434Z',
        serverModified: '2023-09-29T10:20:52.434Z',
        _meta: []
      }
    }
  }, 1000)
  $scope.onFileChange = function (resources) {
    console.log(resources)
  }
  $scope.tinymceModel2 = '<p>שלום עולם</p>'
  $scope.ngDisabled = false
  $interval(() => {
    $scope.ngDisabled = !$scope.ngDisabled
  }, 5000)
}
module.exports = TinyMCEPlaygroundController
