/* global moment */
require('./clock-animation.scss')

module.exports = {
  template: require('./clock-animation.html'),
  bindings: {
    timer: '='
  },
  controllerAs: 'vm',
  controller:
    /**
     * @ngInject
     */
    function ClockAnimationController ($scope, $interval, $translate) {
      const vm = this
      let durationTextInterval
      if (!vm.timer?.start) {
        vm.durationText = $translate.instant('TIME_LOGGING.TIMER')
        vm.start = null
      } else {
        vm.durationText = '00:00'
        vm.start = vm.timer.start
      }
      $scope.$watch(
        'vm.timer',
        async function (newVal, oldVal) {
          if (newVal?.start) {
            vm.start = newVal.start
            setDurationText()
            setDurationInterval()
          } else {
            $interval.cancel(durationTextInterval)
            vm.durationText = $translate.instant('TIME_LOGGING.TIMER')
            vm.start = null
          }
          console.log('vm.timer changed', newVal, oldVal)
        },
        true
      )
      const durationFormatter = function durationFormatter (value) {
        if (
          value === '--' ||
          value <= 0 ||
          _.isUndefined(value) ||
          value === null
        ) {
          return '--'
        }
        return `${String(value.hours()).padStart(2, '0')}:${String(
          value.minutes()
        ).padStart(2, '0')}`
      }

      const setDurationInterval = function setDurationInterval () {
        durationTextInterval = $interval(setDurationText, 1000)
      }
      const setDurationText = function setDurationText () {
        if (vm.start) {
          const duration = moment.duration(moment().diff(vm.start))
          vm.durationText = durationFormatter(duration)
        } else {
          vm.durationText = '00:00'
        }
      }
    }
}
