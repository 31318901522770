/* global angular,document,_ */
/** @ngInject */
function LoginController (
  $scope,
  $rootScope,
  $translate,
  $window,
  $timeout,
  Page,
  LoginService,
  nextplusSocket,
  $mdDialog,
  ResolvedSettings,
  UserModel,
  SyncUtils,
  $mdToast,
  $stateParams,
  DateTimeFormatService
) {
  const vm = this
  $scope.setNewPassword = $stateParams.setNewPassword
  $scope.showConcurrency = $stateParams.showConcurrency
  let loginAnimation = false
  const welcomeAnimation = (nicename = '') => {
    if (loginAnimation) return
    loginAnimation = true
    const messages = ['LOGIN.WELCOME_BACK', 'LOGIN.GOOD_TO_SEE_YOU_AGAIN']
    const emojis = ['😀', '😃', '😄', '😁']
    vm.message = $translate.instant(_.sample(messages), { nicename })
    vm.emoji = _.sample(emojis)
    const welcomAnimationWrapper = document.getElementById(
      'welcomAnimationWrapper'
    )
    angular
      .element(
        `
    <div id="welcomAnimation">${vm.message}<br />${vm.emoji}</div>
    `
      )
      .appendTo(welcomAnimationWrapper)
    welcomAnimationWrapper.classList.add('show')
  }
  if ($rootScope.appSettings.loginWithCard) {
    const onComplete = async barcode => {
      const accessToken = await UserModel.loginUsingScannedValue({
        scanningValue: barcode,
        include: [
          {
            relation: 'user',
            scope: {
              include: ['roles']
            }
          }
        ]
      }).$promise
      // LoopBackAuth.setUser(accessToken.id, accessToken.userId, accessToken.user)
      // LoopBackAuth.save()
      vm.postLogin(accessToken)
    }
    const options = {
      onComplete
    }
    // eslint-disable-next-line
    new window.ScannerDetector(options)
  }
  Page.setTitleText($translate.instant('LOGIN.LOGIN'))
  vm.isEmail = function (email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
  }
  vm.form = {
    imGuest: false
  }
  $scope.$watch(
    'vm.form',
    function () {
      $scope.loginForm.$setValidity('USER_INACTIVE', true)
      $scope.loginForm.$setValidity('LOGIN_FAILED', true)
      $scope.loginForm.$setValidity('USER_IS_BLOCKED', true)
    },
    true
  )

  $scope.hasResetPasswordOption =
    ResolvedSettings.resetViaSMS || ResolvedSettings.smtp || false

  $scope.hasOtherAuthMethod = ResolvedSettings['authentication-saml'] || false

  $scope.SyncUtils = SyncUtils
  $scope.startSync = function startSync (method = 'http') {
    SyncUtils.startSync(method, true)
  }

  vm.openConcurrencyDialog = function openConcurrencyDialog ($event, callback) {
    $mdDialog
      .show({
        controller: /** @ngInject */ function ($scope, $mdDialog) {
          $scope.cancel = () => {
            $mdDialog.cancel()
          }
          $scope.continue = () => {
            $mdDialog.hide($scope.concurrencyModel.method === 'forceLogIn')
          }
          $scope.concurrencyModel = {
            method: 'forceLogIn'
          }
        },
        template: require('./concurrency-dialog/concurrency-dialog.html'),
        parent: angular.element(document.body)
      })
      .then(
        async forceLogin => {
          await callback($event, forceLogin)
        },
        function () {}
      )
  }

  vm.postLogin = async res => {
    nextplusSocket.socket.emit('auth', res.id)
    $scope.loginForm.$setValidity('USER_INACTIVE', true)
    $scope.loginForm.$setValidity('LOGIN_FAILED', true)
    $scope.loginForm.$setValidity('USER_IS_BLOCKED', true)
    const user = await LoginService.getCurrentUserSingleton()
    LoginService.fixMenu(LoginService.currentUser)
    $rootScope.afterLoginRedirect(user)
  }

  vm.login = async function ($event, forceLogin = false) {
    $event.preventDefault()
    const credentials = { forceLogin }
    if (vm.isEmail(vm.form.email)) {
      credentials.email = vm.form.email
    } else {
      credentials.username = vm.form.email
    }
    credentials.password = vm.form.password
    credentials.rememberMe = vm.form.imGuest !== true
    try {
      $scope.loginForm.$setValidity('USER_INACTIVE', true)
      $scope.loginForm.$setValidity('LOGIN_FAILED', true)
      $scope.loginForm.$setValidity('USER_IS_BLOCKED', true)
      const res = await LoginService.login(
        {
          // This remember me is the default loopback localStorage mechnisam
          // We implement other one which store it in the cookies
          rememberMe: false
        },
        credentials,
        [
          {
            relation: 'UserModel',
            scope: {
              include: ['roles']
            }
          }
        ]
      ).$promise
      const user = res.user
      document.getElementById('login-button').focus()
      welcomeAnimation(user.firstName || user.nicename)
      $timeout(() => {
        vm.postLogin(res)
      }, 1500)
    } catch (ex) {
      if (ex.data.error && ex.data.error.code === 'LOGIN_FAILED') {
        $scope.loginForm.$setValidity('LOGIN_FAILED', false)
      } else if (ex.data.error && ex.data.error.code === 'USER_INACTIVE') {
        $scope.loginForm.$setValidity('USER_INACTIVE', false)
      } else if (ex.data.error && ex.data.error.code === 'USER_IS_BLOCKED') {
        $scope.errorObject = {
          blockUntil: DateTimeFormatService.formatDateTime(
            new Date(ex.data.error.details.params.blockUntil),
            'dateTime'
          )
        }
        $scope.loginForm.$setValidity('USER_IS_BLOCKED', false)
      } else if (
        ex.data.error &&
        ex.data.error.code === 'USER_ALREADY_LOGED_IN'
      ) {
        vm.openConcurrencyDialog($event, vm.login)
      } else {
        const mdToast = $mdToast.nextplus({
          position: $rootScope.toastLocation,
          parent: '#content',
          theme: 'error-toast',
          hideDelay: 6000
        })
        $mdToast.show(mdToast)
        if (ex.data.error.code) {
          $mdToast.updateTextContent(
            $translate.instant(`LOGIN.ERRORS.${ex.data.error.code}`)
          )
        } else {
          $mdToast.updateTextContent(ex.data.error.message)
        }
      }
      $scope.$applyAsync()
    }
  }

  vm.otherLogin = async function (empty, forceLogin) {
    $window.location.href = `/api/UserModels/loginWithAuthProvider?forceLogin=${forceLogin}`
  }

  if ($scope.showConcurrency) {
    vm.openConcurrencyDialog({}, vm.otherLogin)
  }
}

module.exports = LoginController
