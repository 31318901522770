/** @ngInject */

function AsBuiltTableController (
  $scope,
  $rootScope,
  $translate,
  $window,
  LocalStorageUtils,
  ReportRes
) {
  const localSettings = LocalStorageUtils.get($rootScope.currentUser.id) || {}
  if (!localSettings.workflowReportSettings) {
    localSettings.workflowReportSettings = {}
  }
  $scope.settings = {
    generalDetails:
      localSettings.workflowReportSettings.generalDetails !== false,
    customFields: localSettings.workflowReportSettings.customFields !== false,
    variables: localSettings.workflowReportSettings.variables !== false,
    actions: localSettings.workflowReportSettings.actions !== false,
    stockItems: localSettings.workflowReportSettings.stockItems !== false,
    usedTools: localSettings.workflowReportSettings.usedTools !== false
  }

  $scope.saveCustomReportSettings = () => {
    localSettings.workflowReportSettings.generalDetails =
      $scope.settings.generalDetails
    localSettings.workflowReportSettings.customFields =
      $scope.settings.customFields
    localSettings.workflowReportSettings.variables = $scope.settings.variables
    localSettings.workflowReportSettings.actions = $scope.settings.actions
    localSettings.workflowReportSettings.stockItems = $scope.settings.stockItems
    localSettings.workflowReportSettings.usedTools = $scope.settings.usedTools
    LocalStorageUtils.set($rootScope.currentUser.id, localSettings)
  }
  const vm = this
  $scope.workflowSessions = ReportRes
  vm.page = {}
  vm.page.title = $translate.instant('ASBUILT.TABLE.TITLE')
  $rootScope.title = vm.page.title
  $scope.createPDF = () => {
    const lang = $translate.use()
    $window.open(
      encodeURI(
        `/api/WorkflowSessionItems/createWorkflowSessionItemReport?WorkflowSessionItemId=${
          $scope.workflowSessions[0].id
        }&lang=${lang}&settings=${JSON.stringify($scope.settings)}`
      )
    )
  }
  $scope.headerOptions = {
    icon: 'icon-file-document-box',
    postTitleHtml: `
    <div style="padding-right: 50px">
      <md-button class="md-raised md-info" ng-click="createPDF()">PDF</md-button>
    </div>`,
    template: require('app/templates/headers/simple.html'),
    title: `${$translate.instant('ASBUILT.TABLE.TITLE')}`,
    fabButton: {}
  }
}

module.exports = AsBuiltTableController
