/* global _ */

/** @ngInject */
function WorkflowSessionItemListController (
  $scope,
  $rootScope,
  $translate,
  PermissionUtils,
  Page,
  KendoGridHelper,
  ViewsService,
  WorkflowSessionItem,
  DateTimeFormatService
) {
  const initScreen = async function initScreen () {
    $scope.title = $rootScope.title = $translate.instant(
      'WORKFLOW_SESSION_ITEM'
    )
    Page.setTitleText($scope.title)
    $scope.PermissionUtils = PermissionUtils

    const tableColumns = [
      // WORKFLOW_NAME
      {
        uniqueId: '266a93ca-7746-41c5-8ef0-6bdda1075558',
        field: 'workflowName',
        translateCode: 'WF.NAME',
        width: '100px'
        // media: '(min-width: 768px)',
      },
      // SERIAL
      {
        uniqueId: 'c35ab9ad-8a8b-4b02-b52d-cc1ef5f4455f',
        field: 'serials',
        type: 'array',
        translateCode: 'WF.SERIAL',
        // media: '(min-width: 768px)',
        template: data => {
          return data.serials ? data.serials.join(', ') : '--'
        }
      },
      // STATUS
      {
        uniqueId: 'd6eaabc9-292d-4616-a501-18c0a3506310',
        field: 'status',
        translateCode: 'WF.STATUS',
        template: data => {
          return $translate.instant(data.status)
        }
        // media: '(min-width: 768px)',
      },
      // PROGRESS
      {
        uniqueId: 'e327799f-5e97-453c-8e80-bbb03cf9baf7',
        field: 'progress',
        translateCode: 'WF.PROGRESS'
        // media: '(min-width: 768px)',
      },
      // START
      {
        uniqueId: '34100050-28d7-47da-8483-c6fb3c430868',
        field: 'start',
        translateCode: 'WF.START_TIME',
        type: 'date',
        // media: '(min-width: 768px)',
        filterable: true,
        template: data => {
          return data.start === null
            ? '--'
            : DateTimeFormatService.formatDateTime(data.start, 'dateTime')
        }
      },
      // END
      {
        uniqueId: '79e2b8d6-8cd3-4f44-8ab1-a210234d194d',
        field: 'end',
        translateCode: 'WF.END_TIME',
        type: 'date',
        // media: '(min-width: 768px)',
        filterable: true,
        template: data => {
          return data.end === null
            ? '--'
            : DateTimeFormatService.formatDateTime(data.end, 'dateTime')
        }
      },
      // ACTIONS
      {
        uniqueId: '2fcc9618-0928-4532-95ac-a3fd34d783e0',
        field: 'id',
        translateCode: 'COMMON.ACTIONS',
        headerAttributes: {
          class: 'actions-column-header'
        },
        // media: '(min-width: 768px)',
        filterable: false,
        sortable: false,
        trustedTemplate: data => {
          return `<div layout="row" layout-align="start center">
                      <md-button  ng-class="($root.isTabletOrMobile) ? ['md-fab','md-mini','md-primary'] : ['md-icon-button','show-button']" 
                                  ng-style="{'padding-top': !$root.isTabletOrMobile ? '12px !important' : 'auto' }" 
                                  ui-sref="app.workflow.workflow-session-item-report({workflowSessionId:'${data.id}'})" data-testid="report-${data.id}">
                        <md-icon md-font-icon="icon-eye" class="s16"></md-icon> 
                      </md-button>
                    </div>`
        }
      }
    ]

    const basicFields = {
      id: true,
      contentPackageId: true,
      created: true,
      status: true,
      workflowName: true,
      progress: true,
      serials: true,
      start: true,
      end: true
    }

    const { defaultTableColumns, customFieldsDisplay } =
      ViewsService.generateCustomFieldColumns(
        'WorkflowSessionItem',
        tableColumns
      )

    const baseFilter = {
      order: 'created DESC',
      fields: _.merge(basicFields, customFieldsDisplay)
    }

    const { columns, selectedViewId } =
      ViewsService.getTablesColumns(defaultTableColumns)

    const { newBaseFilter, filters } = ViewsService.getViewCustomFilters(
      selectedViewId,
      _.cloneDeep(baseFilter)
    )

    const defaultTableSetup = {
      // stateName
      // ignoreParams
      find: WorkflowSessionItem.find,
      count: WorkflowSessionItem.count,
      cleanBaseFilter: baseFilter,
      baseFilter: newBaseFilter,
      selectedViewId,
      columns: defaultTableColumns
    }

    const defaultTableToolbarSetup = {
      // stateName
      columns: defaultTableColumns,
      currentColumnIds: columns.map(c => c.uniqueId),
      filters,
      selectedViewId,
      title: $scope.title
    }

    $scope.downloadFunction = () => {}

    $scope.kendoGrid = await KendoGridHelper.GridInstance(
      defaultTableSetup,
      $scope,
      columns
    )

    await $scope.kendoGrid.isBound()

    $scope.tableToolbar = await ViewsService.GridToolBarInstance(
      defaultTableToolbarSetup,
      $scope.kendoGrid,
      $scope
    )

    $scope.$applyAsync()
  }

  initScreen()

  $scope.headerOptions = {
    icon: 'icon-document',
    template: require('app/templates/headers/simple.html'),
    title: $scope.title,
    fabButton: {}
  }
}

module.exports = WorkflowSessionItemListController
