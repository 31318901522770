/* global moment kendo _ */

/** @ngInject */
function ContentPackageSessionController (
  $scope,
  $rootScope,
  $translate,
  PermissionUtils,
  Page,
  KendoGridHelper,
  ResolvedUsers,
  ViewsService,
  ResolvedContentPackages,
  ContentPackageSession,
  DateTimeFormatService
) {
  const users = ResolvedUsers.map(user => ({
    id: user.id,
    name: user.firstName + ' ' + user.lastName
  }))

  const packagesById = _.keyBy(ResolvedContentPackages, 'id')

  const getGrade = function getGrade (data) {
    if (
      data &&
      (data.cmi_core_lesson_status === 'passed' ||
        data.cmi_core_lesson_status === 'failed')
    ) {
      return data.cmi_core_score_raw ? data.cmi_core_score_raw : 'N/A'
    } else {
      return 'N/A'
    }
  }

  const initScreen = async function initScreen () {
    $scope.title = $rootScope.title = $translate.instant(
      'CONTENT_PACKAGE_SESSION'
    )
    Page.setTitleText($scope.title)
    $scope.PermissionUtils = PermissionUtils

    const tableColumns = [
      // USER
      {
        uniqueId: '4e848054-1b6f-4b62-8c9e-c20e485c6a14',
        field: 'UserId',
        translateCode: 'WF.USER',
        width: '180px',
        type: 'string',
        filterable: {
          mode: 'row',
          cell: {
            showOperators: false,
            template: function (args) {
              args.element.kendoDropDownList({
                filter: 'contains',
                autoBind: false,
                dataTextField: 'name',
                dataValueField: 'id',
                dataSource: new kendo.data.DataSource({
                  data: users
                }),
                valuePrimitive: true
              })
            }
          }
        },
        template: data => {
          const user = _.find(users, { id: data.UserId })
          if (user) return user.name
          return 'Deleted User'
        }
      },
      // CONTENT_PACKAGE_NAME
      {
        uniqueId: '9bdb50f8-6394-4ab8-998d-351c69058446',
        field: 'contentPackageId',
        translateCode: 'WF.NAME',
        width: '180px',
        type: 'object',
        filterable: {
          mode: 'row',
          cell: {
            showOperators: false,
            template: function (args) {
              args.element.kendoDropDownList({
                filter: 'contains',
                autoBind: false,
                dataTextField: 'name',
                dataValueField: 'id',
                dataSource: new kendo.data.DataSource({
                  data: ResolvedContentPackages
                }),
                valuePrimitive: true
              })
            }
          }
        },
        template: data => {
          const pack = packagesById[data.contentPackageId]
          return pack ? pack.name : ''
        }
      },
      // VIEWING DATE
      {
        uniqueId: '0bbed3af-d105-411c-a3e6-79a57153af9d',
        field: 'viewingDate',
        translateCode: 'ContentPackageSession.viewingDate',
        width: '150px',
        type: 'date',
        filterable: true,
        template: data => {
          return data.viewingDate === null
            ? '--'
            : DateTimeFormatService.formatDateTime(data.viewingDate, 'dateTime')
        }
      },
      // GRADE
      {
        uniqueId: '04bff2a1-e0ab-4903-9666-5ed7e4e231da',
        field: 'scromSessions',
        translateCode: 'ContentPackageSession.GRADE',
        width: '150px',
        type: 'object',
        filterable: false,
        template: data => {
          return data.scromSessions
            .map(scromSession => getGrade(scromSession.data))
            .join(', ')
        }
      }
    ]

    const basicFields = {
      id: true,
      UserId: true,
      contentPackageId: true,
      viewingDate: true,
      scromSessions: true,
      created: true
    }

    const { defaultTableColumns, customFieldsDisplay } =
      ViewsService.generateCustomFieldColumns(
        'ContentPackageSession',
        tableColumns
      )

    const baseFilter = {
      include: ['scromSessions'],
      order: 'created DESC',
      fields: _.merge(basicFields, customFieldsDisplay)
    }

    const { columns, selectedViewId } =
      ViewsService.getTablesColumns(defaultTableColumns)

    const { newBaseFilter, filters } = ViewsService.getViewCustomFilters(
      selectedViewId,
      _.cloneDeep(baseFilter)
    )

    const defaultTableSetup = {
      // stateName
      // ignoreParams
      find: ContentPackageSession.find,
      count: ContentPackageSession.count,
      cleanBaseFilter: baseFilter,
      baseFilter: newBaseFilter,
      selectedViewId: selectedViewId,
      columns: defaultTableColumns
    }

    const defaultTableToolbarSetup = {
      // stateName
      columns: defaultTableColumns,
      currentColumnIds: columns.map(c => c.uniqueId),
      filters: filters,
      selectedViewId: selectedViewId,
      title: $scope.title
    }

    $scope.downloadFunction = () => {}

    $scope.kendoGrid = await KendoGridHelper.GridInstance(
      defaultTableSetup,
      $scope,
      columns
    )

    await $scope.kendoGrid.isBound()

    $scope.tableToolbar = await ViewsService.GridToolBarInstance(
      defaultTableToolbarSetup,
      $scope.kendoGrid,
      $scope
    )

    $scope.$applyAsync()
  }

  initScreen()

  $scope.headerOptions = {
    icon: 'icon-document',
    template: require('app/templates/headers/simple.html'),
    title: $scope.title,
    fabButton: {}
  }
}

module.exports = ContentPackageSessionController
