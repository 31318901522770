/* global _ */
/** @ngInject */
function PrintPresetsController (
  $scope,
  $state,
  $mdDialog,
  KendoGridHelper,
  htmlWork,
  ViewsService,
  PrintPreset,
  DialogService,
  $translate,
  $rootScope
) {
  $scope.cancel = () => $mdDialog.hide()

  $scope.selectPreset = async function selectPreset (presetId) {
    $mdDialog.hide(presetId)
  }
  $scope.deletePreset = async function deletePreset (presetId) {
    const presetToDelete = await PrintPreset.findOne({
      filter: {
        where: { id: presetId },
        fields: {
          name: true
        }
      }
    }).$promise
    DialogService.deleteDialog(
      $translate.instant('WF.PRINT_DIALOG.CONFIGURATION.DELETE_PRESET_PROMPT', {
        presetName: presetToDelete.name
      })
    ).then(async function () {
      $rootScope.loadingProgress = true
      try {
        await PrintPreset.deletePreset({ presetId })
        $scope.kendoGrid.reloadData()
        $rootScope.loadingProgress = false
      } catch (e) {
        $rootScope.loadingProgress = false
        console.error(e)
        $rootScope.showErrorToast($translate.instant(e?.data?.error?.code))
      }
    })
  }
  const initScreen = async function initScreen () {
    const stateName = `${$state.$current.name}.dialog`
    const tableColumns = [
      // TITLE
      {
        uniqueId: '0c1b3aea-3a99-4386-a1f7-e246e74ca579',
        field: 'name',
        translateCode: 'Field.title',
        width: '40%',
        template: data => {
          return data.name
        }
      },
      // ACTIONS
      {
        uniqueId: '13021dba-6c48-4c3e-b2d3-1c2def6d65a6',
        field: 'id',
        translateCode: 'WF.ACTIONS',
        width: '20%',
        filterable: false,
        sortable: false,
        trustedTemplate: data => {
          return `<div layout="row">
                    <md-button class="md-icon-button" ng-click="selectPreset('${data.id}')" data-testid="preset-${data.id}">
                      <md-icon md-font-icon="icon-tray-arrow-down" class="s16 md-accent"></md-icon>
                    </md-button>
                    <md-button class="md-icon-button" ng-click="deletePreset('${data.id}')" data-testid="delete-${data.id}">
                      <md-icon md-font-icon="icon-delete" class="s16 md-warn"></md-icon>
                    </md-button>
                  </div>`
        }
      }
    ]

    const basicFields = {
      id: true,
      name: true
    }

    const baseFilter = {
      where: {},
      order: 'modified DESC',
      fields: basicFields
    }

    const { columns, selectedViewId } = ViewsService.getTablesColumns(
      tableColumns,
      stateName
    )

    const { newBaseFilter, filters } = ViewsService.getViewCustomFilters(
      selectedViewId,
      _.cloneDeep(baseFilter),
      stateName
    )

    const defaultTableSetup = {
      stateName,
      ignoreParams: true,
      find: PrintPreset.find,
      count: PrintPreset.count,
      cleanBaseFilter: baseFilter,
      baseFilter: newBaseFilter,
      selectedViewId,
      columns: tableColumns
    }

    const defaultTableToolbarSetup = {
      stateName,
      columns: tableColumns,
      currentColumnIds: columns.map(c => c.uniqueId),
      filters,
      selectedViewId,
      title: '$scope.title'
    }

    $scope.kendoGrid = await KendoGridHelper.GridInstance(
      defaultTableSetup,
      $scope,
      columns
    )

    await $scope.kendoGrid.isBound()

    $scope.tableToolbar = await ViewsService.GridToolBarInstance(
      defaultTableToolbarSetup,
      $scope.kendoGrid,
      $scope
    )

    $scope.$applyAsync()
  }

  initScreen()
}

module.exports = PrintPresetsController
