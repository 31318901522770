'use strict'
const debug = require('debug')('nextplus:date-utils')

/** @ngInject */
function DateUtils ($rootScope) {
  const getDate = function getDate (date) {
    debug('Server Time', $rootScope.appSettings.now)
    debug('Client Time', $rootScope.clientNow)
    debug('Time Diff', $rootScope.timeDiff)
    if (date) return new Date(+new Date(date) + $rootScope.timeDiff)
    return new Date(+new Date() + $rootScope.timeDiff)
  }
  return { Date: getDate }
}

module.exports = DateUtils
