import angular from 'angular'
import StockServiceCall from './stock.service.call'
import StockSKUConversion from './sku-conversion/sku-conversion.controller'
const MODULE_NAME = 'app.workorder.stock'

angular.module(MODULE_NAME, ['ngSanitize']).config(config)

/** @ngInject */
function config ($stateProvider, msNavigationServiceProvider) {
  // State
  $stateProvider
    .state('app.workorder.disassemblyAndAssembly', {
      url: '/disassemblyAndAssembly?:{parent:json}',
      params: {
        parent: {
          dynamic: true
        }
      },
      views: {
        'content@app': {
          template: require('./stock.service.call.html'),
          controller: StockServiceCall
        }
      },
      resolve: {
        ResolvedAvailableWarehouses: function (Priority) {
          return Priority.getAvailableWarehouses().$promise
        }
      },
      bodyClass: 'workorder workorder-show workorder-stock stock-serviceCall'
    })
    .state('app.workorder.sku-conversion', {
      url: '/sku-conversion?:{source:json}',
      params: {
        source: {
          dynamic: true
        }
      },
      views: {
        'content@app': {
          template: require('./sku-conversion/sku-conversion.template.html'),
          controller: StockSKUConversion
        }
      },
      bodyClass: 'workorder workorder-stock stock-sku-conversion'
    })
    .state('app.workorder.stock', {
      abstract: true,
      url: '/?:id/stock'
    })

  msNavigationServiceProvider.saveItem('apps.workorder.serviceCall', {
    title: 'WO.DISASSEMBLY_AND_ASSEMBLY',
    hidden: true,
    weight: 51,
    icon: 'icon-cog',
    state: 'app.workorder.disassemblyAndAssembly',
    acls: [{ model: 'Stock', method: 'serviceCall' }]
  })

  // msNavigationServiceProvider.saveItem('apps.sku-conversion', {
  //   title: 'WO.SKU_CONVERSION.TITLE',
  //   hidden: true,
  //   weight: 52,
  //   icon: 'icon-cached',
  //   state: 'app.workorder.sku-conversion',
  //   acls: [{ model: 'Stock', method: 'serviceCall' }]
  // })
}

export default MODULE_NAME
