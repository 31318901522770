/* global angular */
'use strict'
const moment = require('moment')

/** @ngInject */
function config (
  $translateProvider,
  $provide,
  $logProvider,
  $mdDateLocaleProvider,
  $httpProvider,
  msNavigationServiceProvider,
  $injector
) {
  msNavigationServiceProvider.saveItem('apps', {
    title: 'MAIN_MENU',
    group: true,
    hidden: true,
    weight: 1
  })
  $httpProvider.interceptors.push('httpResponseInterceptor')
  $provide.value('$providerInjector', $injector)
  $provide.value('$provide', $provide)
  /* $mdThemingProvider.definePalette('primary', {
    '50': 'e0e7ea',
    '100': 'b3c3cb',
    '200': '809ca8',
    '300': '4d7485',
    '400': '26566b',
    '500': '003851',
    '600': '00324a',
    '700': '002b40',
    '800': '002437',
    '900': '001727',
    A100: '62adff',
    A200: '2f93ff',
    A400: '0078fb',
    A700: '006ce1',
    contrastDefaultColor: 'light',
    contrastDarkColors: ['50', '100', '200', 'A100', 'A200'],
    contrastLightColors: [
      '300',
      '400',
      '500',
      '600',
      '700',
      '800',
      '900',
      'A400',
      'A700'
    ]
  })
  $mdThemingProvider.definePalette('accent', {
    '50': 'fff8ed',
    '100': 'feeed1',
    '200': 'fee3b3',
    '300': 'fdd895',
    '400': 'fccf7e',
    '500': 'fcc767',
    '600': 'fcc15f',
    '700': 'fbba54',
    '800': 'fbb34a',
    '900': 'faa639',
    A100: 'ffffff',
    A200: 'ffffff',
    A400: 'fff0dd',
    A700: 'ffe4c3',
    contrastDefaultColor: 'light',
    contrastDarkColors: [
      '50',
      '100',
      '200',
      '300',
      '400',
      '500',
      '600',
      '700',
      '800',
      '900',
      'A100',
      'A200',
      'A400',
      'A700'
    ],
    contrastLightColors: []
  })
  $mdThemingProvider
    .theme('nextplus')
    .primaryPalette('primary')
    .accentPalette('accent')
  $mdThemingProvider.setDefaultTheme('nextplus')
  console.log($mdThemingProvider) */

  let $cookies
  angular.injector(['ngCookies']).invoke([
    '$cookies',
    function (_$cookies_) {
      $cookies = _$cookies_
    }
  ])
  let debugEnabled = false
  if ($cookies.get('debugEnabled')) {
    debugEnabled = true
  }
  $logProvider.debugEnabled(debugEnabled)

  window.debugEnabled = $logProvider.debugEnabled
  $translateProvider.useSanitizeValueStrategy('escape')

  $mdDateLocaleProvider.formatDate = function (date) {
    const m = moment(date)
    return m.isValid() ? m.format('DD/MM/YYYY') : ''
  }

  $translateProvider.useLoader('customTranslateLoader')
  $translateProvider.fallbackLanguage('en')
}

module.exports = config
