module.exports = [
  'less',
  'less_or_equal',
  'greater',
  'greater_or_equal',
  'between',
  'not_between',
  'equal',
  'not_equal',
  'checked',
  'not_checked',
  'is_null',
  'is_not_null',
  'before',
  'after',
  'includes',
  'in',
  'includesPositive',
  'includesNegative'
]
