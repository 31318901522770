/* global alert */
/** @ngInject */
function AddEditProductionEntityTypeController ($scope, locals, $mdDialog) {
  $scope.cancel = function () {
    $mdDialog.cancel()
  }

  $scope.typeName = locals.typeName
  $scope.typeId = locals.typeId

  $scope.save = function () {
    if (!$scope.typeName) {
      alert('No type name')
    } else {
      $mdDialog.hide($scope.typeName)
    }
  }
}

module.exports = AddEditProductionEntityTypeController
