/** @ngInject */
function DeviceShowController (
  $scope,
  ResolvedStock,
  ResolvedUsers,
  ResolvedLocations,
  ResolvedTypes,
  ResolvedWorkorderStatus
) {
  $scope.resolvedData = ResolvedStock
  $scope.users = ResolvedUsers
  $scope.locations = ResolvedLocations
  $scope.workflowTypes = ResolvedTypes
  $scope.workorderStatus = ResolvedWorkorderStatus
}

module.exports = DeviceShowController
