/** @ngInject */

function LoginConfig ($stateProvider, msNavigationServiceProvider) {
  // State
  $stateProvider.state('app.login', {
    url: '/login?next',
    params: {
      setNewPassword: null,
      showConcurrency: null,
      next: null
    },
    views: {
      'main@': {
        template: require('../../core/layouts/content-only.html'),
        controller: 'MainController as vm'
      },
      'content@app.login': {
        template: require('./Login.html'),
        controller: 'LoginController as vm'
      }
    },
    resolve: {
      ResolvedSettings: Setting =>
        Setting.publicSettings({
          settingNames: [
            'smtp',
            'authentication-saml',
            'resetViaSMS',
            'dateTimeFormats'
          ]
        }).$promise
    },
    bodyClass: 'login'
  })
}

module.exports = LoginConfig
