/* global angular _ */
import WorkflowCustomFieldsDialogController from '../../workflow/workflowCustomFields/workflow-custom-fields-dialog.controller'
import { move } from 'app/helper'
const UUID = require('uuid')

/** @ngInject */
function WorkorderStatusFieldsEditController (
  $scope,
  $mdDialog,
  locals,
  MultiTranslateService,
  Field,
  $rootScope,
  FormlyHelper
) {
  $scope.selectedFields = angular.copy(locals.selectedFields) || []
  $scope.DBFields = angular.copy(locals.DBFields) || []
  $scope.formFields = []
  $rootScope.loadingProgress = false

  $scope.cancel = function () {
    $mdDialog.cancel()
  }
  $scope.save = function () {
    let fields
    const dbFieldsById = _.keyBy($scope.DBFields, 'fieldId')
    if ($scope.formFields && $scope.formFields.length > 0) {
      fields = $scope.formFields.map(field => {
        if (field.newField) {
          return {
            id: UUID(),
            fieldId: field.key
          }
        } else {
          return {
            id: dbFieldsById[field.key].id,
            fieldId: field.key
          }
        }
      })
      $mdDialog.hide({
        fields: fields
      })
    } else {
      $mdDialog.hide({
        fields: []
      })
    }
  }

  $scope.selectFields = function () {
    $mdDialog
      .show({
        controller: 'WorkflowCustomFieldsController',
        template: require('../../workflow/edit/dialog/workflow.fields.template.html'),
        parent: angular.element(document.body),
        targetEvent: '',
        multiple: true,
        resolve: {
          ResolvedData: () => {
            return {
              selectedFields: [],
              editFieldId: null,
              ignoreSubForms: false
            }
          }
        },
        clickOutsideToClose: false
      })
      .then(
        async function (response) {
          if (response) {
            const { fields } = response
            $scope.fieldsToAdd = fields
            const translatedFields = fields.map(field =>
              MultiTranslateService.getForView(Field, $scope.currentLang, field)
            )
            $scope.selectedFieldOrderModel = { fields: $scope.selectedFields }

            translatedFields.forEach(async field => {
              const formlyObject = await FormlyHelper.convertToFormlyObject(
                field
              )
              formlyObject.newField = true
              const fieldObject = _.cloneDeep(field)
              $scope.selectedFieldOrderModel.fields.push(fieldObject)
              $scope.formFields.push(formlyObject)
            })
          }
        },
        function () {}
      )
  }

  $scope.editFieldDialog = function editFieldDialog (field, idx) {
    $mdDialog
      .show({
        controller: WorkflowCustomFieldsDialogController,
        template: require('../../workflow/workflowCustomFields/workflow.custom-fields.dialog.html'),
        parent: angular.element(document.body),
        targetEvent: '',
        multiple: true,
        locals: {
          field: field || null
        },
        resolve: {
          ResolvedTables: Table => Table.find({}).$promise,
          ResolveFileParser: FileParser =>
            FileParser.find({ filter: { fields: { id: true, name: true } } })
              .$promise
        },
        clickOutsideToClose: false
      })
      .then(
        async function (response) {
          if (response) {
            let fieldIndex = $scope.formFields.findIndex(
              f => f.key === response.id
            )
            if (fieldIndex !== -1) {
              $scope.formFields[fieldIndex] = response
            }
            const formlyObject = await FormlyHelper.convertToFormlyObject(
              response
            )
            fieldIndex = _.findIndex($scope.selectedFieldOrderModel.fields, {
              id: response.id
            })
            if (fieldIndex !== -1) {
              $scope.selectedFieldOrderModel.fields[fieldIndex] = {
                ...response
              }
              $scope.formFields[fieldIndex] = formlyObject
            }
          }
        },
        function () {}
      )
  }

  $scope.selectedFieldOrderFields = [
    {
      key: 'fields',
      type: 'repeatedList',
      templateOptions: {
        label: 'title',
        hideHeader: true,
        limit: 0,

        onRemove: function (removedObject, index) {
          $rootScope.loadingProgress = true
          $scope.formFields.splice(index, 1)

          $rootScope.loadingProgress = false
        },
        onEdit: function (editedObject, index) {
          $rootScope.loadingProgress = true
          $scope.editFieldDialog(editedObject, index)

          $rootScope.loadingProgress = false
        },
        onSorted: function (newArray, oldIndex, newIndex) {
          $rootScope.loadingProgress = true
          $scope.formFields = move($scope.formFields, oldIndex, newIndex)

          $rootScope.loadingProgress = false
        }
      }
    }
  ]
  const initScreen = async function initScreen () {
    $rootScope.loadingProgress = true
    const fields = $scope.selectedFields.map(field =>
      MultiTranslateService.getForView(Field, $scope.currentLang, field)
    )
    $scope.selectedFieldOrderModel = { fields: [] }
    for (let i = 0; i < fields.length; i++) {
      const field = fields[i]
      const formlyObject = await FormlyHelper.convertToFormlyObject(field)
      const fieldObject = _.cloneDeep(field)
      $scope.selectedFieldOrderModel.fields.push(fieldObject)
      $scope.formFields.push(formlyObject)
    }
    $rootScope.loadingProgress = false
  }
  initScreen()
}

module.exports = WorkorderStatusFieldsEditController
