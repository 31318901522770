/* global angular _ */
const debug = require('debug')('nextplus:workorder-templates')
/** @ngInject */
function WorkorderTemplatesController (
  $scope,
  $rootScope,
  $translate,
  $mdDialog,
  PermissionUtils,
  Page,
  KendoGridHelper,
  ViewsService,
  WorkorderTemplate,
  htmlWork,
  isDialog
) {
  $scope.removeWorkorderTemplate = function removeWorkorderTemplate (
    workorderTemplateId
  ) {
    $mdDialog
      .show(
        $mdDialog
          .confirm()
          .title($translate.instant('WO.TEMPLATE.DELETE_MESSAGE'))
          .targetEvent()
          .clickOutsideToClose(true)
          .parent(angular.element(document.body))
          .ok($translate.instant('BUTTONS.DELETE'))
          .cancel($translate.instant('BUTTONS.CANCEL'))
      )
      .then(
        function () {
          WorkorderTemplate.destroyById({ id: workorderTemplateId })
            .$promise.then(() => {
              debug(
                `Workorder template '${workorderTemplateId}' deleted successfully`
              )
              $scope.kendoGrid.reloadData()
            })
            .catch(err => {
              console.error(err)
              $rootScope.showErrorToast('NP-1100')
              debug(
                `An error occurred while trying to delete a workorder template '${workorderTemplateId}'`,
                err
              )
            })
        },
        function () {}
      )
  }

  const hasDestroyPermissions = PermissionUtils.isPermit(
    'WorkorderTemplate',
    'deleteById'
  )
  const hasEditPermissions = PermissionUtils.isPermit(
    'WorkorderTemplate',
    'patchAttributes'
  )
  const hasCreatePermissions = PermissionUtils.isPermit(
    'WorkorderTemplate',
    'create'
  )

  $scope.cancel = () => $mdDialog.cancel()

  $scope.selectWorkTemplate = async function selectWorkTemplate (templateId) {
    const workorderTemplate = await WorkorderTemplate.findOne({
      filter: { where: { id: templateId } }
    }).$promise
    $mdDialog.hide(workorderTemplate)
  }

  const initScreen = async function initScreen () {
    $scope.title = $rootScope.title = $translate.instant(
      'WO.TEMPLATE.WORKORDER_TEMPLATES'
    )
    Page.setTitleText($scope.title)
    $scope.PermissionUtils = PermissionUtils
    $scope.usersPerRow = {}

    const tableColumns = [
      // NUMBER
      {
        uniqueId: '2913cd46-f632-40e6-9826-47afed108d4a',
        field: 'number',
        translateCode: 'WO.TEMPLATE.NUMBER',
        type: 'number',
        trustedTemplate: data => {
          return `<a ui-sref="app.workorder.template({id:'${
            data.id
          }'})">${htmlWork.htmlEncode(data.number)}</a>`
        }
        // media: '(min-width: 768px)',
      },
      // NAME
      {
        uniqueId: 'b5e7d9f7-0cac-4f38-9f8c-066342b5ec5b',
        field: 'name',
        translateCode: 'WO.TEMPLATE.NAME'
        // media: '(min-width: 768px)',
      },
      // ASSIGNEES
      {
        uniqueId: '4d13d9c2-12ca-40c2-8185-aee2a6fe4a6e',
        field: 'users',
        translateCode: 'WO.TEMPLATE.ASSIGNEES',
        type: 'array',
        filterable: false,
        sortable: false,
        // media: '(min-width: 768px)',
        trustedTemplate: data => {
          if (!data.users || data.users.length === 0 || !data.users[0].id) {
            return ''
          }
          $scope.usersPerRow[data.id] = data.users
          return `<users-avatar max-users="3" users="usersPerRow['${data.id}']"></users-avatar>`
        }
      },
      // WORKFLOW_NAME
      {
        uniqueId: '49ad14a6-bb3b-4d0f-8d0e-29798e59eaa1',
        field: 'workflowName',
        translateCode: 'WO.TEMPLATE.WORKFLOW_NAME'
        // media: '(min-width: 768px)',
      },
      // SKU
      {
        uniqueId: '7c6e33c4-a3b2-45f0-b807-efd7e0ca8fe2',
        field: 'sku',
        translateCode: 'WO.TEMPLATE.SKU'
        // media: '(min-width: 768px)',
      },
      // REV
      {
        uniqueId: '2569dfb5-10df-488a-930a-fabde72e53b2',
        field: 'rev',
        translateCode: 'WO.TEMPLATE.REV'
        // media: '(min-width: 768px)',
      },
      // ACTIONS
      {
        uniqueId: '90384574-ef43-441d-bea9-db22ea2db8a0',
        field: 'id',
        translateCode: 'COMMON.ACTIONS',
        headerAttributes: {
          class: 'actions-column-header'
        },
        // media: '(min-width: 768px)',
        filterable: false,
        sortable: false,
        trustedTemplate: data => {
          if (isDialog) {
            return `<div layout="row" layout-align="start center">
                    <md-button class="md-raised md-primary" ng-click="selectWorkTemplate('${data.id}')" data-testid="select-template-${data.id}">
                      <span translate="WO.TEMPLATE.BUTTON.SELECT"></span>
                    </md-button>
                  </div>`
          } else {
            let html = ''

            if (hasEditPermissions) {
              html += `<div layout="row" layout-align="start center">
            <md-button  ng-class="($root.isTabletOrMobile) ? ['md-fab','md-mini','md-primary'] : ['md-icon-button','show-button']"
                        ui-sref="app.workorder.template({id:'${data.id}'})"
                        ng-style="{'padding-top': !$root.isTabletOrMobile ? '12px !important' : 'auto' }"
                        data-testid="edit-${data.id}">
              <md-icon md-font-icon="icon-pencil" class="s16"></md-icon>
            </md-button>`
            }

            if (hasDestroyPermissions) {
              html += `<md-button ng-class="($root.isTabletOrMobile) ? ['md-fab','md-mini'] : ['md-icon-button']" class="md-warn"
                                ng-click="removeWorkorderTemplate('${data.id}')" data-testid="delete-${data.id}">
                          <md-icon md-font-icon="icon-delete" class="s16"></md-icon>
                        </md-button>`
            }
            html += '</div>'
            return html
          }
        }
      }
    ]

    const baseFilter = {
      where: { deletedAt: null },
      order: 'modified DESC'
    }

    const { defaultTableColumns } = ViewsService.generateCustomFieldColumns(
      'WorkorderTemplate',
      tableColumns
    )

    const dialogStateName = 'app.workorder.templates'
    const { columns, selectedViewId } = ViewsService.getTablesColumns(
      defaultTableColumns,
      dialogStateName
    )

    const { newBaseFilter, filters } = ViewsService.getViewCustomFilters(
      selectedViewId,
      _.cloneDeep(baseFilter)
    )

    const defaultTableSetup = {
      stateName: dialogStateName,
      ignoreParams: isDialog,
      autoSize: !isDialog,
      find: WorkorderTemplate.find,
      count: WorkorderTemplate.count,
      cleanBaseFilter: baseFilter,
      baseFilter: newBaseFilter,
      selectedViewId,
      columns: defaultTableColumns,
      encodeTitles: true
    }

    const defaultTableToolbarSetup = {
      stateName: dialogStateName,
      columns: defaultTableColumns,
      currentColumnIds: columns.map(c => c.uniqueId),
      filters,
      selectedViewId,
      title: $scope.title
    }

    $scope.downloadFunction = () => {}

    $scope.kendoGrid = await KendoGridHelper.GridInstance(
      defaultTableSetup,
      $scope,
      columns
    )

    await $scope.kendoGrid.isBound()

    $scope.tableToolbar = await ViewsService.GridToolBarInstance(
      defaultTableToolbarSetup,
      $scope.kendoGrid,
      $scope
    )

    $scope.$applyAsync()
  }

  initScreen()

  $scope.headerOptions = {
    icon: 'icon-file-document-multiple-outline',
    template: require('app/templates/headers/simple.html'),
    title: $scope.title,
    fabButton: {
      template: require('app/templates/headers/fab-button.html'),
      action: null,
      icon: 'icon-plus',
      href: '',
      state: 'app.workorder.template',
      showExpression: () => hasCreatePermissions
    }
  }
}

module.exports = WorkorderTemplatesController
