/** @ngInject */

function config (
  $provide,
  $ariaProvider,
  $mdAriaProvider,
  $logProvider,
  fuseConfigProvider
) {
  // Enable debug logging
  $logProvider.debugEnabled(false)
  $mdAriaProvider.disableWarnings()

  /*eslint-disable */

  // ng-aria configuration
  $ariaProvider.config({
    tabindex: false
  })

  // Fuse theme configurations
  fuseConfigProvider.config({
    disableCustomScrollbars: true,
    disableCustomScrollbarsOnMobile: true,
    disableMdInkRippleOnMobile: true
  })
}

module.exports = config
