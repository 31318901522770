/* global angular */
/** @ngInject */
function NavigationController($scope, $rootScope) {
  var vm = this
  $rootScope.NavigationController = vm
  // Data
  vm.bodyEl = angular.element('body')
  vm.folded = false
  vm.msScrollOptions = {
    suppressScrollX: true
  }

  // Methods
  vm.toggleMsNavigationFolded = toggleMsNavigationFolded

  /// ///////

  /**
   * Toggle folded status
   */
  function toggleMsNavigationFolded() {
    $scope.$emit('msNavigation::toggle')
    vm.folded = !vm.folded
  }

  // Close the mobile menu on $stateChangeSuccess
  $scope.$on('$stateChangeSuccess', function () {
    vm.bodyEl.removeClass('ms-navigation-horizontal-mobile-menu-active')
  })
}

module.exports = NavigationController
