/* global _ */
/** @ngInject */
function ContentPackageViewsController (
  $scope,
  $rootScope,
  $translate,
  PermissionUtils,
  KendoGridHelper,
  ViewsService,
  Page,
  ContentPackageView
) {
  const initScreen = async function initScreen () {
    $scope.title = $rootScope.title = $translate.instant('CONTENT_PACKAGE_TODO')
    Page.setTitleText($scope.title)
    $scope.PermissionUtils = PermissionUtils

    const tableColumns = [
      // NAME
      {
        uniqueId: '445220f3-e730-46e6-a118-ef5ad1228158',
        field: 'contentPackage.name',
        translateCode: 'CONTENTPACKAGESESSION.PACKAGE_NAME',
        width: '100%',
        filterable: false,
        template: data => {
          if (!data || !data.contentPackage) return ''
          return data.contentPackage.name
        }
        // media: '(min-width: 768px)',
      }
    ]

    const basicFields = {
      id: true,
      contentPackageId: true,
      modified: true,
      last: true,
      userIds: true,
      contentPackage: true
    }

    const {
      defaultTableColumns,
      customFieldsDisplay
    } = ViewsService.generateCustomFieldColumns(
      'ContentPackageView',
      tableColumns
    )

    const baseFilter = {
      where: { last: true, userIds: { nin: $rootScope.currentUser.id } },
      include: ['contentPackage'],
      order: 'created DESC',
      fields: _.merge(basicFields, customFieldsDisplay)
    }

    const { columns, selectedViewId } = ViewsService.getTablesColumns(
      defaultTableColumns
    )

    const { newBaseFilter, filters } = ViewsService.getViewCustomFilters(
      selectedViewId,
      _.cloneDeep(baseFilter)
    )

    const defaultTableSetup = {
      // stateName
      // ignoreParams
      find: ContentPackageView.find,
      count: ContentPackageView.count,
      cleanBaseFilter: baseFilter,
      baseFilter: newBaseFilter,
      selectedViewId: selectedViewId,
      columns: defaultTableColumns
    }

    const defaultTableToolbarSetup = {
      // stateName
      columns: defaultTableColumns,
      currentColumnIds: columns.map(c => c.uniqueId),
      filters: filters,
      selectedViewId: selectedViewId,
      title: $scope.title
    }

    $scope.downloadFunction = () => {}

    $scope.kendoGrid = await KendoGridHelper.GridInstance(
      defaultTableSetup,
      $scope,
      columns
    )

    await $scope.kendoGrid.isBound()

    $scope.tableToolbar = await ViewsService.GridToolBarInstance(
      defaultTableToolbarSetup,
      $scope.kendoGrid,
      $scope
    )

    $scope.$applyAsync()
  }

  initScreen()

  $scope.headerOptions = {
    icon: 'icon-document',
    template: require('app/templates/headers/simple.html'),
    title: $scope.title,
    fabButton: {}
  }
}

module.exports = ContentPackageViewsController
