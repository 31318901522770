/* global _ */
import angular from 'angular'

import LocationsController from './locations.controller'
import LocationShowController from './show/location.show.controller'

const MODULE_NAME = 'app.location'

angular
  .module(MODULE_NAME, ['ngSanitize'])
  .controller('LocationsController', LocationsController)
  .config(config)

/** @ngInject */
function config ($stateProvider, msApiProvider, msNavigationServiceProvider) {
  // State
  $stateProvider
    .state('app.location', {
      abstract: true,
      url: '/location'
    })
    .state('app.location.list', {
      url: '/?:filter',
      params: {
        filter: {
          dynamic: true
        }
      },
      views: {
        'content@app': {
          template: require('app/modules/components/tables/kendo-grid/grid.html'),
          controller: 'LocationsController',
          controllerAs: 'vm'
        }
      },
      bodyClass: 'location'
    })
    .state('app.location.show', {
      url: '/show/?:id',
      views: {
        'content@app': {
          template: require('./show/show.html'),
          controller: LocationShowController,
          controllerAs: 'vm'
        }
      },
      bodyClass: 'location location-show',
      resolve: {
        ResolvedLocation: function (Location, $stateParams) {
          if (_.isUndefined($stateParams.id) || $stateParams.id === '') {
            return false
          }
          return Location.findById({
            id: $stateParams.id
          }).$promise
        }
      }
    })

  // Navigation
  msNavigationServiceProvider.saveItem('apps.modeling.location', {
    title: 'Location.LOCATIONS',
    icon: 'icon-map-marker-multiple',
    state: 'app.location.list',
    weight: 50,
    acls: [{ model: 'Location', method: 'create' }]
  })
  // msNavigationServiceProvider.saveItem('apps.location.list', {
  //   title: 'Location.ALL_LOCATIONS',
  //   state: 'app.location.list',
  //   acls: [{ model: 'Location', method: 'create' }]
  // })
  // msNavigationServiceProvider.saveItem('apps.location.create', {
  //   title: 'Location.NEW_LOCATION',
  //   state: 'app.location.show',
  //   acls: [{ model: 'Location', method: 'create' }]
  // })
  // msNavigationServiceProvider.saveItem('apps.location.playground', {
  //   title: 'Playground',
  //   hidden: true,
  //   state:
  //     "app.location.playground({recordId:'04ebc589-8fb7-4dcc-8504-66c6f59790de'})",
  //   acls: []
  // })
}

export default MODULE_NAME
