/* global tinymce angular */
/**
 * Binds a TinyMCE widget to <textarea> elements.
 */
const MODULE_NAME = 'tinymce'
export default MODULE_NAME
import tinymceConfigValue from './tinymce.config.value'
import tinymceService from './tinymce.service'
import tinymceEditorComponent from './components/tinymce-editor.component'
import tinymceDisplayComponent from './components/tinymce-display.component'

angular
  .module(MODULE_NAME, [])
  .value('tinymceConfig', tinymceConfigValue)
  .component('tinymceEditor', tinymceEditorComponent)
  .component('tinymceDisplay', tinymceDisplayComponent)
  .service('tinymceService', tinymceService)
