/* global _ */
/** @ngInject */
function SettingsAddEditCategoryController (
  $scope,
  $rootScope,
  $translate,
  $compile,
  $state,
  locals,
  categories,
  Category,
  FormlyHelper,
  $mdToast,
  $mdDialog
) {
  $scope.Category = Category
  $scope.categories =
    locals && locals.id
      ? categories
        .filter(category => category.id !== locals.id)
        .map(category => ({
          id: category.id,
          name: category.name,
          parent: category.parentId
        }))
      : categories.map(category => ({
        id: category.id,
        name: category.name,
        parent: category.parentId
      }))
  const staticFields = [
    {
      key: 'name',
      type: 'input',
      className: 'layout-row',
      templateOptions: {
        type: 'text',
        required: true,
        label: $translate.instant('SETTINGS.CATEGORY_NAME'),
        onChange: function (value, options) {}
      }
    },
    {
      key: 'haveParent',
      type: 'checkbox',
      className: 'layout-row checkboxMargin',
      templateOptions: {
        label: $translate.instant('SETTINGS.HAS_CATEGORY_PARENT'),
        onChange: function (value, options) {}
      }
    },
    {
      key: 'parentId',
      type: 'selectTree',
      className: 'layout-column',
      templateOptions: {
        label: $translate.instant('SETTINGS.CATEGORY_PARENT'),
        options: $scope.categories,
        multiple: false,
        onChange: function (value, options) {}
      },
      hideExpression: function ($viewValue, $modelValue, scope) {
        return !scope.model.haveParent
      }
    },
    {
      key: 'weight',
      type: 'input',
      className: 'layout-row',
      templateOptions: {
        type: 'number',
        label: $translate.instant('SETTINGS.WEIGHT'),
        onChange: function (value, options) {}
      }
    }
  ]
  $scope.categoryFields = FormlyHelper.buildFields(staticFields, Category)
  $scope.cancel = function () {
    $mdDialog.cancel()
  }
  $scope.cat = locals || {
    name: '',
    parentId: null,
    weight: 0,
    haveParent: false
  }

  const validation = function validation () {
    const mdToast = $mdToast.nextplus({
      position: $rootScope.toastLocation,
      parent: 'body',
      theme: 'error-toast',
      hideDelay: 6000
    })
    if (!$scope.cat.name) {
      $mdToast.updateTextContent(
        $translate.instant('SETTINGS.CATEGORY_NAME_ERROR')
      )
      $mdToast.show(mdToast)
      $scope.translate($rootScope.appSettings.defaultContentLanguage)
      return false
    } else if ($scope.cat.haveParent && !$scope.cat.parentId) {
      $mdToast.updateTextContent(
        $translate.instant('SETTINGS.CATEGORY_PARENT_ERROR')
      )
      $mdToast.show(mdToast)
      return false
    }
    return true
  }

  $scope.save = function () {
    const isValid = validation()
    if (isValid) {
      $mdDialog.hide(_.omit($scope.cat, 'haveParent'))
    }
  }
}

module.exports = SettingsAddEditCategoryController
