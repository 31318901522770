/** @ngInject */
function tinymceService (UserModel) {
  const UITinymceService = function () {
    const ID_ATTR = 'tinymce'
    // uniqueId keeps track of the latest assigned ID
    let uniqueId = 0
    // getUniqueId returns a unique ID
    const getUniqueId = function () {
      uniqueId++
      return ID_ATTR + '-' + uniqueId
    }
    const WILDCARDS = {
      ASTERISK: '*',
      QUESTION_MARK: '?'
    }
    const WILDCARDS_REPLACEMENTS = {
      ASTERISK: `\.\*`,
      QUESTION_MARK: '.'
    }
    const escapeRegExp = function escapeRegExp (text) {
      if (typeof text !== 'string') return text
      Object.keys(WILDCARDS).forEach(wildcard => {
        text = text.split(WILDCARDS[wildcard]).join(wildcard)
      })
      text = text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
      Object.keys(WILDCARDS).forEach(wildcard => {
        text = text.split(wildcard).join(WILDCARDS_REPLACEMENTS[wildcard])
      })
      return text
    }
    const getMentionConfig = function () {
      return {
        name: 'mention',
        valid: () => {
          return UserModel.find({
            filter: { fields: ['id'] }
          }).$promise.then(users => {
            return users.map(user => `USER_${user.id}`)
          })
        },
        mapper: () => {
          return UserModel.find({
            filter: { fields: ['id', 'displayName'] }
          }).$promise.then(users => {
            return users.reduce((acc, user) => {
              acc[`USER_${user.id}`] = `@${user.displayName}`
              return acc
            }, {})
          })
        },
        autocomplete: term => {
          return UserModel.find({
            filter: {
              where: {
                or: [
                  {
                    displayName: {
                      like:
                        '.*' +
                        escapeRegExp(term) +
                        '.*' /*eslint-disable-line*/,
                      options: 'i'
                    }
                  },
                  {
                    email: {
                      like:
                        '.*' +
                        escapeRegExp(term) +
                        '.*' /*eslint-disable-line*/,
                      options: 'i'
                    }
                  }
                ]
              },
              fields: ['id', 'displayName']
            }
          }).$promise.then(users => {
            return users.map(user => {
              return {
                text: user.displayName,
                value: `USER_${user.id}`
              }
            })
          })
        },
        prefix: '@',
        suffix: '@',
        class: 'mention'
      }
    }
    // return the function as a public method of the service
    return {
      getUniqueId,
      getMentionConfig
    }
  }
  // return a new instance of the service
  return new UITinymceService()
}
module.exports = tinymceService
