/* global */
module.exports =
  /** @ngInject */
  function config ($stateProvider, msNavigationServiceProvider) {
    // State
    $stateProvider
      .state('app.timeline', {
        abstract: true,
        url: '/timeline',
        lazyLoad: $transition$ => {
          const $ocLazyLoad = $transition$.injector().get('$ocLazyLoad')
          return import(
            /* webpackChunkName: "form.module" */ './timeline.module.js'
          )
            .then(mod => {
              return $ocLazyLoad.inject(mod.default)
            })
            .catch(err => {
              throw new Error('Ooops, something went wrong, ' + err)
            })
        }
      })
      .state('app.timeline.list', {
        url: '/?:filter',
        params: {
          filter: {
            dynamic: true
          }
        },
        views: {
          'content@app': {
            template: require('app/modules/components/tables/kendo-grid/grid.html'),
            controller: 'TimelineListController',
            controllerAs: 'vm'
          }
        },
        bodyClass: 'timeline'
      })
      .state('app.timeline.show', {
        url: '/show/?:id/?:{initialValues:json}',
        params: {
          initialValues: {
            dynamic: true
          }
        },
        views: {
          'content@app': {
            template: require('./show/timeline-show.html'),
            controller: 'TimelineShowController',
            controllerAs: 'vm'
          }
        },
        bodyClass: 'timeline',
        resolve: {
          ResolvedTimeline: (Timeline, $stateParams) => {
            if (!$stateParams.id || $stateParams.id === '') {
              return null
            }
            return Timeline.findOne({
              filter: { where: { id: $stateParams.id } }
            }).$promise
          },
          ResolvedCategories: Category => Category.find({}).$promise,
          ResolvedGroups: Group => Group.find({}).$promise
        }
      })
      .state('app.timeline.display', {
        url: '/display/:id',
        views: {
          'content@app': {
            template: require('./display/timeline-display.html'),
            controller: 'TimelineDisplayController',
            controllerAs: 'vm'
          }
        },
        bodyClass: 'timeline',
        resolve: {
          ResolvedTimeline: (Timeline, $stateParams) => {
            return Timeline.findOne({
              filter: { where: { id: $stateParams.id } }
            }).$promise
          }
        }
      })

    // Navigation
    msNavigationServiceProvider.saveItem('apps.knowledgeBase.timeline', {
      title: 'TIMELINE.TIMELINES',
      icon: 'icon-timeline-clock-outline',
      state: 'app.timeline.list',
      hidden: false,
      weight: 20,
      acls: [{ model: 'Timeline', method: 'find' }]
    })
  }
