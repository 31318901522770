/** @ngInject */

module.exports = function config ($stateProvider, msNavigationServiceProvider) {
  // State
  $stateProvider
    .state('app.tables', {
      url: '/tables',
      abstract: true,
      lazyLoad: $transition$ => {
        const $ocLazyLoad = $transition$.injector().get('$ocLazyLoad')
        return import(
          /* webpackChunkName: "tables.module" */ './tables.module.js'
        )
          .then(mod => {
            return $ocLazyLoad.load(mod.default)
          })
          .catch(err => {
            throw new Error('Ooops, something went wrong, ' + err)
          })
      }
    })
    .state('app.tables.list', {
      url: '/?:filter',
      params: {
        filter: {
          dynamic: true
        }
      },
      views: {
        'content@app': {
          template: require('app/modules/components/tables/kendo-grid/grid.html'),
          controller: 'TablesController',
          controllerAs: 'vm'
        }
      },
      bodyClass: 'tables'
    })
    .state('app.tables.show', {
      url: '/show/?:id',
      params: {
        id: {
          dynamic: true
        }
      },
      views: {
        'content@app': {
          template: require('./show/table-show.template.html'),
          controller: 'TableShowController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        ResolvedTable: (Table, $stateParams) => {
          if (!$stateParams.id || $stateParams.id === '') {
            return null
          }
          return Table.findOne({
            filter: { where: { id: $stateParams.id } }
          }).$promise
        }
      },
      bodyClass: 'tables'
    })
    /**
     * Asaf Cohen: Some customers creates links to NPT (in document) with the old URL (without trailing /). So I created this route to make the link works.
     */
    .state('app.tables.column-management-old', {
      url: '/column-management/:id',
      resolve: {
        redirect: ($state, $stateParams) => {
          $state.go('app.tables.column-management', $stateParams)
        }
      }
    })
    .state('app.tables.column-management', {
      url: '/column-management/:id/?:filter',
      params: {
        filter: {
          dynamic: true
        }
      },
      views: {
        'content@app': {
          template: require('./column-management/column-management.template.html'),
          controller: 'TableColumnManagementController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        ResolvedTable: (Table, $stateParams) => {
          if (!$stateParams.id || $stateParams.id === '') {
            return null
          }
          return Table.findOne({
            filter: { where: { id: $stateParams.id } }
          }).$promise
        }
      },
      bodyClass: 'tables'
    })

  // Navigation
  msNavigationServiceProvider.saveItem('apps.modeling.tables', {
    title: 'TABLES.TABLES',
    icon: 'icon-table',
    state: 'app.tables.list',
    weight: 30
    // acls: [{ model: 'Table', method: 'create' }]
  })
}
