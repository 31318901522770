/* global angular */
/** @ngInject */
function MsSearchBarController (
  $scope,
  $element,
  $translate,
  $state,
  $rootScope,
  visionService
) {
  var vm = this

  // Data
  vm.collapsed = true
  vm.query = ''
  vm.queryOptions = {
    debounce: vm.debounce || 0
  }
  vm.resultsLoading = false
  vm.results = null
  vm.selectedResultIndex = 0
  vm.ignoreMouseEvents = false

  // Methods
  vm.expand = expand
  vm.collapse = collapse
  vm.makeSearch = makeSearch
  vm.openOCR = openOCR

  vm.absorbEvent = absorbEvent
  vm.handleKeydown = handleKeydown

  vm.sources = [
    { id: 1, name: $translate.instant('SEARCH.EVERYWHERE') },
    { id: 2, name: $translate.instant('SEARCH.DOCUMENTS') },
    { id: 3, name: $translate.instant('SEARCH.CATALOGS') },
    { id: 4, name: $translate.instant('SEARCH.WORKFLOWS') },
    { id: 5, name: $translate.instant('SEARCH.TIMELINES') },
    { id: 6, name: $translate.instant('SEARCH.RESOURCES') },
    { id: 7, name: $translate.instant('SEARCH.UNITS') },
    { id: 8, name: $translate.instant('SEARCH.WORKORDERS') }
  ]
  vm.selectedSource = vm.sources[0]

  /**
   * Expand
   */
  function expand () {
    // Set collapsed status
    vm.collapsed = false

    // Call expand on scope
    $scope.expand()

    // Callback
    if (vm.onExpand && angular.isFunction(vm.onExpand)) {
      vm.onExpand()
    }

    if ($rootScope.appSettings.globalSearchOCR) {
      isCameraAvailable().then(function (hasCamera) {
        if (hasCamera) {
          openOCR()
        }
      })
    }
  }
  async function openOCR () {
    await visionService.openVisionDialog({
      callback: callback,
      returnWords: false,
      key: `GLOBAL_SEARCH`
    })
  }

  function callback (selectedWord) {
    vm.query = selectedWord
    makeSearch(true)
  }
  function isCameraAvailable () {
    return new Promise(function (resolve, reject) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then(function (stream) {
          // User has a camera connected
          resolve(true)
        })
        .catch(function (error) {
          // User does not have a camera or denied access
          resolve(false)
        })
    })
  }

  function makeSearch (isOCR = false) {
    const query = vm.query
    if (query) {
      collapse()
      // if query source is OCR - results will be only from: sessions, workorders and parts
      $state.go('app.search_results.show', {
        type: vm.selectedSource.id,
        query: query,
        isOCR: isOCR
      })
    }
  }

  /**
   * Collapse
   */
  function collapse () {
    // Empty the query
    vm.query = ''

    // Empty results to hide the results view

    // Set collapsed status
    vm.collapsed = true

    // Call collapse on scope
    $scope.collapse()

    // Callback
    if (vm.onCollapse && angular.isFunction(vm.onCollapse)) {
      vm.onCollapse()
    }
  }

  /**
   * Absorb the given event
   *
   * @param event
   */
  function absorbEvent (event) {
    event.preventDefault()
  }

  /**
   * Handle keydown
   *
   * @param event
   */
  function handleKeydown (event) {
    var keyCode = event.keyCode
    var keys = [27]

    // Prevent the default action if
    // one of the keys are pressed that
    // we are listening
    if (keys.indexOf(keyCode) > -1) {
      event.preventDefault()
    }

    switch (keyCode) {
      // Enter
      case 13:
        // Trigger result click
        setTimeout(() => makeSearch(), 500)

        break
      // Escape
      case 27:
        // Collapse the search bar
        vm.collapse()

        break

      default:
        break
    }
  }
}

/** @ngInject */
function msSearchBarDirective ($document) {
  return {
    restrict: 'E',
    scope: {},
    require: 'msSearchBar',
    controller: 'MsSearchBarController as MsSearchBar',
    bindToController: {
      debounce: '=?',
      onSearch: '@',
      onResultClick: '&?',
      onExpand: '&?',
      onCollapse: '&?'
    },
    template: require('../ms-search-bar/ms-search-bar.html'),
    compile: function (tElement) {
      // Add class
      tElement.addClass('ms-search-bar')

      return function postLink (scope, iElement) {
        // Data
        var inputEl
        var bodyEl = $document.find('body')

        // Methods
        scope.collapse = collapse
        scope.expand = expand

        /// ///////

        // Initialize
        init()

        /**
         * Initialize
         */
        function init () {
          // Grab the input element
          inputEl = iElement.find('#ms-search-bar-input')
        }

        /**
         * Expand action
         */
        function expand () {
          // Add expanded class
          iElement.addClass('expanded')

          // Add helper class to the body
          bodyEl.addClass('ms-search-bar-expanded')

          // Focus on the input
          inputEl.focus()
        }

        /**
         * Collapse action
         */
        function collapse () {
          // Remove expanded class
          iElement.removeClass('expanded')

          // Remove helper class from the body
          bodyEl.removeClass('ms-search-bar-expanded')
        }
      }
    }
  }
}

module.exports = { MsSearchBarController, msSearchBarDirective }
