/** @ngInject */
function PrintDialogController (
  $scope,
  $translate,
  locals,
  $mdDialog,
  PriorityLabel,
  labels,
  $mdToast,
  $rootScope,
  $window
) {
  $scope.cancel = () => $mdDialog.hide()
  $scope.executePrint = async function executePrint () {
    $scope.loading = true
    $window.localStorage.setItem(
      `print-label-${$scope.printModel}`,
      $scope.print.labelId
    )
    try {
      await PriorityLabel.print($scope.print).$promise
      $mdToast.show(
        $mdToast.nextplus({
          position: $rootScope.toastLocation,
          parent: 'body',
          theme: 'success-toast',
          hideDelay: 3000
        })
      )
      $mdToast.updateTextContent(
        $translate.instant(
          'PRIORITY.LABELS.MESSAGES.LABELS_PRINTED_SUCCESSFULLY'
        )
      )
      $mdDialog.hide()
    } catch (ex) {
      $mdToast.show(
        $mdToast.nextplus({
          position: $rootScope.toastLocation,
          parent: 'body',
          theme: 'error-toast',
          hideDelay: 3000
        })
      )
      $mdToast.updateTextContent(
        $translate.instant('PRIORITY.LABELS.MESSAGES.ERROR_PRINTING_LABELS') +
          ':' +
          ex.data?.error?.message
      )

      console.error(ex)
    } finally {
      $scope.loading = false
    }
  }
  const initDialog = async function initDialog () {
    const labelIdOptions = [{ name: '', id: '' }]
    // Append label options
    labels.forEach(label => {
      if (label.models.includes(Object.keys(locals.printModels)[0])) {
        labelIdOptions.push({ name: label.name, id: label.id })
      }
    })
    $scope.print = {
      workorderIds: [],
      sessionIds: [],
      copies: 1
    }
    if (
      locals.printModels.Workorder &&
      locals.printModels.Workorder.length > 0
    ) {
      $scope.printModel = 'Workorder'
      $scope.print.workorderIds = locals.printModels.Workorder
    } else {
      $scope.print.sessionIds = locals.printModels.Session
      $scope.printModel = 'Session'
    }
    $scope.print.labelId =
      $window.localStorage.getItem(`print-label-${$scope.printModel}`) || null
    $scope.printFields = [
      {
        key: 'labelId',
        type: 'selectWithSearch',
        className: 'layout-row',
        templateOptions: {
          required: true,
          multiple: false,
          options: labelIdOptions,
          label: $translate.instant('PRIORITY.LABELS.LABEL')
        }
      },
      {
        key: 'copies',
        type: 'input',
        className: 'layout-row',
        templateOptions: {
          required: true,
          type: 'number',
          label: $translate.instant('PRIORITY.LABELS.COPIES')
        }
      }
    ]
    console.log(locals)
    $scope.$applyAsync()
  }

  initDialog()
}

module.exports = PrintDialogController
