/** @ngInject */


function SimpleTableController (Employees) {
  var vm = this

  // Data
  vm.employees = Employees.data

  // Methods

  //////////
}

module.exports = SimpleTableController
