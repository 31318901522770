/* global */

/** @ngInject */
function ShiftHelper (htmlWork, $translate) {
  const generateTypeTemplate = function generateTypeTemplate (
    type,
    foregroundColor,
    backgroundColor
  ) {
    return `<div style="white-space: nowrap; text-overflow: ellipsis;">
                      <span class="shift-type" style="background-color:${htmlWork.escapeHTMLQuotes(
                        backgroundColor
                      )}; color:${foregroundColor}; border-radius: 20px;">${htmlWork.htmlEncode(
      type
    )}</span>
                    </div>`
  }

  return {
    generateTypeTemplate
  }
}

module.exports = ShiftHelper
