/* global $ _ */
/** @ngInject */
function AddParameterController (
  $scope,
  $mdDialog,
  $translate,
  OpcUaServer,
  ResolvedOpcUaServers,
  KendoTreeViewHelper,
  $rootScope,
  ProductionEntityHelper,
  locals
) {
  $scope.parametersById = _.keyBy(locals.parameters, 'id')
  $scope.selectedFieldValue = null
  $scope.loadingValue = false
  let selectedFieldInterval = null
  if (locals.parameter) {
    $scope.model = locals.parameter
    $scope.isEdit = true
  } else {
    $scope.model = {
      id: null,
      nodeId: null,
      name: null,
      niceName: null,
      precision: null,
      maxLogPeriod: 1440,
      parameterType: ResolvedOpcUaServers.length > 0 ? 'OPC UA' : 'API',
      type: null
    }
    $scope.isEdit = false
  }

  $scope.changeType = function () {
    if ($scope.model.parameterType === 'OPC UA') {
      createKendoTree()
    }
  }

  const checkboxChange = function checkboxChange (args) {
    const item = args.sender.dataItem(args.node)
    if (item.checked) {
      $scope.model.id = item.id
      $scope.model.nodeId = item.nodeId
      $scope.model.serverId = item.serverId
      $scope.model.name = item.name
      $scope.loadingValue = true
      OpcUaServer.read({
        id: $scope.model.serverId,
        nodeId: $scope.model.nodeId
      }).$promise.then(function (data) {
        $scope.selectedFieldValue = data.value
        $scope.model.type = data.type
        $scope.model.precision = data.type === 'number' ? 2 : null
        $scope.loadingValue = false
      })
      selectedFieldInterval = setInterval(() => {
        $scope.loadingValue = true
        OpcUaServer.read({
          id: $scope.model.serverId,
          nodeId: $scope.model.nodeId
        }).$promise.then(function (data) {
          $scope.selectedFieldValue = data.value
          $scope.model.type = data.type
          $scope.loadingValue = false
        })
      }, 5000)
    } else {
      $scope.selectedFieldValue = null
      $scope.model.nodeId = null
      $scope.model.serverId = null
      $scope.model.name = null
      $scope.model.type = null
      $scope.model.id = null

      $scope.loadingValue = false
      clearInterval(selectedFieldInterval)
    }
  }
  const validate = function validate () {
    return (
      $scope.model.type &&
      $scope.model.name &&
      $scope.model.niceName &&
      $scope.model.maxLogPeriod &&
      ($scope.model.type !== 'number' ||
        ($scope.model.type === 'number' && $scope.model.precision))
    )
  }
  $scope.save = function () {
    if (validate()) {
      $mdDialog.hide($scope.model)
    } else {
      $rootScope.showErrorToast(
        $translate.instant(
          'PRODUCTION_ENTITY.PARAMETERS_TABLE.ERRORS.FIELDS_REQUIRED'
        )
      )
    }
  }

  $scope.cancel = function () {
    $mdDialog.cancel()
  }

  $scope.isLastStep = function (msWizard) {
    return $scope.isEdit
      ? msWizard.selectedIndex === 0
      : // : $scope.model.parameterType === 'OPC UA'
        // ? msWizard.selectedIndex === 2
        msWizard.selectedIndex === 1
  }

  const createKendoTree = function createKendoTree () {
    const serversData = []
    const nodesData = []
    ResolvedOpcUaServers.forEach(item => {
      if (item.id === item.serverId) {
        $scope.loadedNodeById[item.id] = false
        serversData.push({
          ...item,
          checked: false
        })
      } else {
        nodesData.push({
          ...item,
          checked: false
        })
      }
    })
    $scope.kendoTree.setup(
      {
        dataSource: KendoTreeViewHelper.convertDBToTree([], false)
      },
      $scope
    )
    setImmediate(() => {
      serversData.forEach(item => {
        $scope.kendoTree.instance.dataSource.add(item)
      })
      nodesData.forEach(item => {
        const parentItem = $scope.kendoTree.instance.dataSource.get(
          item.parentId
        )
        parentItem.append(item)
      })
    })
  }

  const initDialog = function initDialog () {
    $scope.loading = false
    if (!$scope.isEdit) {
      $scope.kendoTree = KendoTreeViewHelper.treeViewInstance({
        checkboxes: {
          template:
            '<input ng-if="dataItem.collectable" type="checkbox" ng-disabled="loading || ((model.nodeId && model.nodeId !== dataItem.nodeId)) || parametersById[dataItem.id]"/>'
        },
        check: checkboxChange,
        dragAndDrop: false,
        loadOnDemand: true,
        select: function (e) {
          e.preventDefault()
        },
        expand: async function (e) {
          const nodeElement = $(e.node)
          const item = e.sender.dataItem(e.node)
          if (item.items?.length > 0) {
            const filteredItems = item.items.filter(
              item => !$scope.loadedNodeById[item.id]
            )
            const childrenNodeIds = filteredItems.map(item => item.id)
            if (childrenNodeIds.length > 0) {
              if (nodeElement.find('.k-icon.k-i-loading').length > 0) {
                nodeElement.find('.k-icon.k-i-loading').remove()
              }
              const loadingIcon = $('<span class="k-icon k-i-loading"></span>')
              nodeElement.find('.k-in:first').append(loadingIcon)
              $scope.loading = true
              OpcUaServer.browse({
                serverId: item.serverId,
                nodeId: item.nodeId
              }).$promise.then(function (data) {
                data.forEach(item => {
                  if (!$scope.dataById[item.id]) {
                    $scope.dataById[item.id] = item
                    const parentItem = $scope.kendoTree.instance.dataSource.get(
                      item.parentId
                    )
                    parentItem.append({
                      ...item,
                      checked: false
                    })
                  }
                })
                childrenNodeIds.forEach(id => {
                  $scope.loadedNodeById[id] = true
                })
                loadingIcon.remove()
                $scope.loading = false
              })
            }
          }
        }
      })
      $scope.dataById = _.keyBy(ResolvedOpcUaServers, 'id')
      $scope.loadedNodeById = {}
      createKendoTree()
    }
    $scope.fields = [
      {
        key: 'nodeId',
        type: 'input',
        templateOptions: {
          label: $translate.instant(
            'PRODUCTION_ENTITY.PARAMETERS_TABLE.FIELDS.NODE_ID'
          ),
          required: true
        },
        expressionProperties: {
          'templateOptions.disabled': ($viewValue, $modelValue, scope) => {
            return $scope.model.parameterType === 'OPC UA'
          }
        }
      },
      {
        key: 'name',
        type: 'input',
        templateOptions: {
          label: $translate.instant(
            'PRODUCTION_ENTITY.PARAMETERS_TABLE.FIELDS.NAME'
          ),
          required: true
        },
        expressionProperties: {
          'templateOptions.disabled': ($viewValue, $modelValue, scope) => {
            return $scope.model.parameterType === 'OPC UA'
          }
        }
      },
      {
        key: 'type',
        type: 'select',
        templateOptions: {
          options: ProductionEntityHelper.availableTypes,
          label: $translate.instant(
            'PRODUCTION_ENTITY.PARAMETERS_TABLE.FIELDS.TYPE'
          ),
          required: true
        },
        expressionProperties: {
          'templateOptions.disabled': ($viewValue, $modelValue, scope) => {
            return $scope.model.parameterType === 'OPC UA'
          }
        }
      },
      {
        key: 'niceName',
        type: 'input',
        templateOptions: {
          label: $translate.instant(
            'PRODUCTION_ENTITY.PARAMETERS_TABLE.FIELDS.NICE_NAME'
          ),
          required: true
        }
      },
      {
        key: 'precision',
        type: 'input',
        templateOptions: {
          type: 'number',
          min: 0,
          max: 5,
          label: $translate.instant(
            'PRODUCTION_ENTITY.PARAMETERS_TABLE.FIELDS.PRECISION'
          ),
          required: true
        },
        hideExpression: ($viewValue, $modelValue, scope) => {
          return $scope.model.type !== 'number'
        }
      },
      {
        key: 'maxLogPeriod',
        type: 'select',
        templateOptions: {
          options: ProductionEntityHelper.availableMaxLogPeriods,
          label: $translate.instant(
            'PRODUCTION_ENTITY.PARAMETERS_TABLE.FIELDS.MAX_LOG_PERIOD'
          ),
          required: true
        }
      }
    ]
  }
  initDialog()
  $scope.$on('$destroy', function () {
    clearInterval(selectedFieldInterval)
  })
}

module.exports = AddParameterController
