/* global _ */

export const formPermission = function formPermission (
  permissionType,
  form,
  userCertificates,
  userRoles
) {
  const userPermissions = userCertificates
    .map(certificate => `certificate_${certificate}`)
    .concat(userRoles.map(role => `role_${role}`))
  const { viewCertificate, createCertificate, closeCertificate } = form
  const view = viewCertificate || []
  const create = createCertificate || []
  const close = closeCertificate || []
  let uniqCertificate = []
  switch (permissionType) {
    case 'view': {
      if (view.length === 0) {
        return true
      }
      const viewCertifications = [...view, ...create, ...close]
      uniqCertificate = _.uniq(viewCertifications)
      break
    }
    case 'create': {
      if (create.length === 0) {
        return true
      }
      const createCertifications = [...create, ...close]
      uniqCertificate = _.uniq(createCertifications)
      break
    }
    case 'close': {
      if (close.length === 0) {
        return true
      }
      uniqCertificate = _.uniq(close)
      break
    }
    default:
      return false
  }
  return userPermissions.some(c => uniqCertificate.indexOf(c) >= 0)
}
