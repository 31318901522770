import angular from 'angular'

// -------------------------------CONFIG--------------------------
import fuseConfigProvider from './config/fuseConfig'

// -------------------------------DIRECTIVES----------------------

import msInfoBarDirective from './directives/ms-info-bar/ms-info-bar.directive'

import {
  msNavigationServiceProvider,
  MsNavigationController,
  msNavigationDirective,
  MsNavigationNodeController,
  msNavigationNodeDirective,
  msNavigationItemDirective,
  msNavigationHorizontalDirective,
  MsNavigationHorizontalNodeController,
  msNavigationHorizontalNodeDirective,
  msNavigationHorizontalItemDirective
} from './directives/ms-navigation/ms-navigation.directive'
import {
  MsFormWizardController,
  msFormWizardDirective,
  msFormWizardFormDirective
} from './directives/ms-form-wizard/ms-form-wizard.directive'
import {
  MsSearchBarController,
  msSearchBarDirective
} from './directives/ms-search-bar/ms-search-bar.directive'

import {
  MsShortcutsController,
  msShortcutsDirective
} from './directives/ms-shortcuts/ms-shortcuts.directive'

import msSidenavHelperDirective from './directives/ms-sidenav-helper/ms-sidenav-helper.directive'

import msSplashScreenDirective from './directives/ms-splash-screen/ms-splash-screen.directive'

import {
  MsStepperController,
  msHorizontalStepperDirective,
  msHorizontalStepperStepDirective,
  msVerticalStepperDirective,
  msVerticalStepperStepDirective
} from './directives/ms-stepper/ms-stepper.directive'

import {
  MsTimelineController,
  msTimelineDirective,
  msTimelineItemDirective
} from './directives/ms-timeline/ms-timeline'

import {
  MsWidgetController,
  msWidgetDirective,
  msWidgetFrontDirective,
  msWidgetBackDirective
} from './directives/ms-widget/ms-widget.directive'

import config from './config'
import coreRunBlock from './CoreRunBlock'

import ClockAnimation from './directives/clock-animation/clock-animation'

// -------------------------------FILTERS----------------------

import altDate from './filters/altDate.filter'
import nl2br from './filters/nl2br.filter'

import {
  toTrustedFilter,
  htmlToPlainTextFilter,
  nospaceFilter,
  humanizeDocFilter
} from './filters/basic.filter'

import filterByIds from './filters/filterByIds.filter'

import filterByPropIds from './filters/filterByPropIds.filter'

import { filterByTags, filterSingleByTags } from './filters/tag.filter'

// -------------------------------SERVICES----------------------

import apiResolverService from './services/api-resolver.service'
import msUtils from './services/ms-utils.service'
import msApiProvider from './services/ms-api.provider'

// -------------------------------ThemeOptions----------------------

import {
  MsThemeOptionsController,
  msThemeOptions
} from './theme-options/theme-options.directive'

// -------------------------------Theming----------------------

import fuseGeneratorService from './theming/fuse-generator.service'

import fusePalettes from './theming/fuse-palettes.constant'

import fuseThemes from './theming/fuse-themes.constant'

import Themeconfig from './theming/fuse-theming.config'

import fuseThemingService from './theming/fuse-theming.service'

const MODULE_NAME = 'app.core'

/** @ngInject */

angular
  .module(MODULE_NAME, [
    'ngAnimate',
    'ngAria',
    'ngCookies',
    'ngMessages',
    'ngResource',
    'ngSanitize',
    'ngMaterial',
    'pascalprecht.translate',
    'ui.router',
    'angularMoment'
  ])

  .provider('fuseConfig', fuseConfigProvider)
  .controller('MsFormWizardController', MsFormWizardController)
  .directive('msFormWizard', msFormWizardDirective)
  .directive('msFormWizardForm', msFormWizardFormDirective)

  .directive('msInfoBar', msInfoBarDirective)
  .provider('msNavigationService', msNavigationServiceProvider)
  .controller('MsNavigationController', MsNavigationController)
  // Vertical
  .directive('msNavigation', msNavigationDirective)
  .controller('MsNavigationNodeController', MsNavigationNodeController)
  .directive('msNavigationNode', msNavigationNodeDirective)
  .directive('msNavigationItem', msNavigationItemDirective)
  // Horizontal
  .directive('msNavigationHorizontal', msNavigationHorizontalDirective)
  .controller(
    'MsNavigationHorizontalNodeController',
    MsNavigationHorizontalNodeController
  )
  .directive('msNavigationHorizontalNode', msNavigationHorizontalNodeDirective)
  .directive('msNavigationHorizontalItem', msNavigationHorizontalItemDirective)
  .controller('MsSearchBarController', MsSearchBarController)
  .directive('msSearchBar', msSearchBarDirective)
  .controller('MsShortcutsController', MsShortcutsController)
  .directive('msShortcuts', msShortcutsDirective)
  .directive('msSidenavHelper', msSidenavHelperDirective)
  .directive('msSplashScreen', msSplashScreenDirective)
  .controller('MsStepperController', MsStepperController)
  .directive('msHorizontalStepper', msHorizontalStepperDirective)
  .directive('msHorizontalStepperStep', msHorizontalStepperStepDirective)
  .directive('msVerticalStepper', msVerticalStepperDirective)
  .directive('msVerticalStepperStep', msVerticalStepperStepDirective)
  .controller('MsTimelineController', MsTimelineController)
  .directive('msTimeline', msTimelineDirective)
  .directive('msTimelineItem', msTimelineItemDirective)
  .controller('MsWidgetController', MsWidgetController)
  .directive('msWidget', msWidgetDirective)
  .directive('msWidgetFront', msWidgetFrontDirective)
  .directive('msWidgetBack', msWidgetBackDirective)
  .component('clockAnimation', ClockAnimation)
  .filter('nl2br', nl2br)
  .filter('altDate', altDate)
  .filter('toTrusted', toTrustedFilter)
  .filter('htmlToPlaintext', htmlToPlainTextFilter)
  .filter('nospace', nospaceFilter)
  .filter('humanizeDoc', humanizeDocFilter)
  .filter('filterByIds', filterByIds)
  .filter('filterByPropIds', filterByPropIds)
  .filter('filterByTags', filterByTags)
  .filter('filterSingleByTags', filterSingleByTags)

  .factory('apiResolver', apiResolverService)
  .provider('msApi', msApiProvider)
  .factory('msUtils', msUtils)

  .controller('MsThemeOptionsController', MsThemeOptionsController)
  .directive('msThemeOptions', msThemeOptions)

  .factory('fuseGenerator', fuseGeneratorService)
  .constant('fusePalettes', fusePalettes)
  .constant('fuseThemes', fuseThemes)
  .config(Themeconfig)
  .service('fuseTheming', fuseThemingService)

  .config(config)
  .run(coreRunBlock)

export default MODULE_NAME
