/** @ngInject */

function config (msNavigationServiceProvider) {
  // Navigation
  msNavigationServiceProvider.saveItem('components', {
    title: 'COMPONENTS',
    group: true,
    weight: 4
  })

  msNavigationServiceProvider.saveItem('components.tables', {
    title: 'Tables',
    icon: 'icon-table-large',
    weight: 7
  })

  msNavigationServiceProvider.saveItem('components.tables.simple-table', {
    title: 'Simple Table',
    state: 'app.components_tables_simple-table',
    weight: 8
  })

  msNavigationServiceProvider.saveItem('components.tables.datatable', {
    title: 'Datatable',
    state: 'app.components_tables_datatable',
    weight: 9
  })
}

module.exports = config
