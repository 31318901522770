/** @ngInject */

module.exports = function config (
  $stateProvider,
  msApiProvider,
  msNavigationServiceProvider
) {
  // State
  $stateProvider
    .state('app.forum', {
      abstract: true,
      url: '/forum',
      lazyLoad: $transition$ => {
        const $ocLazyLoad = $transition$.injector().get('$ocLazyLoad')
        return import(/* webpackChunkName: "forum.module" */ './forum.module')
          .then(mod => {
            return $ocLazyLoad.inject(mod.default)
          })
          .catch(err => {
            throw new Error('Ooops, something went wrong, ' + err)
          })
      }
    })
    .state('app.forum.list', {
      url: '/list',
      views: {
        'content@app': {
          template: require('./forum-list/forum-list.html'),
          controller: 'ForumListController'
        }
      },
      resolve: {
        ResolvedForums: Forum =>
          Forum.find({ filter: { include: 'image_object' } }).$promise
      }
    })
    .state('app.forum.new', {
      url: '/new',
      views: {
        'content@app': {
          template: require('./forum-new-edit/forum-new-edit.html'),
          controller: 'ForumNewEditController'
        }
      },
      resolve: {
        ResolvedForum: () => null,
        ResolvedGroups: Group => Group.find({}).$promise,
        ResolvedCategories: Category => Category.find({}).$promise
      }
    })
    .state('app.forum.edit', {
      url: '/edit/:id',
      views: {
        'content@app': {
          template: require('./forum-new-edit/forum-new-edit.html'),
          controller: 'ForumNewEditController'
        }
      },
      resolve: {
        ResolvedForum: function (Forum, $stateParams) {
          return Forum.findOne({
            filter: {
              where: { id: $stateParams.id },
              include: ['image_object']
            }
          }).$promise
        },
        ResolvedGroups: Group => Group.find({}).$promise,
        ResolvedCategories: Category => Category.find({}).$promise
      }
    })
    .state('app.forum.show', {
      url: '/show/:id',
      views: {
        'content@app': {
          template: require('./forum-show/forum-show.html'),
          controller: 'ForumShowController'
        }
      },
      resolve: {
        ResolvedForum: (Forum, $stateParams) =>
          Forum.findOne({
            filter: {
              where: { id: $stateParams.id }
            }
          }).$promise
      }
    })
    .state('app.forum.post-show', {
      url: '/show/:forumId/post/:postId',
      views: {
        'content@app': {
          template: require('./forum-post-show/forum-post-show.html'),
          controller: 'ForumPostShowController'
        }
      }
    })
    .state('app.forum.post-new', {
      url: '/show/:forumId/post/new',
      views: {
        'content@app': {
          template: require('./forum-post-new/forum-post-new.html'),
          controller: 'ForumPostNewController'
        }
      },
      resolve: {
        ResolvedPost: () => {}
      }
    })

  // Navigation
  msNavigationServiceProvider.saveItem('apps.knowledgeBase.forum', {
    title: 'FORUM.FORUM',
    icon: 'icon-forum',
    state: 'app.forum.list',
    hidden: false,
    weight: 70,
    acls: [{ model: 'Forum', method: 'find' }]
  })
}
