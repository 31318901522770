/* global */
/** @ngInject */
function AddEditShiftTypeController (
  $scope,
  $rootScope,
  $translate,
  $mdDialog,
  ResolvedShiftType,
  ShiftType
) {
  $scope.isEdit = !!ResolvedShiftType
  $scope.cancel = function () {
    $mdDialog.cancel()
  }
  const validate = async function validate () {
    if (!$scope.model.name) {
      $rootScope.showErrorToast(
        $translate.instant('SHIFT.SHIFT_TYPES.ERRORS.NAME')
      )
      return false
    } else if (!$scope.model.startTime) {
      $rootScope.showErrorToast(
        $translate.instant('SHIFT.SHIFT_TYPES.ERRORS.START_TIME')
      )
      return false
    } else if (!$scope.model.endTime) {
      $rootScope.showErrorToast(
        $translate.instant('SHIFT.SHIFT_TYPES.ERRORS.END_TIME')
      )
      return false
    }
    return true
  }
  $scope.save = async function () {
    const isValid = await validate()
    if (isValid) {
      if ($scope.isEdit) {
        ShiftType.prototype$patchAttributes(
          { id: ResolvedShiftType.id },
          $scope.model
        ).$promise.then(() => {
          $rootScope.showSuccessToast(
            $translate.instant('SHIFT.SHIFT_TYPES.UPDATE_SUCCESS')
          )
          $mdDialog.hide($scope.model)
        })
      } else {
        ShiftType.create($scope.model).$promise.then(() => {
          $rootScope.showSuccessToast(
            $translate.instant('SHIFT.SHIFT_TYPES.CREATE_SUCCESS')
          )
          $mdDialog.hide($scope.model)
        })
      }
    }
  }

  const initScreen = async function initScreen () {
    $scope.model = {
      name: '',
      startTime: '',
      endTime: '',
      foregroundColor: '#ffffff',
      backgroundColor: '#000000'
    }
    if ($scope.isEdit) {
      $scope.model = {
        name: ResolvedShiftType.name,
        startTime: ResolvedShiftType.startTime,
        endTime: ResolvedShiftType.endTime,
        foregroundColor: ResolvedShiftType.foregroundColor,
        backgroundColor: ResolvedShiftType.backgroundColor
      }
    }
    $scope.fields = [
      // NAME
      {
        key: 'name',
        type: 'input',
        className: 'layout-row',
        templateOptions: {
          type: 'text',
          focus: true,
          label: $translate.instant('SHIFT.SHIFT_TYPES.FIELDS.NAME'),
          placeholder: $translate.instant('SHIFT.SHIFT_TYPES.FIELDS.NAME'),
          onChange: function (value, options) {}
        }
      },
      // START TIME
      {
        key: 'startTime',
        type: 'timePicker',
        className: 'flex',
        templateOptions: {
          required: true,
          label: $translate.instant('SHIFT.SHIFT_TYPES.FIELDS.START_TIME')
        }
      },
      // END TIME
      {
        key: 'endTime',
        type: 'timePicker',
        className: 'flex',
        templateOptions: {
          required: true,
          label: $translate.instant('SHIFT.SHIFT_TYPES.FIELDS.END_TIME'),
          onChange: function (value, options) {}
        }
      },
      // FOREGROUND COLOR
      {
        key: 'foregroundColor',
        type: 'colorPalette',
        className: 'flex-10',
        templateOptions: {
          colorPaletteOptions: {
            opacity: false,
            tileSize: 30,
            paletteDisplayed: false
          },
          label: $translate.instant('SHIFT.SHIFT_TYPES.FIELDS.FOREGROUND_COLOR')
        }
      },
      // BACKGROUND COLOR
      {
        key: 'backgroundColor',
        type: 'colorPalette',
        className: 'flex-10',
        templateOptions: {
          colorPaletteOptions: {
            opacity: false,
            tileSize: 30,
            paletteDisplayed: false
          },
          label: $translate.instant('SHIFT.SHIFT_TYPES.FIELDS.BACKGROUND_COLOR')
        }
      }
    ]
  }

  initScreen()
}

module.exports = AddEditShiftTypeController
