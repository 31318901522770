/* global angular, _, moment */

import Languages from '../../constants/languages'

/** @ngInject */
function ToolbarController (
  $rootScope,
  $q,
  $state,
  $timeout,
  $mdSidenav,
  $translate,
  LoginService,
  msNavigationService,
  UserModel,
  LanguageUtils,
  $scope,
  getUrlFromObj,
  SyncUtils,
  Page,
  BreakService,
  PermissionUtils,
  $window,
  $stateParams,
  NotificationUtils,
  TimeLogReport,
  nextplusSocket,
  $ocLazyLoad,
  $mdDialog
) {
  const vm = this
  $scope.translations = {}
  ;[
    'TOOLBAR.LOAD_MORE',
    'TOOLBAR.NO_NOTIFICATIONS_YET',
    'COMMON.PASSWORD_CHANGE',
    'TOOLBAR.LOGOUT',
    'TOOLBAR.REMOVE_FROM_BOOKMARK',
    'TOOLBAR.MORE',
    'TOOLBAR.BREAK',
    'TOOLBAR.REFRESH',
    'TOOLBAR.TOGGLE_NAVIGATION',
    'TOOLBAR.USER_PROFILE',
    'TOOLBAR.LANGUAGE',
    'TOOLBAR.BOOKMARKS',
    'TOOLBAR.ADD_TO_BOOKMARK',
    'TOOLBAR.NOTIFICATIONS'
  ].forEach(translateCode => {
    $scope.translations[translateCode] = $translate.instant(translateCode)
  })
  vm.LoginService = LoginService
  $scope.PermissionUtils = PermissionUtils
  // Data
  $rootScope.global = {
    search: ''
  }
  $scope.getUrlFromObj = getUrlFromObj
  $scope.getNotificationNotReadCount = () => {
    if (!$rootScope.notifications) $rootScope.notifications = []
    return $rootScope.notifications.filter(
      notification => notification.read === false
    ).length
  }
  const userTimerChange = function userTimerChange (data) {
    if (data?.start) {
      $scope.timer = { start: data.start }
    } else {
      $scope.timer = {}
    }
  }
  nextplusSocket.promiseState.then(() => {
    nextplusSocket.angularSocket.on('manualTimeRecordChange', userTimerChange)
  })

  const checkUserOpenTimer = async function checkUserOpenTimer () {
    const res = await TimeLogReport.checkForUserOpenTimer().$promise
    if (res?.userOpenTimer) {
      $scope.timer = { start: res.userOpenTimer.start }
    } else {
      $scope.timer = {}
    }
  }

  $scope.updateTimePassed = () => {
    $rootScope.notifications = $rootScope.notifications.map(notification => {
      try {
        notification.timePassed = moment(notification.created)
          .locale(LanguageUtils.language)
          .fromNow()
      } catch (err) {
        notification.timePassed = moment(notification.created).fromNow()
      }
      return notification
    })
  }

  $scope.loadMore = event => {
    event.preventDefault()
    event.stopPropagation()
    event.cancelBubble = true
    NotificationUtils.loadMore($rootScope.notifications.length)
  }

  $scope.markAllAsRead = () => {
    const notificationToMarkAsRead = _.cloneDeep(
      $rootScope.notifications.filter(
        notification => notification.read === false
      )
    )
    notificationToMarkAsRead.forEach(notification => {
      const index = _.findIndex($rootScope.notifications, {
        id: notification.id
      })
      $rootScope.notifications[index].read = true
    })
    NotificationUtils.markNotificationsAsRead(
      _.map(notificationToMarkAsRead, 'id')
    )
    $scope.updateTimePassed()
  }

  $rootScope.markAsClicked = function markAsClicked (notification) {
    const index = _.findIndex($rootScope.notifications, { id: notification.id })
    $rootScope.notifications[index].clicked = true
    NotificationUtils.markNotificationsAsClicked(notification.id)
  }

  vm.currentUser = $rootScope.currentUser
  vm.canEditProfile = PermissionUtils.isPermit(
    'UserModel',
    'patchAttributes',
    'ALLOW',
    vm.currentUser.id
  )
  vm.bodyEl = angular.element('body')
  vm.userStatusOptions = [
    {
      title: 'Online',
      icon: 'icon-checkbox-marked-circle',
      color: '#4CAF50'
    },
    {
      title: 'Away',
      icon: 'icon-clock',
      color: '#FFC107'
    },
    {
      title: 'Do not Disturb',
      icon: 'icon-minus-circle',
      color: '#F44336'
    },
    {
      title: 'Invisible',
      icon: 'icon-checkbox-blank-circle-outline',
      color: '#BDBDBD'
    },
    {
      title: 'Offline',
      icon: 'icon-checkbox-blank-circle-outline',
      color: '#616161'
    }
  ]

  vm.languages = {}

  if (!$rootScope.appSettings.availableLanguages) {
    $rootScope.appSettings.availableLanguages = ['en']
  }
  if (!$rootScope.appSettings.language) $rootScope.appSettings.language = 'en'

  vm.languagesCount = $rootScope.appSettings.availableLanguages.length
  $rootScope.appSettings.availableLanguages.forEach(lang => {
    vm.languages[lang] = Languages[lang]
  })

  $scope.goToBookmark = bookmark => $state.go(bookmark.state, bookmark.params)

  $scope.pageInBookmark = () => {
    if (!$rootScope.currentUser) return false
    return (
      _.findIndex($rootScope.currentUser.bookmarks, {
        name: Page.textTitle,
        state: $state.current.name
      }) > -1
    )
  }

  $scope.updateUserBookmarks = () =>
    UserModel.prototype$patchAttributes(
      { id: $rootScope.currentUser.id },
      { bookmarks: $rootScope.currentUser.bookmarks }
    ).$promise

  $scope.removeFromBookmark = () => {
    const index = _.findIndex($rootScope.currentUser.bookmarks, {
      name: Page.textTitle,
      state: $state.current.name
    })
    if (index > -1) $rootScope.currentUser.bookmarks.splice(index, 1)
    $scope.updateUserBookmarks()
  }

  $scope.startBreak = async () => {
    /* if ($state.current.name === 'app.workflow.session.show') {
      $state.go('app.dashboards_project')
    } */
    BreakService.start()
  }

  $scope.reloadPage = () => {
    $window.location.reload()
  }

  $scope.showTimeLoggingReport = function showTimeLoggingReport () {
    $rootScope.loadingProgress = true
    const sessionData = $rootScope.sessionData || null
    import(
      /* webpackChunkName: "time-logging.module" */ 'app/modules/main/time-logging/time-logging.module.js'
    ).then(mod => {
      $ocLazyLoad.inject(mod.default)
      $rootScope.loadingProgress = false
      $mdDialog.show({
        controller: 'TimeLoggingController',
        template: require('../main/workflow/session/templates/workflow-session.time-log-report.template.html'),
        parent: angular.element(document.body),
        multiple: true,
        clickOutsideToClose: false,
        escapeToClose: true,
        fullscreen: true,
        resolve: {
          ResolvedSessionData: () => {
            return sessionData
          },
          ResolvedActiveTimeLog: (TimeLogReport, $rootScope) => {
            return new Promise(async (resolve, reject) => {
              const timeLogs = await TimeLogReport.find({
                filter: {
                  limit: 1,
                  where: {
                    end: { eq: null },
                    userId: $rootScope.currentUser.id
                  }
                }
              }).$promise
              if (timeLogs.length > 0) {
                resolve(timeLogs[0].toJSON())
              } else {
                resolve(null)
              }
            })
          },
          ResolvedBaseFilter: $rootScope => {
            return new Promise(async (resolve, reject) => {
              const baseFilter = {
                order: 'start DESC',
                where: { end: { neq: null } }
              }
              if (sessionData) {
                baseFilter.where.sessionIds = { in: sessionData.sessionIds }
              }
              baseFilter.where.userId = $rootScope.currentUser.id
              resolve(baseFilter)
            })
          },
          ResolvedUsers: UserModel =>
            UserModel.find({
              filter: {
                fields: {
                  id: true,
                  displayName: true
                }
              }
            }).$promise
        }
      })
    })
  }

  $scope.addToBookmark = () => {
    const bookmark = {
      name: Page.textTitle,
      state: $state.current.name,
      params: $rootScope.stateParams
    }
    $rootScope.currentUser.bookmarks.push(bookmark)
    $scope.updateUserBookmarks()
  }

  // Methods
  vm.toggleSidenav = toggleSidenav
  vm.logout = logout
  vm.changeLanguage = changeLanguage
  vm.setUserStatus = setUserStatus
  vm.toggleHorizontalMobileMenu = toggleHorizontalMobileMenu
  vm.toggleMsNavigationFolded = toggleMsNavigationFolded
  vm.search = search
  vm.searchResultClick = searchResultClick

  /// ///////

  init()

  const options = {
    onComplete: function (barcode) {
      if (
        !$rootScope.appSettings.ocrAutoSearch ||
        (this.focusedElement &&
          this.focusedElement.id !== 'ms-search-bar-input' &&
          (this.focusedElement.tagName === 'INPUT' ||
            this.focusedElement.tagName === 'SELECT' ||
            this.focusedElement.tagName === 'TEXTAREA'))
      ) {
        return false
      } else {
        $state.go('app.search_results.show', {
          type: 1,
          query: barcode.trim(),
          isOCR: true
        })
      }
    },
    minLength: 4
  }

  const scannerDetector = new window.ScannerDetector(options)
  $scope.$on('$destroy', function () {
    scannerDetector.stopScanning()
  })
  /**
   * Initialize
   */
  async function init () {
    // Select the first status as a default
    vm.userStatus = vm.userStatusOptions[0]

    // Get the selected language directly from angular-translate module setting
    const langValue = LanguageUtils.language
    vm.selectedLanguage = vm.languages[langValue]
    if ($rootScope.currentUser) {
      $rootScope.currentUser.lang = langValue
    }
    await checkUserOpenTimer()
  }

  /**
   * Toggle sidenav
   *
   * @param sidenavId
   */
  function toggleSidenav (sidenavId) {
    $mdSidenav(sidenavId).toggle()
  }

  /**
   * Sets User Status
   * @param status
   */
  function setUserStatus (status) {
    vm.userStatus = status
  }

  /**
   * Logout Function
   */
  function logout () {
    // Do logout here..
  }

  /**
   * Change Language
   */
  async function changeLanguage (lang) {
    await UserModel.prototype$patchAttributes(
      { id: $rootScope.currentUser.id },
      { lang: lang.code }
    ).$promise
    $rootScope.currentUser.lang = lang.code
    LanguageUtils.language = lang.code
    vm.selectedLanguage = LanguageUtils.language
    $window.location.reload()
  }

  /**
   * Toggle horizontal mobile menu
   */
  function toggleHorizontalMobileMenu () {
    vm.bodyEl.toggleClass('ms-navigation-horizontal-mobile-menu-active')
  }

  /**
   * Toggle msNavigation folded
   */
  function toggleMsNavigationFolded () {
    $scope.$emit('msNavigation::toggle')
    msNavigationService.toggleFolded()
  }

  /**
   * Search action
   *
   * @param query
   * @returns {Promise}
   */
  function search (query) {
    let navigation = []

    const flatNavigation = msNavigationService.getFlatNavigation()

    const deferred = $q.defer()

    // Iterate through the navigation array and
    // make sure it doesn't have any groups or
    // none ui-sref items
    for (let x = 0; x < flatNavigation.length; x++) {
      if (flatNavigation[x].uisref) {
        navigation.push(flatNavigation[x])
      }
    }

    // If there is a query, filter the navigation;
    // otherwise we will return the entire navigation
    // list. Not exactly a good thing to do but it's
    // for demo purposes.
    if (query) {
      navigation = navigation.filter(function (item) {
        return (
          angular.lowercase(item.title).search(angular.lowercase(query)) > -1
        )
      })
    }

    // Fake service delay
    $timeout(function () {
      deferred.resolve(navigation)
    }, 1000)

    return deferred.promise
  }

  /**
   * Search result click action
   *
   * @param item
   */
  function searchResultClick (item) {
    // If item has a link
    if (item.uisref) {
      // If there are state params,
      // use them...
      if (item.stateParams) {
        $state.go(item.state, item.stateParams)
      } else {
        $state.go(item.state)
      }
    }
  }
  $scope.SyncUtils = SyncUtils
  $scope.startSync = function startSync (method = 'http') {
    SyncUtils.startSync(method, false)
  }
}

module.exports = ToolbarController
