/* global angular _ $ */
import CustomFormClass from 'app/directives/custom-form/custom-form.class'
import FormValidationDialogController from 'app/directives/custom-form/dialog/form-validation-dialog.controller'
const debug = require('debug')('nextplus:form-report')
const {
  FAILURE_FORM_DETAILS
} = require('../../../../../../../common/constants/form-constants.json')

/** @ngInject */
function FormReportController (
  $scope,
  $rootScope,
  $mdDialog,
  $translate,
  $mdToast,
  $state,
  $compile,
  $interval,
  $timeout,
  FormData,
  PermissionUtils,
  KendoGridHelper,
  Page,
  ResolvedData,
  FormUtils,
  ViewsService,
  FormlyHelper,
  FieldUtilsService,
  PanelHelper,
  DateTimeFormatService
) {
  const VALID_NON_FIELD_COLUMNS = ['status', 'assignee', 'ownerId']

  const { form, fields, users, openViewDialog, productionEntities } =
    ResolvedData

  const { approvalWorkflows } = form

  $scope.Form = form
  $scope.openViewDialog = openViewDialog
  $scope.productionEntities = productionEntities
  const userById = _.keyBy(users, 'id')
  const userNamesById = users.reduce((acc, user) => {
    acc[user.id] = user.displayName
    return acc
  }, {})
  $scope.fields = fields

  $scope.inlineEditObject = {
    formDataId: null,
    fieldId: null,
    inlineEditModel: {},
    inlineEditFields: {},
    forms: {}
  }

  const canCreate = FormUtils.isPermit('create', $scope.Form)

  const canClose = FormUtils.isPermit('close', $scope.Form)

  const hasCreatePermissions = PermissionUtils.isPermit('FormData', 'create')

  const mdToastError = $mdToast.nextplus({
    position: $rootScope.toastLocation,
    parent: '#content',
    theme: 'error-toast',
    hideDelay: 3000
  })

  let counter = 0

  const resizeGridFunction = function resizeGridFunction () {
    counter++
    if ($scope.kendoGrid && $scope.kendoGrid.instance) {
      $scope.kendoGrid.instance.resize(true)
    }
    if (counter > 31) {
      counter = 0
      if ($scope.resizeInterval) {
        $interval.cancel($scope.resizeInterval)
      }
    }
  }

  const resizeGrid = function resizeGrid () {
    if ($scope.resizeInterval) {
      $interval.cancel($scope.resizeInterval)
      counter = 0
    }
    $scope.resizeInterval = $interval(resizeGridFunction, 250)
  }

  const performSave = function performSave (formData, showToast = true) {
    return new Promise(async (resolve, reject) => {
      $rootScope.loadingProgress = true
      const { formInstance } = $scope.inlineEditObject
      if (!formInstance) return reject(new Error('No form instance'))
      try {
        const formDataObject =
          formInstance.convertModelToFormDataObject(formData)
        const { isValid, errorMessages } = formInstance.checkValidation(
          formData,
          Object.values($scope.inlineEditObject.forms),
          true
        )
        if (!isValid) {
          PanelHelper.openPanel({
            controller: FormValidationDialogController,
            template: require('app/directives/custom-form/dialog/form-validation-dialog.template.html'),
            zIndex: 90,
            locals: {
              errors: errorMessages
            },
            escapeToClose: true,
            clickOutsideToClose: true
          })
            .then(() => {})
            .catch(err => {}) //eslint-disable-line
          $rootScope.loadingProgress = false
          return reject() //eslint-disable-line
        }
        const isNew = formDataObject.isDraft
        formDataObject.isDraft = false
        try {
          const preSaveActions = await formInstance.executeTriggers(
            {
              ...formDataObject,
              ...formInstance.getVariablesForCondition()
            },
            'presave',
            isNew
          )
          if (preSaveActions && preSaveActions.length) {
            await FormUtils.performActions(preSaveActions, formDataObject)
          }
        } catch (err) {
          // trigger validation error
          const error = new Error('validation error')
          error.code = 'FORM.CUSTOM_VALIDATION_ERROR'
          error.message = 'validation error'
          $rootScope.loadingProgress = false
          $scope.$applyAsync()
          return reject(error)
        }
        await FormData.prototype$patchAttributes(
          { id: formInstance.getFormDataId() },
          formDataObject
        ).$promise
        try {
          const obj = {
            id: $scope.formDataId,
            ...formDataObject,
            ...formInstance.getVariablesForCondition()
          }
          const postSaveActions = await formInstance.executeTriggers(
            obj,
            'postsave',
            isNew
          )
          if (postSaveActions && postSaveActions.length) {
            await FormUtils.performActions(postSaveActions, obj)
          }
        } catch (err) {
          console.error('post trigger failed', err)
        }
        if (showToast) {
          $mdToast.show(
            $mdToast.nextplus({
              position: $rootScope.toastLocation,
              parent: 'document.body',
              theme: 'success-toast',
              hideDelay: 2000
            })
          )
          $mdToast.updateTextContent(
            $translate.instant('FORM.SUCCESS.FORM_SAVED')
          )
        }
        resolve()
      } catch (err) {
        reject(err)
        $rootScope.loadingProgress = false
        $mdToast.show(mdToastError)
        $mdToast.updateTextContent(err)
      }
    })
  }

  $scope.openFormForEdit = async function openFormForEdit (ev, formDataId) {
    $rootScope.loadingProgress = true
    if ($scope.inlineEditObject.formDataId) {
      $scope.closeEditForm($scope.inlineEditObject.formDataId)
    }
    const formData = await FormData.findOne({
      filter: { where: { id: formDataId } }
    }).$promise
    $scope.inlineEditObject.formInstance = new CustomFormClass(
      $scope.Form,
      $scope.fields,
      $rootScope.currentUser.certificateIds,
      $rootScope.currentUser.roles,
      formData.sessionId || 'constant',
      formData.sessionId || 'constant',
      formDataId,
      formData
    )
    $scope.inlineEditObject.rowUid = $(ev.currentTarget).parents(
      'tr'
    )[0].attributes['data-uid'].value
    const contentRow = $(
      `.k-grid-content tr[data-uid=${$scope.inlineEditObject.rowUid}]`
    )
    const relevantColumns = contentRow.find('td[field-id]')
    $scope.inlineEditObject.fieldIds = []
    $scope.multiSelectUserFieldIds = []
    for (let i = 0; i < relevantColumns.length; i++) {
      const relevantColumn = relevantColumns[i]
      // const relevantColumn = this
      const fieldId = relevantColumn.getAttribute('field-id')
      if (fieldId) {
        let currentField = fields.find(
          field =>
            form.fields.find(f => f.id === fieldId && f.fieldId === field.id) &&
            FormUtils.editableFieldTypes.includes(field.type) &&
            formData.fields.find(f => f.id === fieldId)
        )
        if (
          fieldId === 'status' &&
          $scope.inlineEditObject.formInstance.hasStatus() &&
          canClose
        ) {
          const approvalWorkflow = approvalWorkflows.find(
            approvalWorkflow =>
              formData.approvalWorkflow &&
              approvalWorkflow.id === formData.approvalWorkflow.id
          )
          const statusIsDisabled =
            approvalWorkflow &&
            approvalWorkflow.readonly &&
            (approvalWorkflow.closeFormWhenDone ||
              (formData.approvalWorkflow &&
                formData.approvalWorkflow.status === 'PENDING'))
          if (!statusIsDisabled) {
            currentField = {
              type: 'select',
              title: '',
              options: formData.statuses.map(status => {
                const statusName = $translate.instant(
                  status.name,
                  null,
                  null,
                  null,
                  'sceParameters'
                )
                return {
                  value: statusName,
                  color: status.color
                }
              }),
              required: true
            }
          }
        } else if (fieldId === 'assignee' || fieldId === 'ownerId') {
          currentField = {
            type: 'selectUser',
            templateOptions: {
              label:
                fieldId === 'assignee'
                  ? $translate.instant('FORM.ASSIGNEE')
                  : $translate.instant('FORM.REPORTER'),
              multiple: fieldId === 'assignee'
            }
          }
        }
        if (currentField) {
          $scope.inlineEditObject.fieldIds.push(fieldId)
          if (currentField.type === 'selectUser' && currentField.multiple) {
            $scope.multiSelectUserFieldIds.push(fieldId)
          }
          let formlyField = null
          if (fieldId === 'assignee' || fieldId === 'ownerId') {
            formlyField = currentField
          } else {
            formlyField = await FormlyHelper.convertToFormlyObject(currentField)
          }
          if (
            formlyField.key ===
              FAILURE_FORM_DETAILS.FAILURE_QUANTITY_DATABASE_FIELD_ID ||
            formlyField.key ===
              FAILURE_FORM_DETAILS.FAILURE_REMOVE_STOCK_ITEMS_DATABASE_FIELD_ID ||
            formlyField.key ===
              FAILURE_FORM_DETAILS.FAILURE_CHANGE_SERIAL_NUMBER_DATABASE_FIELD_ID ||
            formlyField.key ===
              FAILURE_FORM_DETAILS.FAILURE_NEW_SERIAL_NUMBER_DATABASE_FIELD_ID ||
            formlyField.key ===
              FAILURE_FORM_DETAILS.FAILURE_NEW_SERIAL_NUMBER_DATABASE_FIELD_ID
          ) {
            formlyField.templateOptions.disabled = true
          }
          formlyField.expressionProperties =
            formlyField.expressionProperties || {}
          formlyField.expressionProperties['templateOptions.disabled'] = (
            $viewValue,
            $modelValue,
            scope
          ) => {
            return (
              !$scope.inlineEditObject.formInstance ||
              !$scope.inlineEditObject.formInstance.canEditField(
                scope.options.key.replace('temp_', '')
              ) ||
              scope.options.templateOptions.disabled
            )
          }
          formlyField.key = `temp_${fieldId}`
          formlyField.hideExpression = function (
            $viewValue,
            $modelValue,
            scope
          ) {
            const tempModel = {
              fields: []
            }
            const editableKeys = Object.keys(
              $scope.inlineEditObject.inlineEditModel
            ).filter(key => key.includes('temp_'))
            for (let i = 0; i < editableKeys.length; i++) {
              const editModelKey = editableKeys[i]
              const key = editModelKey.replace('temp_', '')
              if (['status', 'assignee', 'ownerId'].includes(key)) {
                tempModel[key] =
                  $scope.inlineEditObject.inlineEditModel[editModelKey]
              } else {
                tempModel.fields.push({
                  id: key,
                  fieldId,
                  value: $scope.inlineEditObject.inlineEditModel[editModelKey]
                })
              }
            }
            $scope.inlineEditObject.formInstance.createDisplayFieldsObject(
              $rootScope.currentUser,
              tempModel
            )
            if (
              $scope?.inlineEditObject?.formInstance?.model
                ?.displayFieldsObject?.[fieldId]
            ) {
              const { hide, options } =
                $scope.inlineEditObject.formInstance.model.displayFieldsObject[
                  fieldId
                ]
              const value =
                $scope.inlineEditObject?.inlineEditModel?.[`temp_${fieldId}`] ||
                null
              if (hide && value) {
                $scope.inlineEditObject.inlineEditModel[`temp_${fieldId}`] =
                  null
              }
              if (
                currentField.type === 'select' ||
                currentField.type === 'radio'
              ) {
                const manipulatedOptions = options.map(option =>
                  option.id ? option.id : option.value
                )
                const realValue =
                  options.find(option => option.value === value)?.id || value
                if (realValue && !manipulatedOptions.includes(realValue)) {
                  $scope.inlineEditObject.inlineEditModel[`temp_${fieldId}`] =
                    null
                } else {
                  $scope.inlineEditObject.inlineEditModel[`temp_${fieldId}`] =
                    realValue
                }
                formlyField.templateOptions.options =
                  FormlyHelper.manipulateOptions(
                    options || [],
                    currentField.type
                  )
              }
              return hide || false
            } else {
              return false
            }
          }
          $scope.inlineEditObject.inlineEditFields[fieldId] = [formlyField]
          relevantColumn.setAttribute('dir', 'none')
          const html = angular
            .element(
              `<div>
                <div layout="row" layout-align="space-between center">
                  <form
                    novalidate
                    name="inlineEditObject.forms['${fieldId}']"
                    autocomplete="off"
                    flex="90"
                    layout="row"
                  >
                    <formly-form
                      model="inlineEditObject.inlineEditModel"
                      fields="inlineEditObject.inlineEditFields['${fieldId}']"
                      layout="column"
                      flex="100"
                      form="inlineEditObject.forms['${fieldId}']"
                    >
                    </formly-form>
                  </form>
                </div>
              </div>`
            )
            .html()
          $compile(angular.element(relevantColumn).html(html))($scope)
        }
      }
    }
    if (relevantColumns[0]) {
      $scope.kendoGrid.instance.editCell(relevantColumns[0])
      $scope.inlineEditObject.formDataId = formDataId
    }
    $rootScope.loadingProgress = false
    $scope.$applyAsync()
    resizeGrid()
  }

  $scope.closeEditForm = function closeEditForm () {
    $scope.inlineEditObject.formDataId = null
    const { rowUid } = $scope.inlineEditObject
    if (rowUid) {
      const contentRow = $(`.k-grid-content tr[data-uid=${rowUid}]`)
      const relevantColumns = contentRow.find('td[field-id]')
      relevantColumns.each(function () {
        const relevantColumn = this
        const fieldId = relevantColumn.getAttribute('field-id')
        if (fieldId) {
          const currentField = fields.find(
            field =>
              form.fields.find(
                f => f.id === fieldId && f.fieldId === field.id
              ) &&
              FormUtils.editableFieldTypes.includes(field.type) &&
              $scope.inlineEditObject.fieldIds.includes(fieldId)
          )
          if (currentField || VALID_NON_FIELD_COLUMNS.includes(fieldId)) {
            relevantColumn.setAttribute('dir', 'auto')
            const html =
              fieldId === 'status'
                ? FormUtils.generateStatusTemplate(
                    $scope.inlineEditObject.inlineEditModel,
                    $scope.inlineEditObject.inlineEditModel.status
                  )
                : fieldId === 'assignee'
                ? FormUtils.generateAssigneeTemplate(
                    $scope.inlineEditObject.inlineEditModel,
                    userById,
                    $scope.inlineEditObject.inlineEditModel.assignee
                  )
                : fieldId === 'ownerId'
                ? FormUtils.generateOwnerTemplate(
                    $scope.inlineEditObject.inlineEditModel,
                    userById,
                    $scope.inlineEditObject.inlineEditModel.ownerId
                  )
                : FieldUtilsService.getFieldHTMLValue(
                    [currentField],
                    currentField.id,
                    _.isUndefined(
                      $scope.inlineEditObject.inlineEditModel.fields[fieldId]
                    )
                      ? null
                      : $scope.inlineEditObject.inlineEditModel.fields[fieldId],
                    userNamesById
                  )
            $compile(angular.element(relevantColumn).html(html))($scope)
          }
        }
      })
      $scope.inlineEditObject.inlineEditModel = null
      $scope.inlineEditObject.formInstance = null
      resizeGrid()
    }
  }

  $scope.updateForm = async function updateForm (formDataId) {
    $rootScope.loadingProgress = true
    try {
      const formModel = $scope.inlineEditObject.formInstance.manipulateModel(
        $rootScope.currentUser
      )
      const editableKeys = Object.keys(
        $scope.inlineEditObject.inlineEditModel
      ).filter(key => key.includes('temp_'))
      for (let i = 0; i < editableKeys.length; i++) {
        const editModelKey = editableKeys[i]
        const key = editModelKey.replace('temp_', '')
        const newValue = $scope.inlineEditObject.inlineEditModel[editModelKey]
        formModel[key] = newValue
      }
      await performSave(formModel)
      const dataObject = $scope.kendoGrid.instance.dataSource.get(formDataId)
      for (let j = 0; j < editableKeys.length; j++) {
        const modelKey = editableKeys[j]
        const key = modelKey.replace('temp_', '')
        const newValue = $scope.inlineEditObject.inlineEditModel[modelKey]
        if (VALID_NON_FIELD_COLUMNS.includes(key)) {
          dataObject.set(key, newValue)
          $scope.inlineEditObject.inlineEditModel[key] = newValue
        } else {
          dataObject[`fields\[\"${key}\"\]`] = newValue //eslint-disable-line
          dataObject.fields[key] = newValue
          $scope.inlineEditObject.inlineEditModel.fields[key] = newValue
        }
      }
      $scope.closeEditForm(formDataId)
    } catch (err) {
      console.error(err)
    } finally {
      $rootScope.loadingProgress = false
    }
  }

  $scope.deleteFormData = function deleteFormData (formDataId) {
    $mdDialog
      .show(
        $mdDialog
          .confirm()
          .title($translate.instant('FORM.DELETE_MESSAGE'))
          .targetEvent()
          .multiple(true)
          .clickOutsideToClose(true)
          .parent(angular.element(document.body))
          .ok($translate.instant('BUTTONS.DELETE'))
          .cancel($translate.instant('BUTTONS.CANCEL'))
      )
      .then(
        function () {
          FormData.destroyById({ id: formDataId })
            .$promise.then(() => {
              debug(`Form instance '${formDataId}' deleted successfully`)
              $scope.kendoGrid.reloadData()
            })
            .catch(err => {
              debug(
                `An error occurred while trying to delete a form instance '${formDataId}'`,
                err
              )
            })
        },
        function () {}
      )
  }

  $scope.$on('$destroy', () => {
    if ($scope.resizeInterval) {
      $interval.cancel($scope.resizeInterval)
    }
  })

  const initScreen = async function initScreen () {
    $scope.stateName = `${$state.current.name}.${form.id}`
    $scope.users = users
    $scope.title = form.name
    $scope.title = $rootScope.title = form.name
    Page.setTitleText($scope.title)
    $scope.PermissionUtils = PermissionUtils

    const defaultTableColumns = FormUtils.generateFormColumns(
      'full',
      form,
      fields,
      users,
      productionEntities,
      $scope
    )

    const baseFilter = {
      where: {},
      order: 'created DESC'
    }

    const { columns, selectedViewId } = ViewsService.getTablesColumns(
      defaultTableColumns,
      $scope.stateName
    )

    const { newBaseFilter, filters } = ViewsService.getViewCustomFilters(
      selectedViewId,
      _.cloneDeep(baseFilter),
      $scope.stateName
    )

    const defaultTableSetup = {
      stateName: $scope.stateName,
      // ignoreParams
      find: FormData.getFormDataList,
      count: FormData.getFormDataCount,
      params: { formId: $scope.Form.id },
      cleanBaseFilter: baseFilter,
      baseFilter: newBaseFilter,
      selectedViewId,
      columns: defaultTableColumns,
      encodeTitles: true,
      extraDataBound: function (e) {
        $scope.inlineEditObject.formDataId = null
        resizeGrid()
      },
      beforeEdit (e) {
        $scope.inlineEditObject.inlineEditModel = JSON.parse(
          JSON.stringify(e.model.toJSON())
        )
        for (let i = 0; i < $scope.inlineEditObject.fieldIds.length; i++) {
          const fieldId = $scope.inlineEditObject.fieldIds[i]
          if (VALID_NON_FIELD_COLUMNS.includes(fieldId)) {
            $scope.inlineEditObject.inlineEditModel[`temp_${fieldId}`] =
              $scope.inlineEditObject.inlineEditModel[fieldId]
          } else {
            $scope.inlineEditObject.inlineEditModel[`temp_${fieldId}`] =
              $scope.inlineEditObject.inlineEditModel[`fields["${fieldId}"]`]
          }
        }
        e.preventDefault()
      }
    }

    const defaultTableToolbarSetup = {
      stateName: $scope.stateName,
      columns: defaultTableColumns,
      currentColumnIds: columns.map(c => c.uniqueId),
      filters,
      selectedViewId,
      allowDownload: true,
      title: $scope.title,
      AiInjections: {
        users: users.map(u => {
          return {
            id: u.id,
            value: u.displayName
          }
        })
      }
    }

    $scope.downloadFunction = async function downloadFunction () {
      const filter = $scope.kendoGrid.instance.dataSource.loopbackFilter
      const columns = []
      $scope.kendoGrid.instance.columns.forEach(c => {
        if (!c.webonly) {
          columns.push({
            title: c.title,
            field: c.field
          })
        }
      })
      const data = {
        filter,
        columns,
        formId: form.id,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
      }
      const htmlForm = $('<form></form>')
        .attr('action', './api/FormData/exportFormData')
        .attr('method', 'post')
        .attr('target', '_blank')
      Object.keys(data).forEach(function (key) {
        const value = data[key]
        if (value instanceof Array || value instanceof Object) {
          htmlForm.append(
            $('<input></input>')
              .attr('type', 'hidden')
              .attr('name', key)
              .attr('value', JSON.stringify(value))
          )
        } else {
          htmlForm.append(
            $('<input></input>')
              .attr('type', 'hidden')
              .attr('name', key)
              .attr('value', value)
          )
        }
      })
      htmlForm.appendTo('body').submit().remove()
    }

    const subFormMapping = KendoGridHelper.createComplexTypeMapping(columns)

    columns.map(c => {
      if (c.uniqueId.includes('_') && !c.uniqueId.includes('_lookup_')) {
        c.attributes = KendoGridHelper.calculateColumnAttributes(
          subFormMapping,
          c.uniqueId
        )
      }
      return c
    })

    $scope.kendoGrid = await KendoGridHelper.GridInstance(
      defaultTableSetup,
      $scope,
      columns
    )

    await $scope.kendoGrid.isBound()

    $scope.tableToolbar = await ViewsService.GridToolBarInstance(
      defaultTableToolbarSetup,
      $scope.kendoGrid,
      $scope
    )
    if ($scope.openViewDialog) {
      $timeout(() => {
        $scope.tableToolbar.openViewDialog()
      }, 500)
    }
    $scope.$applyAsync()

    if (canCreate) {
      $('.kendo-grid-class tbody').on('dblclick', 'td', async function (e) {
        const cellElement = this
        const fieldId = cellElement.getAttribute('field-id')
        if (!fieldId) return
        const rowUid = $(e.currentTarget).parents('tr')[0].attributes[
          'data-uid'
        ].value
        const contentRow = $(`.k-grid-content-locked tr[data-uid=${rowUid}]`)
        if (contentRow && contentRow[0]) {
          const relevantDiv = $(contentRow[0]).find('div[form-data-id]')
          if (relevantDiv && relevantDiv[0]) {
            const formDataId = relevantDiv[0].getAttribute('form-data-id')
            if (formDataId !== $scope.inlineEditObject.formDataId) {
              $scope.openFormForEdit(e, formDataId)
            }
          }
        }
      })
    }
  }

  $scope.enterEditForm = function enterEditForm () {
    $state.go('app.form.show', { id: $scope.Form.id })
  }

  initScreen()

  $scope.headerOptions = {
    icon: 'icon-document',
    template: require('app/templates/headers/simple.html'),
    title: $scope.title,
    postTitleHtml: `<md-menu ng-if="::PermissionUtils.isPermit('Form','create')" md-position-mode="target-right target">
                      <md-button
                        data-testid="form-menu"
                        aria-label="Menu"
                        class="md-icon-button"
                        ng-click="$mdOpenMenu($event)"
                      >
                        <md-icon md-font-icon="icon-dots-vertical"></md-icon>
                      </md-button>
                      <md-menu-content width="4">
                        <md-menu-item ng-click="enterEditForm()" data-testid="edit-form">
                          <md-button layout="row" layout-align="start center">
                            <md-icon md-font-icon="icon-pencil"></md-icon>
                            <p flex>${$translate.instant('FORM.EDIT_FORM')}</p>
                          </md-button>
                        </md-menu-item>
                      </md-menu-content>
                    </md-menu>
    `,
    fabButton: {
      template: require('app/templates/headers/fab-button.html'),
      action: () =>
        $state.go('app.form.standalone-create', { formId: $scope.Form.id }),
      icon: 'icon-plus',
      href: '',
      state: '',
      showExpression: () =>
        form.enableAsStandalone && canCreate && hasCreatePermissions
    }
  }
}

module.exports = FormReportController
