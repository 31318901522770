import angular from 'angular'

// import fileManagerConfig from './file-manager.config'
// import FileManagerController from './file-manager.controller'
import FileDialogController from './FileDialogController'
import UploadMenuDialogController from './upload-menu/upload-menu.dialog.controller'
import FileManagerService from './file-manager-service'
import filePicker from './filePicker'
import filePreview from './filePreview'
import '@flowjs/ng-flow'
import 'ng-file-upload'

const MODULE_NAME = 'app.file-manager'

/** @ngInject */

angular
  .module(MODULE_NAME, ['flow', 'ngFileUpload'])
  // .config(fileManagerConfig)
  // .controller('FileManagerController', FileManagerController)
  .controller('FileDialogController', FileDialogController)
  .controller('UploadMenuDialogController', UploadMenuDialogController)
  .service('FileManagerService', FileManagerService)
  .directive('filePreview', filePreview)
  .directive('filePicker', filePicker)

export default MODULE_NAME
