/** @ngInject */
function toTrustedFilter ($sce) {
  return function (value) {
    return $sce.trustAsHtml(value)
  }
}

/** @ngInject */
function htmlToPlainTextFilter () {
  return function (text) {
    return String(text).replace(/<[^>]+>/gm, '')
  }
}

/** @ngInject */
function nospaceFilter () {
  return function (value) {
    return (!value) ? '' : value.replace(/ /g, '')
  }
}

/** @ngInject */
function humanizeDocFilter () {
  return function (doc) {
    if (!doc) {
      return
    }
    if (doc.type === 'directive') {
      return doc.name.replace(/([A-Z])/g, function ($1) {
        return '-' + $1.toLowerCase()
      })
    }
    return doc.label || doc.name
  }
}

module.exports = {
  htmlToPlainTextFilter,
  humanizeDocFilter, nospaceFilter, toTrustedFilter
}
