'use strict'

/** @ngInject */
function IndexController (
  fuseTheming,
  $scope,
  BreakService,
  $rootScope,
  $interval,
  FormlyHelper,
  $timeout,
  Break,
  $translate
) {
  var vm = this

  // Data
  vm.themes = fuseTheming.themes

  $scope.break = {}

  $scope.breakEnd = function () {
    if (!$scope.break.breakTypeId) {
      return
    }
    if ($scope.passedInterval) {
      $interval.cancel($scope.passedInterval)
    }

    BreakService.stop($scope.break.breakTypeId)
  }

  $scope.createInterval = () => {
    $scope.timePass = '00:00:00'
    $scope.break = {}
    $scope.passedInterval = $interval(() => {
      $scope.timePass = BreakService.getTimePass()
    }, 1000)
  }

  $scope.$on('break-exist', () => $scope.createInterval())
  $scope.$on('break-created', () => $scope.createInterval())

  $rootScope.$on('appSettings', (ev, data) => {
    $timeout(() => {
      $scope.staticBreakFields = [
        {
          key: 'breakTypeId',
          type: 'selectWithSearch',
          className: 'layout-column',
          templateOptions: {
            label: $translate.instant('SETTINGS.BREAKS.BREAK_TYPE'),
            multiple: false,
            required: true,
            searchPlaceholder: 'Search...',
            options: data.breakTypes
          }
        }
      ]
      $scope.breakFields = FormlyHelper.buildFields(
        $scope.staticBreakFields,
        Break
      )
    }, 2000)
  })

  /// ///////
}

module.exports = IndexController
