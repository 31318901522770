/* global angular */

import ResetPasswordController from './reset-password.controller'

const MODULE_NAME = 'app.reset-password'

/** @ngInject */
angular
  .module(MODULE_NAME, [])
  .config(config)
  .controller('ResetPasswordController', ResetPasswordController)
/** @ngInject */
function config ($stateProvider) {
  $stateProvider.state('app.reset-password', {
    url: '/reset-password/?:emailToken',
    views: {
      'main@': {
        template: require('../../core/layouts/content-only.html'),
        controller: 'MainController as vm'
      },
      'content@app.reset-password': {
        template: require('./reset-password.template.html'),
        controller: 'ResetPasswordController as vm'
      }
    },
    resolve: {
      ResolvedSettings: Setting =>
        Setting.publicSettings({
          settingNames: ['smtp', 'resetViaSMS', 'passwordComplexity']
        }).$promise
    },
    bodyClass: 'reset-password'
  })
  $stateProvider.state('app.validate-token', {
    url: '/validate-token/:email',
    views: {
      'main@': {
        template: require('../../core/layouts/content-only.html'),
        controller: 'MainController as vm'
      },
      'content@app.validate-token': {
        template: require('./reset-password.template.html'),
        controller: 'ResetPasswordController as vm'
      }
    },
    resolve: {
      ResolvedSettings: Setting =>
        Setting.publicSettings({
          settingNames: ['smtp', 'resetViaSMS']
        }).$promise
    },
    bodyClass: 'validate-token'
  })
  $stateProvider.state('app.set-new-password', {
    url: '/set-new-password',
    views: {
      'main@': {
        template: require('../../core/layouts/content-only.html'),
        controller: 'MainController as vm'
      },
      'content@app.set-new-password': {
        template: require('./reset-password.template.html'),
        controller: 'ResetPasswordController as vm'
      }
    },
    resolve: {
      ResolvedSettings: Setting =>
        Setting.publicSettings({
          settingNames: ['smtp', 'resetViaSMS', 'passwordComplexity']
        }).$promise
    },
    bodyClass: 'reset-password'
  })
  $stateProvider.state('app.set-new-pincode', {
    url: '/set-new-pincode',
    views: {
      'main@': {
        template: require('../../core/layouts/content-only.html'),
        controller: 'MainController as vm'
      },
      'content@app.set-new-pincode': {
        template: require('./reset-password.template.html'),
        controller: 'ResetPasswordController as vm'
      }
    },
    resolve: {
      ResolvedSettings: Setting =>
        Setting.publicSettings({
          settingNames: ['smtp', 'resetViaSMS', 'passwordComplexity']
        }).$promise
    },
    bodyClass: 'reset-pincode'
  })
  $stateProvider.state('app.reset-sent', {
    url: '/reset-sent',
    views: {
      'main@': {
        template: require('../../core/layouts/content-only.html'),
        controller: 'MainController as vm'
      },
      'content@app.reset-sent': {
        template: require('./reset-password.template.html'),
        controller: 'ResetPasswordController as vm'
      }
    },
    resolve: {
      ResolvedSettings: Setting =>
        Setting.publicSettings({
          settingNames: ['smtp', 'resetViaSMS']
        }).$promise
    },
    bodyClass: 'reset-sent'
  })
}

export default MODULE_NAME
