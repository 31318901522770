/* global angular */
/** @ngInject */

function runBlock (
  msUtils,
  amMoment,
  fuseGenerator,
  fuseConfig,
  $translate,
  $rootScope,
  UserModel,
  $document
) {
  // Create the keyboard open/close event.
  // eslint-disable-next-line
  new Event('keyboardOpened')
  // eslint-disable-next-line
  new Event('keyboardClosed')

  const browserInfo = msUtils.detectBrowser()
  /**
   * Generate extra classes based on registered themes so we
   * can use same colors with non-angular-material elements
   */
  fuseGenerator.generate()

  /**
   * Disable md-ink-ripple effects on mobile
   * if 'disableMdInkRippleOnMobile' config enabled
   */
  if (
    fuseConfig.getConfig('disableMdInkRippleOnMobile') &&
    (msUtils.isMobile() || browserInfo.browser === 'explorer')
  ) {
    const bodyEl = angular.element('body')
    bodyEl.attr('md-no-ink', true)
  }

  /**
   * Put isMobile() to the html as a class
   */
  if (msUtils.isMobile()) {
    angular.element('html').addClass('is-mobile')
  }

  /**
   * Put browser information to the html as a class
   */
  if (browserInfo) {
    const htmlClass =
      browserInfo.browser + ' ' + browserInfo.version + ' ' + browserInfo.os
    angular.element('html').addClass(htmlClass)
  }
}

module.exports = runBlock
