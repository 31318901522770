/* global jQuery, $ */
/**
 * Hack the way for passive event listener
 * https://github.com/jquery/jquery/issues/2871#issuecomment-497963776
 */
const passiveEvents = ['mousewheel', 'wheel', 'touchmove', 'touchstart']
passiveEvents.forEach(event => {
  jQuery.event.special[event] = {
    setup: function (_, ns, handle) {
      this.addEventListener(event, handle, { passive: true })
    }
  }
})
// Add scrollTo function
$.fn.scrollTo = function (elem, direction) {
  if (direction === 'top') {
    if ($(this).offset().top > 450) {
      $(this).scrollTop(
        $(this).scrollTop() - $(this).offset().top + $(elem).offset().top
      )
      return this
    }
  }
  const positionToScroll =
    $(this).scrollTop() -
    $(this).offset().top +
    $(elem).offset().top -
    $(elem)
      .prev()
      .outerHeight(true)
  $(this).scrollTop(positionToScroll)
}
