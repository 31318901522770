/* global $ moment */
require('./workorder-subforms.dialog.scss')

/** @ngInject */
function workorderSubformsDialogController (
  $scope,
  $translate,
  KendoGridHelper,
  ViewsService,
  locals,
  ResolvedSubforms,
  $mdDialog,
  $window,
  $compile
) {
  $scope.workorderNumber = locals.workorderNumber
  $scope.subformsByKey = ResolvedSubforms.res ? ResolvedSubforms.res : {}
  $scope.subFormsStructure = ResolvedSubforms?.subFormsStructure?.SERIAL
    ? ResolvedSubforms.subFormsStructure.SERIAL
    : {}
  const ignoreKeys = ['id', 'name', 'type']
  $scope.subformKeys = Object.keys($scope.subFormsStructure).filter(key =>
    key.endsWith('_SUBFORM')
  )
  $scope.subFormsDataByUid = {}
  $scope.defaultTableSetupBySubForm = {}
  $scope.kendoGridBySubForm = {}

  $scope.defaultSubTableSetupBySubForm = {}
  $scope.kendoGridBySubSubForm = {}
  $scope.subSubFormKeys = {}
  $scope.subSubFormStructure = {}
  $scope.selectedSubformKey = {}

  $scope.cancel = function cancel () {
    $mdDialog.cancel()
  }

  $scope.tabSelected = function tabSelected (level) {
    const element = $(`#sub-subforms-${level}`)
    if (element) {
      element.html('')
    }
  }
  const templateCreator = function templateCreator (dataItem, key, type) {
    if (dataItem[key]) {
      switch (type) {
        case 'date':
          return moment(dataItem[key]).format('DD/MM/YYYY')
        default:
          if (typeof dataItem[key] === 'string') {
            // Regular expression to match http and https URLs
            const urlPattern = /(https?:\/\/[^\s]+)/g
            // Replace URLs with clickable links
            const linkedText = dataItem[key].replace(
              urlPattern,
              function (url) {
                return `<a href="${url}" target="_blank">${url}</a>`
              }
            )
            return $window.DOMPurify.sanitize(linkedText, {
              ADD_ATTR: ['target']
            })
          } else {
            return dataItem[key]
          }
      }
    } else {
      return '--'
    }
  }
  const createTableColumns = function createTableColumns (
    subformKey,
    subFormsStructure,
    level = null
  ) {
    const columns = []
    const subformStructure = subFormsStructure[subformKey]
    const subformStructureKeys = Object.keys(subformStructure).filter(
      key => !key.endsWith('_SUBFORM') && !ignoreKeys.includes(key)
    )
    columns.push({
      // ACTIONS
      uniqueId: '5f0fdf3f-a547-45cf-84ca-1faa43ed470d',
      field: 'id',
      title: $translate.instant(
        'WO.VIEW_PRIORITY_WORKORDER_SUBFORMS.COLUMNS.ACTIONS'
      ),
      width: '150px',
      filterable: false,
      sortable: false,
      trustedTemplate: function (dataItem) {
        const subForms = Object.keys(dataItem).filter(key =>
          key.endsWith('_SUBFORM')
        )
        if (subForms.length > 0) {
          if (!$scope.subFormsDataByUid[dataItem.uid]) {
            $scope.subFormsDataByUid[dataItem.uid] = {}
          }
          subForms.forEach(subForm => {
            $scope.subFormsDataByUid[dataItem.uid][subForm] =
              dataItem[subForm].toJSON()
          })
          return `<div class="text-center">
          <md-button class="md-icon-button" ng-click="openSubforms('${dataItem.uid}', '${subformKey}', '${level}')">
            <md-icon md-font-icon="icon-form-dropdown" class="icon"></md-icon>
            <md-tooltip md-direction="top">
              <span translate="WO.VIEW_PRIORITY_WORKORDER_SUBFORMS.TOOLTIP.OPEN_SUB_SUBFORMS">
            </span></md-tooltip>
          </md-button>`
        } else {
          return '--'
        }
      }
    })
    subformStructureKeys.forEach(key => {
      columns.push({
        uniqueId: key,
        field: key,
        type: subFormsStructure[subformKey][key].type,
        title: subFormsStructure[subformKey][key].name,
        filterable: true,
        sortable: true,
        width: '150px',
        trustedTemplate: function (dataItem) {
          return templateCreator(
            dataItem,
            key,
            subFormsStructure[subformKey][key].type
          )
        }
      })
    })
    return columns
  }
  $scope.openSubforms = async function openSubforms (uid, parentKey, level) {
    const newLevel = level !== 'null' ? parseInt(level) + 1 : 0
    $scope.defaultSubTableSetupBySubForm[newLevel] = {}
    $scope.kendoGridBySubSubForm[newLevel] = {}
    await createSubTables($scope.subFormsDataByUid[uid], parentKey, newLevel)
  }

  const createSubTables = async function createSubTables (
    subformsByKey,
    parentKey,
    level
  ) {
    $scope.subSubFormStructure[level] = $scope.subFormsStructure[parentKey]
      ? $scope.subFormsStructure[parentKey]
      : $scope.subSubFormStructure[level - 1][parentKey]
    $scope.subSubFormKeys[level] = Object.keys(
      $scope.subSubFormStructure[level]
    ).filter(key => key.endsWith('_SUBFORM'))
    for (let i = 0; i < $scope.subSubFormKeys[level].length; i++) {
      const subformKey = $scope.subSubFormKeys[level][i]
      const tableColumns = createTableColumns(
        subformKey,
        $scope.subSubFormStructure[level],
        level
      )
      const columns = ViewsService.getTablesColumns(tableColumns).columns
      $scope.defaultSubTableSetupBySubForm[level][subformKey] = {
        // stateName
        ignoreParams: true,
        data: subformsByKey[subformKey],
        pageSize: 10,
        serverSide: false,
        encodeTitles: true,
        columns
      }
      $scope.kendoGridBySubSubForm[level][subformKey] =
        await KendoGridHelper.GridInstance(
          $scope.defaultSubTableSetupBySubForm[level][subformKey],
          $scope,
          columns
        )
    }
    $scope.selectSubForm = function selectSubForm (subformKey, currentLevel) {
      $scope.selectedSubformKey[currentLevel] = subformKey
      $scope.tabSelected(parseInt(currentLevel) + 1)
      const compiled = $compile(
        `<div class="md-dialog-content simple-table-container">
          <grid-wrapper>
            <div
              flex="grow"
              k-rebind="kendoGridBySubSubForm[${currentLevel}][selectedSubformKey[${currentLevel}]].gridOptions.columns"
              kendo-grid="kendoGridBySubSubForm[${currentLevel}][selectedSubformKey[${currentLevel}]].instance"
              options="kendoGridBySubSubForm[${currentLevel}][selectedSubformKey[${currentLevel}]].gridOptions"
            ></div>
          </grid-wrapper>
        </div>
        `
      )($scope)[0]
      $(`#selectedSubForm-${currentLevel}`).html('').append(compiled)
    }
    const compiled = $compile(
      `<div>
        <div class="subSubFormsButtons" layout="row">
          <md-button class="subSubFormsButton" ng-class="{'selectedSubForm' : subformKey === selectedSubformKey[${level}] }" ng-repeat="subformKey in subSubFormKeys[${level}]" ng-click="selectSubForm(subformKey, '${level}')">
            <span>{{subSubFormStructure[${level}][subformKey].name}}</span>
          </md-button>
        </div>
        <div id="selectedSubForm-${level}"></div>
        <div id="sub-subforms-${level + 1}"></div>
      </div>
      `
    )($scope)[0]
    $(`#sub-subforms-${level}`).html('').append(compiled)
    $scope.selectedSubformKey[level] = $scope.subSubFormKeys[level][0]
    $scope.selectSubForm($scope.selectedSubformKey[level], level)
  }

  const createTables = async function createTables () {
    for (let i = 0; i < $scope.subformKeys.length; i++) {
      const subformKey = $scope.subformKeys[i]
      const tableColumns = createTableColumns(
        subformKey,
        $scope.subFormsStructure
      )
      const columns = ViewsService.getTablesColumns(tableColumns).columns
      $scope.defaultTableSetupBySubForm[subformKey] = {
        // stateName
        ignoreParams: true,
        data: $scope.subformsByKey[subformKey],
        pageSize: 10,
        serverSide: false,
        encodeTitles: true,
        columns
      }
      $scope.kendoGridBySubForm[subformKey] =
        await KendoGridHelper.GridInstance(
          $scope.defaultTableSetupBySubForm[subformKey],
          $scope,
          columns
        )
    }
  }
  const initScreen = async function initScreen () {
    createTables($scope.subformsByKey, $scope.subformKeys)
  }

  initScreen()
}

module.exports = workorderSubformsDialogController
