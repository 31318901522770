/* global _ angular */
const debug = require('debug')('nextplus:locations')
/** @ngInject */
function LocationsController (
  $scope,
  $rootScope,
  $translate,
  $mdDialog,
  Page,
  Location,
  htmlWork,
  KendoGridHelper,
  ViewsService,
  PermissionUtils
) {
  $scope.removeLocation = async function removeLocation (locationId) {
    $mdDialog
      .show(
        $mdDialog
          .confirm()
          .title($translate.instant('FORM.DELETE_MESSAGE'))
          .targetEvent()
          .clickOutsideToClose(true)
          .parent(angular.element(document.body))
          .ok($translate.instant('BUTTONS.DELETE'))
          .cancel($translate.instant('BUTTONS.CANCEL'))
      )
      .then(
        function () {
          Location.deleteLocation({
            locationId
          })
            .$promise.then(() => {
              debug(`Location '${locationId}' deleted successfully`)
              $scope.kendoGrid.reloadData()
            })
            .catch(err => {
              console.error(err)
              $rootScope.showErrorToast('NP-4000')
              debug(
                `An error occurred while trying to delete a location '${locationId}'`,
                err
              )
            })
        },
        function () {}
      )
  }

  const initScreen = async function initScreen () {
    $scope.title = $rootScope.title = $translate.instant('Location.LOCATIONS')
    Page.setTitleText($scope.title)
    $scope.PermissionUtils = PermissionUtils
    const hasDeletePermissions = PermissionUtils.isPermit(
      'Location',
      'deleteLocation'
    )
    const hasEditPermissions = PermissionUtils.isPermit(
      'Location',
      'patchAttributes'
    )

    const tableColumns = [
      // NAME
      {
        uniqueId: '88ce3d5f-d829-4522-b9b9-a183bf8dcdd2',
        field: 'name',
        translateCode: 'Location.name',
        template: data => {
          return data.name
        }
        // media: '(min-width: 768px)'
      },
      // ACTIONS
      {
        uniqueId: '120a6f52-2816-4acb-bce8-1112848770f3',
        field: 'id',
        translateCode: 'COMMON.ACTIONS',
        headerAttributes: {
          class: 'actions-column-header'
        },
        // media: '(min-width: 768px)',
        filterable: false,
        sortable: false,
        trustedTemplate: data => {
          let html = ''
          if (hasEditPermissions) {
            html += `<div layout="row" layout-align="start center" style="direction: ${$rootScope.dir}">
                    <md-button ng-class="($root.isTabletOrMobile) ? ['md-fab','md-mini','md-primary'] : ['md-icon-button','show-button']" data-testid="edit-${data.id}" ui-sref="app.location.show({id: '${data.id}'})">
                      <md-icon md-font-icon="icon-eye" class="s18"></md-icon>
                    </md-button>`
          }
          if (hasDeletePermissions) {
            html += `<md-button ng-class="($root.isTabletOrMobile) ? ['md-fab','md-mini'] : ['md-icon-button']" class="md-warn" ng-click="removeLocation('${data.id}')" data-testid="delete-${data.id}">
                        <md-icon md-font-icon="icon-delete" class="s18"></md-icon>
                    </md-button>`
          }

          html += '</div>'
          return html
        }
      }
    ]

    const basicFields = {
      id: true,
      name: true,
      modified: true,
      translations: true,
      fallbackStatus: true
    }

    const { defaultTableColumns, customFieldsDisplay } =
      ViewsService.generateCustomFieldColumns('Location', tableColumns)

    const baseFilter = {
      order: 'modified DESC',
      fields: _.merge(basicFields, customFieldsDisplay)
    }

    const { columns, selectedViewId } =
      ViewsService.getTablesColumns(defaultTableColumns)

    const { newBaseFilter, filters } = ViewsService.getViewCustomFilters(
      selectedViewId,
      _.cloneDeep(baseFilter)
    )

    const defaultTableSetup = {
      // stateName
      // ignoreParams
      model: Location,
      find: Location.find,
      count: Location.count,
      cleanBaseFilter: baseFilter,
      baseFilter: newBaseFilter,
      selectedViewId,
      columns: defaultTableColumns
    }

    const defaultTableToolbarSetup = {
      // stateName
      columns: defaultTableColumns,
      currentColumnIds: columns.map(c => c.uniqueId),
      filters,
      selectedViewId,
      title: $scope.title
    }

    $scope.downloadFunction = () => {}

    $scope.kendoGrid = await KendoGridHelper.GridInstance(
      defaultTableSetup,
      $scope,
      columns
    )

    await $scope.kendoGrid.isBound()

    $scope.tableToolbar = await ViewsService.GridToolBarInstance(
      defaultTableToolbarSetup,
      $scope.kendoGrid,
      $scope
    )

    $scope.$applyAsync()
  }

  initScreen()

  $scope.headerOptions = {
    icon: 'icon-map-marker-multiple',
    template: require('app/templates/headers/simple.html'),
    title: $scope.title,
    fabButton: {
      template: require('app/templates/headers/fab-button.html'),
      action: null,
      icon: 'icon-plus',
      href: '',
      state: 'app.location.show',
      showExpression: () => PermissionUtils.isPermit('Location', 'create')
    }
  }
}

module.exports = LocationsController
