/** @ngInject */

module.exports = function config (
  $stateProvider,
  msApiProvider,
  msNavigationServiceProvider
) {
  // State
  $stateProvider
    .state('app.iot', {
      url: '/iot',
      abstract: true,
      lazyLoad: $transition$ => {
        const $ocLazyLoad = $transition$.injector().get('$ocLazyLoad')
        return import(/* webpackChunkName: "iot.module" */ './iot.module.js')
          .then(mod => {
            return $ocLazyLoad.load(mod.default)
          })
          .catch(err => {
            throw new Error('Ooops, something went wrong, ' + err)
          })
      }
    })
    .state('app.iot.gateway', {
      url: '/gateway/?:filter',
      params: {
        filter: {
          dynamic: true
        }
      },
      views: {
        'content@app': {
          template: require('app/modules/components/tables/kendo-grid/grid.html'),
          controller: 'GatewaysController',
          controllerAs: 'vm'
        }
      },
      bodyClass: 'iot'
    })
    .state('app.iot.gateway-show', {
      url: '/gateway/show/?:id',
      params: {
        id: {
          dynamic: true
        }
      },
      resolve: {
        ResolvedGateway: (Gateway, $stateParams) =>
          Gateway.findOne({
            filter: { where: { id: $stateParams.id } }
          }).$promise,
        ResolvedStations: Station => Station.find({}).$promise,
        ResolvedDeviceFamilies: DeviceFamily =>
          DeviceFamily.find({
            filter: {
              fields: {
                id: true,
                name: true,
                type: true
              }
            }
          }).$promise
      },
      views: {
        'content@app': {
          template: require('./gateway/gateway-show.template.html'),
          controller: 'NewEditGatewayController',
          controllerAs: 'vm'
        }
      },
      bodyClass: 'iot'
    })
    .state('app.iot.device-family', {
      url: '/device-family/?:filter',
      params: {
        filter: {
          dynamic: true
        }
      },
      views: {
        'content@app': {
          template: require('app/modules/components/tables/kendo-grid/grid.html'),
          controller: 'DeviceFamiliesController',
          controllerAs: 'vm'
        }
      },
      bodyClass: 'iot'
    })
    .state('app.iot.device-family-show', {
      url: '/device-family/show/?:id',
      params: {
        id: {
          dynamic: true
        }
      },
      resolve: {
        ResolvedDeviceFamily: (DeviceFamily, $stateParams) => {
          if (!$stateParams.id || $stateParams.id === '') {
            return null
          }
          return DeviceFamily.findOne({
            filter: { where: { id: $stateParams.id } }
          }).$promise
        },
        ResolvedGateway: Gateway =>
          Gateway.find({
            filter: {
              where: { authorized: true },
              fields: { id: true, name: true }
            }
          }).$promise
      },
      views: {
        'content@app': {
          template: require('./device-family/device-family-show.template.html'),
          controller: 'NewEditDeviceTypeController',
          controllerAs: 'vm'
        }
      },
      bodyClass: 'iot'
    })
    .state('app.iot.station', {
      url: '/station/?:filter',
      params: {
        filter: {
          dynamic: true
        }
      },
      views: {
        'content@app': {
          template: require('app/modules/components/tables/kendo-grid/grid.html'),
          controller: 'StationsController',
          controllerAs: 'vm'
        }
      },
      bodyClass: 'iot'
    })
    .state('app.iot.station-show', {
      url: '/station/show/?:id',
      params: {
        id: {
          dynamic: true
        }
      },
      resolve: {
        ResolvedStation: (Station, $stateParams) => {
          if (!$stateParams.id || $stateParams.id === '') {
            return null
          }
          return Station.findOne({
            filter: { where: { id: $stateParams.id } }
          }).$promise
        }
      },
      views: {
        'content@app': {
          template: require('./station/station-show.template.html'),
          controller: 'NewEditStationController',
          controllerAs: 'vm'
        }
      },
      bodyClass: 'iot'
    })

  // Navigation
  msNavigationServiceProvider.saveItem('apps.modeling.iot', {
    title: 'IOT.NAME',
    icon: 'icon-router-wireless-settings',
    weight: 110,
    hidden: true,
    aclsCondition: 'or',
    acls: [
      { model: 'Gateway', method: 'connectGetway' },
      { model: 'DeviceFamily', method: 'create' },
      { model: 'Station', method: 'create' }
    ]
  })

  msNavigationServiceProvider.saveItem('apps.modeling.iot.gateway', {
    title: 'IOT.GATEWAY.GATEWAYS',
    state: 'app.iot.gateway',
    weight: 1,
    acls: [{ model: 'Gateway', method: 'connectGetway' }]
  })

  msNavigationServiceProvider.saveItem('apps.modeling.iot.device-family', {
    title: 'IOT.DEVICE_FAMILY.DEVICE_FAMILIES',
    state: 'app.iot.device-family',
    weight: 2,
    acls: [{ model: 'DeviceFamily', method: 'create' }]
  })

  msNavigationServiceProvider.saveItem('apps.modeling.iot.station', {
    title: 'IOT.STATION.STATIONS',
    state: 'app.iot.station',
    weight: 3,
    acls: [{ model: 'Station', method: 'create' }]
  })
}
