/* global _ angular moment */
'use strict'
require('./shift.scss')
const UUID = require('uuid')
const EditShiftDialogController = require('./edit-shift/edit-shift.dialog.controller')

/** @ngInject */
function ShiftsController (
  $scope,
  $rootScope,
  $translate,
  $state,
  $stateParams,
  PermissionUtils,
  KendoGridHelper,
  ViewsService,
  Page,
  ResolvedProductionEntities,
  ResolvedShiftTypes,
  DateTimeFormatService,
  $mdDialog,
  Shift
) {
  $scope.$on('$stateChangeSuccess', function (event, toState, toParams) {
    if (
      toParams.date &&
      toParams.date !== moment($scope.model.date).format('YYYY-MM-DD')
    ) {
      const newDate = moment(toParams.date).toDate()
      updateDate(newDate)
    }
  })

  const dropZonesDefinedById = {}

  $scope.shiftTypes = ResolvedShiftTypes.filter(
    shiftType => !shiftType.deletedAt
  )
  $scope.shiftTypesById = _.keyBy(ResolvedShiftTypes, 'id')
  $scope.daysByProductionEntityIdMapping = {}
  let currentWeekShifts = []
  const days = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday'
  ]
  const uniqueIdByDay = {
    sunday: 'a228c8f6-9763-4452-b55f-dfce6777d674',
    monday: '6700c1a9-3afb-408b-92a5-8e766420212d',
    tuesday: '3de2be74-6035-4e26-ad25-9b2bce1c404e',
    wednesday: 'f11ce09c-17b8-4b7f-a432-48382229dbe3',
    thursday: 'b04c4339-fe0f-4395-ace9-00655d997079',
    friday: 'fd8778bf-f24a-44ae-bedd-d1bc50c68f75',
    saturday: '8d1d3007-038e-4bba-8801-45071d513503'
  }

  $scope.setWeekAsDefault = async function setWeekAsDefault () {
    return $mdDialog
      .show(
        $mdDialog
          .confirm()
          .title($translate.instant('SHIFT.APPROVE_SET_WEEK_AS_DEFAULT'))
          .clickOutsideToClose(true)
          .multiple(true)
          .parent(angular.element(document.body))
          .ok($translate.instant('BUTTONS.OK'))
          .cancel($translate.instant('BUTTONS.CANCEL'))
      )
      .then(() => {
        const ids = []
        $scope.data.forEach(productionEntity => {
          days.forEach(day => {
            ids.push(
              ...$scope.daysByProductionEntityIdMapping[
                productionEntity.productionEntityId
              ][day].map(shift => shift.id)
            )
          })
        })
        Shift.setWeekAsDefault({
          ids
        }).$promise.then(async shifts => {
          currentWeekShifts = shifts
          createData()
        })
      })
  }

  $scope.resetToDefault = async function resetToDefault () {
    return $mdDialog
      .show(
        $mdDialog
          .confirm()
          .title($translate.instant('SHIFT.APPROVE_RESET_TO_DEFAULT'))
          .clickOutsideToClose(true)
          .multiple(true)
          .parent(angular.element(document.body))
          .ok($translate.instant('BUTTONS.OK'))
          .cancel($translate.instant('BUTTONS.CANCEL'))
      )
      .then(() => {
        const ids = []
        $scope.data.forEach(productionEntity => {
          days.forEach(day => {
            ids.push(
              ...$scope.daysByProductionEntityIdMapping[
                productionEntity.productionEntityId
              ][day].map(shift => shift.id)
            )
          })
        })
        Shift.resetToDefault({
          startDate: moment($scope.model.date)
            .locale($rootScope.currentLang)
            .format('YYYY-MM-DD'),
          ids
        }).$promise.then(async shifts => {
          currentWeekShifts = shifts
          createData()
        })
      })
  }

  const updateCurrentWeekShifts = async function updateCurrentWeekShifts () {
    const startDate = new Date($scope.model.date)
    startDate.setDate(startDate.getDate() - startDate.getDay())
    currentWeekShifts = await Shift.getCurrentWeekShifts({
      startDate
    }).$promise
  }

  const createData = function createData () {
    $scope.data = []
    $scope.daysByProductionEntityIdMapping = {}
    ResolvedProductionEntities.forEach(productionEntity => {
      $scope.data.push({
        productionEntityId: productionEntity.id,
        productionEntityName: productionEntity.name
      })
      $scope.daysByProductionEntityIdMapping[productionEntity.id] = {}
      days.forEach(day => {
        $scope.daysByProductionEntityIdMapping[productionEntity.id][day] = []
        const date = new Date($scope.dateByDay[day])
        const relevantShifts = currentWeekShifts.filter(shift => {
          const startTime = new Date(shift.startTime)
          startTime.setHours(0, 0, 0, 0)
          const endTime = new Date(shift.endTime)
          endTime.setHours(23, 59, 59, 999)
          return (
            shift.productionEntityId === productionEntity.id &&
            startTime <= date &&
            endTime >= date
          )
        })
        relevantShifts.forEach(shift => {
          const relativeStartTime = new Date(shift.startTime)
          const relativeEndTime = new Date(shift.endTime)
          const currentDay = $scope.dateByDay[day]
          const currentDayStart = new Date(currentDay)
          currentDayStart.setHours(0, 0, 0, 0)
          const currentDayEnd = new Date(currentDay)
          currentDayEnd.setHours(23, 59, 59, 999)
          if (relativeStartTime < currentDayStart) {
            relativeStartTime.setHours(0, 0, 0, 0)
          }
          if (relativeEndTime > currentDayEnd) {
            relativeEndTime.setHours(23, 59, 59, 999)
          }
          $scope.daysByProductionEntityIdMapping[productionEntity.id][day].push(
            {
              ...shift,
              relativeStartTime: DateTimeFormatService.formatDateTime(
                relativeStartTime,
                'time'
              ),
              relativeEndTime: DateTimeFormatService.formatDateTime(
                relativeEndTime,
                'time'
              )
            }
          )
        })
      })
    })
  }

  const updateDate = async function updateDate (date) {
    $rootScope.loadingProgress = true
    $scope.model.date = date
    $scope.initialDate = date
    $state.go(
      '.',
      { date: moment(date).format('YYYY-MM-DD') },
      { notify: false }
    )
    updateDateByDay()
    await updateCurrentWeekShifts()
    createData()
    const newColumns = $scope.kendoGrid.instance
      .getOptions()
      .columns.map(column => {
        if (uniqueIdByDay[column.field]) {
          const dayDate = DateTimeFormatService.formatDateTime(
            $scope.dateByDay[column.field],
            'date'
          )
          const title = $translate.instant(
            `SHIFT.SHIFTS.COLUMNS.${column.field.toUpperCase()}`,
            { date: dayDate }
          )
          return {
            ...column,
            title,
            headerTemplate: `<div layout="column" class="dropzone" id="dropzone_${column.field}"><span>${title}</span></div>`
          }
        }
        return column
      })
    $scope.kendoGrid.instance.setOptions({
      columns: newColumns
    })
    $rootScope.loadingProgress = false
  }
  $scope.moveDate = async function moveDate (direction) {
    $rootScope.loadingProgress = true
    const date = new Date($scope.model.date)
    date.setDate(date.getDate() + (direction === 'next' ? 7 : -7))
    await updateDate(date)
    $rootScope.loadingProgress = false
  }

  const updateDateByDay = function updateDateByDay () {
    const date = new Date($scope.model.date)
    days.forEach((day, index) => {
      date.setDate(date.getDate() - date.getDay() + index)
      date.setHours(0, 0, 0, 0)
      $scope.dateByDay[day] = _.clone(date)
    })
  }

  $scope.editShift = function editShift (shiftId, productionEntityId, day) {
    const shift = $scope.daysByProductionEntityIdMapping[productionEntityId][
      day
    ].find(shift => shift.id === shiftId)
    return $mdDialog
      .show({
        /** @ngInject */
        controller: EditShiftDialogController,
        locals: {
          shift,
          productionEntityName: $scope.data.find(
            data => data.productionEntityId === productionEntityId
          ).productionEntityName,
          shiftName: $scope.shiftTypesById[shift.shiftTypeId].name
        },
        template: require('./edit-shift/edit-shift.dialog.template.html'),
        parent: angular.element(document.body),
        multiple: true,
        fullscreen: true,
        clickOutsideToClose: false
      })
      .then(result => {
        const newShifts = currentWeekShifts.map(shift => {
          if (shift.id === shiftId) {
            return result
          }
          return shift
        })
        currentWeekShifts = newShifts
        createData()
      })
  }

  $scope.deleteShifts = async function deleteShifts (
    shiftId = null,
    productionEntityId = null,
    day = null
  ) {
    let shiftsToDelete = []
    if (shiftId) {
      shiftsToDelete.push(
        $scope.daysByProductionEntityIdMapping[productionEntityId][day].find(
          shift => shift.id === shiftId
        )
      )
    } else if (productionEntityId) {
      days.forEach(day => {
        shiftsToDelete.push(
          ...$scope.daysByProductionEntityIdMapping[productionEntityId][day]
        )
      })
    } else if (day) {
      shiftsToDelete =
        $scope.daysByProductionEntityIdMapping[productionEntityId][day]
    } else {
      $scope.data.forEach(productionEntity => {
        days.forEach(day => {
          shiftsToDelete.push(
            ...$scope.daysByProductionEntityIdMapping[
              productionEntity.productionEntityId
            ][day]
          )
        })
      })
    }
    if (shiftsToDelete.length > 0) {
      let title = $translate.instant('SHIFT.REAL_DELETE_SHIFT')
      if (_.some(shiftsToDelete, shift => shift.isDefault)) {
        title = $translate.instant('SHIFT.REAL_DELETE_SHIFT_WITH_DEFAULT')
      }
      $mdDialog
        .show(
          $mdDialog
            .confirm()
            .title(title)
            .clickOutsideToClose(true)
            .multiple(true)
            .parent(angular.element(document.body))
            .ok($translate.instant('BUTTONS.DELETE'))
            .cancel($translate.instant('BUTTONS.CANCEL'))
        )
        .then(function () {
          $rootScope.loadingProgress = true
          const ids = shiftsToDelete.map(shift => shift.id)
          Shift.deleteMany({ ids })
            .$promise.then(() => {
              $scope.data.forEach(productionEntity => {
                days.forEach(day => {
                  $scope.daysByProductionEntityIdMapping[
                    productionEntity.productionEntityId
                  ][day] = $scope.daysByProductionEntityIdMapping[
                    productionEntity.productionEntityId
                  ][day].filter(shift => !ids.includes(shift.id))
                })
              })
              currentWeekShifts = currentWeekShifts.filter(
                shift => !ids.includes(shift.id)
              )
              $rootScope.showSuccessToast(
                $translate.instant('SHIFT.SHIFT_DELETED_SUCCESS')
              )
              $rootScope.loadingProgress = false
            })
            .catch(err => {
              console.error(err)
              $rootScope.showErrorToast('NP-6532')
              $rootScope.loadingProgress = false
            })
        })
    }
  }

  const addShifts = async function addShifts (
    shiftTypeId,
    shiftsToCreate,
    validate
  ) {
    $rootScope.loadingProgress = true
    if (!shiftTypeId) return
    const shifts = []
    const clonedDaysByProductionEntityIdMapping = _.cloneDeep(
      $scope.daysByProductionEntityIdMapping
    )
    for (let i = 0; i < shiftsToCreate.length; i++) {
      const { productionEntityId, day } = shiftsToCreate[i]
      const current =
        clonedDaysByProductionEntityIdMapping[productionEntityId][day]
      if (
        !validate ||
        (validate && !current.find(shift => shift.shiftTypeId === shiftTypeId))
      ) {
        const shiftType = $scope.shiftTypesById[shiftTypeId]
        const startTime = new Date($scope.dateByDay[day])
        const splittedStartTime = shiftType.startTime.split(':')
        startTime.setHours(
          parseInt(splittedStartTime[0]),
          parseInt(splittedStartTime[1])
        )
        const splittedEndTime = shiftType.endTime.split(':')
        const endTime = new Date(startTime)
        endTime.setHours(
          parseInt(splittedEndTime[0]),
          parseInt(splittedEndTime[1])
        )
        if (endTime <= startTime) {
          endTime.setDate(endTime.getDate() + 1)
        }
        const currentShift = {
          id: UUID(),
          shiftTypeId,
          productionEntityId,
          startTime,
          endTime,
          isDefault: false
        }
        current.push(currentShift)
        shifts.push(currentShift)
      }
    }
    if (shifts.length > 0) {
      Shift.createMany(shifts).$promise.then(() => {
        currentWeekShifts = [...currentWeekShifts, ...shifts]
        createData()
        $rootScope.showSuccessToast(
          $translate.instant('SHIFT.SHIFT_ADDED_SUCCESS')
        )
        $rootScope.loadingProgress = false
      })
    } else {
      $rootScope.loadingProgress = false
    }
  }

  const initializeDraggable = function initializeDraggable () {
    $scope.shiftTypes.forEach(shiftType => {
      const draggable = document.getElementById(`draggable-${shiftType.id}`)
      let draggedClone = null

      // Draggable element events
      draggable.addEventListener('dragstart', e => {
        // Create clone during drag start
        draggedClone = e.target.cloneNode(true)

        // Generate unique ID for the clone
        const uniqueId = `${e.target.id}-clone-${Date.now()}`
        draggedClone.id = uniqueId

        // Store the IDs in dataTransfer
        e.dataTransfer.setData('text/plain', uniqueId)
        e.dataTransfer.setData('originalId', e.target.id)

        // Visual feedback during drag

        // Add dashed border to all dropzones
        const allDropzones = document.querySelectorAll('[id^="dropzone"]')
        allDropzones.forEach(dropzone => {
          dropzone.style.border = '3px dashed #ccc'
        })
      })

      draggable.addEventListener('dragend', e => {
        draggedClone = null

        // Remove dashed border from all dropzones
        const allDropzones = document.querySelectorAll('[id^="dropzone"]')
        allDropzones.forEach(dropzone => {
          dropzone.style.border = '' // Reset to original border style
        })
      })
    })
  }

  const initializeDropzone = function initializeDropzone (
    productionEntityId = null,
    day = null
  ) {
    let dropzoneId = 'dropzone'
    if (productionEntityId) {
      dropzoneId += `_${productionEntityId}`
    }
    if (day) {
      dropzoneId += `_${day}`
    }
    const dropzone = document.getElementById(dropzoneId)
    if (!dropzone) {
      return
    }

    const dropzoneIsColumnHeader = !productionEntityId
    if (dropzoneIsColumnHeader && dropZonesDefinedById[dropzoneId]) {
      return
    }

    dropzone.addEventListener('dragover', e => {
      e.preventDefault()
      dropzone.classList.add('dragover')
    })

    dropzone.addEventListener('dragleave', () => {
      dropzone.classList.remove('dragover')
    })

    dropzone.addEventListener('drop', async e => {
      e.preventDefault()
      dropzone.classList.remove('dragover')

      const originalId = e.dataTransfer.getData('originalId')
      const originalElement = document.getElementById(originalId)
      const shiftTypeId = originalId.replace('draggable-', '')

      const shiftsToCreate = []
      let validate = false
      if (originalElement) {
        // Check if this is the main production entity column header dropzone
        if (dropzone.id === 'dropzone') {
          validate = true
          // Fill all cells for all production entities and days
          for (let i = 0; i < $scope.data.length; i++) {
            const row = $scope.data[i]
            for (let j = 0; j < days.length; j++) {
              const day = days[j]
              shiftsToCreate.push({
                productionEntityId: row.productionEntityId,
                day
              })
            }
          }
        } else {
          // Check if this is a day column header dropzone
          const dayMatch = dropzone.id.match(/^dropzone_([a-z]+)$/)
          // Check if this is a production entity row dropzone
          const productionEntityMatch = dropzone.id.match(/^dropzone_([^_]+)$/)
          if (dayMatch) {
            validate = true
            const day = dayMatch[1]
            // Fill all rows for this day
            for (let i = 0; i < $scope.data.length; i++) {
              const row = $scope.data[i]
              shiftsToCreate.push({
                productionEntityId: row.productionEntityId,
                day
              })
            }
          } else if (productionEntityMatch) {
            validate = true
            const productionEntityId = productionEntityMatch[1]
            // Fill all days for this production entity
            for (let j = 0; j < days.length; j++) {
              const day = days[j]
              shiftsToCreate.push({
                productionEntityId,
                day
              })
            }
          } else {
            // Individual cell dropzone
            shiftsToCreate.push({
              productionEntityId,
              day
            })
          }
        }
        await addShifts(shiftTypeId, shiftsToCreate, validate)
      }
    })

    dropZonesDefinedById[dropzoneId] = true
  }

  const initScreen = async function initScreen () {
    $scope.title = $rootScope.title = $translate.instant('SHIFT.SHIFTS.TITLE')
    Page.setTitleText($scope.title)
    $scope.PermissionUtils = PermissionUtils

    // Use date from URL or default to current week's Sunday
    let date
    if ($stateParams.date) {
      date = moment($stateParams.date).toDate()
    } else {
      date = new Date()
      date.setDate(date.getDate() - date.getDay())
    }
    date.setHours(0, 0, 0, 0)
    $scope.model = { date }
    $scope.initialDate = date
    $scope.dateByDay = {}
    updateDateByDay()
    $scope.fields = [
      {
        key: 'date',
        type: 'datePicker',
        templateOptions: {
          label: $translate.instant('SHIFT.SHIFTS.DATE'),
          removeClearOption: true,
          onChange: async function (value, options) {
            if (!value) return
            const startOfWeek = new Date(value)
            startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay())
            let date = value
            if (value.toISOString() !== startOfWeek.toISOString()) {
              date = startOfWeek
              options.instance.setDate(startOfWeek)
              options.formControl.$setViewValue(startOfWeek)
            }
            if (date.toISOString() !== $scope.initialDate.toISOString()) {
              await updateDate(date)
            }
          }
        }
      }
    ]

    await updateCurrentWeekShifts()
    createData()
    const productionEntityTitle = $translate.instant(
      'SHIFT.SHIFTS.COLUMNS.PRODUCTION_ENTITY'
    )
    const tableColumns = [
      // PRODUCTION_ENTITY
      {
        uniqueId: '98dbea3f-08ea-4a2a-94d8-25411f906729',
        field: 'productionEntityName',
        width: 200,
        headerTemplate: `<div layout="column" class="dropzone" id="dropzone"><span>${productionEntityTitle}</span></div>`,
        title: productionEntityTitle,
        locked: true,
        trustedTemplate: data => {
          return `<div layout="column" class="dropzone" id="dropzone_${data.productionEntityId}"><span>${data.productionEntityName}</span></div>`
        }
        // media: '(min-width: 768px)'
      }
    ]
    days.forEach(day => {
      const title = $translate.instant(
        `SHIFT.SHIFTS.COLUMNS.${day.toUpperCase()}`,
        {
          date: DateTimeFormatService.formatDateTime(
            $scope.dateByDay[day],
            'date'
          )
        }
      )
      tableColumns.push({
        uniqueId: uniqueIdByDay[day],
        field: day,
        headerTemplate: `<div layout="column" class="dropzone" id="dropzone_${day}"><span>${title}</span></div>`,
        filterable: false,
        sortable: false,
        title,
        trustedTemplate: data => {
          return `
        <div layout="column" class="dropzone" id="dropzone_${data.productionEntityId}_${day}">
          <div layout="row" ng-repeat="shift in daysByProductionEntityIdMapping['${data.productionEntityId}']['${day}'] | orderBy: 'startTime'">
            <div layout="row" class="shift-type table-shift" style="white-space: nowrap; text-overflow: ellipsis; background-color: {{shiftTypesById[shift.shiftTypeId].backgroundColor}};">
                <md-icon style="color:{{shiftTypesById[shift.shiftTypeId].foregroundColor}};" md-font-icon="icon-alpha-d-circle-outline" ng-if="shift.isDefault" class="s15 default-shift-mark">
                  <md-tooltip>
                    {{ 'SHIFT.SHIFTS.DEFAULT' | translate }}
                  </md-tooltip>
                </md-icon>
                <div ng-click="editShift(shift.id, '${data.productionEntityId}', '${day}')" class="shift-type-text" layout="column" layout-align="center center">
                  <div ng-style="{
                    color: shiftTypesById[shift.shiftTypeId].foregroundColor
                  }" class="shift-type-name">{{shiftTypesById[shift.shiftTypeId].name}}</div>
                  <div class="shift-type-times" ng-style="{
                    color: shiftTypesById[shift.shiftTypeId].foregroundColor
                  }"
                  >
                    {{ shift.relativeStartTime }} - {{ shift.relativeEndTime }}
                  </div>
                </div>
                <md-icon style="color:{{shiftTypesById[shift.shiftTypeId].foregroundColor}};" md-font-icon="icon-close" class="shift-close-icon s20" ng-click="deleteShifts(shift.id, '${data.productionEntityId}', '${day}'); $event.preventDefault() "></md-icon>
            </div>
          </div>
        </div>`
        }
      })
    })

    const baseFilter = {
      where: {},
      order: 'modified DESC'
    }

    const defaultTableSetup = {
      stateName: 'app.shift.shifts',
      ignoreParams: true,
      data: $scope.data,
      serverSide: false,
      autoSize: false,
      pageable: {
        refresh: false
      },
      encodeTitles: true,
      pageSize: 10,
      baseFilter,
      selectedViewId: 'systemDefault',
      columns: tableColumns,
      extraDataBound: () => {
        initializeDropzone()
        days.forEach(day => {
          initializeDropzone(null, day)
        })
        $scope.data.forEach(row => {
          initializeDropzone(row.productionEntityId)
          days.forEach(day => {
            initializeDropzone(row.productionEntityId, day)
          })
        })
      }
    }

    $scope.kendoGrid = await KendoGridHelper.GridInstance(
      defaultTableSetup,
      $scope,
      tableColumns
    )

    await $scope.kendoGrid.isBound()

    $scope.$applyAsync()
    setImmediate(() => {
      initializeDraggable()
    })
  }

  initScreen()

  $scope.headerOptions = {
    icon: 'icon-account',
    template: require('app/templates/headers/simple.html'),
    title: $scope.title
  }
}

module.exports = ShiftsController
