/* global tinymce _ angular */
import FileTypes from 'app/constants/file-types'
import styles from './tinymce-display.component.plain.scss'
import { languages } from '../tinymce.constants'
require('./tinymce-editor.component.scss')
/** @ngInject */
const tinymceComponent = {
  bindings: {
    ngModel: '=',
    ngDisabled: '<',
    tinymceOptions: '<',
    uploadOptions: '<',
    placeholder: '@'
  },
  template: require('./tinymce-editor.component.html'),
  controller: function (
    $rootScope,
    $compile,
    $timeout,
    $translate,
    $element,
    $mdToast,
    $window,
    $sce,
    $scope,
    tinymceConfig,
    tinymceService,
    UploadService,
    getUrlFromObj,
    FileManagerService,
    AIService,
    $q
  ) {
    // Temporary disable linter
    const $ctrl = this
    let previousNgModel
    let tinyInstance
    // Initialization logic
    this.$onInit = function () {
      tinymceConfig = tinymceConfig || {}
      $ctrl.tinymceOptions = $ctrl.tinymceOptions || {}
      $ctrl.skeletonStyle = {
        height: ($ctrl.tinymceOptions.height || 170) + 'px',
        width: '100%'
      }
      $ctrl.uploadOptions = $ctrl.uploadOptions || {}
      const defaultUploadOptions = {
        container: 'Users',
        extensions: [...FileTypes.media, ...FileTypes.pdf].join(','),
        direct: false
      }
      $ctrl.uploadOptions = Object.assign(
        defaultUploadOptions,
        $ctrl.uploadOptions
      )
      if (tinymceConfig.baseUrl) {
        tinymce.baseURL = tinymceConfig.baseUrl
      }
      previousNgModel = $ctrl.ngModel
      $ctrl.uniqueId = tinymceService.getUniqueId()
    }

    this.$postLink = function () {
      if ($window?.navigator?.userAgent?.includes('Firefox/51.0')) {
        const mdToast = $mdToast.nextplus({
          position: $rootScope.toastLocation,
          parent: 'document.body',
          theme: 'error-toast',
          hideDelay: 3500
        })
        const errorText = `Firefox 51 doesn't support this feature.`
        $mdToast.updateTextContent(errorText)
        $mdToast.show(mdToast)
        throw new Error(errorText)
      }
      import(
        /* webpackChunkName: "tinymce" */ '../../../chunks/tinymce.js'
      ).then(mod => {
        if (!$window.tinymce) {
          return
        }
        $window.tinymce.PluginManager.add('nextplusImage', editor => {
          editor.ui.registry.addButton('nextplusImage', {
            icon: 'image',
            tooltip: 'Insert/edit media',
            onAction: e => {
              let uploadPromise = null
              if ($ctrl.uploadOptions?.direct) {
                uploadPromise = FileManagerService.openUploadMenu(
                  $ctrl.uploadOptions
                )
              } else {
                uploadPromise = FileManagerService.openFileManagerDialog(
                  $ctrl.uploadOptions
                )
              }
              uploadPromise.then(resources => {
                if (!resources) return
                let html = null
                // For base64 support
                if (resources.data) resources = resources.data
                resources.forEach(resource => {
                  const url = getUrlFromObj(resource)

                  if (FileTypes.image.includes(resource.ext)) {
                    html = `<img data-resource-id="${resource.id}" src="${url}" width="400" />`
                  } else if (FileTypes.video.includes(resource.ext)) {
                    html = `
                    <img
                      width=360px""
                      height="203px"
                      class="custom-video"
                      data-resource-id="${resource.id}"
                      src="assets/images/video.png"
                      ta-insert-video="${url}"
                      contenteditable="false"
                      allowfullscreen="true"
                      frameborder="0">​`
                  } else if (FileTypes.pdf.includes(resource.ext)) {
                    html = `<img width="100%"
                          class="custom-pdf"
                          data-resource-id="${resource.id}"
                          src="assets/images/pdf.jpg"
                          ta-insert-video="${url}" contenteditable="false" allowfullscreen="true"  frameborder="0">​`
                  }
                })
                editor.insertContent(html)
              })
            }
          })
        })

        $window.tinymce.PluginManager.add('nextplusLink', editor => {
          editor.ui.registry.addButton('nextplusLink', {
            icon: 'link',
            tooltip: 'Insert/edit link',
            onAction: e => {
              const selectedText = editor.selection
                .getContent({ format: 'html' })
                .trim()
              $rootScope
                .linkToDocument()
                .then(link => {
                  if (link) {
                    const html = `<a href="${link}" target="_blank">${
                      selectedText || link
                    }</a>`
                    editor.insertContent(html)
                  }
                })
                .catch(err => {
                  console.error(err)
                })
            }
          })
        })

        const expression = {}
        const options = {
          uniqueId: $ctrl.uniqueId,
          debounce: false,
          placeholder: $ctrl.placeholder || ''
        }
        let tinyInstance
        const getContentForModel = function (editor) {
          let content = editor.getContent({ format: options.format }).trim()
          content = $sce.trustAsHtml(content)
          if (content.$$unwrapTrustedValue)
            content = content.$$unwrapTrustedValue()
          return content
        }
        const updateView = function (editor) {
          previousNgModel = $ctrl.ngModel = getContentForModel(editor)
          if (!$rootScope.$$phase) {
            $scope.$digest()
          }
        }

        // Debounce update and save action
        const debouncedUpdate = (function (debouncedUpdateDelay) {
          let debouncedUpdateTimer
          return function (ed) {
            $timeout.cancel(debouncedUpdateTimer)
            debouncedUpdateTimer = $timeout(function () {
              return (function (ed) {
                if (ed.isDirty()) {
                  ed.save()
                  updateView(ed)
                }
              })(ed)
            }, debouncedUpdateDelay)
          }
        })(400)

        $ctrl.$render = function () {
          ensureInstance()

          const viewValue = $ctrl.ngModel
            ? $sce.getTrustedHtml($ctrl.ngModel)
            : ''

          // instance.getDoc() check is a guard against null value
          // when destruction & recreation of instances happen
          if (tinyInstance && tinyInstance.getDoc()) {
            tinyInstance.setContent(viewValue)
            // Triggering change event due to TinyMCE not firing event &
            // becoming out of sync for change callbacks
            tinyInstance.fire('change')
          }
        }
        const appLang = $translate.use()

        const plugins = [
          'nextplusImage',
          'visualblocks',
          'emoticons',
          'image',
          'autoresize',
          'quickbars',
          'variable',
          'advlist',
          'autolink',
          'lists',
          'link',
          'nextplusLink',
          'charmap',
          'preview',
          'anchor',
          'searchreplace',
          'insertdatetime',
          'media',
          'table',
          'code',
          'wordcount',
          'directionality'
        ]
        const toolbar = [
          'undo',
          'redo',
          '|',
          'formatselect',
          '|',
          'nextplusLink',
          'nextplusImage',
          'bold',
          'italic',
          'forecolor',
          'backcolor',
          '|',
          'alignleft',
          'aligncenter',
          'alignright',
          'alignjustify',
          '|',
          'bullist',
          'numlist',
          'outdent',
          'indent',
          '|',
          'removeformat',
          '|',
          'ltr',
          'rtl',
          '|',
          'visualblocks'
        ]
        const quickbarsSelectionToolbar = ['nextplusLink']
        const quickbarsInsertToolbar = ['nextplusImage', 'quicktable']
        if ($rootScope.appSettings?.aiLLMServiceEnabled) {
          plugins.push('ai')
          toolbar.unshift('ai-shortcuts')
          toolbar.unshift('ai')
          quickbarsSelectionToolbar.unshift('|')
          quickbarsSelectionToolbar.unshift('ai-shortcuts')
          quickbarsSelectionToolbar.unshift('ai')
        }
        const setupOptions = {
          plugins: plugins.join(' '),
          toolbar: toolbar.join(' '),
          language: languages[appLang] || appLang,
          directionality: $rootScope.dir,
          quickbars_insert_toolbar: quickbarsInsertToolbar.join(' '),
          quickbars_selection_toolbar: quickbarsSelectionToolbar.join(' '),
          content_style: styles,
          images_upload_handler: function (blobInfo, progress) {
            return new Promise((resolve, reject) => {
              const base64 = blobInfo.base64()

              UploadService.uploadBase64File(
                'data:image/png;base64,' + base64,
                $ctrl.uploadOptions
              )
                .then(
                  res => {
                    const resource = res.data[0]
                    const url = getUrlFromObj(resource)
                    resolve(url)
                  },
                  reject,
                  function (evt) {
                    progress(
                      Math.min(100, parseInt((100.0 * evt.loaded) / evt.total))
                    )
                  }
                )
                .catch(reject)
            })

            // console.log(blobInfo, success, failure)
          },
          style_formats: [
            {
              title: 'Info Bar',
              block: 'div',
              wrapper: true,
              classes: 'tinymce-bar tinymce-bar-info'
            },
            {
              title: 'Warning Bar',
              block: 'div',
              wrapper: true,
              classes: 'tinymce-bar tinymce-bar-warning'
            },
            {
              title: 'Danger Bar',
              block: 'div',
              wrapper: true,
              classes: 'tinymce-bar tinymce-bar-error'
            }
          ],
          // Update model when calling setContent
          // (such as from the source editor popup)
          setup: function (ed) {
            ed.ui.registry.addMenuItem('useBrowserSpellcheck', {
              text: 'Use `Ctrl+Right click` to access spellchecker',
              onAction: function () {
                ed.notificationManager.open({
                  text: 'To access the spellchecker, hold the Control (Ctrl) key and right-click on the misspelt word.',
                  type: 'info',
                  timeout: 5000,
                  closeButton: true
                })
              }
            })
            ed.ui.registry.addContextMenu('useBrowserSpellcheck', {
              update: function (node) {
                return ed.selection.isCollapsed()
                  ? ['useBrowserSpellcheck']
                  : []
              }
            })
            ed.on('init', function () {
              $ctrl.$render()
            })
            ed.on('destroy', function () {
              if (typeof $ctrl.tinymceOptions.onDestroy === 'function') {
                $ctrl.tinymceOptions.onDestroy(ed)
              }
            })
            // Update model when:
            // - a button has been clicked [ExecCommand]
            // - the editor content has been modified [change]
            // - the node has changed [NodeChange]
            // - an object has been resized (table, image) [ObjectResized]

            ed.on('ExecCommand change NodeChange ObjectResized', function () {
              if (!options.debounce) {
                ed.save()
                updateView(ed)
                return
              }
              debouncedUpdate(ed)
            })
            ed.on('blur', function () {
              $element[0].blur()
              // $ctrl.ngModel.$setTouched()
              if (!$rootScope.$$phase) {
                $scope.$digest()
              }
            })

            ed.on('remove', function () {
              $element.remove()
            })

            if (tinymceConfig.setup) {
              tinymceConfig.setup(ed, {
                updateView
              })
            }

            if (expression.setup) {
              expression.setup(ed, {
                updateView
              })
            }
          },
          ai: {
            shortcuts: [
              {
                title: 'Compliance and Regulations',
                prompts: [
                  {
                    title: 'ISO 9001 Alignment',
                    prompt:
                      'Modify this text to ensure alignment with ISO 9001 Quality Management System requirements.'
                  },
                  {
                    title: 'ASME Standards',
                    prompt:
                      'Adapt the text to emphasize adherence to American Society of Mechanical Engineers (ASME) standards and codes.'
                  },
                  {
                    title: 'CE Marking Compliance',
                    prompt:
                      'Revise the content to highlight compliance with European Conformity (CE) product standards.'
                  },
                  {
                    title: 'RoHS Directives',
                    prompt:
                      'Modify this text to stress conformity with the Restriction of Hazardous Substances (RoHS) directive.'
                  }
                ]
              },
              {
                title: 'Six Sigma and Lean Principles',
                prompts: [
                  {
                    title: 'DMAIC Focus',
                    prompt:
                      'Reframe the text around the DMAIC (Define, Measure, Analyze, Improve, Control) methodology for process improvements.'
                  },
                  {
                    title: '5S Alignment',
                    prompt:
                      'Revise the content to embody the 5S (Sort, Set in order, Shine, Standardize, Sustain) workplace organization method.'
                  },
                  {
                    title: 'Value Stream Mapping',
                    prompt:
                      'Transform this text to emphasize the steps and flow of a process, similar to a Value Stream Map.'
                  },
                  {
                    title: 'Poka-Yoke Emphasis',
                    prompt:
                      'Adjust the text to embody the Poka-Yoke (error-proofing) principle, ensuring clarity and eliminating potential errors.'
                  }
                ]
              },
              {
                title: 'Technical Specifications',
                prompts: [
                  {
                    title: 'Material Properties',
                    prompt:
                      'Reframe this text to detail specific material properties, such as tensile strength, density, or electrical conductivity.'
                  },
                  {
                    title: 'Component Specifications',
                    prompt:
                      "Describe in depth the component's features, dimensions, tolerances, and intended uses."
                  },
                  {
                    title: 'Assembly Instructions',
                    prompt:
                      'Modify this text to provide clear, step-by-step assembly or installation instructions.'
                  },
                  {
                    title: 'Performance Metrics',
                    prompt:
                      'Reformat the content to emphasize key performance metrics, benchmarks, or testing outcomes.'
                  }
                ]
              },
              {
                title: 'General AI Assistant Tools',
                prompts: [
                  {
                    title: 'Expand Text',
                    prompt:
                      'Elaborate on the given content, adding more depth and details to provide a comprehensive understanding.'
                  },
                  {
                    title: 'Direct Writing',
                    prompt:
                      'Write on the provided topic or theme, ensuring clarity, coherence, and purposeful direction.'
                  }
                ]
              },
              {
                title: 'Pharmaceuticals',
                prompts: [
                  {
                    title: 'GMP Compliance',
                    prompt:
                      'Modify this text to highlight adherence to Good Manufacturing Practices (GMP) in the pharmaceutical industry.'
                  },
                  {
                    title: 'Drug Specifications',
                    prompt:
                      'Refine the content to detail specific drug properties, dosages, side effects, and contraindications.'
                  },
                  {
                    title: 'Clinical Trials',
                    prompt:
                      'Adjust the text to emphasize clinical trial phases, methodologies, results, and ethical considerations.'
                  },
                  {
                    title: 'Regulatory Submissions',
                    prompt:
                      'Revise this text to outline the process and requirements for regulatory submissions to agencies like the FDA or EMA.'
                  }
                ]
              },
              {
                title: 'Food & Beverage',
                prompts: [
                  {
                    title: 'HACCP Guidelines',
                    prompt:
                      'Adjust this text to emphasize adherence to Hazard Analysis and Critical Control Points (HACCP) guidelines in food safety.'
                  },
                  {
                    title: 'Nutritional Information',
                    prompt:
                      'Elaborate on the content to provide comprehensive nutritional information, including calories, macros, and vitamins.'
                  },
                  {
                    title: 'Ingredient Listing',
                    prompt:
                      'Modify the text to detail all ingredients in a food product, including potential allergens.'
                  },
                  {
                    title: 'Beverage Specifications',
                    prompt:
                      "Describe the beverage's properties, ingredients, and any associated health benefits or considerations."
                  }
                ]
              },
              {
                title: 'Standard Operating Procedures',
                prompts: [
                  {
                    title: 'SOP Format',
                    prompt:
                      'Reformat this text to follow a standard operating procedure format, detailing each step sequentially with clarity.'
                  },
                  {
                    title: 'Safety Procedures',
                    prompt:
                      'Adjust this text to emphasize safety protocols and emergency procedures integral to the operation.'
                  },
                  {
                    title: 'Equipment Handling',
                    prompt:
                      'Modify the content to provide instructions on equipment usage, maintenance, and troubleshooting.'
                  },
                  {
                    title: 'Quality Checks',
                    prompt:
                      'Refine this text to incorporate checkpoints for quality assurance throughout the procedure.'
                  }
                ]
              }
            ],
            handleQuestion: function (context, cb) {
              const Completion = new AIService.Completion(
                [
                  {
                    role: 'system',
                    content: `Provide an answer based on the context provided.
                Ensure the reply is in clean HTML format.
                Maintain any HTML structures, hyperlinks, styling, and original language from the context.
                In your response, use HTML only.
                User might ask to rewrite in specific methodology (such as Poka-Yoke) you should follow user request but not mention the methodology in the response.
                Context: """${context}"""`
                  }
                ],
                {},
                cb
              )
              return Completion
            },
            setPreviewHTML: function (html) {
              const previewElm = document.getElementById('ai-preview')
              $scope.aiPreviewHtml = html
              previewElm.innerHTML = `<tinymce-display ng-model="aiPreviewHtml"></tinymce-display>`
              $compile(document.getElementById('ai-preview'))($scope)
            }
          },
          format: expression.format || 'html',
          selector: '#' + $ctrl.uniqueId,
          paste_data_images: true,
          paste_preprocess: function (editor, args) {
            const content = args.content
            if (
              content.includes('mso-') ||
              content.includes('MsoListParagraphCxSpLast') ||
              content.includes('MsoListParagraphCxSpFirst') ||
              content.includes('</v:shape') ||
              content.includes('MsoTableGrid') ||
              content.includes('<o:p></o:p>') ||
              content.includes('MsoNormal') ||
              content.includes('v:imagedata')
            ) {
              // show editor warning
              editor.notificationManager.open({
                text: 'Pasting from Microsoft Word is not supported. Please paste as plain text using `Ctrl+Shift+V`.',
                type: 'error',
                timeout: 1000 * 5
              })
              return args.preventDefault()
            }
            const div = document.createElement('div')
            div.innerHTML = content

            const images = div.querySelectorAll('img')
            const promises = []

            images.forEach(function (img) {
              const src = img.getAttribute('src')
              if (src && isExternal(src)) {
                const promise = fetchImageAsDataURL(src)
                  .then(function (dataUrl) {
                    return UploadService.uploadBase64File(
                      dataUrl,
                      $ctrl.uploadOptions
                    ).then(function (res) {
                      const resource = res.data[0]
                      const url = getUrlFromObj(resource)
                      img.setAttribute('src', url)
                      img.setAttribute('data-resource-id', resource.id)
                    })
                  })
                  .catch(function (e) {
                    console.error('Failed to fetch image', e)
                    const errorMessage =
                      e.message ||
                      'Failed to fetch or upload an image during paste.'
                    editor.notificationManager.open({
                      text: errorMessage,
                      type: 'error',
                      timeout: 5000 // Display for 5 seconds
                    })
                    // Remove the problematic image
                    img.remove()
                  })

                promises.push(promise)
              }
            })

            if (promises.length > 0) {
              args.preventDefault()

              $q.all(promises).then(function () {
                editor.insertContent(div.innerHTML)
              })
            }
          }
        }
        if (!$ctrl.tinymceOptions || !$ctrl.tinymceOptions.removeMention) {
          setupOptions.variable = [tinymceService.getMentionConfig()]
        }
        console.log('setupOptions', setupOptions)
        // extend options with initial tinymceConfig and
        angular.extend(options, tinymceConfig, expression, setupOptions)
        angular.extend(options, tinymceConfig, expression, $ctrl.tinymceOptions)

        // Wrapped in $timeout due to $tinymce:refresh implementation, requires
        // element to be present in DOM before instantiating editor when
        // re-rendering directive
        $timeout(function () {
          if (options.baseURL) {
            tinymce.baseURL = options.baseURL
          }
          const maybeInitPromise = tinymce.init(options)
          $ctrl.tinymceInstance = maybeInitPromise
          if (maybeInitPromise && typeof maybeInitPromise.then === 'function') {
            maybeInitPromise.then(function (tinyInstance) {
              if (tinyInstance[0]) {
                tinyInstance[0].execCommand(
                  `mceDirection${$rootScope.dir.toUpperCase()}`,
                  false,
                  null,
                  { skip_focus: true }
                )
                toggleDisable($ctrl.ngDisabled)
              }
            })
          } else {
            tinyInstance.execCommand(
              `mceDirection${$rootScope.dir.toUpperCase()}`,
              false,
              null,
              { skip_focus: true }
            )
            tinyInstance[0].render()

            toggleDisable($ctrl.ngDisabled)
          }
        })

        // This block is because of TinyMCE not playing well with removal and
        // recreation of instances, requiring instances to have different
        // selectors in order to render new instances properly
        // const unbindEventListener = $scope.$on(
        //   '$tinymce:refresh',
        //   function (e, id) {
        //     const eid = attrs.id
        //     if (angular.isUndefined(id) || id === eid) {
        //       const parentElement = element.parent()
        //       const clonedElement = element.clone()
        //       clonedElement.removeAttr('id')
        //       clonedElement.removeAttr('style')
        //       clonedElement.removeAttr('aria-hidden')
        //       tinymce.execCommand('mceRemoveEditor', false, eid)
        //       parentElement.append($compile(clonedElement)(scope))
        //       unbindEventListener()
        //     }
        //   }
        // )

        $scope.$on('$destroy', function () {
          ensureInstance()

          if (tinyInstance) {
            tinyInstance.remove()
            $ctrl.tinyInstance = tinyInstance = null
          }
        })

        function isExternal (src) {
          const host = $window.location.host
          const link = document.createElement('a')
          link.href = src
          return link.host !== host
        }

        function fetchImageAsDataURL (src) {
          return $q(function (resolve, reject) {
            UploadService.fetchImageViaProxy(src)
              .then(function (dataUrl) {
                resolve(dataUrl)
              })
              .catch(reject)
          })
        }
      })
    }
    function ensureInstance () {
      if (!tinyInstance && $window.tinymce) {
        $ctrl.tinyInstance = tinyInstance = $window.tinymce.get($ctrl.uniqueId)
      }
    }
    $ctrl.$doCheck = function () {
      ensureInstance()
      if (!tinyInstance) return
      if (_.isUndefined($ctrl.ngModel) || $ctrl.ngModel === null) {
        $ctrl.ngModel = ''
      }
      if ($ctrl.ngModel !== previousNgModel) {
        console.log('ngModel has changed')
        tinyInstance.setContent($ctrl.ngModel)
        previousNgModel = $ctrl.ngModel
      }
    }

    const toggleDisable = function toggleDisable (disabled) {
      ensureInstance()
      if (tinyInstance) {
        if (disabled) {
          tinyInstance.mode.set('readonly')
        } else {
          tinyInstance.mode.set('design')
        }
      }
    }
    $ctrl.$onChanges = function (changes) {
      if (changes.ngDisabled && !changes.ngDisabled.isFirstChange()) {
        toggleDisable(changes.ngDisabled.currentValue)
      }
    }
    this.$onDestroy = function () {
      if (tinyInstance) {
        tinyInstance.remove()
        $ctrl.tinyInstance = tinyInstance = null
      }
    }
  },
  require: {
    ngModelCtrl: 'ngModel'
  }
}
module.exports = tinymceComponent
