import angular from 'angular'

import QuickPanelController from './quick-panel.controller'

const MODULE_NAME = 'app.quick-panel'

angular.module(MODULE_NAME, [])
  .config(config)
  .controller('QuickPanelController', QuickPanelController)

/** @ngInject */
function config () {

}

export default MODULE_NAME


