/* global $ */
/** @ngInject */
function WorkflowSignController (
  $scope,
  $rootScope,
  $state,
  $mdDialog,
  UserModel,
  preview,
  $mdToast,
  $translate
) {
  const vm = this
  $scope.user = null
  vm.isEmail = function (email) {
    // eslint-disable-next-line
    var re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
  }

  vm.validateUser = async function validateUser () {
    UserModel.validateElectronicSignUser({
      userOrEmail: vm.form.email
    })
      .$promise.then(user => {
        if (user) {
          $scope.user = user
          $scope.passwordLabel =
            user.source === 'saml' ? 'LOGIN.PIN_CODE' : 'LOGIN.PASSWORD'
        } else {
          $scope.user = null
        }
      })
      .catch(err => {
        let errorMessage = err.data.error.message
        if (
          ['SAML_USER_NO_PIN_CODE', 'USER_NOT_FOUND'].includes(
            err.data.error.code
          )
        ) {
          errorMessage = `${$translate.instant(
            `WF.SIGNS_WORKFLOW.${err.data.error.code}`
          )}`
        }
        const mdToast = $mdToast.nextplus({
          position: $rootScope.toastLocation,
          parent: '.md-dialog-container',
          theme: 'warning-toast',
          hideDelay: 3000
        })
        $mdToast.updateTextContent(errorMessage)
        $mdToast.show(mdToast)
      })
  }

  vm.login = function ($event) {
    $event.preventDefault()
    if (!$scope.user) return
    if (preview) return vm.answer()
    const certificate = {}
    if (vm.isEmail(vm.form.email)) {
      certificate.email = vm.form.email
    } else {
      certificate.username = vm.form.email
    }
    certificate.password = vm.form.password
    const promise = UserModel.validateCredentialsAndApproverACL(
      { rememberMe: vm.form.rememberMe },
      certificate
    )
    promise.$promise.then(function (res) {
      $scope.loginForm.$setValidity('LOGIN_FAILED', true)
      $scope.loginForm.$setValidity('LOGIN_FAILED_USER_NOT_SUPERVISOR', true)
      vm.answer(res)
    })
    promise.$promise.catch(function (res) {
      console.log('Error code', res.data.error.code)
      $scope.loginForm.$setValidity(res.data.error.code, false)
    })
  }
  vm.hide = function () {
    $mdDialog.hide()
  }

  vm.cancel = function () {
    $mdDialog.cancel()
  }

  vm.answer = function (user) {
    $mdDialog.hide(user)
  }

  $(document).keyup(function (event) {
    if (event.which === 13) {
      event.preventDefault()
      if ($scope.user) {
        vm.login(event)
      } else {
        vm.validateUser()
      }
    }
  })
}

module.exports = WorkflowSignController
