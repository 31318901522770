'use strict'

/** @ngInject */
function LanguageUtils ($rootScope, $translate, $window, amMoment) {
  const results = { language: 'en', dir: 'ltr' }
  let currentLang = 'en'
  const rtlLanguages = ['he', 'ar']
  Object.defineProperty(results, 'language', {
    get: () => {
      return currentLang
    },
    set: lang => {
      if (rtlLanguages.includes(lang)) {
        results.dir = 'rtl'
      } else {
        results.dir = 'ltr'
      }
      $rootScope.currentLang = lang
      currentLang = lang
      amMoment.changeLocale(lang)
      $translate.use(lang)
    }
  })
  Object.defineProperty(results, 'dir', {
    get: () => {
      return rtlLanguages.includes(results.language) ? 'rtl' : 'ltr'
    },
    set: dir => {
      $rootScope.dir = dir
      if (dir === 'rtl') {
        $rootScope.toastLocation = 'top left'
      } else {
        $rootScope.toastLocation = 'top right'
      }
      const oppositeDir = dir === 'ltr' ? 'rtl' : 'ltr'
      document.documentElement.classList.remove(`k-${oppositeDir}`)
      document.documentElement.classList.add(`k-${dir}`)
      document.documentElement.setAttribute('dir', dir)
      if ($window.Intercom) {
        window.Intercom('update', {
          alignment: dir === 'rtl' ? 'right' : 'left'
        })
      }
    }
  })

  return results
}

module.exports = LanguageUtils
