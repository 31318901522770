/* global _ kendo */
'use strict'
const debug = require('debug')('nextplus:users')
/** @ngInject */
function UserModelsController (
  $scope,
  $rootScope,
  $translate,
  $mdToast,
  htmlWork,
  PermissionUtils,
  KendoGridHelper,
  ViewsService,
  Page,
  UserModel,
  ResolvedRoles,
  ResolvedGroups,
  ResolvedLocations,
  DateTimeFormatService
) {
  const rolesByIds = _.keyBy(ResolvedRoles, 'id')
  const groupsById = _.keyBy(ResolvedGroups, 'id')
  const locationById = _.keyBy(ResolvedLocations, 'id')

  $scope.unlockUser = async function unlockUser (id) {
    debug(`Unlock user '${id}'`)
    try {
      const { isUnlocked } = await UserModel.unlockUser({ id }).$promise
      if (isUnlocked) {
        debug(`User '${id}' unlocked successfully`)
        $mdToast.show(
          $mdToast.nextplus({
            position: $rootScope.toastLocation,
            parent: '#content-container',
            theme: 'success-toast',
            hideDelay: 2500
          })
        )
        $mdToast.updateTextContent(
          $translate.instant('USER.USER_UNLOCKED_SUCCESSFULLY')
        )
        $scope.kendoGrid.reloadData()
      }
    } catch (err) {
      debug(`An error occurred while trying to unlock an user '${id}'`, err)
      $mdToast.show(
        $mdToast.nextplus({
          position: $rootScope.toastLocation,
          parent: '#content-container',
          theme: 'error-toast',
          hideDelay: 2500
        })
      )
      $mdToast.updateTextContent(
        $translate.instant('USER.USER_UNLOCKED_FAILED')
      )
    }
  }

  const hasCreatePermissions = PermissionUtils.isPermit(
    'UserModel',
    'createNewUser'
  )

  const initScreen = async function initScreen () {
    $scope.title = $rootScope.title = $translate.instant(
      'USER.USERS_CONFIGURATION'
    )
    Page.setTitleText($scope.title)
    $scope.PermissionUtils = PermissionUtils

    const tableColumns = [
      // USERNAME
      {
        uniqueId: '90d694f4-02d5-463f-8561-a3ae7d1f71d8',
        field: 'username',
        translateCode: 'USER.username'
        // media: '(min-width: 768px)'
      },
      // EMAIL
      {
        uniqueId: '78d7cd93-9b04-4d23-96f1-141b5138ed24',
        field: 'email',
        translateCode: 'USER.email'
        // media: '(min-width: 768px)'
      },
      // FIRST_NAME
      {
        uniqueId: '317d5d24-92ee-46be-9031-d61b36be7bb5',
        field: 'firstName',
        translateCode: 'USER.firstName'
        // media: '(min-width: 768px)'
      },
      // LAST_NAME
      {
        uniqueId: 'ed496aed-22bc-4443-993b-e10cb9b5e68e',
        field: 'lastName',
        translateCode: 'USER.lastName'
        // media: '(min-width: 768px)'
      },
      // ROLES
      {
        uniqueId: 'e90573f6-c6e4-49a2-9508-444af9920077',
        field: 'roles.roleId',
        translateCode: 'USER.PERMISSIONS',
        // media: '(min-width: 768px)'
        filterable: {
          mode: 'row',
          cell: {
            showOperators: false,
            operator: 'equal',
            suggestionOperator: 'equal',
            template: function (args) {
              args.element.kendoDropDownList({
                filter: 'contains',
                autoBind: false,
                dataTextField: 'nicename',
                dataValueField: 'id',
                dataSource: new kendo.data.DataSource({
                  data: ResolvedRoles
                }),
                valuePrimitive: true
              })
            }
          }
        },
        sortable: true,
        template: data => {
          if (data.roles && data.roles.length) {
            return data.roles
              .map(role =>
                rolesByIds[role.roleId] ? rolesByIds[role.roleId].nicename : ''
              )
              .join(', ')
          }
          return ''
        }
      },
      // Groups
      {
        uniqueId: '5fbe92df-94f1-4f67-ba46-ff820077cc92',
        field: 'groups',
        translateCode: 'USER.GROUPS',
        hidden: true,
        type: 'array',
        filterable: {
          mode: 'row',
          cell: {
            showOperators: false,
            operator: 'equal',
            suggestionOperator: 'equal',
            template: function (args) {
              args.element.kendoDropDownList({
                filter: 'contains',
                autoBind: false,
                dataTextField: 'name',
                dataValueField: 'id',
                dataSource: new kendo.data.DataSource({
                  data: ResolvedGroups
                }),
                valuePrimitive: true
              })
            }
          }
        },
        sortable: true,
        template: data => {
          if (data.groups && data.groups.length) {
            let groups = data.groups.map(group =>
              groupsById[group] ? groupsById[group].name : ''
            )
            groups = groups.join(', ')
            return groups
          }
          return ''
        }
      },
      // STATIONS
      {
        uniqueId: '3f832c58-c1cf-4588-8951-2a83b2156570',
        field: 'locationIds',
        translateCode: 'Location.LOCATIONS',
        hidden: true,
        type: 'array',
        filterable: {
          mode: 'row',
          cell: {
            showOperators: false,
            operator: 'equal',
            suggestionOperator: 'equal',
            template: function (args) {
              args.element.kendoDropDownList({
                filter: 'contains',
                autoBind: false,
                dataTextField: 'name',
                dataValueField: 'id',
                dataSource: new kendo.data.DataSource({
                  data: ResolvedLocations
                }),
                valuePrimitive: true
              })
            }
          }
        },
        sortable: true,
        template: data => {
          if (data.locationIds && data.locationIds.length) {
            let locations = data.locationIds.map(locationId =>
              locationById[locationId] ? locationById[locationId].name : ''
            )
            locations = locations.join(', ')
            return locations
          }
          return ''
        }
      },
      // STATUS
      {
        uniqueId: '03676a6b-ce39-46e7-8937-b29daadeafcf',
        field: 'active',
        translateCode: 'USER.STATUS',
        type: 'boolean',
        // media: '(min-width: 768px)',
        filterable: {
          mode: 'row',
          cell: {
            showOperators: false,
            operator: 'equal',
            suggestionOperator: 'equal',
            template: function (args) {
              args.element.kendoDropDownList({
                filter: 'contains',
                autoBind: false,
                dataTextField: 'label',
                dataValueField: 'value',
                dataSource: new kendo.data.DataSource({
                  data: [
                    {
                      label: $translate.instant('USER.ACTIVE'),
                      value: true
                    },
                    {
                      label: $translate.instant('USER.INACTIVE'),
                      value: false
                    }
                  ]
                }),
                valuePrimitive: true
              })
            }
          }
        },
        trustedTemplate: data => {
          let text = $translate.instant('USER.INACTIVE')
          let iconColor = 'red-500-fg'
          if (data.active) {
            text = $translate.instant('USER.ACTIVE')
            iconColor = 'green-500-fg'
          }
          return `<div layout="row" layout-align="start center">
                    <md-icon md-font-icon="icon-checkbox-blank-circle" style="margin-inline: 0 0.5rem;" class="s16 ${iconColor}"></md-icon>
                    <span>${text}</span>
                  </div>`
        }
      },
      // MANAGER
      {
        uniqueId: 'f5b6a0b4-3912-45f6-ad8a-8cd9f5f3384a',
        field: 'managerName',
        translateCode: 'USER.MANAGER',
        hidden: true,
        trustedTemplate: data => {
          if (!data.manager) return ''
          return `<user-avatar
              user-id="'${htmlWork.escapeHTMLQuotes(data.manager)}'"
              display-name="'${htmlWork.escapeHTMLQuotes(data.managerName)}'"
              show-name-inline="true"
            ></user-avatar>`
        }
      },
      // internal
      {
        uniqueId: '2fd86f6a-1337-4979-878a-8571b1967ddc',
        field: 'internal',
        translateCode: 'USER.INTERNAL',
        type: 'boolean',
        filterable: true,
        hidden: true
      },
      // Digest email
      {
        uniqueId: 'b28ce5f5-b24a-4fe0-8701-8b3f1401ad68',
        field: 'sendDigestEmail',
        translateCode: 'USER.SEND_DIGEST_EMAIL',
        type: 'boolean',
        filterable: true,
        hidden: true
      },
      // employee phone Number
      {
        uniqueId: '08deb98c-ec2b-433c-b332-e845d440fc63',
        field: 'phone',
        translateCode: 'USER.PHONE_NUMBER',
        hidden: true,
        template: data => {
          if (!data.phone) return ''
          const [prefix, number] = data.phone.split('_')
          if (!prefix) {
            return number
          } else {
            return '(' + prefix + ')' + number
          }
        }
      },
      // last password change
      {
        uniqueId: '83898b23-2e48-4676-a865-1013302c4fe1',
        field: 'lastPasswordChange',
        translateCode: 'USER.LAST_PASSWORD_CHANGE',
        type: 'date',
        filterable: true,
        hidden: true,
        template: data =>
          data && data.lastPasswordChange
            ? DateTimeFormatService.formatDateTime(
                data.lastPasswordChange,
                'dateTime'
              )
            : '--'
      },
      // source
      {
        uniqueId: '85b8e4b9-0694-478c-bf50-6d11605a16e1',
        field: 'source',
        translateCode: 'USER.SOURCE',
        hidden: true
      },
      // display name
      {
        uniqueId: 'da558d9e-8e00-454e-b7a3-b39b2c638262',
        field: 'displayName',
        translateCode: 'USER.DISPLAY_NAME',
        hidden: true
      },
      // employee number
      {
        uniqueId: 'cf0c13b8-6f5b-4481-bad7-0568b125c020',
        field: 'employeeNumber',
        translateCode: 'USER.employeeNumber',
        hidden: true
      }
    ]

    if (hasCreatePermissions) {
      tableColumns.push(
        // ACTIONS
        {
          uniqueId: 'e7dd543b-e17f-47d4-860d-08a122255166',
          field: 'id',
          translateCode: 'COMMON.ACTIONS',
          headerAttributes: {
            class: 'actions-column-header'
          },
          // media: '(min-width: 768px)',
          filterable: false,
          sortable: false,
          trustedTemplate: data => {
            const now = new Date()
            let html = `<div layout="row" layout-align="start center" style="direction: ${$rootScope.dir}"><md-button ng-class="($root.isTabletOrMobile) ? ['md-fab','md-mini','md-primary'] : ['md-icon-button','show-button']" data-testid="show-${data.id}" ui-sref="app.user.show({id: '${data.id}'})">
                        <md-icon md-font-icon="icon-eye" class="s20"></md-icon>
                      </md-button>`
            if (
              !_.isNil(data.blockUntil) &&
              new Date(data.blockUntil).getTime() > now.getTime()
            ) {
              html += `<md-button ng-class="($root.isTabletOrMobile) ? ['md-fab','md-mini','md-primary'] : ['md-icon-button']" data-testid="unlock-${data.id}" ng-click="unlockUser('${data.id}')">
                        <md-icon md-font-icon="icon-lock-unlocked" class="s20"></md-icon>
                      </md-button>`
            }
            html += '</div>'
            return html
          }
        }
      )
    }

    const { defaultTableColumns } = ViewsService.generateCustomFieldColumns(
      'UserModel',
      tableColumns
    )

    const baseFilter = {
      where: {
        email: { neq: 'nextplus_system_user@nextplus.io' }
      },
      include: ['roles'],
      order: 'modified DESC'
    }

    const { columns, selectedViewId } =
      ViewsService.getTablesColumns(defaultTableColumns)

    const { newBaseFilter, filters } = ViewsService.getViewCustomFilters(
      selectedViewId,
      _.cloneDeep(baseFilter)
    )

    const defaultTableSetup = {
      // stateName
      // ignoreParams
      find: UserModel.getDataList,
      count: UserModel.getDataCount,
      cleanBaseFilter: baseFilter,
      baseFilter: newBaseFilter,
      selectedViewId,
      columns: defaultTableColumns
    }

    const defaultTableToolbarSetup = {
      // stateName
      columns: defaultTableColumns,
      currentColumnIds: columns.map(c => c.uniqueId),
      filters,
      selectedViewId,
      title: $scope.title
    }

    $scope.downloadFunction = () => {}

    $scope.kendoGrid = await KendoGridHelper.GridInstance(
      defaultTableSetup,
      $scope,
      columns
    )

    await $scope.kendoGrid.isBound()

    $scope.tableToolbar = await ViewsService.GridToolBarInstance(
      defaultTableToolbarSetup,
      $scope.kendoGrid,
      $scope
    )

    $scope.$applyAsync()
  }

  initScreen()

  $scope.headerOptions = {
    icon: 'icon-account',
    template: require('app/templates/headers/simple.html'),
    title: $scope.title,
    fabButton: {
      template: require('app/templates/headers/fab-button.html'),
      action: null,
      icon: 'icon-plus',
      href: '',
      state: 'app.user.show',
      showExpression: () =>
        hasCreatePermissions && !$rootScope.appSettings.disableLocalUserCreation
    }
  }
}

module.exports = UserModelsController
