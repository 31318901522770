/* global _ angular */

import KitModificationDialogController from '../../../workorder/kit-modification/kit-modification-dialog.controller'

/** @ngInject */
function WorkflowKitController (
  $scope,
  $translate,
  $mdDialog,
  Workorder,
  Stock,
  preview,
  stocksOrWorkorder,
  KendoGridHelper,
  model,
  $rootScope,
  PermissionUtils,
  $mdToast,
  htmlWork,
  PartViewHelper
) {
  const vm = this
  $scope.model = model
  $scope.hasSaveWorkorderPermissions = PermissionUtils.isPermit(
    'Workorder',
    'saveLocalWorkorder'
  )
  const initDialog = async function initDialog () {
    $scope.kitKendoTable = {}

    if (_.isString(stocksOrWorkorder)) {
      vm.stocksOrWorkorder = []
      vm.stocksOrWorkorder[0] = {
        displayName: '',
        id: stocksOrWorkorder
      }
    } else if (_.isArray(stocksOrWorkorder)) {
      vm.stocksOrWorkorder = stocksOrWorkorder
    } else {
      vm.stocksOrWorkorder = []
      vm.stocksOrWorkorder[0] = {
        displayName: 'Preview',
        id: null
      }
    }
  }

  initDialog()

  vm.load = async function load (id) {
    let dataObject = {}
    $scope.dialogInitial = true
    try {
      if (preview) {
        dataObject = {
          kitItems: [
            {
              id: '4d641241-26de-4cb1-8445-8a4bd2babba2',
              quantity: 2,
              issuedQuantity: 0,
              sku: 'AD327207-02',
              partDesc: 'TR PWA',
              isSerial: true,
              issuedToKit: false,
              manualIssue: true,
              unit: "יח'",
              rev: 'A',
              act: 'MECH ASSY',
              serials: [],
              kitLines: ['1']
            },
            {
              id: '90e48429-48f6-40c1-bc2b-58909b1e3535',
              quantity: 4,
              issuedQuantity: 0,
              sku: 'AD327216-01',
              partDesc: 'EXTENDER PWA',
              isSerial: true,
              issuedToKit: false,
              manualIssue: true,
              unit: "יח'",
              rev: '',
              act: 'MECH ASSY',
              serials: [],
              kitLines: ['2']
            },
            {
              id: '6943a1a6-cb65-4a19-b653-f8c26c1c63c2',
              quantity: 82,
              issuedQuantity: 0,
              sku: 'AD327217-02',
              partDesc: 'PATCH ASSY',
              isSerial: false,
              issuedToKit: false,
              manualIssue: true,
              unit: "יח'",
              rev: '',
              act: 'MECH ASSY',
              serials: [],
              kitLines: ['3']
            },
            {
              id: '24440b12-b5c5-4a2b-b0b6-e69e30db788b',
              quantity: 2,
              issuedQuantity: 2,
              sku: 'AD327234-04-C01',
              partDesc: 'REFLECTOR ASSY',
              isSerial: false,
              issuedToKit: true,
              manualIssue: false,
              unit: "יח'",
              rev: '',
              act: 'MECH ASSY',
              serials: [],
              kitLines: ['4']
            },
            {
              id: '4a7784d5-f437-4783-8490-62e2fe690ed3',
              quantity: 82,
              issuedQuantity: 82,
              sku: 'CD327012-02',
              partDesc: 'COAX',
              isSerial: false,
              issuedToKit: true,
              manualIssue: false,
              unit: "יח'",
              rev: '',
              act: 'MECH ASSY',
              serials: [],
              kitLines: ['5']
            },
            {
              id: 'dd443604-22c2-4e58-a7d0-e4ac97717b7a',
              quantity: 0.002,
              issuedQuantity: 0,
              sku: 'CHM00063',
              partDesc: 'ADHESIVE ACRYLIC BLUE ONE COMP. THERMALOCKING',
              isSerial: false,
              issuedToKit: false,
              manualIssue: false,
              unit: 'גרם',
              rev: '',
              act: 'MECH ASSY',
              serials: [],
              kitLines: ['6']
            },
            {
              id: 'fd580553-834b-4044-99b3-5ab66edf5104',
              quantity: 82,
              issuedQuantity: 82,
              sku: 'CHP00752',
              partDesc: 'IC AMP RF PWR GAN-HEMT 0.5-6G 10W PKG-440166 SMD',
              isSerial: false,
              issuedToKit: true,
              manualIssue: false,
              unit: "יח'",
              rev: '',
              act: 'MECH ASSY',
              serials: [],
              kitLines: ['7']
            },
            {
              id: 'c8987ec2-9394-4c36-a0bd-37e747e766ac',
              quantity: 2,
              issuedQuantity: 2,
              sku: 'DD327887-04',
              partDesc: 'BRACKET',
              isSerial: false,
              issuedToKit: true,
              manualIssue: false,
              unit: "יח'",
              rev: '1',
              act: 'MECH ASSY',
              serials: [],
              kitLines: ['8']
            },
            {
              id: 'd60ea758-b82a-4d34-848d-90cf994479ce',
              quantity: 4,
              issuedQuantity: 4,
              sku: 'MEC00283',
              partDesc: 'SCREW S/H HEX CAP 6-32UNCX3/8" ST.ST',
              isSerial: false,
              issuedToKit: true,
              manualIssue: false,
              unit: "יח'",
              rev: '',
              act: 'MECH ASSY',
              serials: [],
              kitLines: ['9']
            },
            {
              id: '60d214b6-0e73-4b82-8d0d-7c0396cfac92',
              quantity: 44,
              issuedQuantity: 44,
              sku: 'MEC99195',
              partDesc: 'WASHER FLAT 6# 0.267" X 0.143" X 0.016" ST.ST',
              isSerial: false,
              issuedToKit: true,
              manualIssue: false,
              unit: "יח'",
              rev: '',
              act: 'MECH ASSY',
              serials: [],
              kitLines: ['10']
            },
            {
              id: 'a70c1a50-cc73-454b-9300-9e13748f3474',
              quantity: 4,
              issuedQuantity: 0,
              sku: 'SCR00025',
              partDesc: 'SCREW P/H 4-40X3/16" ST.ST',
              isSerial: false,
              issuedToKit: false,
              manualIssue: false,
              unit: "יח'",
              rev: '',
              act: 'MECH ASSY',
              serials: [],
              kitLines: ['11']
            },
            {
              id: '9b0911d9-85a7-43bd-8f26-b320ccd0d925',
              quantity: 348,
              issuedQuantity: 0,
              sku: 'SCR00047',
              partDesc: 'SCREW F/H 100° 4-40UNCX1/4" ST.ST',
              isSerial: false,
              issuedToKit: false,
              manualIssue: false,
              unit: "יח'",
              rev: '',
              act: 'MECH ASSY',
              serials: [],
              kitLines: ['12']
            },
            {
              id: '6430d1f3-0959-4e37-9608-e9eebc11f069',
              quantity: 44,
              issuedQuantity: 0,
              sku: 'SCR01123',
              partDesc: 'SCREW S/H HEX CAP 6-32UNCX1/4" ST.ST',
              isSerial: false,
              issuedToKit: false,
              manualIssue: false,
              unit: "יח'",
              rev: '',
              act: 'MECH ASSY',
              serials: [],
              kitLines: ['13']
            },
            {
              id: 'fd7c00cf-fe8a-41bd-ae17-f2cb500f6bc2',
              quantity: 410,
              issuedQuantity: 0,
              sku: 'SCR11371',
              partDesc: 'KIT SCREW S/H 2-56UNCX1/4"+WSHR LOCK+FLAT ST.ST',
              isSerial: false,
              issuedToKit: false,
              manualIssue: false,
              unit: "יח'",
              rev: '',
              act: 'MECH ASSY',
              serials: [],
              kitLines: ['14']
            },
            {
              id: '30a48e80-e76b-467b-a512-79f9a0c508cb',
              quantity: 8,
              issuedQuantity: 0,
              sku: 'WSH00015',
              partDesc: 'WASHER FLAT 4# 0.209" X 0.115" X 0.032" ST.ST',
              isSerial: false,
              issuedToKit: false,
              manualIssue: false,
              unit: "יח'",
              rev: '',
              act: 'MECH ASSY',
              serials: [],
              kitLines: ['15']
            }
          ]
        }
      } else if (model === 'stock') {
        dataObject = await Stock.findById({ id }).$promise
      } else if (model === 'workorder') {
        dataObject = await Workorder.findOne({
          filter: {
            where: { id },
            fields: { kitItems: true, createStock: true }
          }
        }).$promise
      }
    } catch (err) {
      console.error(err)
    }
    $scope.createStock =
      typeof dataObject.createStock === 'undefined'
        ? true
        : dataObject.createStock
    vm.calculateKit(dataObject, id)
  }
  $scope.showPart = function showPart (partNumber, partRev = null) {
    PartViewHelper.openPartViewDialog(partNumber, partRev, {})
  }
  vm.calculateKit = async function calculateKit (res, stockId) {
    vm.items = res.kitItems.map(item => {
      item.quantity = item.quantity.toFixed(3)
      item.issuedQuantity = item.issuedQuantity.toFixed(3)
      if (!item.issuedToKit) {
        item.missing = false
      } else {
        item.missing = item.quantity !== item.issuedQuantity
      }
      return item
    })
    vm.itemsById = _.keyBy(vm.items, 'id')
    vm.items = _.orderBy(
      vm.items,
      ['issuedToKit', 'manualIssue', 'missing'],
      ['desc', 'desc', 'desc']
    )
    const tableColumns = [
      // ICON
      {
        uniqueId: '3d3ed3b4-a692-418b-bea9-1d8dfc7450b5',
        field: 'none',
        title: '#',
        sortable: false,
        filterable: false,
        trustedTemplate: data => {
          if (!data.issuedToKit && !data.manualIssue) {
            return '<md-icon md-font-icon="icon-checkbox-blank-circle" class="icon orange-500-fg"></md-icon>'
          } else if (!data.issuedToKit && data.manualIssue) {
            return '<md-icon md-font-icon="icon-checkbox-blank-circle" class="icon blue-500-fg"></md-icon>'
          } else if (data.issuedToKit && data.missing) {
            return '<md-icon md-font-icon="icon-checkbox-blank-circle" class="icon red-500-fg"></md-icon>'
          } else if (data.issuedToKit && !data.missing) {
            return '<md-icon md-font-icon="icon-checkbox-blank-circle" class="icon green-500-fg"></md-icon>'
          }
          return ''
        }
      },
      // PART_NUMBER
      {
        uniqueId: '1c3ed3b4-a692-418b-bea9-9d8dfc0140b5',
        field: 'sku',
        translateCode: 'WF.PART_NUMBER',
        trustedTemplate: data => {
          return `<a style="cursor: pointer;" ng-click="showPart('${htmlWork.htmlEncode(
            data.sku
          )}', '${htmlWork.htmlEncode(data.rev)}')">${data.sku}</a>`
        }
        // media: '(min-width: 768px)',
      },
      // PART_REVISION
      {
        uniqueId: 'f6dd3305-bba6-4dbe-8d9e-7dee3d1b6346',
        field: 'rev',
        translateCode: 'WF.PART_REVISION'
        // media: '(min-width: 768px)',
      },
      // PART_NUMBER
      {
        uniqueId: '7d3ed3b4-3b92-418b-bea9-8c8dfc0140b2',
        field: 'partDesc',
        translateCode: 'WF.PART_DESCRIPTION'
        // media: '(min-width: 768px)',
      },
      // UNIT
      {
        uniqueId: '6f3ed3b4-a692-418b-bea9-9d8dfc014012',
        field: 'unit',
        translateCode: 'WF.UNIT'
        // media: '(min-width: 768px)',
      },
      // NEEDED_AMOUNT
      {
        uniqueId: '1384298b-29d7-430f-9a58-5ee5f55913af',
        field: 'quantity',
        translateCode: 'WF.NEEDED_AMOUNT',
        type: 'number'
        // media: '(min-width: 768px)',
      },
      // ISSUED_AMOUNT
      {
        uniqueId: '1d773c79-060c-2101-8ba2-1bccb51888ab',
        field: 'none2',
        translateCode: 'WF.ISSUED_AMOUNT',
        filterable: false,
        sortable: false,
        // media: '(min-width: 768px)',
        trustedTemplate: data => {
          if (data.issuedToKit) {
            return `<span>${htmlWork.htmlEncode(data.issuedQuantity)}</span>`
          } else if (!data.issuedToKit && !data.manualIssue) {
            return '<span>Free Stock</span>'
          } else if (!data.issuedToKit && data.manualIssue) {
            return `<span>${$translate.instant('WF.MANUAL_ISSUE')}</span>`
          }
          return ''
        }
      }
    ]

    if ($scope.model === 'workorder' && $scope.hasSaveWorkorderPermissions) {
      tableColumns.push(
        // ACTIONS
        {
          uniqueId: '658bedbe-d7f4-4252-9d4e-cdf779b2dc4b',
          field: 'id',
          translateCode: 'WO.actions',
          width: '20%',
          filterable: false,
          sortable: false,
          trustedTemplate: data => {
            return `<div layout="row">
                  <md-button class="md-icon-button" ng-if="${
                    data.source === 'local'
                  } " ng-click="editKitItem('${
              data.id
            }')" data-testid="preset-${data.id}">
                    <md-icon md-font-icon="icon-pencil" class="s18"></md-icon>
                  </md-button>
                </div>`
          }
        }
      )
    }
    const defaultTableSetup = {
      // stateName
      ignoreParams: true,
      serverSide: false,
      autoSize: true,
      data: vm.items,
      pageSize: 10,
      cleanBaseFilter: {},
      baseFilter: {},
      selectedViewId: 'systemDefault',
      columns: tableColumns
    }

    $scope.kitKendoTable[stockId] = await KendoGridHelper.GridInstance(
      defaultTableSetup,
      $scope,
      tableColumns
    )

    $scope.dialogInitial = false
    $scope.$applyAsync()
  }
  vm.tabSelected = stock => {
    vm.load(stock.id)
  }
  if (preview) {
    vm.load(null)
  }

  vm.hide = function () {
    $mdDialog.hide()
  }

  vm.cancel = function () {
    $mdDialog.cancel()
  }

  vm.answer = function (answer) {
    $mdDialog.hide(answer)
  }
  const successToast = function successToast (translateCode) {
    $mdToast.show(
      $mdToast.nextplus({
        position: $rootScope.toastLocation,
        parent: 'body',
        theme: 'success-toast',
        hideDelay: 6000
      })
    )
    $mdToast.updateTextContent($translate.instant(translateCode))
  }
  $scope.editKitItem = async function editKitItem (kitItemId) {
    $mdDialog
      .show({
        /** @ngInject */
        locals: {
          kitItem: vm.itemsById[kitItemId],
          kitItems: []
        },
        multiple: true,
        controller: KitModificationDialogController,
        template: require('../../../workorder/kit-modification/kit-modification-dialog.template.html'),
        parent: angular.element(document.body),
        targetEvent: '',
        clickOutsideToClose: false,
        escapeToClose: false,
        resolve: {
          ResolvedPart: PartAssembly =>
            PartAssembly.findOne({
              filter: {
                where: {
                  number: vm.itemsById[kitItemId].sku
                },
                fields: {
                  id: true,
                  name: true,
                  number: true,
                  managedByRevision: true,
                  revisions: true,
                  currentRevision: true
                }
              }
            }).$promise
        }
      })
      .then(async model => {
        $rootScope.loadingProgress = true
        if (model.isDelete) {
          Workorder.removeKitItem({
            workorderId: stocksOrWorkorder,
            kitItemId: model.id
          })
            .$promise.then(() => {
              $scope.kitKendoTable[
                stocksOrWorkorder
              ].instance.dataSource.remove(
                $scope.kitKendoTable[
                  stocksOrWorkorder
                ].instance.dataSource._data.find(
                  kitItem => kitItem.id === model.id
                )
              )
              const kitItemIndex = vm.items.findIndex(
                kitItem => kitItem.id === kitItemId
              )
              vm.items.splice(kitItemIndex, 1)
              $rootScope.loadingProgress = false
              successToast('WO.KIT_MODIFICATION.ITEM_DELETED_SUCCESSFULLY')
              $scope.$applyAsync()
            })
            .catch(error => {
              $rootScope.loadingProgress = false
              if (error.data.error.details) {
                $rootScope.showErrorToast(
                  ': ' +
                    $translate.instant(
                      'WO.KIT_MODIFICATION.' + error.data.error.message,
                      error.data.error.details
                    )
                )
              } else if (error.data.error.message) {
                $rootScope.showErrorToast(
                  ': ' +
                    $translate.instant(
                      'WO.KIT_MODIFICATION.' + error.data.error.message
                    )
                )
              } else {
                $rootScope.showErrorToast(': ' + error.message)
              }
            })
        } else {
          Workorder.editKitItem({
            workorderId: stocksOrWorkorder,
            kitItemId: model.id,
            rev: model.rev,
            quantity: model.quantity
          })
            .$promise.then(() => {
              const dataItem = $scope.kitKendoTable[
                stocksOrWorkorder
              ].instance.dataSource.get(model.id)
              if (vm.itemsById[model.id]) {
                dataItem.set('quantity', model.quantity)
              }
              const kitItemIndex = vm.items.findIndex(
                kitItem => kitItem.id === kitItemId
              )
              vm.items[kitItemIndex].quantity = model.quantity
              $rootScope.loadingProgress = false
              successToast('WO.KIT_MODIFICATION.ITEM_EDITED_SUCCESSFULLY')
              $scope.$applyAsync()
            })
            .catch(error => {
              $rootScope.loadingProgress = false
              if (error.data.error.details) {
                $rootScope.showErrorToast(
                  ': ' +
                    $translate.instant(
                      'WO.KIT_MODIFICATION.' + error.data.error.message,
                      error.data.error.details
                    )
                )
              } else if (error.data.error.message) {
                $rootScope.showErrorToast(
                  ': ' +
                    $translate.instant(
                      'WO.KIT_MODIFICATION.' + error.data.error.message
                    )
                )
              } else {
                $rootScope.showErrorToast(': ' + error.message)
              }
            })
        }
      })
  }
  $scope.addKitItem = function addKitItem () {
    $mdDialog
      .show({
        /** @ngInject */
        multiple: true,
        controller: KitModificationDialogController,
        template: require('../../../workorder/kit-modification/kit-modification-dialog.template.html'),
        locals: {
          kitItem: null,
          kitItems: vm.items
        },
        parent: angular.element(document.body),
        targetEvent: '',
        clickOutsideToClose: false,
        escapeToClose: false,
        resolve: {
          ResolvedPart: () => null
        }
      })
      .then(async model => {
        if (model) {
          const obj = {
            workorderId: stocksOrWorkorder,
            sku: model.sku,
            quantity: model.quantity
          }
          if (model.rev) {
            obj.rev = model.rev
          }
          Workorder.addKitItem(obj)
            .$promise.then(kitItem => {
              const newKitItem = _.cloneDeep(kitItem.toJSON())
              vm.items.push(newKitItem)
              vm.itemsById[kitItem.id] = newKitItem
              $scope.kitKendoTable[stocksOrWorkorder].instance.dataSource.add(
                newKitItem
              )
              $scope.$applyAsync()
              $rootScope.loadingProgress = false
            })
            .catch(error => {
              $rootScope.loadingProgress = false
              console.log(error)
              if (
                [
                  'QUANTITY_SHOULD_DIVIDE_BY_WORKORDER_QUANTITY',
                  'PHANTOM_PART_ERROR',
                  'KIT_ITEM_ADD_NO_EACH',
                  'WORKORDER_NOT_CREATE_STOCK'
                ].includes(error?.data?.error?.code)
              ) {
                $rootScope.showErrorToast(
                  ': ' +
                    $translate.instant(
                      'WO.KIT_MODIFICATION.' + error.data.error.code,
                      error?.data?.error?.details
                        ? error.data.error.details
                        : {}
                    )
                )
              } else {
                $rootScope.showErrorToast(': ' + error.message)
              }
            })
        }
      })
  }
}

module.exports = WorkflowKitController
