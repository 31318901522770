/* global angular,atob,btoa, */

require('./reset-password.scss')

/** @ngInject */
function ResetPasswordController (
  $scope,
  $rootScope,
  $translate,
  $state,
  $stateParams,
  $mdDialog,
  $mdMedia,
  LoginService,
  Page,
  UserModel,
  ResolvedSettings,
  $mdToast
) {
  $scope.smallScreen = !$mdMedia('gt-sm')
  if ($state.current.name === 'app.reset-password') {
    if (!$stateParams.emailToken) {
      // send token screen
      $scope.mode = 'SEND_TOKEN'
      $scope.title = $translate.instant('RESETPASSWORD.RESET.TITLE')
      Page.setTitleText($scope.title)
      $scope.buttonText = $translate.instant(
        'RESETPASSWORD.SEND_VERIFICATION_TOKEN'
      )
    } else {
      // handle reset password
      const [email, token] = $stateParams.emailToken.split('_')
      $scope.mode = 'RESET'
      $scope.email = atob(email)
      $scope.token = atob(token)
      $scope.title = $translate.instant('RESETPASSWORD.SET_PASSWORD.TITLE')
      Page.setTitleText($scope.title)
      $scope.buttonText = $translate.instant('RESETPASSWORD.CONFIRM')
    }
  } else if ($state.current.name === 'app.set-new-password') {
    $scope.mode = 'RESET'
    $scope.title = $translate.instant('RESETPASSWORD.SET_PASSWORD.TITLE')
    Page.setTitleText($scope.title)
    $scope.buttonText = $translate.instant('RESETPASSWORD.CONFIRM')
    $scope.headerText = $translate.instant(
      'RESETPASSWORD.PASSWORD_VALIDATION.HEADER'
    )

    if (!$rootScope.currentUser) {
      $state.go('app.login')
    }
  } else if ($state.current.name === 'app.set-new-pincode') {
    $scope.mode = 'RESET'
    $scope.title = $translate.instant('RESETPASSWORD.SET_PIN_CODE.TITLE')
    Page.setTitleText($scope.title)
    $scope.buttonText = $translate.instant('RESETPASSWORD.CONFIRM')
    $scope.headerText = $translate.instant(
      'RESETPASSWORD.PASSWORD_VALIDATION.PIN_CODE_HEADER'
    )
    if (!$rootScope.currentUser) {
      $state.go('app.login')
    }
  } else if ($state.current.name === 'app.reset-sent') {
    $scope.mode = 'RESET-SENT'
  } else if ($stateParams.email) {
    // handle verify token
    $scope.mode = 'TOKEN'
    $scope.email = atob($stateParams.email)
    $scope.title = $translate.instant('RESETPASSWORD.VALIDATE.TITLE')
    Page.setTitleText($scope.title)
    $scope.buttonText = $translate.instant('RESETPASSWORD.CONFIRM')
  } else {
    // 404 - error
    console.error('wrong path')
  }

  const resetPasswordSettingValue =
    ResolvedSettings.smtp || ResolvedSettings.resetViaSMS || false

  if (
    !resetPasswordSettingValue &&
    $state.current.name !== 'app.set-new-password' &&
    $state.current.name !== 'app.set-new-pincode'
  ) {
    $state.go('app.login')
  }

  const setFieldsAccordingToMode = function setFieldsAccordingToMode () {
    switch ($scope.mode) {
      case 'SEND_TOKEN': {
        const resetViaEmailValue = ResolvedSettings.smtp
        const resetViaSMSValue = ResolvedSettings.resetViaSMS
        const hasSMSValue = ResolvedSettings.hasSMS
        const methods = [
          {
            value: 'email',
            name: $translate.instant('RESETPASSWORD.EMAIL_METHOD')
          },
          { value: 'sms', name: $translate.instant('RESETPASSWORD.SMS') }
        ]
        $scope.screenFields = [
          {
            key: 'email',
            type: 'input',
            className: 'layout-row',
            templateOptions: {
              type: 'email',
              required: true,
              label: $translate.instant('RESETPASSWORD.EMAIL_ADDRESS'),
              onChange: function (value, options) {}
            },
            hideExpression: function ($viewValue, $modelValue, scope) {
              return scope.model.method === 'sms'
            }
          },
          {
            key: 'phone',
            type: 'phoneInput',
            templateOptions: {
              type: 'number',
              disabled: !hasSMSValue,
              hint: hasSMSValue
                ? ''
                : $translate.instant('RESETPASSWORD.ERRORS.NO_SMS_LEFT'),
              label: $translate.instant('USER.PHONE_NUMBER'),
              onChange: function (value, options) {}
            },
            hideExpression: function ($viewValue, $modelValue, scope) {
              return scope.model.method === 'email'
            }
          },
          {
            key: 'method',
            type: 'radioMaterial',
            templateOptions: {
              layout: 'column',
              label: $translate.instant('RESETPASSWORD.METHOD'),
              options: methods,
              onChange: function (value, options) {}
            },
            hideExpression: function ($viewValue, $modelValue, scope) {
              return !resetViaEmailValue || !resetViaSMSValue
            }
          }
        ]
        $scope.screenModel = { method: resetViaEmailValue ? 'email' : 'sms' }
        break
      }
      case 'TOKEN': {
        $scope.screenFields = [
          {
            key: 'token',
            type: 'input',
            className: 'layout-row',
            templateOptions: {
              type: 'text',
              focus: true,
              required: true,
              minlength: 6,
              label: $translate.instant('RESETPASSWORD.TOKEN'),
              onChange: function (value, options) {}
            }
          }
        ]
        $scope.screenModel = {}
        break
      }
      case 'RESET': {
        $scope.passwordComplexity = ResolvedSettings.passwordComplexity
        $scope.screenFields = [
          {
            key: 'fakepassword',
            type: 'input',
            hideExpression: () => true,
            templateOptions: {
              type: 'password'
            }
          },
          {
            key: 'password',
            type: 'input',
            className: 'layout-row',
            ngModelAttrs: {
              'new-password': {
                value: 'autocomplete'
              }
            },
            templateOptions: {
              type: 'password',
              focus: true,
              required: true,
              autocomplete: false,
              label:
                $state.current.name === 'app.set-new-pincode'
                  ? $translate.instant('RESETPASSWORD.PIN_CODE')
                  : $translate.instant('RESETPASSWORD.PASSWORD'),
              onChange: function (value, options) {
                if ($scope.passwordComplexity) {
                  if ($scope.passwordComplexity.minLength) {
                    $scope.validMinLength =
                      value.length >= $scope.passwordComplexity.minLength
                  }
                  if ($scope.passwordComplexity.upperAndLowerCase) {
                    $scope.validUpperLowerCase =
                      /[a-z]/.test(value) && /[A-Z]/.test(value)
                  }
                  if ($scope.passwordComplexity.numeric) {
                    $scope.validNumeric = /[0-9]/.test(value)
                  }
                  if ($scope.passwordComplexity.specialChars) {
                    // eslint-disable-next-line
                    $scope.validSpecialChar =
                      /[.~!`:;@#$%^&*()_+=\-{}[\]\\]/.test(value)
                  }
                }
              }
            }
          },
          {
            key: 'fakepassword2',
            type: 'input',
            hideExpression: () => true,
            templateOptions: {
              type: 'password'
            }
          },
          {
            key: 'passwordConfirm',
            type: 'input',
            className: 'layout-row',
            ngModelAttrs: {
              'new-password': {
                value: 'autocomplete'
              }
            },
            templateOptions: {
              type: 'password',
              required: true,
              label:
                $state.current.name === 'app.set-new-pincode'
                  ? $translate.instant('RESETPASSWORD.CONFIRM_PIN_CODE')
                  : $translate.instant('RESETPASSWORD.CONFIRM_PASSWORD'),
              onChange: function (value, options) {}
            }
          }
        ]
        $scope.screenModel = {}
        break
      }
      case 'RESET-SENT': {
        $scope.screenFields = [
          {
            template: `
              <div layout="row" layout-align="center">
                <h2 translate="RESETPASSWORD.EMAIL_SENT_SUCCESSFULLY"></h2>
              </div>`
          }
        ]
        break
      }
    }
  }

  setFieldsAccordingToMode()

  $scope.save = function save () {
    switch ($scope.mode) {
      case 'SEND_TOKEN':
        sendVerificationToken()
        break
      case 'TOKEN':
        validateToken()
        break
      case 'RESET':
        setPassword()
        break
    }
  }

  $scope.disableButton = function disableButton () {
    switch ($scope.mode) {
      case 'SEND_TOKEN': {
        if ($scope.screenModel.method === 'email') {
          return !$scope.screenModel.email
        } else {
          return !$scope.screenModel.phone
        }
      }
      default:
        return (
          $scope.resetPasswordForm.$pristine ||
          $scope.resetPasswordForm.$invalid
        )
    }
  }

  /**
   * Send Verification Token function
   */
  const sendVerificationToken = async function sendVerificationToken () {
    const { method, email, phone } = $scope.screenModel
    if (method === 'email') {
      try {
        await UserModel.resetPasswordViaEmail({ email }).$promise
        $state.go('app.reset-sent')
      } catch (err) {
        errorHandler(err)
      }
    } else {
      try {
        const user = await UserModel.resetPasswordViaSMS({ phone }).$promise
        $state.go('app.validate-token', { email: btoa(user.email) })
      } catch (err) {
        errorHandler(err)
      }
    }
  }

  /**
   * Send validation token function
   */
  const validateToken = async function validateToken () {
    const { token } = $scope.screenModel
    try {
      await UserModel.validateToken({
        email: $scope.email,
        token,
        reason: 'RESET_PASSWORD'
      }).$promise
      $state.go('app.reset-password', {
        emailToken: `${btoa($scope.email)}_${btoa(token)}`
      })
    } catch (err) {
      errorHandler(err)
    }
  }

  /**
   * Send set password function
   */
  const setPassword = async function setPassword () {
    const { password, passwordConfirm } = $scope.screenModel
    if (password !== passwordConfirm) {
      const mdToast = $mdToast.nextplus({
        position: $rootScope.toastLocation,
        parent: '#content',
        theme: 'error-toast',
        hideDelay: 3000
      })
      $mdToast.updateTextContent(
        $translate.instant('RESETPASSWORD.ERRORS.WRONG_PASSWORDS')
      )
      $mdToast.show(mdToast)
    } else {
      try {
        if (
          $state.current.name === 'app.set-new-password' ||
          $state.current.name === 'app.set-new-pincode'
        ) {
          const userId = $rootScope.currentUser.id
          await UserModel.setNewPassword({
            userId,
            password
          }).$promise
          await LoginService.changePassword()
          $scope.moveToLogIn(true)
        } else {
          await UserModel.setPassword({
            email: $scope.email,
            token: $scope.token,
            password
          }).$promise
          $scope.moveToLogIn()
        }
      } catch (err) {
        errorHandler(err)
      }
    }
  }

  /**
   * error handling
   * @param {object} err
   */
  const errorHandler = function errorHandler (err) {
    const {
      data: {
        error: {
          message,
          details: { params }
        }
      }
    } = err
    const mdToast = $mdToast.nextplus({
      position: $rootScope.toastLocation,
      parent: '#content',
      theme: 'error-toast',
      hideDelay: 3000
    })
    $mdToast.updateTextContent(
      $translate.instant(`RESETPASSWORD.ERRORS.${message}`, params)
    )
    $mdToast.show(mdToast)
  }

  $scope.showNoteDialog = function showNoteDialog () {
    $mdDialog
      .show({
        controller: /** @ngInject */ ($scope, $mdDialog, locals) => {
          $scope.html = locals.html
          $scope.cancel = () => {
            $mdDialog.cancel()
          }
        },
        template: `
              <md-dialog>
                <form ng-cloak>
                  <md-toolbar layout="row" style="justify-content: space-between;align-items: center;display: flex;">
                      <h2 translate="RESETPASSWORD.IMPORTANT" style="margin: 0em 0.2em"></h2>
                      <md-button class="md-icon-button" ng-click="cancel()">
                        <md-icon md-font-icon="icon-close" class="icon" style="color:white;"></md-icon>
                      </md-button>
                  </md-toolbar>
                  <md-dialog-content>
                    <div ng-bind-html="html"><div>
                  </md-dialog-content>
                </form>
              </md-dialog>`,
        parent: angular.element(document.body),
        targetEvent: '',
        locals: {
          html: $scope.passwordComplexity.note
        },
        fullscreen: true,
        clickOutsideToClose: false
      })
      .then(async function () {})
  }

  $scope.moveToLogIn = (setNewPassword = false, logout = false) => {
    if ($rootScope.currentUser) {
      LoginService.logout(setNewPassword, logout)
    } else {
      $state.go('app.login', { setNewPassword })
    }
  }
}

module.exports = ResetPasswordController
