/* global _ */
/** @ngInject */

module.exports = function WorkflowConfig (
  $stateProvider,
  msNavigationServiceProvider
) {
  const editState = {
    lazyLoad: $transition$ => {
      const $ocLazyLoad = $transition$.injector().get('$ocLazyLoad')
      return Promise.all(
        [
          import(
            /* webpackChunkName: "kendo.dataviz.diagram" */ '../../../../../node_modules/@progress/kendo-ui/js/kendo.dataviz.diagram.js'
          )
            .then(mod => {
              return $ocLazyLoad.inject(mod.default)
            })
            .catch(err => {
              throw new Error('Ooops, something went wrong, ' + err)
            })
        ],
        new Promise(async (resolve, reject) => {
          try {
            const splitter = await import(
              /* webpackChunkName: "kendo.splitter" */ '@progress/kendo-ui/js/kendo.splitter'
            )
            $ocLazyLoad.inject(splitter.default)
            resolve()
          } catch (ex) {
            console.error(ex)
            reject(ex)
          }
        })
      )
    },
    views: {
      'content@app': {
        template: require('./edit/edit-new.html'),
        controller: 'WorkflowEditNewController',
        controllerAs: 'vm'
      }
    },
    bodyClass: 'workflow workflow-edit'
  }
  // State
  $stateProvider
    .state('app.workflow', {
      abstract: true,
      url: '/workflow',
      lazyLoad: $transition$ => {
        const $ocLazyLoad = $transition$.injector().get('$ocLazyLoad')
        return import(
          /* webpackChunkName: "workflow.module" */ './workflow.module.js'
        )
          .then(mod => {
            return $ocLazyLoad.load(mod.default)
          })
          .catch(err => {
            throw new Error('Ooops, something went wrong, ' + err)
          })
      }
    })
    .state('app.workflow.list', {
      url: '/?:filter',
      params: {
        filter: {
          dynamic: true
        }
      },
      views: {
        'content@app': {
          template: require('app/modules/components/tables/kendo-grid/grid.html'),
          controller: 'WorkflowsController as vm'
        }
      },
      resolve: {
        ResolvedWorkflowTypes: WorkflowType =>
          WorkflowType.find({
            filter: {
              fields: {
                id: true,
                name: true
              }
            }
          }).$promise,
        ResolvedUsers: function (UserModel) {
          return UserModel.find({
            filter: { fields: { id: true, displayName: true } }
          }).$promise
        }
      },
      bodyClass: 'workflow'
    })
    .state('app.workflow.knowledge-base-list', {
      url: '/kb-list/?:filter',
      params: {
        filter: {
          dynamic: true
        }
      },
      views: {
        'content@app': {
          template: require('app/modules/components/tables/kendo-grid/grid.html'),
          controller: 'WorkflowsController as vm'
        }
      },
      resolve: {
        ResolvedWorkflowTypes: WorkflowType =>
          WorkflowType.find({
            filter: {
              fields: {
                id: true,
                name: true
              }
            }
          }).$promise,
        ResolvedUsers: function (UserModel) {
          return UserModel.find({
            filter: { fields: { id: true, displayName: true } }
          }).$promise
        }
      },
      bodyClass: 'workflow'
    })
    .state('app.workflow.notes', {
      url: '/notes/?:filter',
      params: {
        filter: {
          dynamic: true
        }
      },
      views: {
        'content@app': {
          template: require('app/modules/components/tables/kendo-grid/grid.html'),
          controller: 'WorkflowNotesController as vm'
        }
      },
      bodyClass: 'workflow',
      resolve: {
        ResolvedUsers: UserModel =>
          UserModel.find({
            filter: {
              fields: {
                id: true,
                lastName: true,
                firstName: true,
                displayName: true
              }
            }
          }).$promise
      }
    })
    .state('app.workflow.custom-fields', {
      url: '/custom-fields/?:filter',
      params: {
        filter: {
          dynamic: true
        }
      },
      views: {
        'content@app': {
          template: require('app/modules/components/tables/kendo-grid/grid.html'),
          controller: 'WorkflowCustomFieldsController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        ResolvedData: () => {
          return null
        }
      },
      bodyClass: 'workflow'
    })
    .state('app.workflow.create', {
      url: '/create/?:{initialValues:json}',
      params: {
        initialValues: {
          dynamic: true
        }
      },
      views: {
        'content@app': {
          template: require('./edit/create-new.html'),
          controller: 'WorkflowCreateNewController',
          controllerAs: 'vm'
        }
      },
      bodyClass: 'workflow workflow-edit',
      resolve: {}
    })
    .state('app.workflow.edit', {
      url: '/edit/?:id',
      lazyLoad: editState.lazyLoad,
      views: editState.views,
      bodyClass: editState.bodyClass,
      params: {
        id: {
          dynamic: true
        }
      },
      resolve: {
        ResolvedData: ($stateParams, WorkflowUtilsService) => {
          return new Promise(async resolve => {
            const data = await WorkflowUtilsService.generateWorkflowEditPage(
              $stateParams.id
            )
            resolve(data)
          })
        }
      }
    })
    .state('app.workflow.locked', {
      url: '/locked/:lockId',
      lazyLoad: editState.lazyLoad,
      views: editState.views,
      bodyClass: editState.bodyClass,
      params: {
        lockId: {
          dynamic: true
        }
      },
      resolve: {
        ResolvedData: ($stateParams, WorkflowUtilsService) => {
          return new Promise(async resolve => {
            const data = await WorkflowUtilsService.generateWorkflowEditPage(
              null,
              $stateParams.lockId
            )
            resolve(data)
          })
        }
      }
    })
    .state('app.workflow.session', {
      abstract: true,
      url: '/session'
    })
    .state('app.workflow.session.show', {
      url: '/show/?:id/?:recordId/?:workflowSessionIds/?:preview/?:currentReleasedVersion/?:configuration',
      params: {
        workflowSessionIds: {
          dynamic: true
        },
        configuration: {
          dynamic: true
        }
      },
      lazyLoad: $transition$ => {
        const $ocLazyLoad = $transition$.injector().get('$ocLazyLoad')
        // const $window = $transition$.injector().get('$window')
        return new Promise(async (resolve, reject) => {
          try {
            const splitter = await import(
              /* webpackChunkName: "kendo.splitter" */ '@progress/kendo-ui/js/kendo.splitter'
            )
            $ocLazyLoad.inject(splitter.default)
            resolve()
          } catch (ex) {
            console.error(ex)
            reject(ex)
          }
        })
      },
      // for testing
      // params: {
      //   preview: 'false',
      //   workflowId: '6a703c80-3e8e-11ea-933c-91faa528c873',
      //   workflowSessionIds: ['a8672de0-3ead-11ea-95fb-abe8c23c1302', 'b54c9220-3ead-11ea-95fb-abe8c23c1302']
      // },
      views: {
        'content@app': {
          template:
            '<session-show session-data="ResolvedData" preview-mode="isPreview" is-dialog="false"></session-show>',
          controller: ($scope, $stateParams, ResolvedData) => {
            $scope.ResolvedData = ResolvedData
            $scope.isPreview =
              $stateParams.preview &&
              ($stateParams.preview === 'true' ||
                $stateParams.preview !== 'false')
          },
          controllerAs: 'vm'
        }
      },
      bodyClass: 'workflow workflow-show',
      resolve: {
        ResolvedData: function (WorkflowUtilsService, $stateParams) {
          return WorkflowUtilsService.enterSession($stateParams, false)
        }
      }
    })
    .state('app.workflow.workflow-session-item-report', {
      url: '/session/report/:workflowSessionId',
      params: {},
      views: {
        'content@app': {
          template: require('./session/report/workflow-session-item.html'),
          controller: 'WorkflowSessionItemReportController'
        }
      },
      bodyClass: '',
      resolve: {
        ResolvedWorkflowAndSession: (
          $rootScope,
          $stateParams,
          $translate,
          Field,
          MultiTranslateService,
          WorkflowSessionItem,
          Table,
          FieldUtilsService
        ) => {
          const lang = $translate.use()
          return WorkflowSessionItem.getReport({
            id: $stateParams.workflowSessionId,
            lang
          }).$promise.then(({ workflowSessionItems, fields, users }) => {
            fields.map(async field => {
              const newField = MultiTranslateService.getForView(
                Field,
                $rootScope.currentLang,
                field
              )
              if (
                newField.type === 'lookupSelect' &&
                newField.tableType === FieldUtilsService.lookupTypes.NPT
              ) {
                try {
                  const table = await Table.findOne({
                    filter: { where: { id: field.tableId } }
                  }).$promise
                  if (table) {
                    newField.table = table
                  }
                } catch (err) {
                  console.log('err', err)
                }
              }
              return newField
            })
            return {
              workflowSessionItems,
              fields,
              users
            }
          })
        },
        ResolvedFields: (Field, $rootScope, MultiTranslateService) =>
          Field.find({ filter: { deleted: true } }).$promise.then(res => {
            res.forEach(field => {
              field = MultiTranslateService.getForView(
                Field,
                $rootScope.currentLang,
                field
              )
            })
            return res
          })
      }
    })
    .state('app.workflow.session-list', {
      url: '/sessions/?:filter',
      params: {
        filter: {
          dynamic: true
        }
      },
      views: {
        'content@app': {
          template: require('app/modules/components/tables/kendo-grid/grid.html'),
          controller: 'WorkflowSessionListController as vm'
        }
      },
      bodyClass: 'workflow',
      resolve: {
        ResolvedUsers: UserModel =>
          UserModel.find({
            filter: {
              fields: {
                id: true,
                displayName: true
              }
            }
          }).$promise
      }
    })
    .state('app.workflow.show-details', {
      url: '/show-details/:recordId/:tabName/?:dashboard',
      params: {
        tabName: {
          dynamic: false
        },
        dashboard: {
          dynamic: true
        }
      },
      views: {
        'content@app': {
          template: require('./show-details/show-details.html'),
          controller: 'ShowDetailsController',
          controllerAs: 'vm'
        }
      },
      bodyClass: 'workflow',
      resolve: {
        ResolvedLatestVersion: function (Workflow, $stateParams) {
          return Workflow.findOne({
            filter: {
              where: { recordId: $stateParams.recordId, latestVersion: true },
              fields: { id: true }
            }
          }).$promise
        },
        ResolvedReleasedVersion: function (
          $rootScope,
          $stateParams,
          Workflow,
          Field,
          Tool,
          MultiTranslateService
        ) {
          return new Promise(async resolve => {
            const recordId = $stateParams.recordId

            const getNodesToolIdsAndFieldIds =
              function getNodesToolIdsAndFieldIds (nodes) {
                const fieldIds = new Set()
                const toolIds = new Set()
                nodes.forEach(node => {
                  if (node.toolIds && node.toolIds.length > 0) {
                    node.toolIds.forEach(toolId => {
                      toolIds.add(toolId)
                    })
                  }
                  if (node.fieldIds && node.fieldIds.length > 0) {
                    node.fieldIds.forEach(fieldId => {
                      fieldIds.add(fieldId)
                    })
                  }
                })
                return { fieldIds, toolIds }
              }
            const getNodesToolsAndFields =
              async function getNodesToolsAndFields (nodes) {
                const { fieldIds, toolIds } = getNodesToolIdsAndFieldIds(nodes)
                let fields = []
                let tools = []
                if (fieldIds.size > 0) {
                  fields = await Field.getFieldsWithInnerFields({
                    fieldIds: [...fieldIds]
                  }).$promise
                }
                fields = fields.map(field =>
                  MultiTranslateService.getForView(
                    Field,
                    $rootScope.currentLang,
                    field
                  )
                )
                if (toolIds.size > 0) {
                  tools = await Tool.find({
                    filter: {
                      where: {
                        isSerial: true,
                        id: { inq: [...toolIds] }
                      },
                      fields: {
                        fallbackStatus: 1,
                        name: 1,
                        translations: 1,
                        id: 1
                      }
                    }
                  }).$promise
                }
                tools = tools.map(tool =>
                  MultiTranslateService.getForView(
                    Tool,
                    $rootScope.currentLang,
                    tool
                  )
                )
                return { fields, tools }
              }
            let queryFields = {
              id: true,
              name: true,
              ResourceId: true,
              currentReleasedVersion: true,
              recordId: true,
              translations: true,
              fallbackStatus: true,
              parts: true,
              version: true,
              subVersion: true,
              normalizedVersion: true,
              modified: true,
              created: true,
              variables: true,
              _globalNodes: true,
              _nodes: true
            }
            if ($stateParams.tabName === 'analytics') {
              queryFields = {
                id: true,
                name: true,
                tags: true,
                ResourceId: true,
                currentReleasedVersion: true,
                recordId: true,
                translations: true,
                fallbackStatus: true,
                partSku: true,
                partRev: true,
                parts: true,
                version: true,
                subVersion: true,
                normalizedVersion: true,
                modified: true,
                created: true,
                nodes: true,
                _nodes: true,
                variables: true,
                globalNodes: true,
                _globalNodes: true
              }
            }
            const releasedWorkflowFilter = {
              filter: {
                where: {
                  recordId,
                  currentReleasedVersion: true
                },
                order: 'releasedAt desc',
                fields: queryFields,
                include: [
                  {
                    relation: 'resource',
                    scope: {
                      fields: {
                        name: true,
                        container: true
                      }
                    }
                  },
                  { relation: 'globalNodes' }
                ]
              }
            }
            const latestWorkflowFilter = {
              filter: {
                where: {
                  recordId,
                  latestVersion: true
                },
                fields: queryFields,
                include: [
                  {
                    relation: 'resource',
                    scope: {
                      fields: {
                        name: true,
                        container: true
                      }
                    }
                  }
                ]
              }
            }
            let workflow = null
            let nodes
            try {
              workflow = await Workflow.findOne(releasedWorkflowFilter).$promise
              // const nodeToolIdsAndFieldIds = getNodesToolIdsAndFieldIds(
              //   workflow.globalNodes
              // )
              nodes = workflow.globalNodes
              // toolIds = nodeToolIdsAndFieldIds.toolIds
              // fieldIds = nodeToolIdsAndFieldIds.fieldIds

              // workflow.globalNodes.forEach(node => {
              //   if (node.toolIds && node.toolIds.length > 0) {
              //     node.toolIds.forEach(toolId => {
              //       toolIds.add(toolId)
              //     })
              //   }
              //   if (node.fieldIds && node.fieldIds.length > 0) {
              //     node.fieldIds.forEach(fieldId => {
              //       fieldIds.add(fieldId)
              //     })
              //   }
              // })
            } catch (err) {
              workflow = await Workflow.findOne(latestWorkflowFilter).$promise
              nodes = workflow._nodes

              // const nodeToolIdsAndFieldIds = getNodesToolIdsAndFieldIds(_nodes)
              // toolIds = nodeToolIdsAndFieldIds.toolIds
              // fieldIds = nodeToolIdsAndFieldIds.fieldIds
              // workflow._nodes.forEach(node => {
              //   if (node.fieldIds && node.fieldIds.length > 0) {
              //     node.fieldIds.forEach(fieldId => {
              //       fieldIds.add(fieldId)
              //     })
              //   }
              // })
            }
            const { fields, tools } = await getNodesToolsAndFields(nodes)
            workflow = MultiTranslateService.getForView(
              Workflow,
              $rootScope.currentLang,
              workflow
            )

            resolve({ fields, tools, ReleasedWorkflow: workflow })
          })
        },
        ResolvedWorkflowVersions: (Workflow, $stateParams) =>
          Workflow.getWorkflowVersions({
            recordId: $stateParams.recordId
          }).$promise,

        ResolvedUsers: UserModel =>
          UserModel.find({
            filter: {
              fields: { id: true, displayName: true }
            }
          }).$promise,
        ResolvedAnalyticsData: function (WorkflowSessionItem, $stateParams) {
          if ($stateParams.tabName === 'analytics') {
            return WorkflowSessionItem.analyticsData({
              recordId: $stateParams.recordId
            }).$promise
          } else {
            return Promise.resolve({ sessions: null, fields: [] })
          }
        }
      }
    })
    .state('app.workflow.realwear-session-show', {
      url: '/session/realwear/show/?:id/?:recordId/?:workflowSessionIds/?:preview/?:currentReleasedVersion',
      params: {
        workflowSessionIds: {
          dynamic: true
        }
      },
      lazyLoad: $transition$ => {
        return import(
          /* webpackChunkName: "wearml_engine" */ 'root/src/vendors/wearml_engine'
        )
          .then(mod => {
            console.log(
              'mod.defaultmod.defaultmod.defaultmod.default',
              mod.default
            )
            window.wearML = mod.default.default
          })
          .catch(err => {
            throw new Error('Ooops, something went wrong, ' + err)
          })
      },
      views: {
        'main@': {
          template: require('../../core/layouts/content-only.html'),
          controller: 'MainController as vm'
        },
        'content@app.workflow.realwear-session-show': {
          template:
            '<session-show session-data="ResolvedData" is-preview="false" is-dialog="false"></session-show>',
          controller: ($scope, ResolvedData) => {
            $scope.ResolvedData = ResolvedData
          },
          controllerAs: 'vm'
        }
      },
      bodyClass: 'workflow workflow-show',
      resolve: {
        ResolvedData: function (WorkflowUtilsService, $stateParams) {
          return WorkflowUtilsService.enterSession($stateParams, true)
        }
      }
    })
    .state('app.workflow.print', {
      url: 'print/?:workflowIds/?:sessionIds',
      params: {
        nodeIds: null,
        workflowIds: {
          dynamic: true
        },
        sessionIds: {
          dynamic: true
        }
      },
      views: {
        'main@': {
          template: require('../../core/layouts/content-only.html'),
          controller: 'MainController as vm'
        },
        'content@app.workflow.print': {
          template: require('./print/workflow.print.template.html'),
          controller: 'WorkflowPrintController',
          controllerAs: 'vm'
        }
      },
      bodyClass: 'workflow-print',
      resolve: {
        ResolvedSessions: ($stateParams, WorkflowSessionItem) => {
          if (
            !$stateParams.sessionIds ||
            $stateParams.sessionIds.length === 0
          ) {
            return null
          }
          let sessionIds = $stateParams.sessionIds
          if (!Array.isArray($stateParams.sessionIds)) {
            sessionIds = [sessionIds]
          }

          return WorkflowSessionItem.getSessionsForPrint({
            sessionIds
          }).$promise
        },
        ResolvedUsers: UserModel =>
          UserModel.find({}).$promise.then(res => _.keyBy(res, 'id'))
      }
    })
    .state('app.workflow.configurations', {
      url: '/configurations/?:filter',
      params: {
        filter: {
          dynamic: true
        }
      },
      views: {
        'content@app': {
          template: require('app/modules/components/tables/kendo-grid/grid.html'),
          controller: 'ConfigurationsController as vm'
        }
      },
      bodyClass: 'configurations'
    })
    .state('app.workflow.configuration-show', {
      url: '/configuration/show/?:id',
      views: {
        'content@app': {
          template: require('./configuration/configuration-show.html'),
          controller: 'ConfigurationShowController',
          controllerAs: 'vm'
        }
      },
      bodyClass: 'configuration',
      resolve: {
        ResolvedConfiguration: (Configuration, $stateParams) => {
          if (!$stateParams.id || $stateParams.id === '') {
            return null
          }
          return Configuration.findOne({
            filter: { where: { id: $stateParams.id } }
          }).$promise
        },
        ResolvedWorkflows: (Workflow, $rootScope, MultiTranslateService) =>
          Workflow.find({
            filter: {
              where: {
                latestVersion: true
              },
              fields: {
                recordId: true,
                name: true,
                translations: true,
                fallbackStatus: true
              }
            }
          }).$promise.then(res =>
            res.map(workflow =>
              MultiTranslateService.getForView(
                Workflow,
                $rootScope.currentLang,
                workflow
              )
            )
          )
      }
    })
    .state('app.workflow.workflow-types', {
      url: '/workflow-types',
      resolve: {
        ResolvedWorkflowTypes: WorkflowType =>
          WorkflowType.find({
            filter: {
              fields: {
                id: true,
                name: true,
                default: true,
                createStock: true,
                erpWorkorderDefault: true,
                erpServiceDefault: true,
                localDefault: true,
                builtin: true
              },
              order: 'created asc'
            }
          }).$promise
      },
      views: {
        'content@app': {
          template: require('app/modules/components/tables/kendo-grid/grid.html'),
          controller: 'WorkflowTypesController as vm'
        }
      },
      bodyClass: 'workflow-types'
    })
    .state('app.workflow.list-items', {
      url: '/list-items',
      resolve: {
        selectedListItems: () => {
          return null
        },
        listId: () => {
          return null
        },
        ResolvedLists: (List, MultiTranslateService, $rootScope) => {
          return List.find({}).$promise.then(lists =>
            lists.map(list =>
              MultiTranslateService.getForView(
                List,
                $rootScope.currentLang,
                list
              )
            )
          )
        }
      },
      views: {
        'content@app': {
          template: require('app/modules/components/tables/kendo-grid/grid.html'),
          controller: 'ListItemTableController'
        }
      },
      bodyClass: 'list-items'
    })

  // Navigation

  msNavigationServiceProvider.saveItem('apps.modeling.workflow', {
    title: 'WF.WORKFLOWS',
    weight: 1,
    icon: 'icon-sitemap',
    aclsCondition: 'or',
    acls: [
      { model: 'Workflow', method: 'find' },
      { model: 'Workflow', method: 'create' },
      { model: 'WorkflowNote', method: 'find' },
      { model: 'CustomField', method: 'find' }
    ]
  })
  msNavigationServiceProvider.saveItem('apps.modeling.workflow.list', {
    title: 'WF.ALL_WORKFLOWS',
    state: 'app.workflow.list',
    weight: 1,
    acls: [{ model: 'Workflow', method: 'find' }]
  })

  msNavigationServiceProvider.saveItem('apps.knowledgeBase.workflow-list', {
    title: 'WF.ALL_WORKFLOWS',
    state: 'app.workflow.knowledge-base-list',
    icon: 'icon-sitemap',
    weight: 50,
    acls: [{ model: 'Workflow', method: 'find' }]
  })

  msNavigationServiceProvider.saveItem('apps.knowledgeBase.sessions', {
    title: 'WF.SESSIONS',
    state: 'app.workflow.session-list',
    hidden: true,
    weight: 60,
    acls: []
  })
  msNavigationServiceProvider.saveItem('apps.modeling.workflow.customFields', {
    title: 'WF.CUSTOM_FIELDS',
    state: 'app.workflow.custom-fields',
    hidden: true,
    weight: 10,
    acls: [{ model: 'Field', method: 'create' }]
  })

  msNavigationServiceProvider.saveItem(
    'apps.modeling.workflow.configurations',
    {
      title: 'WF.CONFIGURATIONS',
      state: 'app.workflow.configurations',
      weight: 30,
      hidden: true,
      acls: [{ model: 'Configuration', method: 'create' }]
    }
  )

  msNavigationServiceProvider.saveItem(
    'apps.modeling.workflow.workflow-types',
    {
      title: 'WF.WORKFLOW_TYPES',
      state: 'app.workflow.workflow-types',
      weight: 20,
      acls: [{ model: 'WorkflowType', method: 'create' }]
    }
  )

  msNavigationServiceProvider.saveItem('apps.settings.archive.notes', {
    title: 'WF.NOTES_REPORT',
    state: 'app.workflow.notes',
    hidden: false,
    weight: 1,
    acls: [{ model: 'WorkflowNote', method: 'find' }]
  })

  msNavigationServiceProvider.saveItem('apps.modeling.workflow.list-items', {
    title: 'WF.LIST_ITEMS',
    state: 'app.workflow.list-items',
    hidden: false,
    weight: 40,
    acls: [{ model: 'ListItem', method: 'find' }]
  })
}
