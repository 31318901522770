/* global io */
const debug = require('debug')('nextplus:index.socket')

/** @ngInject */
function socket (
  socketFactory,
  newVersionDialog,
  $rootScope,
  $window,
  $timeout
) {
  const self = { disconnectMessagesTimeout: null }
  // eslint-disable-next-line
  let resolvePromise, rejectPromise
  self.promiseState = new Promise((resolve, reject) => {
    resolvePromise = resolve
    rejectPromise = reject
  })
  self.socket = null
  self.connect = () => {
    return new Promise((resolve, reject) => {
      self.socket = io.connect(window.location.href, {
        transports: ['websocket'],
        reconnection: true,
        forceNew: true
      })
      self.angularSocket = socketFactory({
        ioSocket: self.socket
      })
      const connection = () => {
        debug('connect/reconnect')
        if (self.disconnectMessagesTimeout) {
          $timeout.cancel(self.disconnectMessagesTimeout)
          self.disconnectMessagesTimeout = null
        }
        $rootScope.connected = true
        $rootScope.disconnectionDialogVisible = false
        $rootScope.socketId = self.socket.id
        $rootScope.$broadcast('socket:connected')
        resolvePromise()
        resolve()
      }
      setTimeout(() => {
        if (!$rootScope.connected) {
          rejectPromise()
          reject(
            new Error(
              'Unexpected error occurred. Please try to refresh the page.'
            )
          )
        }
      }, 30 * 1000)
      self.socket.on('connect', connection)
      self.socket.on('jsVersion', version => {
        $rootScope.jsVersion = version
        if (
          $rootScope.jsVersion !== '' &&
          typeof $window.jsVersion !== 'undefined' &&
          $rootScope.jsVersion !== $window.jsVersion
        ) {
          if (!newVersionDialog.isDialogOpen()) {
            newVersionDialog.showDialog()
          }
        }
      })
      self.socket.on('reconnect', connection)
      self.socket.on('break', data => {
        $rootScope.$broadcast('break-exist', data)
      })
      self.socket.on('break-end', data => {
        $rootScope.$broadcast('break-end', data)
      })
      self.socket.on('disconnect', () => {
        $rootScope.$broadcast('socket:disconnect')
        $rootScope.connected = false
        if (self.disconnectMessagesTimeout !== null) {
          $timeout.cancel(self.disconnectMessagesTimeout)
        }
        self.disconnectMessagesTimeout = $timeout(() => {
          $rootScope.disconnectionDialogVisible = true
        }, 5000)
      })
    })
  }
  self.disconnect = () => {
    try {
      self.socket.disconnect()
    } catch (e) {}
    self.socket = null
  }
  $window.reconnect = (ms = 100) => {
    self.socket.close()
    setTimeout(() => {
      self.socket.open()
    }, ms)
  }
  return self
}

module.exports = socket
