/** @ngInject */
const commentNew = {
  bindings: {
    ngDisabled: '<',
    parentId: '=',
    commentModel: '=',
    onCreate: '='
  },
  template: require('./comment-new.html'),
  controller: function (
    $rootScope,
    $mdToast,
    $translate,
    PermissionUtils,
    IntercomService
  ) {
    require('./comment-new.scss')
    const $ctrl = this
    $ctrl.newCommentPlaceholder = $translate.instant('COMMENT.NEW_PLACEHOLDER')
    const resetDrafting = () => {
      $ctrl.drafting = false
      $ctrl.draft = ''
    }
    $ctrl.onClick = () => {
      if (!$ctrl.canComment) {
        const mdToast = $mdToast.nextplus({
          position: $rootScope.toastLocation,
          parent: 'body',
          theme: 'error-toast',
          hideDelay: 6000
        })
        $mdToast.updateTextContent($translate.instant('COMMENT.NO_PERMISSIONS'))
        $mdToast.show(mdToast)
      } else {
        $ctrl.drafting = true
      }
    }
    $ctrl.onCancel = () => {
      resetDrafting()
    }
    $ctrl.onSave = () => {
      $ctrl.commentModel
        .create(
          { id: $ctrl.parentId },
          {
            content: $ctrl.draft
          }
        )
        .$promise.then(comment => {
          resetDrafting()
          comment.createdUser = {
            id: $rootScope.currentUser.id,
            displayName: $rootScope.currentUser.displayName
          }
          if ($ctrl.onCreate) {
            $ctrl.onCreate(comment)
          }
          IntercomService.trackEvent('comment-create')
        })
        .catch(error => {
          console.log(error)
          const mdToast = $mdToast.nextplus({
            position: $rootScope.toastLocation,
            parent: 'body',
            theme: 'error-toast',
            hideDelay: 6000
          })
          $mdToast.updateTextContent(
            error.data.error.message || $translate.instant('ERRORS.UNKNOWN')
          )
          $mdToast.show(mdToast)
        })
    }

    $ctrl.$onInit = async function () {
      $ctrl.userId = $rootScope.currentUser.id
      $ctrl.userDisplayName = $rootScope.currentUser.displayName

      resetDrafting()
      $ctrl.canComment = PermissionUtils.isPermit(
        'FormData',
        '__create__comments',
        'ALLOW'
      )
    }

    $ctrl.$postLink = function () {}

    $ctrl.$onChanges = async function (changes) {}

    $ctrl.$onDestroy = function () {}
  }
}
module.exports = commentNew
