/** @ngInject */

function config ($stateProvider, msApiProvider, msNavigationServiceProvider) {
  // State
  $stateProvider
    .state('app.pages', {
      abstract: true,
      url: '/pages',
      lazyLoad: $transition$ => {
        const $ocLazyLoad = $transition$.injector().get('$ocLazyLoad')
        return import(
          /* webpackChunkName: "pages-management.module" */ './pages-management.module.js'
        )
          .then(mod => {
            return $ocLazyLoad.load(mod.default)
          })
          .catch(err => {
            throw new Error('Ooops, something went wrong, ' + err)
          })
      }
    })
    .state('app.pages.list', {
      url: '/list',
      views: {
        'content@app': {
          template: require('./pages-list/pages-list.html'),
          controller: 'PagesListController'
        }
      },
      resolve: {
        ResolvedPages: function (Pages) {
          return Pages.find({}).$promise
        }
      }
    })
    .state('app.pages.new', {
      url: '/new',
      views: {
        'content@app': {
          template: require('./new-edit-page/new-edit-page.html'),
          controller: 'NewEditPageController'
        }
      },
      resolve: {
        ResolvedPage: function () {
          return null
        }
      }
    })
    .state('app.pages.edit', {
      url: '/edit/:id',
      views: {
        'content@app': {
          template: require('./new-edit-page/new-edit-page.html'),
          controller: 'NewEditPageController'
        }
      },
      resolve: {
        ResolvedPage: function (Pages, $stateParams) {
          return Pages.findOne({ filter: { where: { id: $stateParams.id } } })
            .$promise
        }
      }
    })
    .state('app.pages.show', {
      url: '/show/:id',
      views: {
        'content@app': {
          template: require('./page-show/page-show.html'),
          controller: 'ShowPageController'
        }
      },
      resolve: {
        ResolvedPage: function (Pages, $stateParams) {
          return Pages.findOne({ filter: { where: { id: $stateParams.id } } })
            .$promise
        }
      }
    })
    .state('app.pages.content', {
      url: '/content/:id',
      views: {
        'content@app': {
          template: require('./page-content/page-content.html'),
          controller: 'ShowPageContentController'
        }
      },
      resolve: {
        ResolvedPage: function (Pages, $stateParams) {
          return Pages.findOne({ filter: { where: { id: $stateParams.id } } })
            .$promise
        }
      }
    })
    .state('app.pages.external', {
      url: '/external/:externalUrl/:target'
    })
}
module.exports = config
