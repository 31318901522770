import angular from 'angular'

import UserModelShowController from './show/user.show.controller'
import UserModelsController from './users.controller'

const MODULE_NAME = 'app.user'

angular
  .module(MODULE_NAME, ['ngSanitize'])
  .controller('UserModelsController', UserModelsController)
  .controller('UserModelShowController', UserModelShowController)
  .config(config)

/** @ngInject */
function config (
  $stateProvider,
  msApiProvider,
  msNavigationServiceProvider,
  $translateProvider
) {
  // State
  $stateProvider
    .state('app.user', {
      abstract: true,
      url: '/user'
    })
    .state('app.user.list', {
      url: '/?:filter',
      params: {
        filter: {
          dynamic: true
        }
      },
      views: {
        'content@app': {
          template: require('app/modules/components/tables/kendo-grid/grid.html'),
          controller: 'UserModelsController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        ResolvedRoles: ($rootScope, $window, PermissionUtils, Role) => {
          if (!PermissionUtils.isPermit('UserModel', 'createNewUser')) {
            $window.location.href = $rootScope.currentUser.homepage
            return []
          }
          return Role.find({
            filter: {
              fields: { id: true, nicename: true },
              where: {
                name: {
                  nin: [
                    '$authenticated',
                    '$unauthenticated',
                    '$everyone',
                    '$owner'
                    // '$sysadmin' // sysadmin name
                  ]
                }
              }
            }
          }).$promise
        },
        ResolvedGroups: Group => {
          return Group.find({
            filter: {
              fields: { id: true, name: true }
            }
          }).$promise
        },
        ResolvedLocations: Location => Location.find({}).$promise
      },

      bodyClass: 'user user-list'
    })
    .state('app.user.show', {
      url: '/show/?:id',
      views: {
        'content@app': {
          template: require('./show/show.html'),
          controller: 'UserModelShowController',
          controllerAs: 'vm'
        }
      },
      bodyClass: 'user user-show',
      lazyLoad: $transition$ => {
        return import(
          /* webpackChunkName: "libphonenumber" */ 'libphonenumber-js/bundle/libphonenumber-js.min'
        )
          .then(mod => {
            global.libphonenumber = mod
          })
          .catch(err => {
            throw new Error('Ooops, something went wrong, ' + err)
          })
      },
      resolve: {
        ResolvedUser: function (UserModel, $stateParams) {
          if (!$stateParams.id) return false
          return UserModel.findById({
            id: $stateParams.id,
            filter: {
              include: ['roles']
            }
          }).$promise
        },
        ResolvedRoles: UserModel => UserModel.getAvailableRoles().$promise,
        ResolvedStations: Station => Station.find({}).$promise
      }
    })

  // Navigation
  msNavigationServiceProvider.saveItem('apps.settings.user', {
    title: 'USER.USERS',
    icon: 'icon-account',
    weight: 1
  })

  msNavigationServiceProvider.saveItem('apps.settings.user.users', {
    title: 'USER.USERS_CONFIGURATION',
    state: 'app.user.list',
    weight: 1,
    acls: [{ model: 'UserModel', method: 'createNewUser' }]
  })
}

export default MODULE_NAME
