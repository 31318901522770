/** @ngInject */
function QuickPanelController (msApi) {
  // var vm = this
  // return;
  // // Data
  // vm.date = new Date()
  // vm.settings = {
  //   notify: true,
  //   cloud: false,
  //   retro: true
  // }
  //
  // msApi.request('quickPanel.activities@get', {},
  //   // Success
  //   function (response) {
  //     vm.activities = response.data
  //   }
  // )
  //
  // msApi.request('quickPanel.events@get', {},
  //   // Success
  //   function (response) {
  //     vm.events = response.data
  //   }
  // )
  //
  // msApi.request('quickPanel.notes@get', {},
  //   // Success
  //   function (response) {
  //     vm.notes = response.data
  //   }
  // )
  //
  // // Methods
  //
  // /// ///////
}

module.exports = QuickPanelController
