/* global angular */

import StockSKUConversionResultsDialog from './sku-conversion-results-dialog.controller'

/** @ngInject */
function StockSKUConversion (
  $rootScope,
  $scope,
  $translate,
  $mdDialog,
  $stateParams,
  Stock,
  PartAssembly,
  Page
) {
  const sendForm = async function sendForm (wizardData) {
    $scope.inProgress = $rootScope.loadingProgress = true
    let res
    let error
    try {
      res = await Stock.convertStock(wizardData).$promise
    } catch (err) {
      error = err.data.error
      console.error(err)
    } finally {
      $mdDialog
        .show({
          /** @ngInject */
          controller: StockSKUConversionResultsDialog,
          template: require('./sku-conversion-results-dialog.template.html'),
          parent: angular.element(document.body),
          targetEvent: '',
          locals: {
            res,
            error
          },
          fullscreen: true,
          multiple: true,
          escapeToClose: true,
          clickOutsideToClose: true
        })
        .then(
          function () {},
          function () {
            if (res) {
              $scope.wizardModel.wizard = {}
              $scope.selectedIndex = 0
            }
          }
        )
      $scope.inProgress = $rootScope.loadingProgress = false
    }
  }

  const initScreen = function initScreen () {
    $scope.title = $rootScope.title = $translate.instant(
      'WO.SKU_CONVERSION.TITLE'
    )
    Page.setTitleText($scope.title)

    $scope.wizardModel = {
      conversion: {}
    }

    if ($stateParams.source) {
      const { sku, serial } = $stateParams.source
      $scope.wizardModel.conversion.sourceSKU = sku
      $scope.wizardModel.conversion.sourceSerial = serial
    }

    $scope.wizardFields = [
      {
        key: 'conversion',
        type: 'wizardGroup',
        templateOptions: {
          class: '',
          selectedIndex: $scope.selectedIndex,
          nextButtonText: $translate.instant('WO.WIZARD.NEXT'),
          backButtonText: $translate.instant('WO.WIZARD.BACK'),
          lastStageButtonText: $translate.instant('WO.WIZARD.SEND'),
          busy: $scope.inProgress,
          callback: sendForm,
          fields: [
            // stage 1
            {
              wrapper: 'wizardItem',
              templateOptions: {
                label: $translate.instant(
                  'WO.SKU_CONVERSION.WIZARD.SOURCE_STOCK'
                ),
                fieldGroup: [
                  {
                    key: 'sourceSKU',
                    type: 'modelSelect',
                    templateOptions: {
                      required: true,
                      findMethod: PartAssembly.find,
                      mapObject: {
                        id: 'number',
                        name: 'number',
                        displayName: 'name'
                      },
                      baseFilterObject: {
                        where: {},
                        fields: {
                          number: true,
                          name: true
                        }
                      },
                      label: $translate.instant(
                        'WO.SKU_CONVERSION.WIZARD.SOURCE_SKU'
                      )
                    }
                  },
                  {
                    key: 'sourceSerial',
                    type: 'input',
                    className: 'layout-row',
                    templateOptions: {
                      required: true,
                      type: 'text',
                      label: $translate.instant(
                        'WO.SKU_CONVERSION.WIZARD.SOURCE_SERIAL'
                      )
                    }
                  }
                ]
              }
            },
            // stage 2
            {
              wrapper: 'wizardItem',
              templateOptions: {
                label: $translate.instant(
                  'WO.SKU_CONVERSION.WIZARD.TARGET_STOCK'
                ),
                fieldGroup: [
                  {
                    key: 'targetSKU',
                    type: 'modelSelect',
                    templateOptions: {
                      required: true,
                      findMethod: PartAssembly.find,
                      mapObject: {
                        id: 'number',
                        name: 'number',
                        displayName: 'name'
                      },
                      baseFilterObject: {
                        where: {},
                        fields: {
                          number: true,
                          name: true
                        }
                      },
                      label: $translate.instant(
                        'WO.SKU_CONVERSION.WIZARD.TARGET_SKU'
                      )
                    }
                  },
                  {
                    key: 'targetSerial',
                    type: 'input',
                    className: 'layout-row',
                    templateOptions: {
                      required: true,
                      type: 'text',
                      label: $translate.instant(
                        'WO.SKU_CONVERSION.WIZARD.TARGET_SERIAL'
                      )
                    }
                  }
                ]
              }
            }
          ]
        }
      }
    ]
  }

  initScreen()

  $scope.headerOptions = {
    icon: 'icon-cached',
    template: require('app/templates/headers/simple.html'),
    title: $scope.title,
    fabButton: {}
  }
}

module.exports = StockSKUConversion
