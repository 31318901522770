/** @ngInject */
function CloneSessionController (
  $scope,
  $rootScope,
  $translate,
  Workorder,
  WorkflowSessionItem,
  Page,
  $mdToast,
  htmlWork
) {
  const vm = this

  $scope.sessionClone = async function sessionClone () {
    Workorder.cloneSession({
      sourceWorkorderId: vm.cloneModel.sourceWorkorderId,
      targetWorkorderId: vm.cloneModel.targetWorkorderId,
      sourceSessionId: vm.cloneModel.sourceSessionId,
      targetSessionId: vm.cloneModel.targetSessionId,
      formsAction: vm.cloneModel.formsAction
    })
      .$promise.then(res => {
        const successToast = $mdToast.nextplus({
          position: $rootScope.toastLocation,
          parent: 'body.body',
          theme: 'success-toast',
          hideDelay: 3000
        })
        $mdToast.show(successToast)
        $mdToast.updateTextContent(
          $translate.instant('WO.CLONE_SESSION.SESSION_CLONED_SUCCESSFULLY')
        )
      })
      .catch(error => {
        const errorToast = $mdToast.nextplus({
          position: $rootScope.toastLocation,
          parent: 'body.body',
          theme: 'error-toast',
          hideDelay: 3000
        })
        const availableErrors = [
          'SOURCE_WORKORDER_NOT_EXIST',
          'TARGET_SESSION_NOT_EXIST',
          'SOURCE_WORKORDER_CANT_BE_NEW',
          'TARGET_WORKORDER_SHOULD_BE_NEW',
          'TARGET_SESSION_SHOULD_BE_NEW',
          'TARGET_WORKORDER_NOT_EXIST',
          'WORKORDERS_DIFFERENT_TYPES',
          'PART_NOT_IN_SOURCE_WORKFLOW'
        ]
        $mdToast.show(errorToast)
        if (availableErrors.includes(error.data.error.message)) {
          $mdToast.updateTextContent(
            $translate.instant(
              'WO.CLONE_SESSION.ERRORS.' + error.data.error.message
            )
          )
        } else {
          $mdToast.updateTextContent(
            $translate.instant(error.data.error.message)
          )
        }
      })
  }
  const initScreen = function initScreen () {
    $scope.title = $rootScope.title = $translate.instant(
      'WO.CLONE_SESSION.TITLE'
    )
    Page.setTitleText($scope.title)

    vm.cloneModel = {
      sourceWorkorderId: null,
      targetWorkorderId: null,
      sourceSessionId: null,
      targetSessionId: null,
      formsAction: 'nothing'
    }
    $scope.sourceFields = [
      {
        key: 'sourceWorkorderId',
        type: 'modelSelect',
        templateOptions: {
          label: $translate.instant('WO.CLONE_SESSION.SOURCE_WORKORDER'),
          required: true,
          findMethod: Workorder.find,
          mapObject: {
            id: 'id',
            name: 'workorderNumber'
          },
          baseFilterObject: {
            where: {},
            fields: {
              id: true,
              workorderNumber: true
            }
          },
          onChange: function (value, options) {
            addSourceSessionIdFIeld()
          }
        }
      }
    ]
    const addSourceSessionIdFIeld = function addSourceSessionIdFIeld () {
      if ($scope.sourceFields.length === 2) {
        $scope.sourceFields.pop()
        vm.cloneModel.sourceSessionId = null
      }
      $scope.sourceFields.push({
        key: 'sourceSessionId',
        type: 'modelSelect',
        templateOptions: {
          label: $translate.instant('WO.CLONE_SESSION.SOURCE_SERIAL'),
          required: true,
          findMethod: WorkflowSessionItem.find,
          templateOption: function (option) {
            if (option) {
              return option.originalModel.serials?.length > 0 &&
                option.originalModel.serials[0]
                ? htmlWork.htmlEncode(option.originalModel.serials.join(', '))
                : htmlWork.htmlEncode(option.originalModel.indicator)
            }
          },
          mapObject: {
            id: 'id',
            name: 'serials'
          },
          baseFilterObject: {
            where: {
              workorderId: vm.cloneModel.sourceWorkorderId
            },
            fields: {
              id: true,
              serials: true,
              indicator: true
            }
          }
        }
      })
    }
    $scope.targetFields = [
      {
        key: 'targetWorkorderId',
        type: 'modelSelect',
        templateOptions: {
          label: $translate.instant('WO.CLONE_SESSION.TARGET_WORKORDER'),
          required: true,
          findMethod: Workorder.find,
          mapObject: {
            id: 'id',
            name: 'workorderNumber'
          },
          baseFilterObject: {
            where: {},
            fields: {
              id: true,
              workorderNumber: true
            }
          },
          onChange: function (value, options) {
            addTargetSessionIdFIeld()
          }
        }
      }
    ]
    const addTargetSessionIdFIeld = function addTargetSessionIdFIeld () {
      if ($scope.targetFields.length === 2) {
        $scope.targetFields.pop()
        vm.cloneModel.targetSessionId = null
      }
      $scope.targetFields.push({
        key: 'targetSessionId',
        type: 'modelSelect',
        templateOptions: {
          label: $translate.instant('WO.CLONE_SESSION.TARGET_SERIAL'),
          required: true,
          findMethod: WorkflowSessionItem.find,
          templateOption: function (option) {
            if (option) {
              return option.originalModel.serials?.length > 0 &&
                option.originalModel.serials[0]
                ? htmlWork.htmlEncode(option.originalModel.serials.join(', '))
                : htmlWork.htmlEncode(option.originalModel.indicator)
            }
          },
          mapObject: {
            id: 'id',
            name: 'serials'
          },
          baseFilterObject: {
            where: {
              workorderId: vm.cloneModel.targetWorkorderId
            },
            fields: {
              id: true,
              serials: true,
              indicator: true
            }
          }
        }
      })
    }
    $scope.extraFields = [
      {
        key: 'formsAction',
        type: 'radioMaterial',
        className: 'layout-column',
        templateOptions: {
          label: $translate.instant('WO.CLONE_SESSION.FORMS_ACTIONS.TITLE'),
          options: [
            {
              name: $translate.instant(
                'WO.CLONE_SESSION.FORMS_ACTIONS.OPTIONS.DO_NOTHING'
              ),
              value: 'nothing'
            },
            {
              name: $translate.instant(
                'WO.CLONE_SESSION.FORMS_ACTIONS.OPTIONS.COPY'
              ),
              value: 'copy'
            },
            {
              name: $translate.instant(
                'WO.CLONE_SESSION.FORMS_ACTIONS.OPTIONS.MOVE'
              ),
              value: 'move'
            }
          ]
        }
      }
    ]
  }
  initScreen()

  $scope.headerOptions = {
    icon: 'icon-content-copy',
    template: require('app/templates/headers/simple.html'),
    title: $scope.title,
    fabButton: {}
  }
}
module.exports = CloneSessionController
