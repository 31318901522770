import angular from 'angular'

import ToolbarController from './toolbar.controller'

const MODULE_NAME = 'app.toolbar'

angular
  .module(MODULE_NAME, [])
  .controller('ToolbarController', ToolbarController)

export default MODULE_NAME
