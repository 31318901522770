/* global angular $ _ */
;('use strict')
const eachOfSeries = require('async/eachOfSeries')

const getFileExtension = (
  extensions = '',
  FileTypes,
  filetype,
  extendExtensions
) => {
  let extensionsList

  const extensionByFiletype = FileTypes[filetype] || []
  const extensionAsParam = extensions ? extensions.split(',') : []

  if (
    extensionAsParam.includes('*') ||
    (_.isEmpty(extensionByFiletype) && _.isEmpty(extensionAsParam))
  ) {
    extensionsList = false
  } else {
    if (!_.isEmpty(extensionByFiletype) && !_.isEmpty(extensionAsParam)) {
      extensionsList = _.intersection(extensionByFiletype, extensionAsParam)
    } else {
      extensionsList = _.isEmpty(extensionByFiletype)
        ? extensionAsParam
        : extensionByFiletype
    }
    const extendExtensionsList = extendExtensions
      ? extendExtensions.split(',')
      : []
    extensionsList = _.uniq(extendExtensionsList.concat(extensionsList))
  }
  return extensionsList
}

/** @ngInject */
function FileDialogController (
  $scope,
  $mdDialog,
  $rootScope,
  $translate,
  $state,
  $mdPanel,
  $mdToast,
  $window,
  Resource,
  LastSeenResource,
  filetype,
  extensions,
  extendExtensions,
  displayType,
  mediatype,
  FileTypes,
  modelName,
  modelId,
  ResolvedLastSeen,
  resolvesFolders,
  Folder,
  getUrlFromObj,
  ResourceUtils,
  UploadService,
  DateTimeFormatService
) {
  $scope._mdPanel = $mdPanel
  $scope.displayType = displayType
  $scope.canStreamVideo = false
  const vm = this
  vm.files = []

  $scope.sort = {
    name: null,
    date: null
  }

  const userDataFolder = {
    id: '203d8870-2e8d-4ef6-85a8-9478ef6e4d56',
    name: $translate.instant('FM.USER_DATA_FOLDER'),
    parentFolderId: null
  }

  resolvesFolders.unshift(userDataFolder)

  $scope.sortFiles = type => {
    $scope.sort[type] = !$scope.sort[type]
    if (type === 'name') {
      $scope.sort.date = null
    } else {
      $scope.sort.name = null
    }
    vm.currentOffset = 0
    vm.files = []
    vm.fetchFiles()
  }

  UploadService.canStreamVideo().then(bool => {
    $scope.canStreamVideo = bool
  })

  $scope.openCameraCaptureDialog = function openCameraCaptureDialog (
    folderId = null
  ) {
    if (vm.currentFolderId) {
      folderId = vm.currentFolderId
    }
    UploadService.openCameraCaptureDialog().then(encodedFile => {
      const options = {
        keepOriginal: false,
        hidden: false,
        folderId: folderId
      }
      UploadService.uploadBase64File(encodedFile, options)
        .then(
          function (res) {
            if (res) {
              vm.progress = 101
              if (vm.currentFolderId === folderId || folderId === null) {
                const newFiles = res.data.map(f => manipulateFile(f))
                vm.files = _.unionBy(_.reverse(newFiles), vm.files, 'id')
              }
              if (res.data && _.isArray(res.data)) {
                res.data[0].user = $rootScope.currentUser
              }

              if ($scope.displayType === 'upload') {
                if (res.data && _.isArray(res.data)) {
                  vm.data = res.data
                  $mdDialog.hide(vm.data)
                } else {
                  $mdDialog.cancel(false)
                }
              }
            }
          },
          function (res) {
            if (res.status > 0) {
              const mdToast = $mdToast.nextplus({
                position: $rootScope.toastLocation,
                parent: 'document.body',
                theme: 'error-toast',
                hideDelay: 3500
              })
              vm.errorMsg = res.status + ': ' + res.data
              switch (res.status) {
                case 413:
                  $mdToast.show(mdToast)
                  $mdToast.updateTextContent(
                    $translate.instant('FM.LARGE_FILE_ERROR')
                  )
                  break
                default:
                  $mdToast.show(mdToast)
                  $mdToast.updateTextContent(
                    $translate.instant('FM.GENERAL_ERROR')
                  )
              }
              vm.progress = 101
            }
          },
          function (evt) {
            console.log(parseInt((100.0 * evt.loaded) / evt.total))
            vm.progress = Math.min(
              100,
              parseInt((100.0 * evt.loaded) / evt.total)
            )
          }
        )
        .catch(err => {
          console.error(err)
          $mdToast.show(
            $mdToast.nextplus({
              position: $rootScope.toastLocation,
              parent: 'document.body',
              theme: 'error-toast',
              hideDelay: 3500
            })
          )
          $mdToast.updateTextContent($translate.instant('FM.GENERAL_ERROR'))
          vm.progress = 101
        })
    })
  }

  vm.progress = 101
  const rootFolder = { id: null, name: $translate.instant('FM.ROOT') }
  vm.folders = resolvesFolders.filter(folder => folder.parentFolderId === null)
  $scope.$state = $state.currentState
  $scope.paths = [rootFolder]
  $scope.selectedFolder = null

  const manipulateFile = function manipulateFile (file) {
    if (file.ext === 'mp4') {
      file.videoUrl = ResourceUtils.generateHLSSrc(file)
    }
    file.downloadUrl = getUrlFromObj(file)
    if (file.posters && file.posters.length) {
      let poster = file.posters.find(p => p.name === 'thumbnail')
      if (!poster) {
        poster = file.posters[0]
      }
      if (poster) {
        file.poster =
          '/api/containers/' + file.container + '/download/' + poster.fileName
      }
    }
    file.ext = file.ext.toLowerCase()
    return file
  }

  $scope.selectedType = null

  $scope.downloadFile = function downloadFile (resourceId) {
    let resource = vm.files.find(f => f.id === resourceId)
    if (!resource) {
      resource = vm.lastSeen.find(f => f.id === resourceId)
    }
    if (resource) {
      return ResourceUtils.downloadByName(resource.container, resource.name)
    }
  }

  const fallbackCopyTextToClipboard = function fallbackCopyTextToClipboard (
    text
  ) {
    return new Promise((resolve, reject) => {
      const textArea = document.createElement('textarea')
      textArea.value = text
      textArea.style.top = '0'
      textArea.style.left = '0'
      textArea.style.position = 'fixed'
      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()

      try {
        const successful = document.execCommand('copy')
        if (successful) resolve()
        else reject(new Error('Copy failed'))
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err)
        reject(new Error('Copy failed'))
      }
      document.body.removeChild(textArea)
    })
  }

  const copyTextToClipboard = function copyTextToClipboard (text) {
    return new Promise(async (resolve, reject) => {
      if (!navigator.clipboard) {
        try {
          await fallbackCopyTextToClipboard(text)
          return resolve()
        } catch {
          return reject(new Error('Copy failed'))
        }
      }
      navigator.clipboard.writeText(text).then(
        function () {
          resolve()
        },
        function (err) {
          console.error('Async: Could not copy text: ', err)
          reject(new Error('Copy failed'))
        }
      )
    })
  }

  $scope.copyToClipboard = async function copyToClipboard (resourceId) {
    $rootScope.loadingProgress = true
    try {
      let resource = vm.files.find(f => f.id === resourceId)
      if (!resource) {
        resource = vm.lastSeen.find(f => f.id === resourceId)
      }
      const url = ResourceUtils.createDownloadLink(resource)
      await copyTextToClipboard(url)
      const mdToast = $mdToast.nextplus({
        position: $rootScope.toastLocation,
        parent: 'document.body',
        theme: 'success-toast',
        hideDelay: 3500
      })
      $mdToast.show(mdToast)
      $mdToast.updateTextContent($translate.instant('FM.COPY_SUCCESS_MESSAGE'))
    } catch {
      const mdToast = $mdToast.nextplus({
        position: $rootScope.toastLocation,
        parent: 'document.body',
        theme: 'error-toast',
        hideDelay: 3500
      })
      $mdToast.show(mdToast)
      $mdToast.updateTextContent($translate.instant('FM.COPY_ERROR_MESSAGE'))
    } finally {
      $rootScope.loadingProgress = false
    }
  }

  $scope.openMoveMenu = function openMoveMenu (type, id, ev) {
    let offsetX = ev.originalEvent.offsetX
    const offsetY = ev.originalEvent.offsetY

    if (window.innerWidth < ev.originalEvent.clientX + 250) {
      offsetX = offsetX - 250 < 0 ? 0 : offsetX - 250
    }

    const position = $scope._mdPanel
      .newPanelPosition()
      .relativeTo(`.class_${id}`)
      .addPanelPosition(
        $scope._mdPanel.xPosition.ALIGN_START,
        $scope._mdPanel.yPosition.CENTER
      )
      .withOffsetX(offsetX)
      .withOffsetY(offsetY)

    const config = {
      attachTo: angular.element(document.body),
      controller: /** @ngInject */ (
        mdPanelRef,
        $scope,
        Folder,
        Resource,
        targetId,
        targetType,
        targetFolder,
        foldersInsideTargetFolder
      ) => {
        $scope._mdPanelRef = mdPanelRef
        $scope.targetFolderId = targetFolder.id

        $scope.currentFolder =
          targetFolder.id === null
            ? { id: null, name: $translate.instant('FM.ROOT') }
            : targetFolder
        $scope.foldersInside =
          targetType === 'folder'
            ? foldersInsideTargetFolder.filter(folder => folder.id !== id)
            : foldersInsideTargetFolder

        $scope.selectAsNewFolder = () => {
          if (type === 'folder') {
            Folder.prototype$patchAttributes(
              { id: targetId },
              { parentFolderId: $scope.currentFolder.id }
            )
              .$promise.then(() => $scope.close())
              .then(() => {
                let index = _.findIndex(vm.folders, { id: targetId })
                vm.folders.splice(index, 1)
                index = _.findIndex(resolvesFolders, { id: targetId })
                resolvesFolders[index].parentFolderId = $scope.currentFolder.id
                $scope.selectedFolder = null
                $scope.selectedType = null
              })
              .catch(console.log)
          } else {
            Resource.prototype$patchAttributes(
              { id: targetId },
              { folderId: $scope.currentFolder.id }
            )
              .$promise.then(() => $scope.close())
              .then(() => {
                const index = _.findIndex(vm.files, { id: targetId })
                vm.files.splice(index, 1)
                vm.data = []
                $scope.selectedFileIncludes = []
                $scope.selectedType = null
              })
              .catch(console.log)
          }
        }

        $scope.addFolder = ev => {
          const confirm = $mdDialog
            .prompt({
              onShowing: function afterShowAnimation (scope, element) {
                $(element).css('z-index', 101)
                $(element).find('input').select()
              }
            })
            .title($translate.instant('FM.CREATE_FOLDER_TITLE'))
            .placeholder($translate.instant('FM.FOLDER_NAME'))
            .initialValue($translate.instant('FM.NEW_FOLDER'))
            .targetEvent(ev)
            .multiple(true)
            .required(true)
            .ok($translate.instant('FM.OK'))
            .cancel($translate.instant('FM.CANCEL'))

          $mdDialog.show(confirm).then(
            function (result) {
              if (result) {
                Folder.create({
                  name: result,
                  parentFolderId: $scope.currentFolder.id
                })
                  .$promise.then(newFolder => {
                    if ($scope.currentFolder.id === null) {
                      newFolder.user = $rootScope.currentUser
                      vm.folders.push(newFolder)
                    }
                    resolvesFolders.push(newFolder)
                    $scope.goNext(newFolder)
                  })
                  .then(() => vm.moveInsideFolder(vm.currentFolderId))
                  .catch(console.log)
              }
            },
            function () {}
          )
        }

        $scope.goNext = folder => {
          $scope.currentFolder = folder
          $scope.foldersInside = resolvesFolders.filter(
            folder => folder.parentFolderId === $scope.currentFolder.id
          )
        }

        $scope.goBack = () => {
          $scope.parentFolder = _.find(resolvesFolders, {
            id: $scope.currentFolder.parentFolderId
          })
          $scope.currentFolder = $scope.parentFolder || {
            id: null,
            name: $translate.instant('FM.ROOT')
          }
          $scope.foldersInside = resolvesFolders
            .filter(folder => folder.parentFolderId === $scope.currentFolder.id)
            .filter(folder => folder.id !== id)
        }

        $scope.close = () => {
          if ($scope._mdPanelRef) {
            $scope._mdPanelRef.close()
          }
        }
      },
      template: require('./templates/move-menu.html'),
      panelClass: 'demo-menu-example',
      position: position,
      locals: {
        targetId: id,
        targetType: type,
        targetFolder:
          vm.currentFolderId === null
            ? { id: null, name: $translate.instant('FM.ROOT') }
            : _.find(resolvesFolders, { id: vm.currentFolderId }),
        foldersInsideTargetFolder: resolvesFolders.filter(
          folder => folder.parentFolderId === vm.currentFolderId
        )
      },
      multiple: true,
      openFrom: ev,
      clickOutsideToClose: true,
      escapeToClose: true,
      focusOnOpen: false,
      zIndex: 100
    }

    $scope._mdPanel.open(config)
  }

  $scope.openContextMenu = function openContextMenu (
    type,
    id,
    ev,
    relativeId = null
  ) {
    if (vm.currentFolderId === '203d8870-2e8d-4ef6-85a8-9478ef6e4d56') return
    let offsetX = ev.originalEvent.offsetX
    let offsetY = ev.originalEvent.offsetY

    if (window.innerWidth < ev.originalEvent.clientX + 250) {
      offsetX = offsetX - 250 < 0 ? 0 : offsetX - 250
    }

    if (window.innerHeight < ev.originalEvent.clientY + 250) {
      offsetY = offsetY - 250 < 0 ? 0 : offsetY - 250
    }
    if (!relativeId) {
      relativeId = `.class_${id}`
    } else {
      relativeId = `.class_${relativeId}`
    }

    const position = $scope._mdPanel
      .newPanelPosition()
      .relativeTo(relativeId)
      .addPanelPosition(
        $scope._mdPanel.xPosition.ALIGN_START,
        $scope._mdPanel.yPosition.CENTER
      )
      .withOffsetX(offsetX)
      .withOffsetY(offsetY)

    const config = {
      attachTo: angular.element(document.body),
      controller: /** @ngInject */ (
        mdPanelRef,
        $scope,
        relativeId,
        id,
        type,
        ev,
        openMoveMenu,
        deleteItem,
        changeFile,
        processing,
        renameFolder,
        renameFile,
        downloadFile
      ) => {
        $scope.type = type
        $scope.id = id
        $scope._mdPanelRef = mdPanelRef
        setTimeout(() => $(relativeId).click())

        $scope.renameItem = () => {
          if ($scope.type === 'folder') {
            renameFolder(ev)
          } else {
            renameFile(ev)
          }
        }

        $scope.moveItem = () => {
          openMoveMenu(type, id, ev)
        }

        $scope.changeFile = () => {
          changeFile(id, ev)
        }

        $scope.processing = () => {
          processing(id, ev)
        }

        $scope.deleteItem = () => {
          deleteItem(ev)
        }

        $scope.downloadItem = () => {
          downloadFile(id)
        }

        $scope.close = () => {
          if ($scope._mdPanelRef) {
            $scope._mdPanelRef.close()
          }
        }
      },
      template: require('./templates/content-menu.html'),
      panelClass: 'demo-menu-example',
      position: position,
      locals: {
        relativeId: relativeId,
        id: id,
        type: type,
        ev: ev,
        openMoveMenu: $scope.openMoveMenu,
        renameFolder: $scope.renameFolder,
        renameFile: $scope.renameFile,
        changeFile: $scope.changeFile,
        processing: $scope.processing,
        deleteItem: $scope.delete,
        downloadFile: $scope.downloadFile
      },
      multiple: true,
      openFrom: ev,
      clickOutsideToClose: true,
      escapeToClose: true,
      focusOnOpen: false,
      zIndex: 100
    }
    if (type === 'folder' && id === '203d8870-2e8d-4ef6-85a8-9478ef6e4d56') {
      // skip
    } else {
      $scope._mdPanel.open(config)
    }
  }

  $scope.changeFile = function changeFile (id, ev) {
    $scope.fileForChange = vm.files.find(file => file.id === id)
    $scope.uploadChangeExtensions = '.' + $scope.fileForChange.ext

    const filename = vm.data[0].originalFilename
    const confirm = $mdDialog
      .confirm()
      .title($translate.instant('FM.SURE_CHANGE_FILE'))
      .textContent($translate.instant('FM.SURE_CHANGE_FILE_DESC', { filename }))
      .targetEvent(ev)
      .multiple(true)
      .ok($translate.instant('FM.YES'))
      .cancel($translate.instant('FM.CANCEL'))

    $mdDialog.show(confirm).then(
      function () {
        // replace
        $('#select-for-change').click()
      },
      function () {}
    )
  }

  const whitelistCompressExt = [
    'jpeg',
    'jpg',
    'gif',
    'png',
    'bmp',
    'mp4',
    'avi'
  ]

  $scope.processing = function processing (id) {
    $rootScope.loadingProgress = true
    const idx = vm.files.findIndex(f => f.id === id)
    if (idx > -1) {
      const file = vm.files[idx]
      const { ext } = file
      if (!whitelistCompressExt.includes(ext)) {
        $mdToast.updateTextContent(
          $translate.instant('FM.UNSUPPORTED_FORMAT', { ext: ext })
        )
        $mdToast.show(
          $mdToast.nextplus({
            position: $rootScope.toastLocation,
            parent: 'document.body',
            theme: 'error-toast',
            hideDelay: 3500
          })
        )
        $rootScope.loadingProgress = false
        return
      }
      Resource.processingFile({ id: id })
      $mdToast.updateTextContent(
        $translate.instant('FM.PROCESSING_ADD_TO_QUEUE')
      )
      $mdToast.show(
        $mdToast.nextplus({
          position: $rootScope.toastLocation,
          parent: 'document.body',
          theme: 'success-toast',
          hideDelay: 3500
        })
      )
    }
    $rootScope.loadingProgress = false
  }

  $scope.delete = ev => {
    const title =
      $scope.selectedType === 'folder'
        ? $translate.instant('FM.SURE_REMOVE_FOLDER')
        : $translate.instant('FM.SURE_REMOVE_FILE')
    const name =
      $scope.selectedType === 'folder'
        ? $scope.selectedFolder.name
        : vm.data[0].originalFilename
    const confirm = $mdDialog
      .confirm()
      .title(title + ' ' + name + '?')
      .targetEvent(ev)
      .multiple(true)
      .ok($translate.instant('FM.YES'))
      .cancel($translate.instant('FM.CANCEL'))

    $mdDialog.show(confirm).then(
      function () {
        // delete
        if ($scope.selectedType === 'folder') {
          Folder.isHaveContentById({
            id: $scope.selectedFolder.id
          }).$promise.then(res => {
            if (res.haveContent) {
              $mdDialog.show(
                $mdDialog
                  .alert()
                  .parent(angular.element(document.querySelector('body')))
                  .clickOutsideToClose(true)
                  .multiple(true)
                  .title($translate.instant('FM.ERROR'))
                  .textContent(
                    $translate.instant('FM.CANT_DELETE_FOLDER_WITH_CONTENT')
                  )
                  .ok($translate.instant('FM.OK'))
                  .targetEvent(ev)
              )
            } else {
              Folder.destroyById({ id: $scope.selectedFolder.id })
                .$promise.then(() => {
                  let index = resolvesFolders.findIndex(
                    folder => folder.id === $scope.selectedFolder.id
                  )
                  resolvesFolders.splice(index, 1)
                  index = vm.folders.findIndex(
                    folder => folder.id === $scope.selectedFolder.id
                  )
                  vm.folders.splice(index, 1)
                  const lastSeenIndex = vm.lastSeen.findIndex(
                    folder => folder.id === $scope.selectedFolder.id
                  )
                  if (lastSeenIndex > -1) {
                    vm.lastSeen.splice(lastSeenIndex, 1)
                  }
                  $scope.selectedFolder = null
                  $scope.selectedType = null
                })
                .catch(console.log)
            }
          })
        } else {
          let canBeDeleted = true

          $scope.selectedFileIncludes.forEach(include => {
            include.dependencies.forEach(dependency => {
              if (
                dependency.canDelete === false ||
                dependency.canDelete === undefined
              ) {
                canBeDeleted = false
              }
            })
          })

          if (canBeDeleted === false) {
            $mdDialog.show(
              $mdDialog
                .alert()
                .parent(angular.element(document.querySelector('body')))
                .clickOutsideToClose(true)
                .multiple(true)
                .title($translate.instant('FM.ERROR'))
                .textContent(
                  $translate.instant('FM.CANT_DELETE_FILE_WHICH_INCLUDED')
                )
                .ok($translate.instant('FM.OK'))
                .targetEvent(ev)
            )
          } else {
            Resource.deleteFileById({ id: vm.data[0].id })
              .$promise.then(() => {
                const index = vm.files.findIndex(
                  file => file.id === vm.data[0].id
                )
                vm.files.splice(index, 1)
                const lastSeenIndex = vm.lastSeen.findIndex(
                  file => file.id === vm.data[0].id
                )
                if (lastSeenIndex > -1) {
                  vm.lastSeen.splice(lastSeenIndex, 1)
                }
                vm.data = []
                $scope.selectedFileIncludes = []
                $scope.selectedType = null
              })
              .catch(console.log)
          }
        }
      },
      function () {}
    )
  }

  vm.buildFolderPath = function buildFolderPath (folderId) {
    const tmpPath = []
    const findParentFolder = id => {
      const index = _.findIndex(resolvesFolders, { id: id })
      tmpPath.push({
        name: resolvesFolders[index].name,
        id: resolvesFolders[index].id
      })
      if (resolvesFolders[index].parentFolderId !== null) {
        findParentFolder(resolvesFolders[index].parentFolderId)
      }
    }
    if (folderId !== null) {
      findParentFolder(folderId)
    }
    $scope.paths = [rootFolder].concat(_.reverse(tmpPath))
  }

  vm.moveInsideFolder = function moveInsideFolder (folderId) {
    _.each(resolvesFolders, function (f) {
      f.class = ''
    })
    vm.folders = resolvesFolders.filter(
      folder => folder.parentFolderId === folderId
    )
    if (folderId) {
      vm.lastSeen = []
    } else {
      // vm.folders.unshift(userDataFolder)
      vm.lastSeen = ResolvedLastSeen
    }
    vm.buildFolderPath(folderId)
    vm.currentFolderId = folderId
    vm.saveLastSeen(
      'folder',
      resolvesFolders.find(folder => folder.id === folderId)
    )
    vm.data = []
    $scope.selectedFolder = null
    vm.files = []
    vm.currentOffset = 0
    vm.fetchFiles()
  }

  vm.saveLastSeen = async function saveLastSeen (type, object) {
    if (object) {
      await LastSeenResource.insertNewLastSeen({
        type,
        valueId: object.id
      }).$promise
    }
  }

  $scope.getSizeOfResource = function getSizeOfResource (size) {
    if (size < 1024) {
      return parseFloat(size).toFixed(2) + ' B'
    } else if (size >= 1024 && size < 1048576) {
      return parseFloat(size / 1024).toFixed(2) + ' KB'
    } else if (size >= 1048576 && size < 1073741824) {
      return parseFloat(size / (1024 * 1024)).toFixed(2) + ' MB'
    } else {
      return parseFloat(size / (1024 * 1024 * 1024)).toFixed(2) + ' GB'
    }
  }

  $scope.renameFolder = function renameFolder (ev) {
    const confirm = $mdDialog
      .prompt()
      .title($translate.instant('FM.CREATE_FOLDER_TITLE'))
      .placeholder($translate.instant('FM.FOLDER_NAME'))
      .initialValue($scope.selectedFolder.name)
      .targetEvent(ev)
      .multiple(true)
      .required(true)
      .ok($translate.instant('FM.OK'))
      .cancel($translate.instant('FM.CANCEL'))

    $mdDialog.show(confirm).then(
      function (result) {
        if (result) {
          Folder.prototype$patchAttributes(
            { id: $scope.selectedFolder.id },
            { name: result }
          )
            .$promise.then(() => {
              const index = _.findIndex(resolvesFolders, {
                id: $scope.selectedFolder.id
              })
              if (index > -1) {
                resolvesFolders[index].name = result
              }
            })
            .then(() => vm.moveInsideFolder(vm.currentFolderId))
            .catch(console.log)
        }
      },
      function () {}
    )
  }

  $scope.renameFile = function renameFile (ev) {
    const confirm = $mdDialog
      .prompt()
      .title($translate.instant('FM.CHANGE_FILE_NAME_TITLE'))
      .placeholder($translate.instant('FM.FILE_NAME'))
      .initialValue(
        $scope.selectedFile.originalFilename.replace(
          `.${$scope.selectedFile.ext}`,
          ''
        )
      )
      .targetEvent(ev)
      .multiple(true)
      .required(true)
      .ok($translate.instant('FM.OK'))
      .cancel($translate.instant('FM.CANCEL'))

    $mdDialog.show(confirm).then(
      function (result) {
        if (result) {
          const newFileName = `${result}.${$scope.selectedFile.ext}`
          Resource.prototype$patchAttributes(
            { id: $scope.selectedFile.id },
            { originalFilename: newFileName }
          )
            .$promise.then(() => {
              const index = vm.files.findIndex(
                file => file.id === $scope.selectedFile.id
              )
              if (index > -1) {
                vm.files[index].originalFilename = newFileName
                const lastSeenFileIndex = vm.lastSeen.findIndex(
                  file => file.id === $scope.selectedFile.id
                )
                if (lastSeenFileIndex > -1) {
                  vm.lastSeen[lastSeenFileIndex].originalFilename = newFileName
                }
              }
            })
            .then(() => {})
            .catch(console.log)
        }
      },
      function () {}
    )
  }

  $scope.addFolder = function addFolder (ev) {
    const confirm = $mdDialog
      .prompt()
      .title($translate.instant('FM.CREATE_FOLDER_TITLE'))
      .placeholder($translate.instant('FM.FOLDER_NAME'))
      .initialValue($translate.instant('FM.NEW_FOLDER'))
      .targetEvent(ev)
      .multiple(true)
      .required(true)
      .ok($translate.instant('FM.OK'))
      .cancel($translate.instant('FM.CANCEL'))

    $mdDialog.show(confirm).then(
      function (result) {
        if (result) {
          Folder.create({ name: result, parentFolderId: vm.currentFolderId })
            .$promise.then(newFolder => {
              newFolder.user = $rootScope.currentUser
              resolvesFolders.push(newFolder)
            })
            .then(() => vm.moveInsideFolder(vm.currentFolderId))
            .catch(console.log)
        }
      },
      function () {}
    )
  }

  vm.selectFolder = function selectFolder (folder) {
    vm.data = []
    $scope.selectedFolder = folder
    $scope.selectedType = 'folder'

    _.each(vm.lastSeen, function (f) {
      f.class = ''
    })
    _.each(vm.files, function (f) {
      f.class = ''
    })
    _.each(vm.folders, function (f) {
      f.class = ''
    })
    folder.class = 'selected'
    $scope.selectedFolder.createdString = DateTimeFormatService.formatDateTime(
      $scope.selectedFolder.created,
      'dateTime'
    )
  }

  vm.select = function select (file) {
    vm.data = []
    $scope.selectedFolder = null
    $scope.selectedType = 'file'
    $scope.selectedFile = file

    _.each(vm.lastSeen, function (f) {
      f.class = ''
    })
    _.each(vm.files, function (f) {
      f.class = ''
    })
    _.each(vm.folders, function (f) {
      f.class = ''
    })
    file.class = 'selected'
    file.createdString = DateTimeFormatService.formatDateTime(
      file.created,
      'dateTime'
    )
    vm.data.push(file)
    $scope.isLoading = true
    Resource.getResourceIncluded({ id: file.id }).$promise.then(res => {
      $scope.isLoading = false
      $scope.selectedFileIncludes = res
    })
  }

  $scope.uploadFiles = function uploadFiles (
    file,
    errFiles,
    customMediaType,
    replace,
    folderId = null
  ) {
    return new Promise(async (resolve, reject) => {
      const mdToast = $mdToast.nextplus({
        position: $rootScope.toastLocation,
        parent: 'document.body',
        theme: 'error-toast',
        hideDelay: 3500
      })

      if (!file || file.length === 0) {
        if (Array.isArray(errFiles) && errFiles.length > 0) {
          if (errFiles[0].$error === 'pattern') {
            const { name, $errorParam } = errFiles[0]
            $mdToast.show(mdToast)
            $mdToast.updateTextContent(
              $translate.instant('FM.PATTERN_ERROR', {
                fileName: name,
                ext: $errorParam
              })
            )
          } else {
            $rootScope.showErrorToast('NP-9300')
          }
        }
        return
      }

      const selectedFile = file
      if (selectedFile) {
        vm.progress = 0
        const extraData = {}
        if (mediatype || customMediaType) {
          extraData.mediatype = customMediaType || mediatype
        }

        if (replace) {
          extraData.replaceId = replace.replaceId
          extraData.replaceName = replace.replaceName
        }
        extraData.folderId = vm.currentFolderId

        if (folderId) {
          extraData.folderId = folderId
        }
        extraData.keepOriginal = false
        const uploadedFiles = []
        if (!Array.isArray(file)) {
          file = [file]
        }
        await eachOfSeries(file, (fileRef, index, callback) => {
          const uploadPayload = {
            ...extraData,
            file: [fileRef]
          }
          UploadService.uploadFile(uploadPayload).then(
            res => {
              console.log('Upload completed')
              uploadedFiles.push(res.data)
              callback(null, res.data)
            },
            function (res) {
              if (res.status > 0) {
                vm.errorMsg = res.status + ': ' + res.data
                switch (res.status) {
                  case 413:
                    $mdToast.show(mdToast)
                    $mdToast.updateTextContent(
                      $translate.instant('FM.LARGE_FILE_ERROR')
                    )
                    break
                  default:
                    $mdToast.show(mdToast)
                    $mdToast.updateTextContent(
                      $translate.instant('FM.GENERAL_ERROR')
                    )
                }
                vm.progress = 101
              } else {
              }
            },
            function (evt) {
              console.log(parseInt((100.0 * evt.loaded) / evt.total))
              vm.progress = Math.min(
                100,
                parseInt((100.0 * evt.loaded) / evt.total)
              )
            }
          )
        })
        if (uploadedFiles) {
          const tmp = angular.copy($scope.fileForChange)
          $scope.fileForChange = null
          vm.progress = 101
          if (replace) {
            const index = vm.files.findIndex(file => file.id === tmp.id)
            const tmpFile = angular.copy(vm.files.splice(index, 1))
            tmpFile[0].originalFilename = selectedFile.name
            vm.files.splice(index, 0, tmpFile[0])
            const lastSeenIndex = vm.lastSeen.findIndex(
              file => file.id === tmp.id
            )
            if (lastSeenIndex > -1) {
              const newFile = manipulateFile(tmpFile[0][0])
              newFile.type = 'file'
              vm.lastSeen.splice(lastSeenIndex, 1, tmpFile[0][0])
            }
            $scope.$applyAsync()
          } else {
            if (vm.currentFolderId === folderId || folderId === null) {
              const newFiles = uploadedFiles.map(f => manipulateFile(f[0]))
              vm.files = _.unionBy(_.reverse(newFiles), vm.files, 'id')
            }
            if (uploadedFiles && Array.isArray(uploadedFiles)) {
              uploadedFiles[0][0].user = $rootScope.currentUser
            }

            if ($scope.displayType === 'upload') {
              if (uploadedFiles && _.isArray(uploadedFiles)) {
                vm.data = uploadedFiles
                $mdDialog.hide(vm.data)
              } else {
                $mdDialog.cancel(false)
              }
            }
          }
        }
      }
    })
  }

  $scope.uploadStoryline = (file, errFiles) => {
    $scope.uploadFiles(file, errFiles, 'storyline')
  }

  $scope.uploadChangeFiles = (file, errFiles) => {
    $scope.uploadFiles(file, errFiles, null, {
      replaceId: $scope.fileForChange.id,
      replaceName: $scope.fileForChange.name
    })
  }

  vm.getThumbnail = function (file) {
    return '/api/v1/getFile/' + file.id + '/thumbnail'
  }

  vm.cancel = function () {
    $mdDialog.cancel(false)
  }

  vm.closeDialog = async function () {
    if (vm.currentFolderId && modelName && modelId) {
      let folderMapping = $window.localStorage.getItem(
        'resource-folder-mapping'
      )
      if (folderMapping) {
        folderMapping = JSON.parse(folderMapping)
      } else {
        folderMapping = {}
      }
      if (!folderMapping[modelName]) {
        folderMapping[modelName] = {}
      }
      folderMapping[modelName][modelId] = vm.currentFolderId
      $window.localStorage.removeItem('resource-folder-mapping')
      $window.localStorage.setItem(
        'resource-folder-mapping',
        JSON.stringify(folderMapping)
      )
    }
    await vm.saveLastSeen('file', _.isArray(vm.data) ? vm.data[0] : vm.data)
    $mdDialog.hide(vm.data)
  }

  vm.search = ''
  vm.filter = {
    where: {},
    include: ['meta'],
    order: 'created DESC'
  }

  vm.extensions = getFileExtension(
    extensions,
    FileTypes,
    filetype,
    extendExtensions
  )

  vm.lastSeen = ResolvedLastSeen.map(lastSeen => {
    if (lastSeen.type === 'file') {
      return manipulateFile(lastSeen)
    }
    return lastSeen
  }).filter(lastSeen => {
    return (
      lastSeen.type !== 'file' ||
      (lastSeen.type === 'file' &&
        (!vm.extensions ||
          vm.extensions.length === 0 ||
          vm.extensions.includes(lastSeen.ext)))
    )
  })

  if (vm.extensions) {
    vm.extensions = vm.extensions.map(x => '.' + x).join(',')
  }

  vm.loadMore = function () {
    vm.fetchFiles()
  }

  vm.limit = 24
  vm.currentOffset = 0
  vm.loadMoreBtn = false
  vm.currentFolderId = null

  vm.fetchFiles = function () {
    const extensionsList = getFileExtension(
      extensions,
      FileTypes,
      filetype,
      extendExtensions
    )
    if (extensionsList) {
      vm.filter.where = {
        and: [
          {
            or: extensionsList.map(extension => ({
              originalFilename: { like: extension }
            }))
          }
        ]
      }
    }
    if (mediatype) {
      vm.filter.where = {
        and: [
          { mediatype: mediatype },
          {
            or: extensionsList.map(extension => ({
              originalFilename: { like: extension }
            }))
          }
        ]
      }
    }

    vm.filter.skip = vm.currentOffset
    vm.filter.limit = vm.limit

    vm.filter.where.originalFilename = {
      like: '.*' + vm.search + '.*',
      options: 'i'
    }

    if (!vm.filter.where.and) vm.filter.where.and = []

    vm.filter.where.container = 'Users'

    if (vm.currentFolderId === '203d8870-2e8d-4ef6-85a8-9478ef6e4d56') {
      vm.filter.where.hidden = true
    } else {
      vm.filter.where.hidden = { neq: true }
    }

    if (
      vm.currentFolderId === null ||
      vm.currentFolderId === '203d8870-2e8d-4ef6-85a8-9478ef6e4d56'
    ) {
      // remove folderId condition
      const folderIdIndex = []

      vm.filter.where.and.forEach((elem, i) => {
        if (
          (elem.folderId === null || elem.folderId !== null) &&
          elem.folderId !== undefined
        ) {
          folderIdIndex.push(i)
        }
      })
      _.pullAt(vm.filter.where.and, folderIdIndex)

      // add two "or" empty folderId condition
      vm.filter.where.and.push({
        or: [{ folderId: null }, { folderId: { exists: false } }]
      })
    } else {
      // remove two "or" empty folderId condition
      const orIndex = []

      vm.filter.where.and.forEach((elem, i) => {
        if (
          elem.or &&
          elem.or[0] &&
          (elem.or[0].folderId === null || _.isObject(elem.or[0].folderId))
        ) {
          orIndex.push(i)
        }
      })
      _.pullAt(vm.filter.where.and, orIndex)

      let index = -1
      vm.filter.where.and.forEach((elem, i) => {
        if (elem.folderId) {
          index = i
        }
      })
      // add folderId condition
      if (index === -1) {
        vm.filter.where.and.push({ folderId: vm.currentFolderId })
      } else {
        vm.filter.where.and[index].folderId = vm.currentFolderId
      }
    }

    vm.filter.include = ['user']

    if ($scope.sort.name !== null || $scope.sort.date !== null) {
      if ($scope.sort.name === true) {
        vm.filter.order = 'originalFilename ASC'
      } else if ($scope.sort.name === false) {
        vm.filter.order = 'originalFilename DESC'
      } else if ($scope.sort.date === true) {
        vm.filter.order = 'created ASC'
      } else {
        vm.filter.order = 'created DESC'
      }
    }

    Resource.find({ filter: vm.filter }, function (res) {
      const resources = res.map(file => manipulateFile(file))
      vm.files = vm.files.concat(resources)
      const filesById = _.keyBy(vm.files, 'id')
      vm.lastSeen.forEach(lastSeenFile => {
        if (!filesById[lastSeenFile.id]) {
          vm.files.push(lastSeenFile)
        }
      })
      vm.currentOffset = vm.currentOffset + vm.filter.limit
      vm.loadMoreBtn = res.length === vm.limit
    })
  }

  $scope.$watch(
    'vm.search',
    () => {
      vm.currentOffset = 0
      vm.files = []
      vm.fetchFiles()
    },
    true
  )

  if (modelName && modelId) {
    let folderMapping = $window.localStorage.getItem('resource-folder-mapping')
    if (folderMapping) {
      folderMapping = JSON.parse(folderMapping)
    } else {
      folderMapping = {}
    }
    if (folderMapping[modelName] && folderMapping[modelName][modelId]) {
      const folderId = folderMapping[modelName][modelId]
      const folderExists = resolvesFolders.find(
        folder => folder.id === folderId
      )
      if (folderExists) {
        vm.buildFolderPath(folderId)
        vm.folders = resolvesFolders.filter(
          folder => folder.parentFolderId === folderId
        )
        if (folderId) {
          vm.lastSeen = []
        }
        vm.currentFolderId = folderId
        vm.data = []
        $scope.selectedFolder = null
        vm.files = []
        vm.currentOffset = 0
      } else {
        delete folderMapping[modelName][modelId]
        $window.localStorage.removeItem('resource-folder-mapping')
        $window.localStorage.setItem(
          'resource-folder-mapping',
          JSON.stringify(folderMapping)
        )
      }
    }
  }
}

module.exports = FileDialogController
