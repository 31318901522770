/* global _ */

/** @ngInject */
function WorkorderTemplateShowController (
  $scope,
  $rootScope,
  $mdToast,
  $translate,
  $state,
  Page,
  $stateParams,
  WorkorderTemplate,
  ResolvedWorkorderTemplate,
  ResolvedGroups,
  PermissionUtils
) {
  if (_.isUndefined($stateParams.id) || $stateParams.id === '') {
    // new
    $scope.editMode = false
    $scope.title = $translate.instant('WO.TEMPLATE.NEW_WORKORDER_TEMPLATE')
  } else {
    // edit
    $scope.editMode = true
    $scope.title = ResolvedWorkorderTemplate.name
  }

  $scope.hasDestroyPermissions = PermissionUtils.isPermit(
    'WorkorderTemplate',
    'deleteById'
  )

  Page.setTitleText($scope.title)

  $scope.workorderTemplate = ResolvedWorkorderTemplate || {
    quantity: 1,
    dueDateInHours: 24,
    releasedBefore: 0,
    workorderTypeId: null,
    assignedUsers: [],
    scheduling: [],
    notifications: [],
    groups: []
  }

  $scope.groups = ResolvedGroups

  $scope.isValid = function isValid () {
    if (!$scope.workorderTemplate.product || !$scope.workorderTemplate.sku) {
      return false
    }
    if (
      !$scope.workorderTemplate.quantity ||
      $scope.workorderTemplate.quantity < 0
    ) {
      return false
    }
    if (
      !$scope.workorderTemplate.name ||
      $scope.workorderTemplate.name === ''
    ) {
      return false
    }
    if (!$scope.workorderTemplate.recordId) return false
    if (
      !$scope.workorderTemplate.dueDateInHours ||
      $scope.workorderTemplate.dueDateInHours < 0
    ) {
      return false
    }
    if (
      _.isUndefined($scope.workorderTemplate.releasedBefore) ||
      $scope.workorderTemplate.releasedBefore === null ||
      $scope.workorderTemplate.releasedBefore < 0
    ) {
      return false
    }
    if (
      $scope.workorderTemplate.scheduling &&
      $scope.workorderTemplate.scheduling.length
    ) {
      if (
        $scope.workorderTemplate.scheduling.some(schedule =>
          _.isUndefined(schedule.frequency)
        ) ||
        $scope.workorderTemplate.scheduling.some(schedule =>
          _.isUndefined(schedule.time)
        ) ||
        $scope.workorderTemplate.scheduling.some(schedule => !schedule.every) ||
        $scope.workorderTemplate.scheduling.some(
          schedule => !schedule.stopMethod
        )
      ) {
        return false
      }
    }
    return true
  }

  const mdToast = $mdToast.nextplus({
    position: $rootScope.toastLocation,
    parent: '#content-container',
    theme: 'error-toast',
    hideDelay: 2500
  })

  const validNotifications = function validNotifications () {
    if ($scope.workorderTemplate.notifications.length) {
      for (let i = 0; i < $scope.workorderTemplate.notifications.length; i++) {
        const notification = $scope.workorderTemplate.notifications[i]
        if (!notification.type) {
          $mdToast.show(mdToast)
          $mdToast.updateTextContent(
            $translate.instant('WO.TEMPLATE.ERROR.NO_TYPE_SELECTED')
          )
          return false
        }
        if (
          notification.enabled &&
          (!notification.to || !notification.to.length)
        ) {
          $mdToast.show(mdToast)
          $mdToast.updateTextContent(
            $translate.instant('WO.TEMPLATE.ERROR.NO_USERS_FOR_NOTIFICATION')
          )
          return false
        }
      }
    }
    return true
  }

  const validSchedule = function validSchedule () {
    if ($scope.workorderTemplate.scheduling.length) {
      for (let i = 0; i < $scope.workorderTemplate.scheduling.length; i++) {
        const schedule = $scope.workorderTemplate.scheduling[i]
        if (
          schedule.frequency === 'Weekly' &&
          (!schedule.days || schedule.days.length === 0)
        ) {
          $mdToast.show(mdToast)
          $mdToast.updateTextContent(
            $translate.instant('WO.TEMPLATE.ERROR.WEEK_SCHEDULE_WITHOUT_DAYS')
          )
          return false
        }
        if (schedule.frequency === 'Monthly' && !schedule.day) {
          $mdToast.show(mdToast)
          $mdToast.updateTextContent(
            $translate.instant('WO.TEMPLATE.ERROR.MONTH_SCHEDULE_WITHOUT_DAY')
          )
          return false
        }
        if (
          schedule.frequency === 'Yearly' &&
          (!schedule.day || _.isUndefined(schedule.month))
        ) {
          $mdToast.show(mdToast)
          $mdToast.updateTextContent(
            $translate.instant(
              'WO.TEMPLATE.ERROR.YEAR_SCHEDULE_WITHOUT_DAY_OR_MONTH'
            )
          )
          return false
        }
        if (schedule.stopMethod === 'quantity' && !schedule.quantity) {
          $mdToast.show(mdToast)
          $mdToast.updateTextContent(
            $translate.instant('WO.TEMPLATE.ERROR.SCHEDULE_WITHOUT_QUANTITY')
          )
          return false
        }
        if (schedule.stopMethod === 'date' && !schedule.date) {
          $mdToast.show(mdToast)
          $mdToast.updateTextContent(
            $translate.instant('WO.TEMPLATE.ERROR.SCHEDULE_WITHOUT_DATE')
          )
          return false
        }
      }
    }
    return true
  }

  $scope.save = async function save () {
    const scheduleIsValid = validSchedule()
    if (!scheduleIsValid) {
      return
    }
    const notificationIsValid = validNotifications()
    if (!notificationIsValid) {
      return
    }
    $scope.workorderTemplate.scheduling =
      $scope.workorderTemplate.scheduling.map(schedule => {
        const omitArr = []
        if (schedule.frequency !== 'Yearly') {
          omitArr.push('month')
        }
        if (
          schedule.frequency !== 'Yearly' &&
          schedule.frequency !== 'Monthly'
        ) {
          omitArr.push('day')
        }
        if (schedule.frequency !== 'Weekly') {
          omitArr.push('days')
        }
        if (schedule.stopMethod !== 'quantity') {
          omitArr.push('quantity')
        }
        if (schedule.stopMethod !== 'date') {
          omitArr.push('date')
        }
        if (omitArr.length) {
          schedule = _.omit(schedule, omitArr)
        }
        return schedule
      })
    try {
      if ($scope.editMode) {
        const attributes = _.omit($scope.workorderTemplate, [
          'id',
          'createStock'
        ])
        await WorkorderTemplate.prototype$patchAttributes(
          { id: $scope.workorderTemplate.id },
          attributes
        ).$promise
      } else {
        const data = _.omit($scope.workorderTemplate, ['id', 'createStock'])
        await WorkorderTemplate.create(data)
      }
      $state.go('app.workorder.templates')
    } catch (err) {
      console.error(err)
      $rootScope.showErrorToast('NP-1100')
    }
  }

  $scope.headerOptions = {
    icon: 'icon-file-document-edit-outline',
    template: require('app/templates/headers/simple.html'),
    title: $scope.title,
    fabButton: {}
  }
}

module.exports = WorkorderTemplateShowController
