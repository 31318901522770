/* global */
'use strict'

/** @ngInject */

function OpcUaServerEditController (
  $translate,
  $scope,
  $stateParams,
  ResolvedOpcUaServer,
  FormlyHelper,
  OpcUaServer,
  $state,
  $rootScope
) {
  const vm = this

  const errorsHandler = function errorsHandler (err) {
    console.error(err)
    let message = $translate.instant('PRODUCTION_ENTITY.OPC_UA_SERVER.ERROR')
    if (
      [
        'NAME_REQUIRED',
        'ENDPOINT_URL_REQUIRED',
        'SECURITY_MODE_REQUIRED',
        'SECURITY_POLICY_REQUIRED',
        'USER_NAME_REQUIRED',
        'PASSWORD_REQUIRED',
        'WRONG_USERNAME_OR_PASSWORD'
      ].includes(err.data.error.code)
    ) {
      message = $translate.instant(
        'PRODUCTION_ENTITY.OPC_UA_SERVER.ERRORS.' + err.data.error.code
      )
    } else if (err?.data?.error?.message) {
      message = err.data.error.message
    }
    $rootScope.showErrorToast(message)
  }

  $scope.save = function save () {
    $rootScope.loadingProgress = true
    if (ResolvedOpcUaServer) {
      OpcUaServer.prototype$patchAttributes(
        { id: ResolvedOpcUaServer.id },
        vm.opcUaServer
      )
        .$promise.then(() => {
          $rootScope.showSuccessToast(
            $translate.instant(
              'PRODUCTION_ENTITY.OPC_UA_SERVER.SAVED_SUCCESSFULLY'
            )
          )
          $rootScope.loadingProgress = false
          $state.go('app.opc-ua-server.list')
        })
        .catch(err => {
          errorsHandler(err)
          $rootScope.loadingProgress = false
        })
    } else {
      OpcUaServer.create(vm.opcUaServer)
        .$promise.then(() => {
          $rootScope.showSuccessToast(
            $translate.instant(
              'PRODUCTION_ENTITY.OPC_UA_SERVER.SAVED_SUCCESSFULLY'
            )
          )
          $rootScope.loadingProgress = false
          $state.go('app.opc-ua-server.list')
        })
        .catch(err => {
          errorsHandler(err)
          $rootScope.loadingProgress = false
        })
    }
  }

  $scope.testConnection = function testConnection () {
    $rootScope.loadingProgress = true
    OpcUaServer.testConnection({
      opcUaServer: vm.opcUaServer?.toJSON
        ? vm.opcUaServer.toJSON()
        : vm.opcUaServer
    })
      .$promise.then(() => {
        $rootScope.showSuccessToast(
          $translate.instant(
            'PRODUCTION_ENTITY.OPC_UA_SERVER.TEST_CONNECTION_SUCCESS',
            {
              name: vm.opcUaServer.name
            }
          )
        )
        $rootScope.loadingProgress = false
      })
      .catch(err => {
        errorsHandler(err)
        $rootScope.loadingProgress = false
      })
  }

  const initScreen = function initScreen () {
    vm.edit = !!$stateParams.id
    vm.opcUaServer = {
      active: true,
      name: '',
      endpointUrl: '',
      securityMode: 1,
      securityPolicy: 'None',
      username: '',
      password: ''
    }
    if (ResolvedOpcUaServer) {
      vm.opcUaServer = {
        ...vm.opcUaServer,
        ...ResolvedOpcUaServer.toJSON()
      }
    }
    const staticFields = [
      {
        key: 'active',
        type: 'switch',
        templateOptions: {
          label: $translate.instant(
            'PRODUCTION_ENTITY.OPC_UA_SERVER.FIELDS.ACTIVE'
          )
        }
      },
      {
        key: 'name',
        type: 'input',
        templateOptions: {
          label: $translate.instant(
            'PRODUCTION_ENTITY.OPC_UA_SERVER.FIELDS.NAME'
          ),
          required: true
        }
      },
      {
        key: 'endpointUrl',
        type: 'input',
        templateOptions: {
          label: $translate.instant(
            'PRODUCTION_ENTITY.OPC_UA_SERVER.FIELDS.ENDPOINT_URL'
          ),
          required: true
        }
      },
      {
        key: 'securityMode',
        type: 'select',
        templateOptions: {
          options: [
            {
              name: 1,
              value: 1
            },
            {
              name: 2,
              value: 2
            },
            {
              name: 3,
              value: 3
            }
          ],
          label: $translate.instant(
            'PRODUCTION_ENTITY.OPC_UA_SERVER.FIELDS.SECURITY_MODE'
          ),
          required: true
        }
      },
      {
        key: 'securityPolicy',
        type: 'select',
        templateOptions: {
          options: [
            {
              name: 'None',
              value: 'None'
            },
            {
              name: 'Basic256Sha256',
              value: 'Basic256Sha256'
            },
            {
              name: 'Basic256',
              value: 'Basic256'
            },
            {
              name: 'Basic128Rsa15',
              value: 'Basic128Rsa15'
            },
            {
              name: 'Aes256_Sha256_RsaPss',
              value: 'Aes256_Sha256_RsaPss'
            },
            {
              name: 'Aes128_Sha256_RsaOaep',
              value: 'Aes128_Sha256_RsaOaep'
            }
          ],
          label: $translate.instant(
            'PRODUCTION_ENTITY.OPC_UA_SERVER.FIELDS.SECURITY_POLICY'
          ),
          required: true
        }
      },
      {
        key: 'username',
        type: 'input',
        templateOptions: {
          label: $translate.instant(
            'PRODUCTION_ENTITY.OPC_UA_SERVER.FIELDS.USER_NAME'
          )
        },
        hideExpression: function hideExpression () {
          return vm.opcUaServer.securityMode === 1
        }
      },
      {
        key: 'password',
        type: 'input',
        ngModelAttrs: {
          'new-password': {
            value: 'autocomplete'
          }
        },
        templateOptions: {
          type: 'password',
          label: $translate.instant(
            'PRODUCTION_ENTITY.OPC_UA_SERVER.FIELDS.PASSWORD'
          ),
          placeholder:
            ResolvedOpcUaServer && ResolvedOpcUaServer.securityMode !== 1
              ? $translate.instant(
                  'PRODUCTION_ENTITY.OPC_UA_SERVER.FIELDS.LEAVE_BLANK_FOR_UNCHANGE'
                )
              : ''
        },
        hideExpression: function hideExpression () {
          return vm.opcUaServer.securityMode === 1
        }
      }
    ]
    $scope.fields = FormlyHelper.buildFields(staticFields, OpcUaServer)
  }

  initScreen()
  $scope.headerOptions = {
    icon: 'icon-account',
    template: require('app/templates/headers/simple.html'),
    title: vm.edit
      ? `${$translate.instant('PRODUCTION_ENTITY.OPC_UA_SERVER.TITLE')}: ${
          vm.opcUaServer.name
        }`
      : $translate.instant('PRODUCTION_ENTITY.OPC_UA_SERVER.CREATE'),
    fabButton: {}
  }
}

module.exports = OpcUaServerEditController
