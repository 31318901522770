/* global */
module.exports =
  /** @ngInject */
  function config ($stateProvider, msNavigationServiceProvider) {
    // State
    $stateProvider
      .state('app.time-logging', {
        abstract: true,
        url: '/time-logging',
        lazyLoad: $transition$ => {
          const $ocLazyLoad = $transition$.injector().get('$ocLazyLoad')
          return import(
            /* webpackChunkName: "time-logging.module" */ './time-logging.module.js'
          )
            .then(mod => {
              return $ocLazyLoad.inject(mod.default)
            })
            .catch(err => {
              throw new Error('Ooops, something went wrong, ' + err)
            })
        }
      })
      .state('app.time-logging.list', {
        url: '/:reportType/?:filter',
        params: {
          filter: {
            dynamic: true
          }
        },
        resolve: {
          ResolvedSessionData: () => {
            return null
          },
          ResolvedActiveTimeLog: (TimeLogReport, $rootScope) => {
            return new Promise(async (resolve, reject) => {
              const timeLogs = await TimeLogReport.find({
                filter: {
                  limit: 1,
                  where: {
                    end: { eq: null },
                    userId: $rootScope.currentUser.id
                  }
                }
              }).$promise
              if (timeLogs.length > 0) {
                resolve(timeLogs[0].toJSON())
              } else {
                resolve(null)
              }
            })
          },
          ResolvedBaseFilter: ($stateParams, $rootScope, UserModel) => {
            return new Promise(async (resolve, reject) => {
              const baseFilter = {
                order: 'start DESC',
                where: {
                  and: [
                    { userId: null },
                    { userId: { neq: null } },
                    { end: { neq: null } }
                  ]
                }
              }
              if ($stateParams.reportType === 'me') {
                baseFilter.where.and[0].userId = $rootScope.currentUser.id
              } else {
                if (
                  $rootScope.appSettings.usersToSeeAllTimeRecords.includes(
                    $rootScope.currentUser.id
                  )
                ) {
                  // removes the userId:null filter
                  baseFilter.where.and.shift()
                } else {
                  const userWithEmployees =
                    await UserModel.getEmployeesByUserId({
                      userId: $rootScope.currentUser.id
                    }).$promise
                  if (userWithEmployees?.employees?.length > 0) {
                    baseFilter.where.and[0].userId = {
                      inq: userWithEmployees.employees.map(e => e.id)
                    }
                  }
                }
              }
              resolve(baseFilter)
            })
          },
          ResolvedUsers: UserModel =>
            UserModel.find({
              filter: {
                fields: {
                  id: true,
                  displayName: true
                }
              }
            }).$promise
        },
        views: {
          'content@app': {
            templateUrl: 'app/modules/main/time-logging/time-logging.html',
            controller: 'TimeLoggingController',
            controllerAs: 'vm'
          }
        },
        bodyClass: 'time-logging-list'
      })

    // Navigation
    msNavigationServiceProvider.saveItem('apps.time-log-report', {
      title: 'TIME_LOGGING.TITLE',
      icon: 'icon-timer',
      hidden: false,
      weight: 5,
      acls: [{ model: 'TimeLogReport', method: 'find' }]
    })
    msNavigationServiceProvider.saveItem('apps.time-log-report.my-records', {
      title: 'TIME_LOGGING.MY_RECORDS',
      state: 'app.time-logging.list',
      stateParams: {
        reportType: 'me'
      },
      hidden: false,
      weight: 10,
      acls: [{ model: 'TimeLogReport', method: 'find' }]
    })
    msNavigationServiceProvider.saveItem(
      'apps.time-log-report.employee-records',
      {
        title: 'TIME_LOGGING.MY_EMPLOYEE_RECORDS',
        state: 'app.time-logging.list',
        stateParams: {
          reportType: 'employees'
        },
        hidden: false,
        weight: 20,
        acls: [{ model: 'TimeLogReport', method: 'find' }]
      }
    )
  }
