import angular from 'angular'

import simpleTableConfig from './simple-table.config'
import simpleTableController from './simple-table.controller'

const MODULE_NAME = 'app.components.tables.simple-table'

angular.module(MODULE_NAME, [])
  .config(simpleTableConfig)
  .controller('SimpleTableController', simpleTableController)

export default MODULE_NAME


