module.exports = {
  formDetails: {
    link: {
      type: 'string',
      translationKey: 'FORM.VARIABLES.formDetails.link',
      defaultValue: 'http://www.nextplus.io'
    },
    formName: {
      type: 'string',
      translationKey: 'FORM.VARIABLES.formDetails.formName',
      defaultValue: 'Hello'
    },
    formNumber: {
      type: 'number',
      translationKey: 'FORM.VARIABLES.formDetails.formNumber',
      defaultValue: ''
    },
    modified: {
      type: 'date',
      translationKey: 'FORM.VARIABLES.formDetails.modified',
      defaultValue: ''
    },
    created: {
      type: 'date',
      translationKey: 'FORM.VARIABLES.formDetails.created',
      defaultValue: ''
    },
    closedAt: {
      type: 'date',
      translationKey: 'FORM.VARIABLES.formDetails.closedAt',
      defaultValue: ''
    },
    status: {
      type: 'string',
      translationKey: 'FORM.VARIABLES.formDetails.status',
      defaultValue: 'Open'
    }
  },
  formCreator: {
    displayName: {
      type: 'string',
      translationKey: 'FORM.VARIABLES.formCreator.displayName',
      defaultValue: 'User Name Placeholder'
    },
    email: {
      type: 'string',
      translationKey: 'FORM.VARIABLES.formCreator.email',
      defaultValue: 'User Email Placeholder'
    }
  },
  formCloser: {
    displayName: {
      type: 'string',
      translationKey: 'FORM.VARIABLES.formCloser.displayName',
      defaultValue: ''
    },
    email: {
      type: 'string',
      translationKey: 'FORM.VARIABLES.formCloser.email',
      defaultValue: ''
    }
  },
  addressee: {
    displayName: {
      type: 'string',
      translationKey: 'FORM.VARIABLES.addressee.displayName',
      defaultValue: 'User Name Placeholder'
    },
    email: {
      type: 'string',
      translationKey: 'FORM.VARIABLES.addressee.email',
      defaultValue: 'User Email Placeholder'
    }
  },
  origin: {
    serial: {
      type: 'string',
      translationKey: 'FORM.VARIABLES.origin.serial',
      defaultValue: 'Origin S/N Placeholder'
    },
    sku: {
      type: 'string',
      translationKey: 'FORM.VARIABLES.origin.sku',
      defaultValue: 'Origin P/N Placeholder'
    }
  },
  device: {
    serial: {
      type: 'string',
      translationKey: 'FORM.VARIABLES.device.serial',
      defaultValue: 'Device S/N Placeholder'
    },
    sku: {
      type: 'string',
      translationKey: 'FORM.VARIABLES.device.sku',
      defaultValue: 'Device P/N Placeholder'
    },
    lot: {
      type: 'string',
      translationKey: 'FORM.VARIABLES.device.lot',
      defaultValue: 'Device Lot Placeholder'
    }
  },
  workorder: {
    workorderNumber: {
      type: 'string',
      translationKey: 'FORM.VARIABLES.workorder.workorderNumber',
      defaultValue: 'Work Order Number Placeholder'
    },
    customer: {
      accountName: {
        type: 'string',
        translationKey: 'WF.VARIABLES.workorder.customer.accountName',
        defaultValue: 'Account Name Placeholder'
      },
      accountNumber: {
        type: 'string',
        translationKey: 'WF.VARIABLES.workorder.customer.accountNumber',
        defaultValue: 'Account Number Placeholder'
      }
    },
    sku: {
      type: 'string',
      translationKey: 'FORM.VARIABLES.workorder.sku',
      defaultValue: 'Work Order P/N Placeholder'
    },
    npi: {
      type: 'boolean',
      translationKey: 'FORM.VARIABLES.workorder.npi',
      defaultValue: true
    }
  },
  approvalProcess: {
    submittedForApproval: {
      type: 'boolean',
      translationKey: 'FORM.VARIABLES.approvalProcess.submittedForApproval',
      defaultValue: false
    },
    approved: {
      type: 'boolean',
      translationKey: 'FORM.VARIABLES.approvalProcess.approved',
      defaultValue: false
    },
    rejected: {
      type: 'boolean',
      translationKey: 'FORM.VARIABLES.approvalProcess.rejected',
      defaultValue: false
    }
  }
}
