/* global _ */

/** @ngInject */
function InventoryTransactionController (
  $scope,
  $translate,
  $rootScope,
  Page,
  PermissionUtils,
  KendoGridHelper,
  ViewsService,
  InventoryTransaction,
  DateTimeFormatService,
  ResolvedUsers,
  htmlWork
) {
  const availableTypesOptions = [
    {
      id: 'PRODUCTION',
      name: $translate.instant('INVENTORY_TRANSACTION.TYPES.PRODUCTION')
    },
    {
      id: 'PRODUCTION',
      name: $translate.instant('INVENTORY_TRANSACTION.TYPES.ISSUE')
    }
  ]
  const initScreen = async function initScreen () {
    $scope.PermissionUtils = PermissionUtils
    $scope.users = ResolvedUsers
    $scope.usersById = _.keyBy($scope.users, 'id')
    $scope.userPerRow = {}
    $scope.title = $rootScope.title = $translate.instant(
      'INVENTORY_TRANSACTION.TITLE'
    )

    Page.setTitleText($scope.title)

    const tableColumns = [
      // DATE
      {
        uniqueId: '58cb678d-cc56-4e94-80b6-aad9d42802fc',
        field: 'created',
        translateCode: 'INVENTORY_TRANSACTION.COLUMNS.DATE',
        type: 'date',
        filterable: true,
        template: data => {
          return DateTimeFormatService.formatDateTime(data.created, 'dateTime')
        }
      },
      // USER
      {
        uniqueId: 'e8e2e8a6-0e0d-4a4c-9a7e-8f0e5f1c8e4b',
        field: 'userId',
        translateCode: 'INVENTORY_TRANSACTION.COLUMNS.USER',
        filterable: {
          mode: 'row',
          cell: {
            showOperators: false,
            template: function (args) {
              args.element.kendoDropDownList({
                filter: 'contains',
                autoBind: false,
                dataTextField: 'displayName',
                dataValueField: 'id',
                dataSource: new kendo.data.DataSource({
                  data: $scope.users
                }),
                valuePrimitive: true
              })
            }
          }
        },
        sortable: false,
        width: 'auto',
        trustedTemplate: data => {
          if (!data.userId || !$scope.usersById[data.userId]) return '--'
          $scope.userPerRow[data.userId] =
            $scope.usersById[data.userId].displayName
          return `<user-avatar
                        user-id="'${htmlWork.escapeHTMLQuotes(data.userId)}'"
                        display-name="userPerRow['${htmlWork.escapeHTMLQuotes(
                          data.userId
                        )}']"
                        show-name-inline="true"
                    ></user-avatar>`
        }
      },
      // TYPE
      {
        uniqueId: 'a7e8a9f8-1b9b-4e6d-9a5b-7b6a5e3f6e6e',
        field: 'type',
        translateCode: 'INVENTORY_TRANSACTION.COLUMNS.TYPE',
        filterable: {
          mode: 'row',
          cell: {
            showOperators: false,
            template: function (args) {
              args.element.kendoDropDownList({
                filter: 'contains',
                autoBind: false,
                dataTextField: 'name',
                dataValueField: 'id',
                dataSource: new kendo.data.DataSource({
                  data: availableTypesOptions
                }),
                valuePrimitive: true
              })
            }
          }
        },
        sortable: false,
        width: 'auto',
        template: data => {
          return data.type
            ? $translate.instant(`INVENTORY_TRANSACTION.TYPES.${data.type}`)
            : '--'
        }
      },
      // QUANTITY
      {
        uniqueId: 'bda7b938-6019-47ab-83c6-fd930a3f15de',
        field: 'quantity',
        translateCode: 'INVENTORY_TRANSACTION.COLUMNS.QUANTITY'
      },
      // FROM_SKU
      {
        uniqueId: 'd5e4e2c0-2d5c-4f9b-9f7d-5b4c4a4c6c4f',
        field: 'fromSku',
        translateCode: 'INVENTORY_TRANSACTION.COLUMNS.FROM_SKU',
        template: data => {
          return data.fromSku || '--'
        }
      },
      // FROM_SERIAL
      {
        uniqueId: '9412884b-2e8c-4fe0-a48b-278344f4e839',
        field: 'fromSerial',
        translateCode: 'INVENTORY_TRANSACTION.COLUMNS.FROM_SERIAL',
        template: data => {
          return data.fromSerial || '--'
        }
      },
      // FROM_LOT
      {
        uniqueId: '8aff0845-8d14-44a2-b417-3fe12061c2c1',
        field: 'fromLot',
        translateCode: 'INVENTORY_TRANSACTION.COLUMNS.FROM_LOT',
        template: data => {
          return data.fromLot || '--'
        }
      },
      // TO_SKU
      {
        uniqueId: 'caa60ab3-8945-486a-8d1b-819d0da3299e',
        field: 'toSku',
        translateCode: 'INVENTORY_TRANSACTION.COLUMNS.TO_SKU',
        template: data => {
          return data.toSku || '--'
        }
      },
      // TO_SERIAL
      {
        uniqueId: '4c491308-0bc1-4959-a928-f508c604be7a',
        field: 'toSerial',
        translateCode: 'INVENTORY_TRANSACTION.COLUMNS.TO_SERIAL',
        template: data => {
          return data.toSerial || '--'
        }
      },
      // TO_LOT
      {
        uniqueId: '3bc93a1c-194b-4ced-b6fa-bec3c31d0f7d',
        field: 'toLot',
        translateCode: 'INVENTORY_TRANSACTION.COLUMNS.TO_LOT',
        template: data => {
          return data.toLot || '--'
        }
      }
    ]

    const baseFilter = {
      where: {},
      order: 'created DESC'
    }
    const { defaultTableColumns } = ViewsService.generateCustomFieldColumns(
      'InventoryTransaction',
      tableColumns
    )

    const { columns, selectedViewId } =
      ViewsService.getTablesColumns(defaultTableColumns)

    const { newBaseFilter, filters } = ViewsService.getViewCustomFilters(
      selectedViewId,
      _.cloneDeep(baseFilter)
    )

    const defaultTableSetup = {
      // stateName
      // ignoreParams
      find: InventoryTransaction.find,
      count: InventoryTransaction.count,
      cleanBaseFilter: baseFilter || {},
      baseFilter: newBaseFilter,
      selectedViewId: selectedViewId,
      columns: defaultTableColumns
    }

    const defaultTableToolbarSetup = {
      // stateName
      columns: defaultTableColumns,
      currentColumnIds: columns.map(c => c.uniqueId),
      filters: filters,
      selectedViewId: selectedViewId,
      title: $scope.title
    }

    $scope.downloadFunction = () => {}

    $scope.kendoGrid = await KendoGridHelper.GridInstance(
      defaultTableSetup,
      $scope,
      columns
    )

    await $scope.kendoGrid.isBound()

    $scope.tableToolbar = await ViewsService.GridToolBarInstance(
      defaultTableToolbarSetup,
      $scope.kendoGrid,
      $scope
    )

    $scope.$apply()
  }

  initScreen()

  $scope.headerOptions = {
    icon: 'icon-file-arrow-up-down-outline',
    template: require('../../../../templates/headers/simple.html'),
    title: $scope.title,
    fabButton: {}
  }
}

module.exports = InventoryTransactionController
