/** @ngInject */
function WorkorderPriorityPullDialog (
  $scope,
  $translate,
  $mdDialog,
  $rootScope,
  $mdToast,
  Workorder,
  $state,
  locals
) {
  $scope.inProgress = false
  $scope.isServiceCall = locals.isServiceCall
  $scope.formFields = [
    {
      key: 'workorderNumber',
      type: 'input',
      className: 'layout-row',
      templateOptions: {
        type: 'text',
        required: true,
        label: $scope.isServiceCall
          ? $translate.instant('WO.SERVICE_CALL')
          : $translate.instant('WO.workorderNumber'),
        onChange: function (value, options) {}
      }
    }
  ]

  $scope.cancel = function () {
    $mdDialog.cancel()
  }
  $scope.workorder = {
    workorderNumber: ''
  }
  const openSuccessToast = function openSuccessToast (text) {
    $mdToast.show(
      $mdToast.nextplus({
        position: $rootScope.toastLocation,
        parent: 'document.body',
        theme: 'success-toast',
        hideDelay: 1500
      })
    )
    $mdToast.updateTextContent(text)
  }

  $scope.save = async function save () {
    $scope.inProgress = true
    try {
      openSuccessToast(
        $scope.isServiceCall
          ? $translate.instant('WO.PULL_SERVICE_CALL_SUCCESS')
          : $translate.instant('WO.PULL_WORKORDER_SUCCESS')
      )
      const res = await Workorder.fetchWorkorderFromPriority({
        workorderNumber: $scope.workorder.workorderNumber,
        type: $scope.isServiceCall ? 'serviceCall' : 'workorder'
      }).$promise
      if (res.success === true) {
        $state.go('app.workorder.show', { id: res.workorderId })
      } else {
        $mdToast.show(
          $mdToast.nextplus({
            position: $rootScope.toastLocation,
            parent: 'document.body',
            theme: 'error-toast',
            hideDelay: 1000 * 15
          })
        )
        $mdToast.updateTextContent(res.msg)
      }
    } catch (ex) {
      $mdToast.show(
        $mdToast.nextplus({
          position: $rootScope.toastLocation,
          parent: 'document.body',
          theme: 'error-toast',
          hideDelay: 1000 * 15
        })
      )
      $mdToast.updateTextContent(JSON.stringify(ex))
      console.error(ex)
    } finally {
      $scope.inProgress = false
    }
  }
}

module.exports = WorkorderPriorityPullDialog
