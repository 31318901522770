/* global _ angular */
'use strict'
const debug = require('debug')('nextplus:servers-list.controller')
/** @ngInject */
function OpcUaServersListController (
  $scope,
  $rootScope,
  $translate,
  PermissionUtils,
  KendoGridHelper,
  ViewsService,
  Page,
  $mdDialog,
  htmlWork,
  OpcUaServer
) {
  const hasCreatePermissions = PermissionUtils.isPermit('OpcUaServer', 'create')
  const hasEditPermissions = PermissionUtils.isPermit(
    'OpcUaServer',
    'patchAttributes'
  )
  const hasDeletePermissions = PermissionUtils.isPermit(
    'OpcUaServer',
    'deleteById'
  )
  $scope.deleteOpcUaServer = function deleteOpcUaServer (id, name) {
    $mdDialog
      .show(
        $mdDialog
          .confirm()
          .title(
            $translate.instant(
              'PRODUCTION_ENTITY.OPC_UA_SERVER.REAL_DELETE_OPC_UA_SERVER',
              {
                name
              }
            )
          )
          .clickOutsideToClose(true)
          .multiple(true)
          .parent(angular.element(document.body))
          .ok($translate.instant('BUTTONS.DELETE'))
          .cancel($translate.instant('BUTTONS.CANCEL'))
      )
      .then(
        function () {
          OpcUaServer.deleteById({ id })
            .$promise.then(() => {
              debug(`Type '${id}' deleted successfully`)
              $scope.kendoGrid.reloadData()
            })
            .catch(err => {
              console.error(err)
              $rootScope.showErrorToast('NP-8054')
              debug(
                `An error occurred while trying to delete type '${id}'`,
                err
              )
            })
        },
        function () {}
      )
  }
  const initScreen = async function initScreen () {
    $scope.title = $rootScope.title = $translate.instant(
      'PRODUCTION_ENTITY.OPC_UA_SERVER.LIST_TITLE'
    )
    Page.setTitleText($scope.title)
    $scope.PermissionUtils = PermissionUtils

    const tableColumns = [
      // NAME
      {
        uniqueId: '74a7824d-8957-4a4d-9745-d1e0428dc773',
        field: 'name',
        translateCode: 'PRODUCTION_ENTITY.OPC_UA_SERVER.FIELDS.NAME'
        // media: '(min-width: 768px)'
      },
      // ENDPOINT URL
      {
        uniqueId: 'c9a4c3d2-0b5b-4a4d-8d45-d1e0428dc773',
        field: 'endpointUrl',
        translateCode: 'PRODUCTION_ENTITY.OPC_UA_SERVER.FIELDS.ENDPOINT_URL'
        // media: '(min-width: 768px)'
      },
      // ACTIVE
      {
        uniqueId: '3b4b6b6e-1b8b-4c7b-9c4e-9f4f7b2f7a7f',
        field: 'active',
        translateCode: 'PRODUCTION_ENTITY.OPC_UA_SERVER.FIELDS.ACTIVE',
        // media: '(min-width: 768px)',
        type: 'boolean',
        filterable: true,
        sortable: true
      }
    ]

    if (hasCreatePermissions) {
      tableColumns.push(
        // ACTIONS
        {
          uniqueId: '3b4b6b6e-1b8b-4c7b-9c4e-9f4f7b2f7a7f',
          field: 'id',
          translateCode: 'COMMON.ACTIONS',
          headerAttributes: {
            class: 'actions-column-header'
          },
          // media: '(min-width: 768px)',
          filterable: false,
          sortable: false,
          trustedTemplate: data => {
            const name = htmlWork.escapeHTMLQuotes(data.name)
            let html = `<div layout="row" layout-align="start center" style="direction: ${$rootScope.dir}">`
            if (hasEditPermissions) {
              html += `<md-button ng-class="($root.isTabletOrMobile) ? ['md-fab','md-mini','md-primary'] : ['md-icon-button','show-button']" data-testid="show-${data.id}" ui-sref="app.opc-ua-server.edit({id: '${data.id}'})">
                        <md-icon md-font-icon="icon-pencil" class="s20"></md-icon>
                      </md-button>`
            }
            if (hasDeletePermissions) {
              html += `<md-button ng-class="($root.isTabletOrMobile) ? ['md-fab','md-mini'] : ['md-icon-button']" class="md-warn"
                                          ng-click="deleteOpcUaServer('${data.id}','${name}')" data-testid="delete-${data.id}">
                                <md-icon md-font-icon="icon-delete" class="s16"></md-icon>
                              </md-button>`
            }
            html += '</div>'
            return html
          }
        }
      )
    }

    const { defaultTableColumns } = ViewsService.generateCustomFieldColumns(
      'OpcUaServer',
      tableColumns
    )

    const baseFilter = {
      where: {},
      order: 'modified DESC'
    }

    const { columns, selectedViewId } =
      ViewsService.getTablesColumns(defaultTableColumns)

    const { newBaseFilter, filters } = ViewsService.getViewCustomFilters(
      selectedViewId,
      _.cloneDeep(baseFilter)
    )

    const defaultTableSetup = {
      // stateName
      // ignoreParams
      find: OpcUaServer.find,
      count: OpcUaServer.count,
      cleanBaseFilter: baseFilter,
      baseFilter: newBaseFilter,
      selectedViewId,
      columns: defaultTableColumns
    }

    const defaultTableToolbarSetup = {
      // stateName
      columns: defaultTableColumns,
      currentColumnIds: columns.map(c => c.uniqueId),
      filters,
      selectedViewId,
      title: $scope.title
    }

    $scope.downloadFunction = () => {}

    $scope.kendoGrid = await KendoGridHelper.GridInstance(
      defaultTableSetup,
      $scope,
      columns
    )

    await $scope.kendoGrid.isBound()

    $scope.tableToolbar = await ViewsService.GridToolBarInstance(
      defaultTableToolbarSetup,
      $scope.kendoGrid,
      $scope
    )

    $scope.$applyAsync()
  }

  initScreen()

  $scope.headerOptions = {
    icon: 'icon-account',
    template: require('app/templates/headers/simple.html'),
    title: $scope.title,
    fabButton: {
      template: require('app/templates/headers/fab-button.html'),
      action: null,
      icon: 'icon-plus',
      href: '',
      state: 'app.opc-ua-server.create',
      showExpression: () => hasCreatePermissions
    }
  }
}

module.exports = OpcUaServersListController
