/* global */

/** @ngInject */
function StockSKUConversionResultsDialog (
  $rootScope,
  $state,
  $scope,
  $translate,
  $mdDialog,
  locals
) {
  $scope.cancel = function cancel () {
    $mdDialog.cancel()
  }

  const initDialog = function initDialog () {
    $scope.success = locals.res
    if (locals.error) {
      const { code, details } = locals.error
      if (details.sessionId) {
        details.sessionLink = $state.href('app.workflow.session.show', {
          workflowSessionIds: details.sessionId
        })
      }

      $scope.errorMessage = $translate.instant(
        `WO.SKU_CONVERSION.ERRORS.${code}`,
        details || {},
        null,
        null,
        'sceParameters'
      )

      if (
        [
          'STOCK_ALREADY_IN_USE',
          'PART_NOT_LINK_TO_SAME_WORKFLOW',
          'STOCKS_THAT_FINISHED_PRODUCTION',
          'STOCKS_WITH_STOCK_ITEMS',
          'CANNOT_CONVERT_WO_MULTIPLE_SKU',
          'CANNOT_CONVERT_WO_TARGET_SERIALS_EXISTS',
          'STOCK_WITH_DIFFERENT_STOCK_ITEMS'
        ].includes(code)
      ) {
        $scope.details = details
      }
    }
  }

  initDialog()
}

module.exports = StockSKUConversionResultsDialog
