/** @ngInject */
function nl2br () {
  return function (value) {
    if (value !== void 0) {
      return value.replace(/\n/g, '<br>')
    }
  }
}

module.exports = nl2br
