/* global */
/** @ngInject */
function WorkflowProductionEntityTransitionController (
  $scope,
  $mdDialog,
  locals,
  $translate
) {
  $scope.items = locals.items
  $scope.sourceMode = locals.sourceMode
  $scope.targetMode = locals.targetMode
  const quantityField =
    $scope.sourceMode === 'pending'
      ? 'pendingQty'
      : $scope.sourceMode === 'checkedIn'
      ? 'checkedInQty'
      : 'checkedOutQty'
  $scope.cancel = () => {
    $mdDialog.cancel()
  }
  $scope.save = function save () {
    const manipulatedModel = {}
    Object.keys($scope.model).forEach(key => {
      const splittedKey = key.split('-')
      const stockIndex = splittedKey[0]
      const stockProperty = splittedKey[1]
      if (manipulatedModel[stockIndex]) {
        manipulatedModel[stockIndex][stockProperty] = $scope.model[key]
      } else {
        manipulatedModel[stockIndex] = {
          [stockProperty]: $scope.model[key]
        }
      }
    })
    $mdDialog.hide(Object.values(manipulatedModel))
  }
  const buildTitle = function buildTitle () {
    const parts = []

    switch ($scope.sourceMode) {
      case 'pending':
        parts.push($translate.instant('WF.WORKFLOW_PRODUCTION_ENTITY.PENDING'))
        break
      case 'checkedIn':
        parts.push(
          $translate.instant('WF.WORKFLOW_PRODUCTION_ENTITY.CHECKED_IN')
        )
        break
      case 'checkedOut':
        parts.push(
          $translate.instant('WF.WORKFLOW_PRODUCTION_ENTITY.CHECKED_OUT')
        )
        break
      default:
        break
    }

    switch ($scope.targetMode) {
      case 'pending':
        parts.push($translate.instant('WF.WORKFLOW_PRODUCTION_ENTITY.PENDING'))
        break
      case 'checkedIn':
        parts.push(
          $translate.instant('WF.WORKFLOW_PRODUCTION_ENTITY.CHECKED_IN')
        )
        break
      case 'checkedOut':
        parts.push(
          $translate.instant('WF.WORKFLOW_PRODUCTION_ENTITY.CHECKED_OUT')
        )
        break
      default:
        break
    }

    $scope.titleParts = parts
  }
  const initDialog = async function initDialog () {
    $scope.model = {}
    $scope.fields = []
    buildTitle()

    const productionEntityId =
      locals.availableProductionEntities?.length === 1
        ? locals.availableProductionEntities[0].id
        : null
    $scope.items.forEach((item, index) => {
      $scope.model[`${index}-workorderId`] = item.workorderId
      $scope.model[`${index}-workorderNumber`] = item.workorderNumber
      $scope.model[`${index}-sku`] = item.sku
      $scope.model[`${index}-sessionNodeId`] = item.sessionNodeId
      $scope.model[`${index}-stockId`] = item.stockId
      $scope.model[`${index}-sessionId`] = item.sessionId
      $scope.model[`${index}-serial`] = item.serial
      $scope.model[`${index}-displayName`] = item.displayName
      $scope.model[`${index}-quantity`] = item[quantityField]
      if ($scope.sourceMode === 'pending') {
        $scope.model[`${index}-productionEntityId`] = productionEntityId
      } else {
        $scope.model[`${index}-productionEntityId`] =
          item.productionEntityId || null
      }
      const fields = []
      if (item.isSerial) {
        fields.push(
          // SERIAL
          {
            key: `${index}-serial`,
            type: 'input',
            className: 'flex-xs-100 flex-30 m-5 layout-row',
            ngModelAttrs: {
              readonly: {
                bound: 'ng-readonly',
                attribute: 'ng-readonly'
              }
            },
            templateOptions: {
              label: $translate.instant(
                'WF.WORKFLOW_PRODUCTION_ENTITY.COLUMNS.SERIAL'
              ),
              className: 'flex forceDirLTR',
              readonly: true
            }
          }
        )
      } else {
        fields.push(
          // INDICATOR_AND_NAME
          {
            key: `${index}-displayName`,
            type: 'input',
            className: 'flex-xs-100 flex-30 m-5 layout-row',
            ngModelAttrs: {
              readonly: {
                bound: 'ng-readonly',
                attribute: 'ng-readonly'
              }
            },
            templateOptions: {
              label: $translate.instant(
                'WF.WORKFLOW_PRODUCTION_ENTITY.COLUMNS.INDICATOR_AND_NAME'
              ),
              className: 'flex forceDirLTR',
              readonly: true
            }
          }
        )
      }
      fields.push(
        // QUANTITY
        {
          key: `${index}-quantity`,
          type: 'input',
          className: 'flex-xs-100 flex-30 m-5 layout-row',
          templateOptions: {
            disabled: item.isSerial,
            type: 'number',
            min: 0,
            max: item.quantity,
            label: $translate.instant(
              'WF.WORKFLOW_PRODUCTION_ENTITY.COLUMNS.QUANTITY'
            ),
            className: 'flex forceDirLTR',
            required: true
          }
        }
      )
      fields.push(
        // PRODUCTION_ENTITY_ID
        {
          key: `${index}-productionEntityId`,
          type: 'selectWithSearch',
          className: 'flex-xs-100 flex-30 m-5 layout-row',
          templateOptions: {
            disabled: !!productionEntityId || $scope.sourceMode !== 'pending',
            label: $translate.instant(
              'WF.WORKFLOW_PRODUCTION_ENTITY.COLUMNS.PRODUCTION_ENTITY'
            ),
            className: 'flex forceDirLTR',
            options: locals.availableProductionEntities,
            required: true
          }
        }
      )
      $scope.fields.push({
        className: 'display-flex layout-xs-column layout-md-row',
        fieldGroup: fields
      })
    })
  }

  initDialog()
}

module.exports = WorkflowProductionEntityTransitionController
