require('./comment-wrapper.scss')
const commentWrapper = {
  bindings: {
    comments: '<',
    commentModel: '=',
    parentId: '='
  },
  template: require('./comment-wrapper.html'),
  /** @ngInject */
  controller: function ($scope) {
    const $ctrl = this
    $ctrl.onCreate = function (comment) {
      $ctrl.comments.unshift(comment)
      $scope.$applyAsync()
    }
    this.$onInit = async function () {}

    this.$postLink = function () {}

    this.$onChanges = async function (changes) {}

    this.$onDestroy = function () {}
  }
}
module.exports = commentWrapper
