/* global angular kendo _ */
import WorkflowCustomFieldsDialogController from './workflow-custom-fields-dialog.controller'
import { mapOrder } from 'app/helper'
const typesDeclaration = require('./constants/types.json')
const subTypesDeclaration = require('./constants/sub-types.json')
const debug = require('debug')('nextplus:fields')

/** @ngInject */
function WorkflowCustomFieldsController (
  $scope,
  $translate,
  $rootScope,
  $mdDialog,
  PermissionUtils,
  Page,
  KendoGridHelper,
  ViewsService,
  htmlWork,
  Field,
  ResolvedData,
  $state
) {
  $scope.ignoreFieldId = ResolvedData?.ignoreFieldId
    ? ResolvedData.ignoreFieldId
    : null
  $scope.selectedFields = ResolvedData?.selectedFields
    ? angular.copy(ResolvedData.selectedFields)
    : []
  $scope.editFieldId = ResolvedData?.editFieldId
    ? ResolvedData.editFieldId
    : null
  $scope.ignoreSubForms = ResolvedData?.ignoreSubForms
    ? ResolvedData.ignoreSubForms
    : false
  $scope.hideAddButton = ResolvedData?.hideAddButton
    ? ResolvedData.hideAddButton
    : false
  const isDialog = !!ResolvedData

  const hasDeletePermissions = PermissionUtils.isPermit('Field', 'deleteById')

  const hasEditPermissions = PermissionUtils.isPermit(
    'Field',
    'patchAttributes'
  )

  $scope.exists = function (item) {
    return $scope.selectedFields.indexOf(item) > -1
  }
  $scope.toggle = function (fieldId) {
    const idx = $scope.selectedFields.indexOf(fieldId)
    if (idx > -1) {
      debug(`Remove field '${fieldId}'`)
      $scope.selectedFields.splice(idx, 1)
    } else {
      debug(`Add field '${fieldId}'`)
      $scope.selectedFields.push(fieldId)
    }
  }
  $scope.save = async function () {
    if ($scope.selectedFields && $scope.selectedFields.length > 0) {
      const fields = await Field.find({
        filter: {
          where: { id: { inq: $scope.selectedFields } },
          deleted: true,
          include: ['resource']
        }
      }).$promise
      $mdDialog.hide({
        selectedFields: $scope.selectedFields,
        fields: mapOrder(fields, $scope.selectedFields, 'id')
      })
      return
    }
    $mdDialog.hide({
      selectedFields: [],
      fields: []
    })
  }

  $scope.addCustomField = function addCustomField (field = null) {
    $mdDialog
      .show({
        controller: WorkflowCustomFieldsDialogController,
        template: require('./workflow.custom-fields.dialog.html'),
        parent: angular.element(document.body),
        targetEvent: '',
        multiple: true,
        locals: {
          removeSubForm: $scope.ignoreSubForms,
          field: field || null,
          fieldIsUsed: field ? $scope.selectedFields.includes(field.id) : false
        },
        resolve: {
          ResolvedTables: Table => Table.find({}).$promise,
          ResolveFileParser: FileParser =>
            FileParser.find({ filter: { fields: { id: true, name: true } } })
              .$promise
        },
        clickOutsideToClose: false
      })
      .then(
        function (data) {
          debug('Close create field dialog with data', data)
          if (data) {
            if (isDialog && !$scope.selectedFields.includes(data.id)) {
              const replace = data.replace
              delete data.replace
              if (replace) {
                const index = $scope.selectedFields.indexOf(field.id)
                if (index !== -1) {
                  $scope.selectedFields[index] = data.id
                } else {
                  $scope.selectedFields.push(data.id)
                }
              } else {
                $scope.selectedFields.push(data.id)
              }
            }
            $scope.kendoGrid.reloadData()
          }
        },
        function () {
          debug('Close create field dialog without save')
        }
      )
  }
  $scope.editCustomField = async function editCustomField (id) {
    const field = await Field.findOne({
      filter: { where: { id }, include: ['resource'] }
    }).$promise
    if (field) {
      $scope.addCustomField(field)
    }
  }

  $scope.cancel = () => $mdDialog.hide()

  $scope.deleteCustomField = function deleteCustomField (fieldId) {
    const currentRow = $scope.kendoGrid.instance._data.find(
      row => row.id === fieldId
    )
    $mdDialog
      .show(
        $mdDialog
          .confirm()
          .title(
            $translate.instant('Field.FIELD_DELETE_MESSAGE', {
              title: currentRow.title
            })
          )
          .targetEvent()
          .clickOutsideToClose(true)
          .multiple(true)
          .parent(angular.element(document.body))
          .ok($translate.instant('BUTTONS.DELETE'))
          .cancel($translate.instant('BUTTONS.CANCEL'))
      )
      .then(
        function () {
          Field.destroyById({ id: fieldId })
            .$promise.then(() => {
              const index = $scope.selectedFields.indexOf(fieldId)
              if (index !== -1) {
                $scope.selectedFields.splice(index, 1)
              }
              debug(`Field '${fieldId}' deleted successfully`)
              $scope.kendoGrid.reloadData()
            })
            .catch(err => {
              console.error(err)
              let message = 'NP-2020'
              if (err?.data?.error?.code === 'FIELD_IN_USE') {
                message = $translate.instant('Field.DIALOG.FIELD_IN_USE')
              }
              $rootScope.showErrorToast(message)
              debug(
                `An error occurred while trying to delete a field '${fieldId}'`,
                err
              )
            })
        },
        function () {}
      )
  }

  const initScreen = async function initScreen () {
    $scope.title = $rootScope.title = $translate.instant('WF.CUSTOM_FIELDS')
    const stateName = $state.$current.name
    if (isDialog) {
      stateName.concat('.dialog')
    }
    Page.setTitleText($scope.title)
    $scope.PermissionUtils = PermissionUtils

    const types = typesDeclaration.map(type => {
      type.name = $translate.instant(type.name)
      return type
    })

    const subTypes = subTypesDeclaration.map(subType => {
      subType.name = $translate.instant(subType.name)
      return subType
    })

    const tableColumns = [
      // TITLE
      {
        uniqueId: 'b4a711d6-0852-4919-9dcd-38bc9701580f',
        field: 'title',
        translateCode: 'Field.NAME',
        template: data => {
          return data.title
        }
        // media: '(min-width: 768px)',
      },
      // TYPE
      {
        uniqueId: '9980502b-9e1c-4871-bde7-802a5f9dbb6d',
        field: 'type',
        translateCode: 'Field.type',
        type: 'string',
        values: types.map(o => ({ text: o.name, value: o.id })),
        // media: '(min-width: 768px)',
        filterable: {
          mode: 'row',
          cell: {
            showOperators: false,
            operator: 'eq',
            suggestionOperator: 'eq',
            template: function (args) {
              args.element.kendoDropDownList({
                filter: 'contains',
                autoBind: false,
                dataTextField: 'name',
                dataValueField: 'id',
                template: `<div style="display:flex; flex-direction: row; padding: 1rem 0;">
                            <span class="grid-select-filter-css" style="--icon: '#:data.iconCode#';"></span>
                            <h5 style="margin:0;">#:data.name#</h5> 
                          </div>`,
                dataSource: new kendo.data.DataSource({
                  data: types
                }),
                valuePrimitive: true
              })
            }
          }
        },
        trustedTemplate: data => {
          const valueType = types.find(ty => ty.id === data.type)
          return valueType
            ? `<div layout="row" layout-align="start center">
                      <md-icon md-font-icon="${htmlWork.escapeHTMLQuotes(
                        valueType.icon
                      )}" style="margin:0 5px;"></md-icon>
                      <span>${htmlWork.htmlEncode(valueType.name)}</span>
                    </div>`
            : htmlWork.htmlEncode(data.type)
        }
      },
      // SUB-TYPE
      {
        uniqueId: '9980502b-9e1c-4871-bde7-123a5f9dbb6d',
        field: 'subtype',
        translateCode: 'Field.subtype',
        type: 'string',
        values: subTypes.map(o => ({ text: o.name, value: o.id })),
        // media: '(min-width: 768px)',
        filterable: {
          mode: 'row',
          cell: {
            showOperators: false,
            operator: 'eq',
            suggestionOperator: 'eq',
            template: function (args) {
              args.element.kendoDropDownList({
                filter: 'contains',
                autoBind: false,
                dataTextField: 'name',
                dataValueField: 'id',
                template: `<div style="display:flex; flex-direction: row; padding: 1rem 0;">
                            <span class="grid-select-filter-css" style="--icon: '#:data.iconCode#';"></span>
                            <h5 style="margin:0;">#:data.name#</h5> 
                          </div>`,
                dataSource: new kendo.data.DataSource({
                  data: subTypes
                }),
                valuePrimitive: true
              })
            }
          }
        },
        trustedTemplate: data => {
          if (_.isNil(data.subtype)) return ''
          const valueType = subTypes.find(ty => ty.id === data.subtype)
          return valueType
            ? `<div layout="row" layout-align="start center">
                      <md-icon md-font-icon="${htmlWork.htmlEncode(
                        valueType.icon
                      )}" style="margin:0 5px;"></md-icon>
                      <span>${htmlWork.htmlEncode(valueType.name)}</span>
                    </div>`
            : htmlWork.htmlEncode(data.subtype)
        }
      },
      // HELP TEXT
      {
        uniqueId: 'd33a343b-9051-4b63-b655-41795d45f55a',
        field: 'helpText',
        translateCode: 'Field.helpText',
        type: 'string',
        filterable: true,
        hidden: true
      },
      // PLACEHOLDER
      {
        uniqueId: '37d7f763-10cb-4893-a6ab-df1f684b1d70',
        field: 'placeholder',
        translateCode: 'Field.placeholder',
        type: 'string',
        filterable: true,
        hidden: true
      },
      // PATTERN
      {
        uniqueId: 'd8224e0a-1126-487a-888a-6f8c47fabbaf',
        field: 'pattern',
        translateCode: 'Field.pattern',
        type: 'string',
        filterable: true,
        hidden: true
      },
      // DEFAULT VALUE
      {
        uniqueId: 'a379c038-d830-4714-93d7-fecc2fa97a43',
        field: 'defaultValue',
        translateCode: 'Field.defaultValue',
        type: 'string',
        filterable: true,
        hidden: true
      },
      // MIN
      {
        uniqueId: 'e97c33be-20f4-4777-80e5-74030feb4592',
        field: 'min',
        translateCode: 'Field.min',
        type: 'number',
        filterable: true,
        hidden: true
      },
      // MAX
      {
        uniqueId: '1aedbf93-f49c-479d-8b76-4e34d6e3cdf1',
        field: 'max',
        translateCode: 'Field.max',
        type: 'number',
        filterable: true,
        hidden: true
      },
      // REQUIRED
      {
        uniqueId: 'b4a711d6-0852-4919-9dcd-38b22701580f',
        field: 'required',
        translateCode: 'Field.required',
        type: 'boolean',
        filterable: {
          cell: {
            operator: 'eq',
            suggestionOperator: 'eq'
          }
        }
      },
      // FIELD ID
      {
        uniqueId: 'a4a711d6-0852-4919-9dcd-38b22701580f',
        field: 'id',
        translateCode: 'Field.fieldId',
        type: 'string',
        filterable: true,
        sortable: true,
        hidden: true
      },
      // ACTIONS
      {
        uniqueId: '06bd9c06-9ea6-4138-8f57-9f47ab1ab768',
        field: 'id',
        translateCode: 'WF.ACTIONS',
        filterable: false,
        sortable: false,
        // media: '(min-width: 768px)',
        trustedTemplate: data => {
          let html = `<div layout="row" layout-align="start center" style="direction: ${$rootScope.dir}">`
          if (isDialog) {
            html += `<md-checkbox style="margin-bottom: 0px;" ng-checked="exists('${data.id}')" data-testid="check-${data.id}" ng-click="toggle('${data.id}')">
            </md-checkbox>`
          }
          if (hasEditPermissions) {
            html += `<md-button  ng-class="($root.isTabletOrMobile) ? ['md-fab','md-mini','md-primary'] : ['md-icon-button']"
                                ng-click="editCustomField('${data.id}')" data-testid="edit-${data.id}">
                      <md-icon md-font-icon="icon-pencil" class="s16"></md-icon>
                    </md-button>`
          }
          if (hasDeletePermissions) {
            html += `<md-button ng-class="($root.isTabletOrMobile) ? ['md-fab','md-mini'] : ['md-icon-button']" class="md-warn"
                                ng-click="deleteCustomField('${data.id}')" data-testid="delete-${data.id}">
                      <md-icon md-font-icon="icon-delete" class="s16"></md-icon>
                    </md-button>`
          }
          html += '</div>'
          return html
        }
      }
    ]

    const basicFields = {
      id: true,
      type: true,
      subtype: true,
      title: true,
      created: true,
      required: true,
      translations: true,
      fallbackStatus: true,
      helpText: true,
      placeholder: true,
      pattern: true,
      defaultValue: true,
      min: true,
      max: true
    }

    const { defaultTableColumns, customFieldsDisplay } =
      ViewsService.generateCustomFieldColumns('Field', tableColumns)

    const baseFilter = {
      where: {
        deletedAt: null,
        isTemplate: { neq: true }
      },
      order: 'modified DESC',
      include: ['user'],
      fields: _.merge(basicFields, customFieldsDisplay)
    }

    if ($scope.editFieldId) {
      baseFilter.where.id = $scope.editFieldId
    } else if ($scope.ignoreSubForms) {
      baseFilter.where.type = { neq: 'subForm' }
    }
    if ($scope.ignoreFieldId) {
      baseFilter.where.id = { neq: $scope.ignoreFieldId }
    }

    const { columns, selectedViewId } = ViewsService.getTablesColumns(
      defaultTableColumns,
      stateName
    )

    const { newBaseFilter, filters } = ViewsService.getViewCustomFilters(
      selectedViewId,
      _.cloneDeep(baseFilter),
      stateName
    )

    const defaultTableSetup = {
      stateName,
      ignoreParams: isDialog,
      find: Field.find,
      count: Field.count,
      cleanBaseFilter: baseFilter,
      baseFilter: newBaseFilter,
      selectedViewId,
      columns: defaultTableColumns
    }

    const defaultTableToolbarSetup = {
      stateName,
      columns: defaultTableColumns,
      currentColumnIds: columns.map(c => c.uniqueId),
      filters,
      selectedViewId,
      title: $scope.title
    }

    $scope.downloadFunction = () => {}

    $scope.kendoGrid = await KendoGridHelper.GridInstance(
      defaultTableSetup,
      $scope,
      columns
    )

    await $scope.kendoGrid.isBound()

    $scope.tableToolbar = await ViewsService.GridToolBarInstance(
      defaultTableToolbarSetup,
      $scope.kendoGrid,
      $scope
    )

    $scope.$applyAsync()
  }

  initScreen()

  $scope.headerOptions = {
    icon: 'icon-file-document-box',
    template: require('app/templates/headers/simple.html'),
    title: $translate.instant('WF.CUSTOM_FIELDS'),
    fabButton: {
      template: require('app/templates/headers/fab-button.html'),
      action: $scope.addCustomField,
      icon: 'icon-plus',
      href: '',
      state: '',
      showExpression: () => true
    }
  }
}

module.exports = WorkflowCustomFieldsController
