/* global */
/** @ngInject */
function EditShiftDialogController (
  $scope,
  $translate,
  $mdDialog,
  locals,
  DateTimeFormatService,
  Shift,
  $rootScope
) {
  $scope.cancel = function cancel () {
    $mdDialog.cancel()
  }

  $scope.save = async function save () {
    Shift.prototype$patchAttributes(
      { id: locals.shift.id },
      {
        startTime: $scope.model.startTime,
        endTime: $scope.model.endTime
      }
    )
      .$promise.then(result => {
        $rootScope.showSuccessToast(
          $translate.instant('SHIFT.SHIFT_UPDATED_SUCCESS')
        )
        $mdDialog.hide(result)
      })
      .catch(error => {
        let message = $translate.instant('SHIFT.SHIFTS.ERRORS.GENERAL_ERROR')
        if (error.data.error.code === 'START_TIME_AFTER_END_TIME') {
          message = $translate.instant(
            'SHIFT.SHIFTS.ERRORS.START_TIME_AFTER_END_TIME'
          )
        }
        $rootScope.showErrorToast(message)
      })
  }

  const initDialog = function initDialog () {
    $scope.title = $translate.instant('SHIFT.EDIT_SHIFT_DIALOG.TITLE', {
      productionEntityName: locals.productionEntityName,
      date: DateTimeFormatService.formatDateTime(
        locals.shift.startTime,
        'date'
      ),
      shiftName: locals.shiftName
    })
    $scope.model = {
      startTime: locals.shift.startTime,
      endTime: locals.shift.endTime,
      isDefault: locals.shift.isDefault
    }
    $scope.fields = [
      // DEFAULT_SHIFT_WARNING
      {
        template: `
        <ms-info-bar type="warning">
          <span class="info-header" translate="PAY_ATTENTION"></span>
          <span class="info-text" translate="SHIFT.DEFAULT_SHIFT_WARNING"></span>
        </ms-info-bar>`,
        hideExpression: function () {
          return !$scope.model.isDefault
        }
      },
      {
        key: 'startTime',
        type: 'dateTimePicker',
        templateOptions: {
          label: $translate.instant('SHIFT.SHIFT_TYPES.FIELDS.START_TIME')
        }
      },
      {
        key: 'endTime',
        type: 'dateTimePicker',
        templateOptions: {
          label: $translate.instant('SHIFT.SHIFT_TYPES.FIELDS.END_TIME')
        }
      }
    ]
  }

  initDialog()
}

module.exports = EditShiftDialogController
