module.exports = {
  today: { type: 'today', translationKey: 'today', defaultValue: new Date() },
  workflow: {
    name: {
      type: 'string',
      translationKey: 'WF.VARIABLES.workflow.name',
      defaultValue: 'Workflow Name Placeholder'
    }
  },
  workorder: {
    workorderNumber: {
      type: 'string',
      translationKey: 'WF.VARIABLES.workorder.workorderNumber',
      defaultValue: 'Work Order Number Placeholder'
    },
    quantity: {
      type: 'number',
      translationKey: 'WF.VARIABLES.workorder.quantity',
      defaultValue: 1
    },
    name: {
      type: 'string',
      translationKey: 'WF.VARIABLES.workorder.name',
      defaultValue: 'Work Order Name Placeholder'
    },
    customer: {
      accountName: {
        type: 'string',
        translationKey: 'WF.VARIABLES.workorder.customer.accountName',
        defaultValue: 'Account Name Placeholder'
      },
      accountNumber: {
        type: 'string',
        translationKey: 'WF.VARIABLES.workorder.customer.accountNumber',
        defaultValue: 'Account Number Placeholder'
      }
    },
    sku: {
      type: 'string',
      translationKey: 'WF.VARIABLES.workorder.sku',
      defaultValue: 'Work Order P/N Placeholder'
    },
    npi: {
      type: 'boolean',
      translationKey: 'WF.VARIABLES.workorder.npi',
      defaultValue: true
    },
    kitItems: {
      type: 'kitArray',
      translationKey: 'WF.VARIABLES.workorder.kitItems',
      defaultValue: []
    },
    groups: {
      type: 'array',
      translationKey: 'WF.VARIABLES.workorder.groups',
      defaultValue: []
    }
  },
  sessionId: {
    type: 'string',
    translationKey: 'WF.VARIABLES.sessionId',
    defaultValue: '11111111-1111-1111-1111-111111111111'
  },
  session: {
    quantity: {
      type: 'number',
      translationKey: 'WF.VARIABLES.session.quantity',
      defaultValue: 1
    },
    validQuantity: {
      type: 'number',
      translationKey: 'WF.VARIABLES.session.validQuantity',
      defaultValue: 1
    },
    invalidQuantity: {
      type: 'number',
      translationKey: 'WF.VARIABLES.session.invalidQuantity',
      defaultValue: 0
    }
  },
  unit: {
    serial: {
      type: 'string',
      translationKey: 'WF.VARIABLES.unit.serial',
      defaultValue: 'Unit S/N Placeholder'
    },
    sku: {
      type: 'string',
      translationKey: 'WF.VARIABLES.unit.sku',
      defaultValue: 'Unit P/N Placeholder'
    },
    rev: {
      type: 'string',
      translationKey: 'WF.VARIABLES.unit.rev',
      defaultValue: 'Unit Rev Placeholder'
    }
  },
  currentUser: {
    firstName: {
      type: 'string',
      translationKey: 'WF.VARIABLES.currentUser.firstName',
      defaultValue: 'User First Name Placeholder'
    },
    lastName: {
      type: 'string',
      translationKey: 'WF.VARIABLES.currentUser.lastName',
      defaultValue: 'User Last Name Placeholder'
    },
    displayName: {
      type: 'string',
      translationKey: 'WF.VARIABLES.currentUser.displayName',
      defaultValue: 'User Name Placeholder'
    },
    email: {
      type: 'string',
      translationKey: 'WF.VARIABLES.currentUser.email',
      defaultValue: 'User Email Placeholder'
    },
    username: {
      type: 'string',
      translationKey: 'WF.VARIABLES.currentUser.username',
      defaultValue: 'User Name Placeholder'
    }
  }
}
