/* global moment */

/** @ngInject */
function ReleaseLogsDialogController (
  $scope,
  $mdDialog,
  locals,
  $translate,
  PermissionUtils,
  WorkflowUtilsService,
  htmlWork,
  workflowReleaseLogs,
  DateTimeFormatService
) {
  $scope.canEdit = PermissionUtils.isPermit('Workflow', 'create')
  if (locals.workflow) {
    $scope.workflowId = locals.workflow.id
    $scope.workflowVersion = $translate.instant(
      'WF.RELEASE_LOGS_DIALOG.VERSION',
      { version: locals.workflow.normalizedVersion }
    )
  }
  workflowReleaseLogs = workflowReleaseLogs.sort(
    (a, b) => new Date(b.releasedAt) - new Date(a.releasedAt)
  )
  $scope.releasedLogs = workflowReleaseLogs.map(releaseLog => {
    releaseLog.releasedDetails = `${$translate.instant(
      'WF.RELEASE_LOGS_DIALOG.RELEASE_AT'
    )} ${DateTimeFormatService.formatDateTime(
      releaseLog.releasedAt,
      'date'
    ).toString()} ${$translate.instant('WF.RELEASE_LOGS_DIALOG.BY')} ${
      releaseLog.releasedByDisplayName || 'Unknown'
    }`
    releaseLog.changeTypeName = $translate.instant(
      `WF.RELEASE_LOGS_DIALOG.CHANGE_TYPES.${releaseLog.type}`
    )
    releaseLog.description = htmlWork.nl2br(
      htmlWork.htmlEncode(releaseLog.description),
      false,
      true
    )
    return releaseLog
  })

  $scope.cancel = () => $mdDialog.cancel()
}
module.exports = ReleaseLogsDialogController
