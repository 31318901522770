/** @ngInject */
function msSplashScreenDirective ($animate, $timeout) {
  return {
    restrict: 'E',
    link: function (scope, iElement) {
      const splashScreenRemoveEvent = scope.$on(
        'msSplashScreen::remove',
        function () {
          $animate.leave(iElement).then(function () {
            // De-register scope event
            splashScreenRemoveEvent()

            // Null-ify everything else
            scope = iElement = null
          })
        }
      )
    }
  }
}

module.exports = msSplashScreenDirective
