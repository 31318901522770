require('./comment-edit.scss')
/** @ngInject */
const commentEdit = {
  bindings: {
    ngModel: '=',
    ngDisabled: '<',
    onSave: '&',
    onCancel: '&',
    parentId: '=',
    commentModel: '='
  },
  template: require('./comment-edit.html'),
  controller: function ($translate, $element) {
    const $ctrl = this
    $ctrl.uploadOptions = { hidden: true, direct: true }
    $ctrl.tinymceOptions = {
      menubar: false,
      placeholder: $translate.instant('COMMENT.PLACEHOLDER'),
      height: 170,
      onInit: function (editor) {
        editor.contentDocument.addEventListener('keydown', ctrlEnterListener)
      }
    }
    $ctrl.cancel = () => {
      $ctrl.onCancel()
    }
    $ctrl.save = () => {
      $ctrl.onSave()
    }
    const ctrlEnterListener = function (event) {
      // Check if the "Ctrl" key is pressed and the key is "Enter"
      if ((event.ctrlKey || event.metaKey) && event.key === 'Enter') {
        event.preventDefault()
        event.stopPropagation()
        $ctrl.save()
      }
    }

    this.$onInit = async function () {}

    this.$postLink = function () {}

    this.$onChanges = async function (changes) {}

    this.$onDestroy = function () {}
  }
}
module.exports = commentEdit
