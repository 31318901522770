module.exports = {
  workorder: {
    workorderNumber: {
      type: 'string',
      translationKey: 'WO.TEMPLATE.VARIABLES.workorder.workorderNumber',
      defaultValue: 'Work Order Number Placeholder'
    },
    customer: {
      accountName: {
        type: 'string',
        translationKey: 'WF.VARIABLES.workorder.customer.accountName',
        defaultValue: 'Account Name Placeholder'
      },
      accountNumber: {
        type: 'string',
        translationKey: 'WF.VARIABLES.workorder.customer.accountNumber',
        defaultValue: 'Account Number Placeholder'
      }
    },
    workorderName: {
      type: 'string',
      translationKey: 'WO.TEMPLATE.VARIABLES.workorder.workorderName',
      defaultValue: 'Work Order Name Placeholder'
    },
    quantity: {
      type: 'number',
      translationKey: 'WO.TEMPLATE.VARIABLES.workorder.quantity',
      defaultValue: 1
    },
    link: {
      type: 'string',
      translationKey: 'WO.TEMPLATE.VARIABLES.workorder.link',
      defaultValue: 'https://nextplus.io'
    }
  },
  addressee: {
    firstName: {
      type: 'string',
      translationKey: 'WO.TEMPLATE.VARIABLES.addressee.firstName',
      defaultValue: 'User First Name Placeholder'
    },
    lastName: {
      type: 'string',
      translationKey: 'WO.TEMPLATE.VARIABLES.addressee.lastName',
      defaultValue: 'User Last Name Placeholder'
    },
    displayName: {
      type: 'string',
      translationKey: 'WO.TEMPLATE.VARIABLES.addressee.displayName',
      defaultValue: 'User Display Name Placeholder'
    },
    email: {
      type: 'string',
      translationKey: 'WO.TEMPLATE.VARIABLES.addressee.email',
      defaultValue: 'User Email Placeholder'
    },
    username: {
      type: 'string',
      translationKey: 'WO.TEMPLATE.VARIABLES.addressee.username',
      defaultValue: 'User Username Placeholder'
    }
  }
}
