import AsBuiltController from './asbuilt/asbuild.controller'
import AsBuiltTreeController from './asbuilt/tree/asbuild.tree.controller'
import AsBuiltTableController from './asbuilt/table/asbuild.table.controller'
import ContentPackageSessionController from './package-session-report/package-session-report'
import WorkflowSessionController from './workflow-session-report/workflow-session-report'
import WorkflowSessionItemListController from './workflow-session-items-report/workflow-session-items-report'
import ContentPackageViewsController from './package-views-report/package-views-report'
import FormReportController from './forms-report/form-report.controller'
import TranslationsController from './translations-report/translations-report'
import InventoryTransactionController from './inventory-transaction/inventory-transaction.controller'
import angular from 'angular'

const MODULE_NAME = 'app.reports'

angular
  .module(MODULE_NAME, ['ngSanitize', 'ui.tree'])
  .config(config)
  .controller('AsBuiltController', AsBuiltController)
  .controller('AsBuiltTreeController', AsBuiltTreeController)
  .controller('AsBuiltTableController', AsBuiltTableController)
  .controller(
    'ContentPackageSessionController',
    ContentPackageSessionController
  )
  .controller('WorkflowSessionController', WorkflowSessionController)
  .controller(
    'WorkflowSessionItemListController',
    WorkflowSessionItemListController
  )
  .controller('ContentPackageViewsController', ContentPackageViewsController)
  .controller('FormReportController', FormReportController)
  .controller('TranslationsController', TranslationsController)
  .controller('InventoryTransactionController', InventoryTransactionController)

/** @ngInject */
function config ($stateProvider, msApiProvider, msNavigationServiceProvider) {
  // State
  $stateProvider
    .state('app.reports', {
      abstract: true,
      url: '/reports'
    })
    .state('app.reports.asbuilt', {
      url: '/asbuilt/?:filter',
      params: {
        filter: {
          dynamic: true
        }
      },
      views: {
        'content@app': {
          template: require('app/modules/components/tables/kendo-grid/grid.html'),
          controller: 'AsBuiltController as vm'
        }
      },
      bodyClass: 'workorder stock-view',
      resolve: {}
    })
    .state('app.reports.content-package-session-list', {
      url: '/content-package-session-list/?:filter',
      params: {
        filter: {
          dynamic: true
        }
      },
      views: {
        'content@app': {
          template: require('app/modules/components/tables/kendo-grid/grid.html'),
          controller: 'ContentPackageSessionController',
          controllerAs: 'vm'
        }
      },
      bodyClass: 'content-package content-package-list',
      resolve: {
        ResolvedUsers: UserModel =>
          UserModel.find({
            filter: { fields: { id: true, firstName: true, lastName: true } }
          }).$promise,
        ResolvedContentPackages: (
          ContentPackage,
          MultiTranslateService,
          $rootScope
        ) =>
          ContentPackage.find({
            filter: {
              fields: {
                id: true,
                name: true,
                translations: true,
                fallbackStatus: true
              }
            }
          }).$promise.then(res =>
            res.map(contentPackage => {
              contentPackage = MultiTranslateService.getForView(
                ContentPackage,
                $rootScope.currentLang,
                contentPackage
              )
              return contentPackage
            })
          )
      }
    })
    .state('app.reports.workflow-session-list', {
      url: '/workflow-session-list/?:filter',
      params: {
        filter: {
          dynamic: true
        }
      },
      views: {
        'content@app': {
          template: require('app/modules/components/tables/kendo-grid/grid.html'),
          controller: 'WorkflowSessionController',
          controllerAs: 'vm'
        }
      },
      bodyClass: 'content-package content-package-list',
      resolve: {
        ResolvedUsers: UserModel =>
          UserModel.find({
            filter: { fields: { id: true, firstName: true, lastName: true } }
          }).$promise,
        ResolvedWorkflow: (Workflow, MultiTranslateService, $rootScope) =>
          Workflow.find({
            filter: {
              fields: {
                id: true,
                name: true,
                translations: true,
                fallbackStatus: true
              }
            }
          }).$promise.then(res =>
            res.map(workflow => {
              workflow = MultiTranslateService.getForView(
                Workflow,
                $rootScope.currentLang,
                workflow
              )
              return workflow
            })
          )
      }
    })
    .state('app.reports.workflow-session-item-list', {
      url: '/workflow-session-item-list/?:filter',
      params: {
        filter: {
          dynamic: true
        }
      },
      views: {
        'content@app': {
          template: require('app/modules/components/tables/kendo-grid/grid.html'),
          controller: 'WorkflowSessionItemListController',
          controllerAs: 'vm'
        }
      },
      bodyClass: 'content-package content-package-list',
      resolve: {}
    })
    .state('app.reports.content-package-views-list', {
      url: '/package-views-list/?:filter',
      params: {
        filter: {
          dynamic: true
        }
      },
      views: {
        'content@app': {
          template: require('app/modules/components/tables/kendo-grid/grid.html'),
          controller: 'ContentPackageViewsController',
          controllerAs: 'vm'
        }
      },
      bodyClass: 'content-package content-package-list',
      resolve: {}
    })
    .state('app.reports.asbuilt.table', {
      url: '/:id/table',
      views: {
        'content@app': {
          template: require('../workflow/session/report/workflow-session-item.html'),
          controller: 'AsBuiltTableController as vm'
        }
      },
      resolve: {
        ReportRes: function ($q, $translate, $stateParams, Stock) {
          const lang = $translate.use()

          const deferred = $q.defer()
          Stock.generateAsBuiltReport(
            { stockIds: [$stateParams.id], lang },
            function (res) {
              deferred.resolve(res)
            }
          )
          return deferred.promise
        }
      },
      bodyClass: 'workorder workorder-show workorder-table'
    })
    .state('app.reports.asbuilt.tree', {
      url: '/:id/tree',
      views: {
        'content@app': {
          template: require('./asbuilt/tree/asbuilt-tree.html'),
          controller: 'AsBuiltTreeController as vm'
        }
      },
      resolve: {
        ReportRes: function ($q, $stateParams, Stock) {
          const deferred = $q.defer()
          Stock.generateAsBuiltReport(
            { stockIds: [$stateParams.id], view: 'tree' },
            function (res) {
              deferred.resolve(res.report)
            }
          )
          return deferred.promise
        }
      },
      bodyClass: 'workorder workorder-show workorder-tree'
    })
    .state('app.reports.translations', {
      url: '/translations/?:modelName',
      params: {
        modelName: {
          dynamic: true
        }
      },
      views: {
        'content@app': {
          template: require('./translations-report/translations-report.html'),
          controller: 'TranslationsController as vm'
        }
      },
      bodyClass: 'workorder stock-view',
      resolve: {}
    })
    .state('app.reports.form', {
      url: '/form/:id/?:openViewDialog/?:filter',
      params: {
        filter: {
          dynamic: true
        }
      },
      views: {
        'content@app': {
          template: require('app/modules/components/tables/kendo-grid/grid.html'),
          controller: 'FormReportController as vm'
        }
      },
      bodyClass: 'form-report',
      resolve: {
        redirect: ($state, $stateParams, $transitions) => {
          let previousId = null
          $transitions.onStart({}, transition => {
            const toParams = transition.params('to')
            const newId = toParams.id

            if (
              toParams.filter &&
              newId &&
              newId !== previousId &&
              previousId
            ) {
              previousId = newId
              return new Promise(async (resolve, reject) => {
                resolve(
                  $state.go('app.reports.form', {
                    id: newId,
                    filter: ''
                  })
                )
              })
            } else {
              previousId = newId
            }
          })
        },
        ResolvedData: (
          $rootScope,
          $state,
          $stateParams,
          FormUtils,
          Form,
          Field,
          MultiTranslateService
        ) => {
          if ($stateParams.id && $stateParams.id !== '') {
            const openViewDialog = $stateParams.openViewDialog || false
            return Form.getFormData({
              formId: $stateParams.id
            }).$promise.then(
              async ({ form, fields, users, productionEntities }) => {
                if (!FormUtils.isPermit('view', form)) {
                  return $state.go('app.404')
                }
                return {
                  form,
                  fields: fields.map(field =>
                    MultiTranslateService.getForView(
                      Field,
                      $rootScope.currentLang,
                      field
                    )
                  ),
                  users,
                  openViewDialog,
                  productionEntities
                }
              }
            )
          }
          return null
        }
      }
    })
    .state('app.reports.inventory-transaction', {
      url: '/inventory-transaction/?:filter',
      params: {
        filter: {
          dynamic: true
        }
      },
      views: {
        'content@app': {
          template: require('../../components/tables/kendo-grid/grid.html'),
          controller: InventoryTransactionController
        }
      },
      resolve: {
        ResolvedUsers: UserModel => UserModel.find({}).$promise
      },
      bodyClass: 'inventory-transaction'
    })

  // Navigation
  msNavigationServiceProvider.saveItem('apps.reports', {
    title: 'REPORTS',
    icon: 'icon-file-document-multiple',
    weight: 25,
    aclsCondition: 'or',
    acls: [
      { model: 'Stock', method: 'find' },
      { model: 'WorkflowSession', method: 'find' }
    ]
  })

  msNavigationServiceProvider.saveItem('apps.reports.devices', {
    title: 'HOME.DEVICES',
    state: 'app.devices',
    weight: 10
  })
  msNavigationServiceProvider.saveItem('apps.reports.inventory-transaction', {
    title: 'INVENTORY_TRANSACTION.TITLE',
    state: 'app.reports.inventory-transaction',
    weight: 15
  })

  msNavigationServiceProvider.saveItem('apps.reports.workflowsessionitemlist', {
    title: 'WORKFLOW_SESSION_ITEM',
    state: 'app.reports.workflow-session-item-list',
    hidden: true,
    weight: 20,
    acls: [{ model: 'WorkflowSessionItem', method: 'find' }]
  })

  msNavigationServiceProvider.saveItem('apps.reports.contentpackagesession', {
    title: 'CONTENT_PACKAGE_SESSION',
    state: 'app.reports.content-package-session-list',
    weight: 30,
    hidden: true,
    acls: [{ model: 'ContentPackageSession', method: 'find' }]
  })

  msNavigationServiceProvider.saveItem('apps.reports.asbuilt', {
    title: 'AS_BUILT_REPORT',
    state: 'app.reports.asbuilt',
    weight: 40,
    hidden: true,
    acls: [{ model: 'Stock', method: 'find' }]
  })

  msNavigationServiceProvider.saveItem('apps.reports.workflowsessionlist', {
    title: 'WORKFLOW_SESSION',
    state: 'app.reports.workflow-session-list',
    hidden: true,
    weight: 50,
    acls: [{ model: 'WorkflowSession', method: 'find' }]
  })

  msNavigationServiceProvider.saveItem('apps.reports.api-method-log', {
    title: 'API_METHOD_LOG.TITLE',
    state: 'app.api-method-log.list',
    hidden: false,
    weight: 60,
    acls: [{ model: 'ApiMethodLog', method: 'find' }]
  })

  msNavigationServiceProvider.saveItem('apps.forms', {
    title: 'FORM.FORMS',
    icon: 'icon-file-document-edit',
    weight: 20,
    aclsCondition: 'or',
    acls: [
      { model: 'Stock', method: 'find' },
      { model: 'WorkflowSession', method: 'find' }
    ]
  })

  msNavigationServiceProvider.saveItem('apps.reports.translations', {
    title: 'TRANSLATIONS_REPORT',
    hidden: true,
    state: 'app.reports.translations',
    weight: 100,
    acls: [{ model: 'Setting', method: 'updateSystemSettings' }]
  })
}

export default MODULE_NAME
