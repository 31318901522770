/*eslint-disable*/
/** @ngInject */

function LocationShowController (
  $rootScope,
  $log,
  $translate,
  $scope,
  $state,
  $stateParams,
  FormlyValidations,
  Location,
  $timeout,
  ResolvedLocation,
  FormlyHelper,
  ErrorHandlerService
) {
  var vm = this
  vm.edit = !!$stateParams.id
  if (vm.edit) {
    $scope.location = ResolvedLocation
    $scope.originalLocation = _.cloneDeep(ResolvedLocation)
  } else {
    $scope.location = { name: '' }
  }
  $scope.Location = Location
  const staticFields = [
    {
      key: 'name',
      type: 'input',
      className: 'layout-row',
      validators: {
        noComma: FormlyValidations.noComma
      },
      templateOptions: {
        type: 'text',
        focus: true,
        required: true,
        label: $translate.instant('Location.name'),
        placeholder: $translate.instant('Location.name'),
        onChange: function (value, options) {
          options.validation.errorExistsAndShouldBeVisible = null
          options.formControl.$setValidity('serverError', true)
        }
      }
    }
  ]
  $scope.locationFields = FormlyHelper.buildFields(staticFields, Location)

  vm.errorHandler = function (res) {
    $scope.translate($rootScope.appSettings.defaultContentLanguage)
    $timeout(
      () => {
        ErrorHandlerService.formlyErrorHandler(
          res,
          $scope.locationFields,
          $scope.locationForm
        )
      },
      0,
      false
    )
  }

  $scope.formValid = () => {
    $scope.locationForm.$pristine = false
  }

  vm.save = function () {
    if (vm.edit) {
      console.log('EDIT')
      var promise = Location.prototype$patchAttributes(
        { id: $scope.location.id },
        $scope.location
      ).$promise
      promise.then(function (res) {
        $state.go('app.location.list')
      })
      promise.catch(function (res) {
        vm.errorHandler(res)
      })
    } else {
      var promise = Location.create($scope.location).$promise
      promise.then(function (res) {
        $state.go('app.location.list')
      })

      promise.catch(function (res) {
        vm.errorHandler(res)
      })
    }
  }

  $scope.headerOptions = {
    icon: 'icon-map-marker-multiple',
    template: require('app/templates/headers/simple.html'),
    title: vm.edit
      ? $translate.instant('Location.EDIT_LOCATION') +
        ':' +
        $scope.originalLocation.name
      : $translate.instant('Location.CREATE_LOCATION'),
    fabButton: {}
  }
}

module.exports = LocationShowController
