/* global */
/** @ngInject */
function CreateQRController (
  $scope,
  $rootScope,
  $mdDialog,
  $timeout,
  $window,
  $location,
  locals
) {
  $scope.cancel = () => $mdDialog.cancel()
  const newSession = locals.newSession
  const recordId = locals.recordId
  const preview = locals.preview || false
  const sessionId = locals.sessionId || null
  $timeout(() => {
    let url = `${$location.protocol()}://${$location.host()}`
    if (
      $location.port() &&
      $location.port() !== 80 &&
      $location.port() !== 443
    ) {
      url = `${$location.protocol()}://${$location.host()}:${$location.port()}`
    }
    const navigateUrl = newSession
      ? encodeURIComponent(
          `${url}/#!/workflow/session/realwear/show/?recordId=${recordId}&preview=${preview}`
      )
      : encodeURIComponent(
          `${url}/#!/workflow/session/realwear/show/?recordId=${recordId}&workflowSessionIds=${sessionId}`
      )
    url += `/api/UserModels/LoginByAccessTokenAndRedirect?accessTokenId=${$rootScope.currentUser.accessTokenId}&url=${navigateUrl}`
    const codeWriter = new $window.BrowserQRCodeSvgWriter()
    codeWriter.writeToDom('#container', url, 400, 400)
  }, 500)
}

module.exports = CreateQRController
