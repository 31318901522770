/* global angular */
const UUID = require('uuid')
require('./configuration-edit-dialog.scss')

/** @ngInject */
function ConfigurationEditDialogController (
  $scope,
  $rootScope,
  $mdDialog,
  $mdToast,
  $translate,
  $state,
  $timeout,
  locals,
  nextplusSocket,
  WorkflowSessionItem,
  Resolved
) {
  $scope.stocks = locals.stocks.map(stock => ({
    displayName: stock.displayName,
    sessionId: stock.session.id
  }))
  $scope.sessionIds = Resolved.sessionIds
  $scope.workflows = Resolved.workflowsByRecordId
  $scope.configurationsById = Resolved.configurationsById
  $scope.configurationIds = Resolved.configurationIds
  $scope.sessionConfiguration = Resolved.sessionConfiguration
  const validRecordIds = Object.keys($scope.workflows)
  const configIds = Object.keys($scope.configurationsById)
  configIds.forEach(configId => {
    $scope.configurationsById[configId].options = $scope.configurationsById[
      configId
    ].options.filter(recordId => validRecordIds.includes(recordId))
  })

  $scope.getWorkflowName = function getWorkflowName (recordId) {
    if ($scope.workflows[recordId]) {
      let name = $scope.workflows[recordId].name
      const parts = $scope.workflows[recordId].parts
      if (parts && parts.length > 0) {
        name = `${name} - ${parts
          .map(part => `${part.sku} ${part.rev ? `(${part.rev})` : ''}`)
          .join(', ')}`
      }
      return name
    }
  }

  $scope.isLoading = false

  $scope.lockedSessions = {}

  if (!$rootScope.appSettings.allowMultiUsersInSession) {
    nextplusSocket.angularSocket.on('lockedSessions', data => {
      $scope.lockedSessions = data
    })

    $scope.checkLockedSessions = () => {
      // make call by socket about lock sessions
      nextplusSocket.socket.emit('lockedSessions')
      $scope.socketLockTimeout = $timeout($scope.checkLockedSessions, 2000)
    }
    $scope.checkLockedSessions()
  }

  $scope.$on('$destroy', function () {
    nextplusSocket.angularSocket.removeAllListeners('lockedSessions')
    if ($scope.socketLockTimeout) {
      $timeout.cancel($scope.socketLockTimeout)
    }
  })

  $scope.goToSession = function goToSession (configuration) {
    $state.transitionTo(
      'app.workflow.session.show',
      {
        workflowSessionIds: configuration.sessionId,
        recordId: configuration.recordId
      },
      {
        location: true,
        inherit: false,
        reload: false,
        relative: $state.$current,
        notify: true
      }
    )
  }

  $scope.createSession = function createSession (sessionId, configuration) {
    $state.transitionTo(
      'app.workflow.session.show',
      {
        preview: false,
        recordId: configuration.recordId,
        configuration: JSON.stringify({
          parentConfigurationId: configuration.id,
          parentSession: sessionId
        })
      },
      {
        location: 'replace',
        inherit: false,
        reload: false,
        relative: $state.$current,
        notify: true
      }
    )
  }

  $scope.saveConfiguration = async function saveConfiguration (
    sessionId,
    configuration,
    idx
  ) {
    $scope.isLoading = true
    $scope.sessionConfiguration[sessionId][configuration.configurationId][
      idx
    ].id = UUID()
    const configurationArray = convertConfigurationsBeforeSave(
      $scope.sessionConfiguration[sessionId]
    )
    try {
      await WorkflowSessionItem.updateConfiguration({
        sessionId,
        configuration: configurationArray
      })
      $scope.isLoading = false
    } catch (err) {
      console.err(err)
      $scope.isLoading = false
    } finally {
      $scope.$applyAsync()
    }
  }

  $scope.addConfiguration = function addConfiguration (
    sessionId,
    configurationId
  ) {
    if (!$scope.sessionConfiguration[sessionId][configurationId]) {
      $scope.sessionConfiguration[sessionId][configurationId] = []
    }
    $scope.sessionConfiguration[sessionId][configurationId].push({
      id: null,
      recordId: null,
      sessionId: null,
      configurationId: configurationId
    })
  }

  $scope.deleteConfiguration = function deleteConfiguration (
    sessionId,
    configuration,
    idx
  ) {
    if (!configuration.id) {
      $scope.sessionConfiguration[sessionId][
        configuration.configurationId
      ].splice(idx, 1)
    } else {
      const workflowName = $scope.getWorkflowName(configuration.recordId)
      let title = $translate.instant(
        'CONFIGURATION.DELETE_CONFIGURATION_TITLE',
        {
          name: workflowName
        }
      )
      if (configuration.sessionId) {
        title = $translate.instant(
          'CONFIGURATION.DELETE_CONFIGURATION_AND_SESSION_TITLE',
          { name: workflowName }
        )
      }
      $mdDialog
        .show(
          $mdDialog
            .confirm()
            .title(title)
            .targetEvent()
            .clickOutsideToClose(true)
            .parent(angular.element(document.body))
            .multiple(true)
            .ok($translate.instant('BUTTONS.DELETE'))
            .cancel($translate.instant('BUTTONS.CANCEL'))
        )
        .then(
          async function () {
            // check if configuration is mandatory
            $scope.isLoading = true
            const isMandatory =
              $scope.configurationsById[configuration.configurationId].required
            if (
              isMandatory &&
              $scope.sessionConfiguration[sessionId][
                configuration.configurationId
              ].length === 1
            ) {
              $mdToast.updateTextContent(
                `${$translate.instant(
                  'CONFIGURATION.ERROR.DELETE_MANDATORY_CONFIGURATION',
                  {
                    name:
                      $scope.configurationsById[configuration.configurationId]
                        .name,
                    workflowName: workflowName
                  }
                )}`
              )
              $mdToast.show(
                $mdToast.nextplus({
                  position: $rootScope.toastLocation,
                  parent: '#md-dialog-content',
                  theme: 'error-toast',
                  hideDelay: 5000,
                  content: `${$translate.instant(
                    'CONFIGURATION.ERROR.DELETE_MANDATORY_CONFIGURATION',
                    {
                      name:
                        $scope.configurationsById[configuration.configurationId]
                          .name,
                      workflowName: workflowName
                    }
                  )}`
                })
              )
              $scope.isLoading = false
              return
            }
            // delete configuration
            try {
              const deleteObject = {
                parentSessionId: sessionId,
                parentConfigurationId: configuration.id
              }
              if (configuration.sessionId) {
                deleteObject.sessionId = configuration.sessionId
              }
              await WorkflowSessionItem.deleteConfiguration(deleteObject)
                .$promise
              $scope.sessionConfiguration[sessionId][
                configuration.configurationId
              ] = $scope.sessionConfiguration[sessionId][
                configuration.configurationId
              ].filter(config => config.id !== configuration.id)
              $scope.isLoading = false
              $scope.$applyAsync()
            } catch (err) {
              $mdToast.show(
                $mdToast.nextplus({
                  position: $rootScope.toastLocation,
                  parent: '#md-dialog-content',
                  theme: 'error-toast',
                  hideDelay: 4000,
                  content: err.message
                })
              )
              $mdToast.updateTextContent(err.message)
              $scope.isLoading = false
            }
          },
          function () {}
        )
    }
  }

  const convertConfigurationsBeforeSave = function convertConfigurationsBeforeSave (
    configuration
  ) {
    const configurationArray = []
    Object.keys(configuration).forEach(configurationId => {
      const currentConfiguration = configuration[configurationId]
      configurationArray.push(
        ...currentConfiguration.filter(config => config.id)
      )
    })
    return configurationArray
  }

  $scope.close = () => {
    // if we need the data in session-show controller that how we should get it
    // const data = {}
    // $scope.sessions.forEach(session => {
    //   data[session.id] = convertConfigurationsBeforeSave(
    //     $scope.sessionConfiguration[session.id]
    //   )
    // })
    // $mdDialog.hide(data)
    $mdDialog.hide()
  }
}

module.exports = ConfigurationEditDialogController
