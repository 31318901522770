/* global _ angular */
const UUID = require('uuid')
require('./workorder-status.scss')

/** @ngInject */
function WorkorderStatusController (
  $scope,
  $rootScope,
  $translate,
  $mdToast,
  $mdDialog,
  Page,
  PermissionUtils,
  ResolvedWorkorderStatus,
  KendoGridHelper,
  ViewsService,
  WorkorderStatus,
  ResolvedRoles,
  ResolvedCertificates,
  htmlWork
) {
  const validProperties = [
    'id',
    'name',
    'color',
    'systemDefault',
    'onCreateDefault',
    'onStartDefault',
    'onEndDefault',
    'statusType',
    'isChangeReasonRequired',
    'changeReasonFields',
    'transitionToPermitRoleIds',
    'transitionToPermitCertificateIds'
  ]

  $scope.statusesTypes = [
    'WO.STATUSES.NEW',
    'WO.STATUSES.IN_PROGRESS',
    'WO.STATUSES.FROZEN',
    'WO.STATUSES.END'
  ]
  $scope.permissions = []
  ResolvedRoles.filter(
    role =>
      !role.name.includes('$authenticated') &&
      !role.name.includes('$everyone') &&
      !role.name.includes('$unauthenticated') &&
      !role.name.includes('$owner')
  ).forEach(role => {
    $scope.permissions.push({
      group: 'Roles',
      id: 'role_' + role.id,
      name: role.nicename,
      notAnOption: role.disableUse
    })
  })
  ResolvedCertificates.forEach(certificate => {
    $scope.permissions.push({
      group: 'Certificate',
      id: 'certificate_' + certificate.id,
      name: certificate.name
    })
  })

  const createNewWorkorderStatus = function createNewWorkorderStatus () {
    const newWorkorderStatus = {
      id: UUID(),
      name: '',
      systemDefault: false,
      onCreateDefault: false,
      onStartDefault: false,
      onEndDefault: false,
      statusType: 'WO.STATUSES.NEW',
      isChangeReasonRequired: false,
      transitionToPermitRoleIds: [],
      transitionToPermitCertificateIds: [],
      new: true
    }
    $scope.workorderStatuses.push(newWorkorderStatus)
    $scope.statusesById[newWorkorderStatus.id] = newWorkorderStatus
    $scope.inlineEditObject.models[newWorkorderStatus.id] = newWorkorderStatus
    $scope.defaultTableSetup.data = $scope.workorderStatuses
    $scope.kendoGrid.instance.dataSource.add(newWorkorderStatus)
    updateRowInKendo(newWorkorderStatus.id)
  }

  $scope.deleteWorkorderStatus = async function deleteWorkorderStatus (
    workorderStatusId
  ) {
    const workorderStatus = $scope.workorderStatuses.find(
      wt => wt.id === workorderStatusId
    )
    let statusIsUsed = false
    if (workorderStatus) {
      if (!workorderStatus.new) {
        statusIsUsed = (
          await WorkorderStatus.checkIfStatusIsUsed({
            statusId: workorderStatusId
          }).$promise
        ).used
      }

      if (statusIsUsed) {
        $mdDialog
          .show({
            /** @ngInject */
            controller: ($scope, locals, $mdDialog) => {
              $scope.header = $translate.instant('WO.WORKORDER_STATUS_CHANGE')
              $scope.statuses = locals.statuses
              $scope.selectedStatus = null
              $scope.save = () => $mdDialog.hide($scope.selectedStatus)
              $scope.cancel = () => $mdDialog.cancel()
            },
            locals: {
              statuses: $scope.workorderStatuses.filter(
                status =>
                  status.id !== workorderStatusId &&
                  status.statusType === workorderStatus.statusType &&
                  !status.new
              )
            },
            multiple: true,
            template: `<md-dialog style="height: 300px; width: 400px;">
                          <md-toolbar>
                            <div class="md-toolbar-tools">
                              <h2>{{header}}</h2>
                            </div>
                          </md-toolbar>
                          <md-dialog-content style="height: 100%;">
                            <div>
                            <div>${$translate.instant(
                              'WO.WORKORDER_STATUS_IS_USED'
                            )}</div>
                              <md-select data-testid="status-select" ng-model="selectedStatus" required>
                                <md-option
                                  ng-value="status"
                                  ng-repeat="status in statuses">
                                  {{status.name}}
                                </md-option>
                              </md-select>
                            </div>
                          </md-dialog-content>
                          <md-dialog-actions>
                          <md-button ng-click="cancel()" class="md-raised md-warn">
                          ${$translate.instant('BUTTONS.CANCEL')}
                            </md-button>
                            <md-button ng-disabled="!selectedStatus" ng-click="save()" class="md-raised md-primary">
                            ${$translate.instant('BUTTONS.OK')}
                            </md-button>
                          </md-dialog-actions>
                      </md-dialog>`,
            parent: angular.element(document.body),
            targetEvent: '',
            clickOutsideToClose: false,
            escapeToClose: false
          })
          .then(async selectedStatus => {
            await WorkorderStatus.bulkWorkorderStatusChange({
              statusId: workorderStatusId,
              newStatus: selectedStatus
            })
            await removeStatus(workorderStatus)
          })
      } else {
        $mdDialog
          .show(
            $mdDialog
              .confirm()
              .title($translate.instant('WO.WORKORDER_STATUS_DELETE_MESSAGE'))
              .multiple(true)
              .escapeToClose(true)
              .clickOutsideToClose(true)
              .parent(angular.element(document.body))
              .ok($translate.instant('BUTTONS.DELETE'))
              .cancel($translate.instant('BUTTONS.CANCEL'))
          )
          .then(
            async function () {
              try {
                await removeStatus(workorderStatus)
              } catch (err) {
                console.error(err)
                $rootScope.showErrorToast('NP-2043')
              }
            },
            function () {}
          )
      }
    }
  }

  const removeStatus = async function removeStatus (workorderStatus) {
    const workorderStatusId = workorderStatus.id
    if (!workorderStatus.new) {
      await WorkorderStatus.destroyById({ id: workorderStatusId }).$promise
    }
    const index = $scope.workorderStatuses.findIndex(
      wt => wt.id === workorderStatusId
    )
    if (index > -1) {
      $scope.workorderStatuses.splice(index, 1)
    }
    delete $scope.inlineEditObject.models[workorderStatusId]
    $scope.defaultTableSetup.data = $scope.workorderStatuses
    $scope.kendoGrid.instance.dataSource.remove(
      $scope.kendoGrid.instance.dataSource._data.find(
        status => status.id === workorderStatusId
      )
    )
    $mdToast.show(
      $mdToast.nextplus({
        position: $rootScope.toastLocation,
        parent: '#content',
        theme: 'success-toast',
        hideDelay: 3000
      })
    )
    $mdToast.updateTextContent(
      $translate.instant('WO.WORKORDER_STATUS_DELETED_SUCCESSFULLY')
    )
  }

  $scope.save = async function save () {
    try {
      const workorderStatuses = Object.keys($scope.inlineEditObject.models).map(
        workorderStatusId => {
          if (
            $scope.inlineEditObject.models[workorderStatusId]
              .isChangeReasonRequired
          ) {
            $scope.inlineEditObject.models[
              workorderStatusId
            ].changeReasonFields = $scope.fieldsByStatusId[workorderStatusId]
          } else {
            $scope.inlineEditObject.models[
              workorderStatusId
            ].changeReasonFields = []
            $scope.fieldsByStatusId[workorderStatusId] = []
          }
          $scope.inlineEditObject.models[
            workorderStatusId
          ].transitionToPermitRoleIds = []
          $scope.inlineEditObject.models[
            workorderStatusId
          ].transitionToPermitCertificateIds = []
          if (
            $scope.inlineEditObject.models[workorderStatusId]
              .transitionToPermit &&
            $scope.inlineEditObject.models[workorderStatusId].transitionToPermit
              .length > 0
          ) {
            $scope.inlineEditObject.models[
              workorderStatusId
            ].transitionToPermit.forEach(permit => {
              if (permit) {
                if (permit.startsWith('role')) {
                  $scope.inlineEditObject.models[
                    workorderStatusId
                  ].transitionToPermitRoleIds.push(permit.replace('role_', ''))
                } else {
                  $scope.inlineEditObject.models[
                    workorderStatusId
                  ].transitionToPermitCertificateIds.push(
                    permit.replace('certificate_', '')
                  )
                }
              }
            })
          } else {
            $scope.inlineEditObject.models[
              workorderStatusId
            ].transitionToPermit = []
          }
          return _.pick(
            $scope.inlineEditObject.models[workorderStatusId],
            validProperties
          )
        }
      )
      $rootScope.loadingProgress = true
      await WorkorderStatus.bulkSave({
        workorderStatuses
      }).$promise
      $scope.workorderStatuses.forEach(w => {
        w.new = false
      })
      $mdToast.show(
        $mdToast.nextplus({
          position: $rootScope.toastLocation,
          parent: '#content',
          theme: 'success-toast',
          hideDelay: 3000
        })
      )
      $mdToast.updateTextContent(
        $translate.instant('WO.WORKORDER_STATUSES_UPDATED_SUCCESSFULLY')
      )
    } catch (err) {
      console.error(err)
      if (err.data.error.code) {
        const mdToast = $mdToast.nextplus({
          position: $rootScope.toastLocation,
          parent: '#content',
          theme: 'error-toast',
          hideDelay: 6000
        })
        $mdToast.show(mdToast)
        if (err.data.error.details) {
          if (err.data.error.details.statusType) {
            err.data.error.details.statusType = $translate.instant(
              err.data.error.details.statusType
            )
            if (err.data.error.details.defaultStatus) {
              err.data.error.details.defaultStatus = $translate.instant(
                err.data.error.details.defaultStatus
              )
              $mdToast.updateTextContent(
                $translate.instant(
                  `WO.WORKORDER_STATUS_ERRORS.${err.data.error.code}`,
                  err.data.error.details
                )
              )
            } else {
              $mdToast.updateTextContent(
                $translate.instant(
                  `WO.WORKORDER_STATUS_ERRORS.${err.data.error.code}`,
                  err.data.error.details
                )
              )
            }
          }
          $mdToast.updateTextContent(
            $translate.instant(
              `WO.WORKORDER_STATUS_ERRORS.${err.data.error.code}`,
              err.data.error.details
            )
          )
        } else {
          $mdToast.updateTextContent(
            $translate.instant(
              `WO.WORKORDER_STATUS_ERRORS.${err.data.error.code}`
            )
          )
        }
      } else {
        $rootScope.showErrorToast('NP-2045')
      }
    } finally {
      $rootScope.loadingProgress = false
    }
  }

  const getFieldsByFieldIds = async function (fieldIds) {
    return await WorkorderStatus.getFieldsByFieldIds({ fieldIds })
      .$promise
  }
  $scope.editChangeReasonFields = async function editChangeReasonFields (
    statusId
  ) {
    const fields = []
    if (
      $scope.fieldsByStatusId[statusId] &&
      $scope.fieldsByStatusId[statusId].length > 0
    ) {
      const fieldIds = $scope.fieldsByStatusId[statusId].map(
        field => field.fieldId
      )
      const tempFields = await getFieldsByFieldIds(fieldIds)
      const fieldsByFieldId = _.keyBy(tempFields, 'id')
      fieldIds.forEach(fieldId => {
        if (fieldsByFieldId[fieldId]) {
          fields.push(fieldsByFieldId[fieldId])
        }
      })
    }
    $mdDialog
      .show({
        controller: 'WorkorderStatusFieldsEditController',
        template: require('./workorder-status-fields-dialog-edit.template.html'),
        parent: angular.element(document.body),
        targetEvent: '',
        multiple: true,
        locals: {
          selectedFields: fields,
          DBFields: $scope.fieldsByStatusId[statusId],
          editFieldId: null,
          ignoreSubForms: false
        },
        clickOutsideToClose: false
      })
      .then(
        async function (response) {
          if (response) {
            $scope.fieldsByStatusId[statusId] = response.fields
          }
        },
        function () {}
      )
  }

  _.mixin({
    memoizeDebounce: function (func, wait = 0, options = {}) {
      const mem = _.memoize(function (param) {
        return _.debounce(func, wait, options)
      })
      return function (param) {
        mem(param)(param)
      }
    }
  })

  const updateRowInKendo = function updateRowInKendo (id) {
    const dataItem = $scope.kendoGrid.instance.dataSource.get(id)
    if ($scope.statusesById[id]) {
      dataItem.set('name', $scope.statusesById[id].name)
      dataItem.set('color', $scope.statusesById[id].color)
      dataItem.set('onCreateDefault', $scope.statusesById[id].onCreateDefault)
      dataItem.set('onEndDefault', $scope.statusesById[id].onEndDefault)
      dataItem.set('onStartDefault', $scope.statusesById[id].onStartDefault)
      dataItem.set('statusType', $scope.statusesById[id].statusType)
      dataItem.set(
        'isChangeReasonRequired',
        $scope.statusesById[id].isChangeReasonRequired
      )
      dataItem.set(
        'transitionToPermit',
        $scope.statusesById[id].transitionToPermit
      )
    }
  }

  const debounceUpdateRowInKendo = _.memoizeDebounce(updateRowInKendo, 1000)

  const initScreen = async function initScreen () {
    $scope.title = $rootScope.title = $translate.instant(
      'WO.WORKORDER_STATUSES'
    )
    Page.setTitleText($scope.title)
    $scope.workorderStatuses = ResolvedWorkorderStatus
    $scope.statusesById = _.keyBy($scope.workorderStatuses, 'id')
    $scope.PermissionUtils = PermissionUtils
    $scope.fieldsByStatusId = {}
    $scope.isChangeReasonRequiredByStatusId = {}
    $scope.workorderStatuses = $scope.workorderStatuses.map(status => {
      $scope.fieldsByStatusId[status.id] = status.changeReasonFields
      $scope.isChangeReasonRequiredByStatusId[status.id] =
        status.isChangeReasonRequired
      status.transitionToPermit = status.transitionToPermitRoleIds.map(
        role => 'role_' + role
      )
      status.transitionToPermit.push(
        ...status.transitionToPermitCertificateIds.map(
          certificate => 'certificate_' + certificate
        )
      )
      return status
    })

    $scope.fields = {
      name: [
        {
          key: 'name',
          type: 'input',
          defaultValue: false,
          className: 'layout-column',
          templateOptions: {
            required: true,
            type: 'text',
            label: $translate.instant('WO.STATUS_NAME'),
            onChange: (value, options, scope) => {
              debounceUpdateRowInKendo(scope.model.id)
            }
          }
        }
      ],
      color: [
        {
          key: 'color',
          type: 'colorPalette',
          templateOptions: {
            colorPaletteOptions: {
              opacity: false,
              tileSize: 30,
              paletteDisplayed: false
            },
            onChange: (value, options, scope) => {
              debounceUpdateRowInKendo(scope.model.id)
            }
          }
        }
      ],
      onCreateDefault: [
        {
          key: 'onCreateDefault',
          type: 'checkbox',
          defaultValue: false,
          className: 'layout-column',
          templateOptions: {
            required: false,
            onChange: (value, options, scope) => {
              debounceUpdateRowInKendo(scope.model.id)
            }
          }
        }
      ],
      onStartDefault: [
        {
          key: 'onStartDefault',
          type: 'checkbox',
          defaultValue: false,
          className: 'layout-column',
          templateOptions: {
            required: false,
            onChange: (value, options, scope) => {
              debounceUpdateRowInKendo(scope.model.id)
            }
          }
        }
      ],
      onEndDefault: [
        {
          key: 'onEndDefault',
          type: 'checkbox',
          defaultValue: false,
          className: 'layout-column',
          templateOptions: {
            required: false,
            onChange: (value, options, scope) => {
              debounceUpdateRowInKendo(scope.model.id)
            }
          }
        }
      ],
      statusType: [
        {
          key: 'statusType',
          type: 'select',
          defaultValue: false,
          className: 'layout-column',
          templateOptions: {
            required: false,
            options: $scope.statusesTypes.map(status => ({
              value: status,
              name: $translate.instant(status)
            })),
            onChange: (value, options, scope) => {
              debounceUpdateRowInKendo(scope.model.id)
            }
          }
        }
      ],
      isChangeReasonRequired: [
        {
          key: 'isChangeReasonRequired',
          type: 'checkbox',
          defaultValue: false,
          className: 'layout-column',
          templateOptions: {
            required: false,
            onChange: (value, options, scope) => {
              $scope.isChangeReasonRequiredByStatusId[scope.model.id] = value
              debounceUpdateRowInKendo(scope.model.id)
            }
          }
        }
      ],
      transitionToPermit: [
        {
          key: 'transitionToPermit',
          type: 'selectWithSearch',
          className: 'layout-column',
          templateOptions: {
            hasGroupedOptions: true,
            enableRemoveDisabledSelected: true,
            multiple: true,
            required: false,
            options: $scope.permissions
          }
        }
      ]
    }

    $scope.inlineEditObject = {
      forms: {},
      models: ResolvedWorkorderStatus.reduce((obj, curr) => {
        obj[curr.id] = curr
        return obj
      }, {})
    }

    const tableColumns = [
      // STATUS
      {
        uniqueId: '53c5d8d4-3ccf-4288-a19d-e8c593be7221',
        field: 'name',
        translateCode: 'WO.STATUS_NAME',
        trustedTemplate: data => {
          return `<div layout="row" layout-align="center center">
                      <form
                        novalidate
                        name="inlineEditObject.forms['${data.id}']"
                        autocomplete="off"
                        layout="row"
                      >
                        <formly-form
                          model="inlineEditObject.models['${data.id}']"
                          fields="fields.name"
                          layout="column"
                          flex="100"
                          form="inlineEditObject.forms['${data.id}']"
                        >
                        </formly-form>
                      </form>
                    </div>`
        }
      },
      // COLOR
      {
        uniqueId: '4deb6185-1cb6-4a45-9dc7-88f99af5579c',
        field: 'color',
        translateCode: 'WO.STATUS_COLOR',
        filterable: false,
        trustedTemplate: data => {
          return `<div layout="row" layout-align="center center">
                      <form
                        novalidate
                        name="inlineEditObject.forms['${data.id}']"
                        autocomplete="off"
                        layout="row"
                      >
                        <formly-form
                          model="inlineEditObject.models['${data.id}']"
                          fields="fields.color"
                          layout="column"
                          flex="100"
                          form="inlineEditObject.forms['${data.id}']"
                        >
                        </formly-form>
                      </form>
                    </div>`
        }
      },
      // onCreateDefault
      {
        uniqueId: '8a4dfc12-b31d-43ac-992e-63aeb73faa46',
        field: 'onCreateDefault',
        translateCode: 'WO.DEFAULT_STATUSES.ON_CREATION_STATUS',
        type: 'boolean',
        filterable: true,
        trustedTemplate: data => {
          return `<div layout="row" layout-align="center center">
                    <form
                      novalidate
                      name="inlineEditObject.forms['${data.id}']"
                      autocomplete="off"
                      layout="row"
                    >
                      <formly-form
                        model="inlineEditObject.models['${data.id}']"
                        fields="fields.onCreateDefault"
                        layout="column"
                        flex="100"
                        form="inlineEditObject.forms['${data.id}']"
                      >
                      </formly-form>
                    </form>
                  </div>`
        }
      },
      // onStartDefault
      {
        uniqueId: '7f53d969-fe35-4a32-ae9b-a6ce2582cdcf',
        field: 'onStartDefault',
        translateCode: 'WO.DEFAULT_STATUSES.ON_START_STATUS',
        type: 'boolean',
        filterable: true,
        trustedTemplate: data => {
          return `<div layout="row" layout-align="center center">
                    <form
                      novalidate
                      name="inlineEditObject.forms['${data.id}']"
                      autocomplete="off"
                      layout="row"
                    >
                      <formly-form
                        model="inlineEditObject.models['${data.id}']"
                        fields="fields.onStartDefault"
                        layout="column"
                        flex="100"
                        form="inlineEditObject.forms['${data.id}']"
                      >
                      </formly-form>
                    </form>
                  </div>`
        }
      },
      // onEndDefault
      {
        uniqueId: '84011c7d-ecce-445c-8c5a-056ae80daaef',
        field: 'onEndDefault',
        translateCode: 'WO.DEFAULT_STATUSES.ON_END_STATUS',
        type: 'boolean',
        filterable: true,
        trustedTemplate: data => {
          return `<div layout="row" layout-align="center center">
                    <form
                      novalidate
                      name="inlineEditObject.forms['${data.id}']"
                      autocomplete="off"
                      layout="row"
                    >
                      <formly-form
                        model="inlineEditObject.models['${data.id}']"
                        fields="fields.onEndDefault"
                        layout="column"
                        flex="100"
                        form="inlineEditObject.forms['${data.id}']"
                      >
                      </formly-form>
                    </form>
                  </div>`
        }
      },
      // statusType
      {
        uniqueId: 'c0525a78-ccd3-4b35-bc9d-a478365d9ab1',
        field: 'statusType',
        translateCode: 'WO.STATUS_TYPE',
        filterable: true,
        trustedTemplate: data => {
          return `<div layout="row" layout-align="center center">
                    <form
                      novalidate
                      name="inlineEditObject.forms['${data.id}']"
                      autocomplete="off"
                      flex="90"
                      layout="row"
                    >
                      <formly-form
                        model="inlineEditObject.models['${data.id}']"
                        fields="fields.statusType"
                        layout="column"
                        flex="100"
                        form="inlineEditObject.forms['${data.id}']"
                      >
                      </formly-form>
                    </form>
                  </div>`
        }
      },
      // isChangeReasonRequired
      {
        uniqueId: 'bfd62aaf-3ffc-4a89-94cb-aa534744ab98',
        field: 'isChangeReasonRequired',
        translateCode: 'WO.REQUIRE_STATUS_CHANGE_REASON',
        filterable: true,
        type: 'boolean',
        trustedTemplate: data => {
          return `<div layout="row" layout-align="center center">
                    <form
                      novalidate
                      name="inlineEditObject.forms['${data.id}']"
                      autocomplete="off"
                      layout="row"
                    >
                      <formly-form
                        model="inlineEditObject.models['${data.id}']"
                        fields="fields.isChangeReasonRequired"
                        layout="column"
                        flex="100"
                        form="inlineEditObject.forms['${data.id}']"
                      >
                      </formly-form>
                    </form>
                    <div class="change-reason-white-space" ng-if="!isChangeReasonRequiredByStatusId['${data.id}']"></div>
                    <md-button class="md-icon-button" ng-if="isChangeReasonRequiredByStatusId['${data.id}']"
                     ng-click="editChangeReasonFields('${data.id}')">
                    <md-icon md-font-icon="icon-pencil" class="s18"></md-icon>
                    </md-button>
                  </div>`
        }
      },
      // transitionToPermit
      {
        uniqueId: '2b55a8b1-78e6-4c00-8e29-2258cff8f650',
        field: 'transitionToPermit',
        translateCode: 'WO.PERMITTED_TO_CHANGE',
        filterable: false,
        trustedTemplate: data => {
          return `<div layout="row" layout-align="center center">
                    <form
                      novalidate
                      name="inlineEditObject.forms['${data.id}']"
                      autocomplete="off"
                      flex="90"
                      layout="row"
                    >
                      <formly-form
                        model="inlineEditObject.models['${data.id}']"
                        fields="fields.transitionToPermit"
                        layout="column"
                        flex="100"
                        form="inlineEditObject.forms['${data.id}']"
                      >
                      </formly-form>
                    </form>
                  </div>
                  `
        }
      },
      // ACTIONS
      {
        uniqueId: 'af318781-c824-44cc-95e6-5a27293df8d7',
        field: 'id',
        translateCode: 'COMMON.ACTIONS',
        headerAttributes: {
          class: 'actions-column-header'
        },
        filterable: false,
        sortable: false,
        // media: '(min-width: 768px)',
        trustedTemplate: data => {
          return !data.systemDefault
            ? `<md-button ng-disabled="!canDelete" class="md-icon-button" ng-click="deleteWorkorderStatus('${data.id}')">
                    <md-icon md-font-icon="icon-delete" class="s18 md-warn"></md-icon>
                </md-button>`
            : ''
        }
      }
    ]

    const { defaultTableColumns } = ViewsService.generateCustomFieldColumns(
      'WorkorderStatus',
      tableColumns
    )

    const baseFilter = {}

    const { columns, selectedViewId } =
      ViewsService.getTablesColumns(defaultTableColumns)

    const { newBaseFilter, filters } = ViewsService.getViewCustomFilters(
      selectedViewId,
      _.cloneDeep(baseFilter)
    )

    $scope.defaultTableSetup = {
      // stateName
      ignoreParams: true,
      data: $scope.workorderStatuses,
      serverSide: false,
      pageable: false,
      pageSize: 9999,
      cleanBaseFilter: baseFilter,
      baseFilter: newBaseFilter,
      selectedViewId,
      columns: defaultTableColumns
    }

    const defaultTableToolbarSetup = {
      // stateName
      columns: defaultTableColumns,
      currentColumnIds: columns.map(c => c.uniqueId),
      filters,
      selectedViewId,
      title: $scope.title
    }

    $scope.downloadFunction = () => {}

    $scope.kendoGrid = await KendoGridHelper.GridInstance(
      $scope.defaultTableSetup,
      $scope,
      columns
    )

    await $scope.kendoGrid.isBound()

    $scope.tableToolbar = await ViewsService.GridToolBarInstance(
      defaultTableToolbarSetup,
      $scope.kendoGrid,
      $scope
    )

    $scope.$applyAsync()
  }

  initScreen()
  $scope.canCreate = PermissionUtils.isPermit('WorkorderStatus', 'create')
  $scope.canDelete = PermissionUtils.isPermit('WorkorderStatus', 'deleteById')
  $scope.headerOptions = {
    template: require('app/templates/headers/simple.html'),
    title: $scope.title,
    postTitleHtml: $scope.canCreate
      ? `<md-button class="md-raised md-primary" ng-click="save()" ng-disabled="$root.loadingProgress">
                      <md-icon md-font-icon="icon-content-save" class="s20"></md-icon>
                      <span translate="BUTTONS.SAVE"></span>
                    </md-button>`
      : '',
    fabButton: {
      template: require('app/templates/headers/fab-button.html'),
      action: createNewWorkorderStatus,
      disabled: !PermissionUtils.isPermit('WorkorderStatus', 'create'),
      icon: 'icon-plus',
      href: '',
      state: '',
      showExpression: () => true
    }
  }
}

module.exports = WorkorderStatusController
