/** @ngInject */

function msInfoBarDirective ($document) {
  return {
    restrict: 'E',
    scope: {
      showRemove: '=?',
      type: '@?'
    },
    transclude: true,
    template: require('./ms-info-bar.html'),
    link: function (scope, element) {
      /**
       * Remove the info bar
       */
      function removeInfoBar () {
        element.remove()
        scope.$destroy()
      }

      // Expose functions
      scope.removeInfoBar = removeInfoBar
    },
    controller: /** @ngInject */ function msInfoBarController ($scope) {
      const INFO_BAR_TYPES = {
        INFORMATION: 'information',
        WARNING: 'warning',
        ERROR: 'error',
        SUCCESS: 'success'
      }
      if (
        !$scope.type ||
        !INFO_BAR_TYPES[$scope.type.toString().toUpperCase()]
      ) {
        $scope.type = INFO_BAR_TYPES.INFORMATION
      }
      switch ($scope.type) {
        case INFO_BAR_TYPES.INFORMATION:
          $scope.typeIcon = 'icon-information-outline'
          break
        case INFO_BAR_TYPES.WARNING:
          $scope.typeIcon = 'icon-alert-outline'
          break
        case INFO_BAR_TYPES.ERROR:
          $scope.typeIcon = 'icon-close-circle-outline'
          break
        case INFO_BAR_TYPES.SUCCESS:
          $scope.typeIcon = 'icon-checkbox-marked-circle-outline'
          break
      }
    }
  }
}

module.exports = msInfoBarDirective
