/** @ngInject */
function ProductionEntityFormsDialogController ($scope, $mdDialog, locals) {
  $scope.selectForm = function selectForm (formId) {
    $mdDialog.hide({ formId })
  }

  /**
   * Initialization function
   */
  const initDialog = async function initDialog () {
    $scope.forms = locals.forms.map(form => ({
      id: form.id,
      icon: form.icon,
      name: form.name
    }))
  }

  initDialog()
}
module.exports = ProductionEntityFormsDialogController
