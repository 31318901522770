/* global tinymce angular */
/**
 * Binds a TinyMCE widget to <textarea> elements.
 */
const MODULE_NAME = 'comment'
export default MODULE_NAME

angular
  .module(MODULE_NAME, [])
  .component('comment', require('./components/comment/comment.js'))
  .component('commentNew', require('./components/comment-new/comment-new.js'))
  .component(
    'commentWrapper',
    require('./components/comment-wrapper/comment-wrapper.js')
  )
  .component(
    'commentEdit',
    require('./components/comment-edit/comment-edit.js')
  )

  .component(
    'commentList',
    require('./components/comment-list/comment-list.js')
  )
