/* global $, _ */
const modelsProperties = require('root/../common/services/translations/model-declaration')
const modelsHandling = require('root/../common/services/translations/model-special-handling')

/** @ngInject */
function TranslationsController (
  $scope,
  $rootScope,
  $state,
  $window,
  $translate,
  KendoGridHelper,
  UploadService,
  ContentPackage,
  Field,
  Tool,
  Location,
  Workflow,
  Certificate,
  PermissionUtils,
  Page,
  Menus,
  Category,
  $location,
  htmlWork,
  List,
  ListItem,
  Unit
) {
  const vm = this
  let languages = $rootScope.appSettings.availableContentLanguages
  const defaultLanguage = $rootScope.appSettings.defaultContentLanguage
  languages = _.filter(languages, lang => lang !== defaultLanguage)
  vm.page = {}
  vm.page.title = $translate.instant('TRANSLATIONS.TITLE')
  $rootScope.title = vm.page.title
  vm.models = Object.keys(modelsProperties)
  Object.keys(modelsHandling).forEach(model => {
    if (!_.isUndefined(modelsHandling[model].Relationships)) {
      const relationships = modelsHandling[model].Relationships
      Object.keys(relationships).forEach(relation => {
        _.remove(vm.models, key => key === relation)
      })
    }
  })
  if (
    vm.models.findIndex(model => model === $location.search().modelName) !== -1
  ) {
    vm.selectedModel = $location.search().modelName
  } else {
    $state.transitionTo(
      $state.$current,
      { modelName: 'ContentPackage' },
      {
        location: true,
        inherit: true,
        relative: $state.$current,
        notify: false
      }
    )
    vm.selectedModel = 'ContentPackage'
  }

  const renderScreen = async function renderScreen (selectedModel) {
    vm.model = ''
    $scope.baseFilter = {}
    $scope.baseFilter =
      !_.isUndefined(modelsHandling[selectedModel]) &&
      !_.isUndefined(modelsHandling[selectedModel].Filter)
        ? modelsHandling[selectedModel].Filter
        : {}
    const columns = []
    switch (selectedModel) {
      case 'ContentPackage':
        columns.push({
          uniqueId: '828ec9ea-9d7a-4d31-a2ab-cf73aef0a1c8',
          field: 'name',
          translateCode: 'TRANSLATIONS.NAME',
          width: '300px',
          type: 'string',
          filterable: {
            cell: {
              operator: 'contains',
              suggestionOperator: 'contains'
            }
          }
        })
        vm.model = ContentPackage
        break
      case 'Menus':
        columns.push({
          uniqueId: '2fd1576a-fd40-4400-a74d-df130dca4a64',
          field: 'title',
          translateCode: 'TRANSLATIONS.NAME',
          width: '300px',
          type: 'string',
          filterable: {
            cell: {
              operator: 'contains',
              suggestionOperator: 'contains'
            }
          }
        })
        vm.model = Menus
        $scope.baseFilter = {
          where: {
            or: [{ baseId: null }, { baseId: { exists: false } }]
          }
        }
        break
      case 'Field':
        columns.push({
          uniqueId: 'c469d6fb-1354-44fe-a597-bd91a5eb49f4',
          field: 'title',
          translateCode: 'TRANSLATIONS.NAME',
          width: '300px',
          type: 'string',
          filterable: {
            cell: {
              operator: 'contains',
              suggestionOperator: 'contains'
            }
          }
        })
        columns.push({
          uniqueId: '24b2d5bf-bfcc-4788-a9ba-1e4ab1af1118',
          field: 'type',
          translateCode: 'TRANSLATIONS.NAME',
          width: '300px',
          type: 'string',
          filterable: {
            cell: {
              operator: 'contains',
              suggestionOperator: 'contains'
            }
          }
        })
        vm.model = Field
        break
      case 'Location':
        columns.push({
          uniqueId: 'cf940332-ee27-4a6c-be6e-239f4d88241c',
          field: 'name',
          headerTemplate: "{{'TRANSLATIONS.NAME' | translate}}",
          width: '300px',
          type: 'string',
          filterable: {
            cell: {
              operator: 'contains',
              suggestionOperator: 'contains'
            }
          }
        })
        vm.model = Location
        break
      case 'Tool':
        columns.push({
          uniqueId: '0f4e7812-19d8-4740-b474-8f62ff3ee244',
          field: 'name',
          translateCode: 'TRANSLATIONS.NAME',
          width: '300px',
          type: 'string',
          filterable: {
            cell: {
              operator: 'contains',
              suggestionOperator: 'contains'
            }
          }
        })
        vm.model = Tool
        break
      case 'Certificate':
        columns.push({
          uniqueId: '1430e5d1-825b-40c5-bfa5-573101e9da8e',
          field: 'name',
          translateCode: 'TRANSLATIONS.NAME',
          width: '300px',
          type: 'string',
          filterable: {
            cell: {
              operator: 'contains',
              suggestionOperator: 'contains'
            }
          }
        })
        vm.model = Certificate
        break
      case 'Workflow':
        columns.push({
          uniqueId: 'cd4bcc9c-64fe-427e-a229-e61c56283146',
          field: 'name',
          translateCode: 'TRANSLATIONS.NAME',
          width: '300px',
          type: 'string',
          filterable: {
            cell: {
              operator: 'contains',
              suggestionOperator: 'contains'
            }
          }
        })
        vm.model = Workflow
        break
      case 'Category':
        columns.push({
          uniqueId: '9a423ea4-9209-47fa-b466-6d18f92d66b2',
          field: 'name',
          translateCode: 'TRANSLATIONS.NAME',
          width: '300px',
          type: 'string',
          filterable: {
            cell: {
              operator: 'contains',
              suggestionOperator: 'contains'
            }
          }
        })
        vm.model = Category
        break
      case 'List':
        columns.push({
          uniqueId: '786374ee-e34c-4934-9850-c17d7a915a2a',
          field: 'name',
          translateCode: 'TRANSLATIONS.NAME',
          width: '300px',
          type: 'string',
          filterable: {
            cell: {
              operator: 'contains',
              suggestionOperator: 'contains'
            }
          }
        })
        vm.model = List
        break
      case 'ListItem':
        columns.push({
          uniqueId: '0a40774d-d543-4239-a8b2-e35dd0b6e430',
          field: 'name',
          translateCode: 'TRANSLATIONS.NAME',
          width: '300px',
          type: 'string',
          filterable: {
            cell: {
              operator: 'contains',
              suggestionOperator: 'contains'
            }
          }
        })
        vm.model = ListItem
        break
      case 'Unit':
        columns.push({
          uniqueId: '0a40774d-d543-4239-a8b2-e35dd0b6e430',
          field: 'name',
          translateCode: 'TRANSLATIONS.NAME',
          width: '300px',
          type: 'string',
          filterable: {
            cell: {
              operator: 'contains',
              suggestionOperator: 'contains'
            }
          }
        })
        vm.model = Unit
        break
      default:
        break
    }
    languages.forEach(language => {
      columns.push({
        field: `translations.${language}.translationCompleted`,
        headerTemplate: `{{ 'TRANSLATIONS.${language}' | translate}}`,
        width: '300px',
        type: 'string',
        filterable: false,
        trustedTemplate: data => {
          return `
            <div>
              <md-checkbox ng-checked="${
                data.translations
                  ? data.translations[language]
                    ? typeof data.translations[language]
                        .translationCompleted === 'string'
                      ? htmlWork.escapeHTMLQuotes(
                          data.translations[language].translationCompleted
                        )
                      : data.translations[language].translationCompleted
                    : false
                  : false
              }" disabled></md-checkbox>
            </div>
            `
        }
      })
    })
    if ($scope.kendoGrid && $scope.kendoGrid.instance) {
      $scope.kendoGrid.setOptions({
        find: vm.model.find,
        count: vm.model.count,
        baseFilter: $scope.baseFilter,
        columns: columns,
        serverSide: true
      })
    } else {
      const defaultTableSetup = {
        // stateName
        // ignoreParams
        find: vm.model.find,
        count: vm.model.count,
        baseFilter: $scope.baseFilter,
        selectedViewId: 'systemDefault',
        columns: columns,
        toolbar: `<div layout="row" layout-align="space-between center" class="toolbar">
                      <md-input-container flex="40">
                        <label translate="TRANSLATIONS.MODEL"></label>
                        <md-select ng-model="vm.selectedModel" ng-change="changeModel()">
                          <md-option ng-repeat="model in vm.models" ng-value="model">
                            {{model}}
                          </md-option>
                        </md-select>
                      </md-input-container>
                      <div>
                        <md-button class="md-raised md-primary" ng-click="onExport()">
                          <span layout="row" layout-align="center center">
                            <md-icon md-font-icon="icon-export" class="s20" style="margin: 0; margin-inline-end: 0.5rem;"></md-icon>
                            <span translate="TRANSLATE.EXPORT"></span>
                          </span>
                        </md-button>
                        <md-button class="md-raised md-primary" ng-click="onImport()">
                          <span layout="row" layout-align="center center">
                            <md-icon md-font-icon="icon-import" class="s20" style="margin: 0; margin-inline-end: 0.5rem;"></md-icon>
                            <span translate="TRANSLATE.IMPORT"></span>
                          </span>
                        </md-button>
                      </div>
                    </div>`
      }
      $scope.kendoGrid = await KendoGridHelper.GridInstance(
        defaultTableSetup,
        $scope,
        columns
      )
    }
  }

  $scope.changeModel = function changeModel () {
    renderScreen(vm.selectedModel)
    $state.transitionTo(
      $state.$current,
      { modelName: vm.selectedModel },
      {
        location: true,
        inherit: true,
        relative: $state.$current,
        notify: false
      }
    )
  }

  $scope.onExport = function onExport () {
    const name = vm.model.model.plural || vm.selectedModel + 's'
    const filter = JSON.stringify($scope.kendoGrid.getFilters())
    $window.location = `./api/${name}/exportTranslations?filter=${filter}`
  }

  $scope.onImport = function onImport () {
    const input = document.createElement('input')
    input.type = 'file'
    input.onchange = async event => {
      $scope.kendoGrid.setLoading(true)
      const name = vm.model.model.plural || vm.selectedModel + 's'
      await UploadService.uploadFile(
        { file: event.target.files[0], container: 'Temp' },
        `./api/${name}/importTranslations`
      )
      $scope.kendoGrid.setLoading(false)
      // refresh data
      const table = $('#translationsTable').data('kendoGrid')
      table.dataSource.read()
    }
    input.click()
  }

  const initScreen = async function initScreen () {
    $scope.title = $rootScope.title = $translate.instant('TRANSLATIONS.TITLE')
    Page.setTitleText($scope.title)
    $scope.PermissionUtils = PermissionUtils

    await renderScreen(vm.selectedModel)
  }

  initScreen()

  $scope.headerOptions = {
    icon: 'icon-file-document-box',
    template: require('app/templates/headers/simple.html'),
    title: $scope.title,
    fabButton: {}
  }
}

module.exports = TranslationsController
