/* global _ */
import { isNullOrUndefined } from 'app/helper'
const UUID = require('uuid')

const getFieldTypeAndSubType = function getFieldTypeAndSubType (field) {
  let type = null
  let subType = null
  switch (field.type) {
    case 'radioMaterial':
      type = 'radio'
      break
    case 'selectWithSearch':
      type = 'select'
      break
    case 'upload':
      type = 'button'
      subType = 'upload'
      break
    case 'input':
      type = field.type
      subType = field.inputType
      break
    default:
      type = field.type
  }
  return { type, subType }
}

const getFieldExtraParams = function getFieldExtraParams (field) {
  let caption = null
  let pattern = null
  let minDate = null
  let maxDate = null
  let min = null
  let max = null
  let runFileParser = false
  let fileParser = null
  let minRequiredRows = 0
  let fieldIds = []
  let direction = 'horizontal'
  let tableId = null
  let lookupField = null
  let resourceId = null
  let shapes = []
  let layers = []
  let tableType = null
  let lookupFieldType = null
  let multiple = false
  let mode = 'full'
  let visionAi = false
  let aiPrompt = ''

  switch (field.type) {
    case 'input':
      pattern = field.pattern || null
      min = !isNullOrUndefined(field.min) && field.min !== '' ? field.min : null
      max = !isNullOrUndefined(field.max) && field.max !== '' ? field.max : null

      break
    case 'radioMaterial':
      if (!field.radioAlign) {
        direction = 'vertical'
      }
      break
    case 'upload':
      caption = field.caption
      runFileParser = field.runFileParser || false
      fileParser = field.fileParser || null
      mode = field.mode || 'full'
      break
    case 'subForm':
      caption = field.caption
      fieldIds = field.fieldIds || []
      minRequiredRows = field.minRequiredRows || 0
      break
    case 'lookupSelect':
      tableType = field.tableType
      lookupFieldType = field.lookupFieldType
      tableId = field.tableId
      lookupField = field.lookupField
      fieldIds = field.fieldIds || []
      break
    case 'datePicker':
    case 'dateTimePicker':
      minDate = field.minDate || null
      maxDate = field.maxDate || null
      break
    case 'imageBasedForm':
      fieldIds = field.fieldIds || []
      resourceId = field.resourceId || null
      shapes = field.shapes || []
      layers = field.layers || null
      break
    case 'selectUser':
      multiple = field.multiple || false
      break
  }
  visionAi = field?.visionAi || false
  aiPrompt = field?.aiPrompt || ''
  return {
    direction,
    caption,
    pattern,
    minDate,
    maxDate,
    min,
    max,
    fieldIds,
    tableId,
    tableType,
    lookupFieldType,
    lookupField,
    runFileParser,
    fileParser,
    minRequiredRows,
    resourceId,
    shapes,
    layers,
    multiple,
    mode,
    visionAi,
    aiPrompt
  }
}

/**
 * Convert object from custom-field dialog structure to database structure
 * @param {object} item custom field object
 */
export const convertFromCustomFieldDialogObject =
  function convertFromCustomFieldDialogObject (item) {
    const obj = {}
    obj.title = item.title
    const { type, subType } = getFieldTypeAndSubType(item)
    obj.type = type
    obj.subtype = subType
    const {
      direction,
      caption,
      pattern,
      minDate,
      maxDate,
      min,
      max,
      fieldIds,
      tableId,
      tableType,
      lookupFieldType,
      lookupField,
      runFileParser,
      fileParser,
      minRequiredRows,
      resourceId,
      shapes,
      layers,
      multiple,
      mode,
      visionAi,
      aiPrompt
    } = getFieldExtraParams(item)
    obj.direction = direction
    obj.caption = caption
    obj.pattern = pattern
    obj.minDate = minDate
    obj.maxDate = maxDate
    obj.min = min
    obj.max = max
    obj.fieldIds = fieldIds
    obj.tableId = tableId
    obj.tableType = tableType
    obj.lookupFieldType = lookupFieldType
    obj.lookupField = lookupField
    obj.runFileParser = runFileParser
    obj.fileParser = fileParser
    obj.minRequiredRows = minRequiredRows
    obj.helpText = item.helpText
    obj.helpImage = item.helpImage || ''
    obj.required = item.required || false
    obj.placeholder = item.placeholder || ''
    obj.multiple = multiple || false
    obj.mode = mode
    obj.visionAi = visionAi || false
    obj.aiPrompt = aiPrompt || ''
    if (resourceId) {
      obj.images = [
        {
          id: item.imageId || UUID(),
          resourceId,
          layers,
          shapes
        }
      ]
    }
    if (item.options && item.options.length > 0) {
      const options = []
      item.options.forEach(option => {
        options.push({
          value: option.name,
          resourceId: option.resourceId
        })
      })
      obj.options = options
    } else {
      obj.options = []
    }
    if (!isNullOrUndefined(item.defaultValue) && item.defaultValue !== '') {
      switch (obj.type) {
        case 'input':
        case 'textarea':
        case 'datePicker':
        case 'dateTimePicker':
          if (obj.type === 'input' && obj.subtype === 'number') {
            item.defaultValue = parseFloat(item.defaultValue)
          }
          obj.defaultValue = item.defaultValue
          break
        case 'select':
        case 'radio':
          {
            const defaultOption = item.options.find(
              option =>
                option.id === item.defaultValue ||
                option.value === item.defaultValue
            )
            if (defaultOption && defaultOption.name) {
              obj.defaultValue = defaultOption.name
            }
          }
          break
        default:
          break
      }
    } else {
      obj.defaultValue = null
    }
    if (item.translations) {
      obj.translations = _.cloneDeep(item.translations)
      Object.keys(item.translations).forEach(language => {
        const currentLanguage = item.translations[language]
        if (currentLanguage.options && currentLanguage.options.length > 0) {
          obj.translations[language].options = []
          currentLanguage.options.forEach(option => {
            const value = option.name || option.value
            const translateOption = {
              value
            }
            if (option.resourceId) {
              translateOption.resourceId = option.resourceId
            }
            obj.translations[language].options.push(translateOption)
          })
        }
      })
    }
    return obj
  }
