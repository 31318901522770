require('./comment-list.scss')
/** @ngInject */
const commentDraft = {
  bindings: {
    comments: '<',
    parentId: '=',
    commentModel: '='
  },
  template: require('./comment-list.html'),
  controller: function (DateTimeFormatService) {
    const $ctrl = this
    $ctrl.editing = false
    $ctrl.cancel = () => {
      $ctrl.editing = false
    }
    $ctrl.save = () => {
      alert($ctrl.content)
    }
    $ctrl.onDelete = id => {
      _.remove($ctrl.comments, { id: id })
    }
    $ctrl.edit = () => {
      $ctrl.editing = true
    }
    this.$onInit = async function () {}

    this.$postLink = function () {}

    this.$onChanges = async function (changes) {
      if (changes.date) {
        $ctrl.displayDate = DateTimeFormatService.formatDateTime(
          $ctrl.date,
          'dateTime'
        )
      }
      if (changes.comments && $ctrl.comments) {
        // Order by new comment to old comment
        $ctrl.comments = $ctrl.comments.sort((a, b) => {
          return new Date(b.created) - new Date(a.created)
        })
      }
    }

    this.$onDestroy = function () {}
  }
}
module.exports = commentDraft
