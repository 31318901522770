/** @ngInject */
module.exports = function config (
  $stateProvider,
  msApiProvider,
  msNavigationServiceProvider
) {
  // State

  $stateProvider
    .state('app.menus', {
      abstract: true,
      url: '/menus',
      lazyLoad: $transition$ => {
        const $ocLazyLoad = $transition$.injector().get('$ocLazyLoad')
        return import(
          /* webpackChunkName: "menu-management.module" */ './menu-management.module.js'
        )
          .then(mod => {
            return $ocLazyLoad.load(mod.default)
          })
          .catch(err => {
            throw new Error('Ooops, something went wrong, ' + err)
          })
      }
    })
    .state('app.menus.list', {
      url: '/list',
      views: {
        'content@app': {
          template: require('./menu-list/menu-list.html'),
          controller: 'MenusListController'
        }
      },
      resolve: {
        ResolvedMenus: function (Menus, $rootScope, MultiTranslateService) {
          return Menus.find({}).$promise.then(res =>
            res.map(menu =>
              MultiTranslateService.getForView(
                Menus,
                $rootScope.currentLang,
                menu
              )
            )
          )
        },
        ResolvedBaseMenus: function (msNavigationService) {
          return msNavigationService.getFlatNavigation().map(x => ({
            title: x.title,
            id: x._path,
            roles: x.roles
          }))
        }
      }
    })
    .state('app.menus.new', {
      url: '/new',
      views: {
        'content@app': {
          template: require('./menu-new-edit/menu-new-edit.html'),
          controller: 'NewEditMenuController'
        }
      },

      lazyLoad: () => {
        return import(
          /* webpackChunkName: "icons" */ '../../core/assets/icons/selection.json'
        )
          .then(mod => {
            global.Icons = mod.default
          })
          .catch(err => {
            throw new Error('Ooops, something went wrong, ' + err)
          })
      },
      resolve: {
        ResolvedMenu: function () {
          return null
        },
        ResolvedPages: function (Pages) {
          return Pages.find({}).$promise
        },
        ResolvedRoles: function (Role) {
          return Role.find({}).$promise
        },
        ResolvedMenus: function (msNavigationService) {
          return msNavigationService.getFlatNavigation().map(x => ({
            title: x.title,
            id: x._path
          }))
        }
      }
    })
    .state('app.menus.edit', {
      url: '/edit/:id/:base',
      views: {
        'content@app': {
          template: require('./menu-new-edit/menu-new-edit.html'),
          controller: 'NewEditMenuController'
        }
      },
      lazyLoad: () => {
        return import(
          /* webpackChunkName: "icons" */ '../../core/assets/icons/selection.json'
        )
          .then(mod => {
            global.Icons = mod.default
          })
          .catch(err => {
            throw new Error('Ooops, something went wrong, ' + err)
          })
      },
      resolve: {
        ResolvedMenu: function (Menus, $stateParams) {
          const where =
            $stateParams.base !== 'true'
              ? { id: $stateParams.id }
              : { baseId: $stateParams.id }
          return Menus.find({ filter: { where: where } }).$promise.then(menu =>
            menu && menu[0] ? menu[0] : null
          )
        },
        ResolvedPages: function (Pages) {
          return Pages.find({}).$promise
        },
        ResolvedRoles: function (Role) {
          return Role.find({}).$promise
        },
        ResolvedMenus: function (msNavigationService) {
          return msNavigationService.getFlatNavigation().map(x => ({
            title: x.title,
            id: x._path,
            roles: x.roles,
            rolesBlocklist: x.rolesBlocklist,
            hidden: x.hidden,
            icon: x.icon,
            weight: x.weight
          }))
        }
      }
    })
}
