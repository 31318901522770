require('./upload-menu.scss')
/** @ngInject */
function UploadMenuDialogController ($scope, $translate, $mdDialog) {
  $scope.selectMethod = function selectMethod (method = 'files') {
    $mdDialog.hide({ method })
  }

  /**
   * Initialization function
   */
  const initDialog = async function initDialog () {
    $scope.options = [
      {
        code: 'files',
        icon: 'icon-file-upload',
        title: $translate.instant('FM.FILES')
      },
      {
        code: 'camera',
        icon: 'icon-camera',
        title: $translate.instant('FM.CAMERA')
      }
    ]
    if (navigator?.mediaDevices?.getDisplayMedia) {
      $scope.options.push({
        code: 'screenshot',
        icon: 'icon-monitor-screenshot',
        title: $translate.instant('FM.SCREENSHOT')
      })
    }
  }

  initDialog()
}
module.exports = UploadMenuDialogController
