require('./comment.scss')
/** @ngInject */
const commentDraft = {
  bindings: {
    comment: '<',
    parentId: '=',
    commentModel: '=',
    onDelete: '='
  },
  template: require('./comment.html'),
  controller: function (
    DateTimeFormatService,
    PermissionUtils,
    $rootScope,
    DialogService,
    $mdToast,
    $translate
  ) {
    const $ctrl = this
    $ctrl.delete = () => {
      DialogService.deleteDialog(
        $translate.instant('COMMON.DELETE_MESSAGE')
      ).then(() => {
        $ctrl.commentModel
          .destroyById({
            id: $ctrl.parentId,
            fk: $ctrl.comment.id
          })
          .$promise.then(() => {
            console.log($ctrl.comment)
            $ctrl.onDelete($ctrl.comment.id)
          })
          .catch(error => {
            console.log(error)
            const mdToast = $mdToast.nextplus({
              position: $rootScope.toastLocation,
              parent: 'body',
              theme: 'error-toast',
              hideDelay: 6000
            })
            $mdToast.updateTextContent(
              error.data.error.message || $translate.instant('ERRORS.UNKNOWN')
            )
            $mdToast.show(mdToast)
          })
      })
    }
    $ctrl.cancel = () => {
      $ctrl.editing = false
    }
    $ctrl.save = () => {
      // Update comment by id
      $ctrl.commentModel
        .updateById(
          {
            id: $ctrl.parentId,
            fk: $ctrl.comment.id
          },
          { content: $ctrl.content }
        )
        .$promise.then(() => {
          $ctrl.comment.content = $ctrl.content
          $ctrl.content = ''
          $ctrl.editing = false
        })
        .catch(error => {
          console.log(error)
          const mdToast = $mdToast.nextplus({
            position: $rootScope.toastLocation,
            parent: 'body',
            theme: 'error-toast',
            hideDelay: 6000
          })
          $mdToast.updateTextContent(
            error.data.error.message || $translate.instant('ERRORS.UNKNOWN')
          )
          $mdToast.show(mdToast)
        })
    }

    $ctrl.edit = () => {
      $ctrl.content = $ctrl.comment.content
      $ctrl.editing = true
    }
    this.$onInit = async function () {
      $ctrl.editing = false
    }

    this.$postLink = function () {}

    this.$onChanges = async function (changes) {
      if (changes.comment) {
        $ctrl.createdDisplay = DateTimeFormatService.formatDateTime(
          $ctrl.comment.created,
          'dateTime'
        )
        $ctrl.modifiedDisplay = DateTimeFormatService.formatDateTime(
          $ctrl.comment.modified,
          'dateTime'
        )
        $ctrl.edited = $ctrl.comment.created !== $ctrl.comment.modified
        $ctrl.canEdit = $ctrl.canDelete =
          $ctrl.comment.createdBy === $rootScope.currentUser.id &&
          PermissionUtils.isPermit(
            'FormData',
            '__updateById__comments',
            'ALLOW',
            $ctrl.comment.createdBy
          )
        $ctrl.canDelete =
          $ctrl.comment.createdBy === $rootScope.currentUser.id &&
          PermissionUtils.isPermit(
            'FormData',
            '__destroyById__comments',
            'ALLOW',
            $ctrl.comment.createdBy
          )
      }
    }

    this.$onDestroy = function () {}
  }
}
module.exports = commentDraft
